export const BOAT_API_ROUTES = {
  GET_ALL_BOATS: "/boat/",
  CREATE: "/boat/create",
  GET_BOAT_MODELS: "/boat/model",
  GET_BOAT_BRANDS: "/boat/brand",
  DELETE_BOAT: "/boat/delete",
  CHECK_BOAT_PRIVATE: "/boat/check/private",
  SWITCH: "/boat/switch",
  UPLOAD_BOAT_IMAGES: "/boat/upload",
  GET_BOAT_IMAGES: "/boat/images",
  DELETE_BOAT_IMAGE: "/boat/image/delete",
  FIND_BOAT: "/boat/find",
  EDIT_BOAT: "/boat/edit"
}
