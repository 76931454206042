import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <>
      <h1>Page Not Found</h1>
      <Button onClick={() => navigate("/dashboard")}>Go To Dashboard</Button>
    </>
  )
}

export default PageNotFound
