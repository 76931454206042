export const PLATE_NUMBER_ROUTES = {
  CREATE_PLATE_NUMBER: "/plate-number/create",
  GET_PLATE_NUMBER_BY_ID: "/plate-number/id",
  EDIT_PLATE_NUMBER: "/plate-number/edit",
  CHECK_PRIVATE: "/plate-number/private",
  UPLOAD_IMAGE: "/plate-number/image/upload",
  DELETE_IMAGE: "/plate-number/image/delete",
  GET_PLATE_NUMBERS: "/plate-number/",
  DELETE_PLATE_NUMBER: "/plate-number/delete"
}
