module.exports.boat_engine = [
  "Outboard engines",
  "Inboard engines",
  "Stern drive engines",
  "Jet propulsion engines",
  "Electric engines",
  "Hybrid engines",
  "Steam engines ",
  "Gas turbine engines ",
  "Diesel engines ",
  "Stirling engines ",
  "Rotary engines ",
  "Steam turbine engines",
  ""
]
