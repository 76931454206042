import { Col, Row } from "react-bootstrap"
function CarDetails({ carDetails }) {
  return (
    <>
      <Row className='mt-3'>
        <Col xs={12}>
          <h6>Description</h6>
          <p>{carDetails.description}</p>
        </Col>
        <Col xs={12}>
          <h6>Badge</h6>
          <p>{carDetails?.badge ? carDetails?.badge : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Internal Id</h6>
          <p>{carDetails.internalId ? carDetails.internalId : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Chassis Number (VIN)</h6>
          <p>{carDetails.chassisNumber ? carDetails.chassisNumber : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Engine Number</h6>
          <p>{carDetails.engineNumber ? carDetails.engineNumber : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Brand</h6>
          <p>{carDetails.brand ? carDetails.brand : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Model</h6>
          <p>{carDetails.model ? carDetails.model : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Year</h6>
          <p>{carDetails.year ? carDetails.year : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Price ($)</h6>
          <p>{carDetails.price ? carDetails.price : "N/A"}</p>
        </Col>

        <Col md={4} xs={6}>
          <h6>Price Status</h6>
          <p>{carDetails.priceStatus ? carDetails.priceStatus : "N/A"}</p>
        </Col>

        <Col md={4} xs={6}>
          <h6>Type</h6>
          <p>{carDetails.type ? carDetails.type : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Body</h6>
          <p>{carDetails.body ? carDetails.body : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Exterior Color</h6>
          <p>{carDetails.exteriorColor ? carDetails.exteriorColor : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Interior Color</h6>
          <p>{carDetails.interiorColor ? carDetails.interiorColor : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Gears</h6>
          <p>{carDetails.gears ? carDetails.gears : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Doors</h6>
          <p>{carDetails.doors ? carDetails.doors : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Cylinders</h6>
          <p>{carDetails.cylinders ? carDetails.cylinders : "N/A"}</p>
        </Col>

        <Col md={4} xs={6}>
          <h6>Horsepower (HP)</h6>
          <p>{carDetails.horsepower ? carDetails.horsepower : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Engine Size (L)</h6>
          <p>{carDetails.engineSize ? carDetails.engineSize : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Transmission</h6>
          <p>{carDetails.transmission ? carDetails.transmission : "N/A"}</p>
        </Col>

        <Col md={4} xs={6}>
          <h6>Legal Papers</h6>
          <p>{carDetails.legalPapers ? carDetails.legalPapers : "N/A"}</p>
        </Col>

        <Col md={4} xs={6}>
          <h6>Odometer</h6>
          <p>{carDetails.odometer ? carDetails.odometer : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Steering Wheel</h6>
          <p>{carDetails.steeringWheel ? carDetails.steeringWheel : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Driving Wheel</h6>
          <p>{carDetails.driveWheels ? carDetails.driveWheels : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Fuel Type</h6>
          <p>{carDetails.fuelType ? carDetails.fuelType : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>City</h6>
          <p>{carDetails.city ? carDetails.city : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Country</h6>
          <p>{carDetails.country ? carDetails.country : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Address</h6>
          <p>{carDetails.address ? carDetails.address : "N/A"}</p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Rent Starting Price($)</h6>
          <p>
            {carDetails.rentStartingPrice
              ? carDetails.rentStartingPrice
              : "N/A"}
          </p>
        </Col>
        <Col md={4} xs={6}>
          <h6>Status</h6>
          <p>{carDetails.status ? carDetails.status : "N/A"}</p>
        </Col>
      </Row>
    </>
  )
}

export default CarDetails
