import Choose from "../../components/controlStatements/Choose"
import When from "../../components/controlStatements/When"
import Otherwise from "../../components/controlStatements/Otherwise"

import { useEffect } from "react"
import Car from "../../components/Car/Car"
import { useState } from "react"
import carApi from "../../api/carApi"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import SearchBar from "../../components/SearchBar/SearchBar"

import searchApi from "../../api/searchApi"
import {
  Col,
  Pagination,
  Row,
  Form,
  ListGroup,
  Card,
  Spinner
} from "react-bootstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
function CarList() {
  const [cars, setCars] = useState([])
  const [loading, setLoading] = useState({ state: false, message: "" })
  const [pages, setPages] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [searchText, setSearchText] = useState("")

  const getCars = async () => {
    onSearchSubmit("")
  }

  const deleteCar = async (id) => {
    try {
      setLoading({ state: true, message: "Deleting car..." })
      await carApi.deleteCar(id)
      notify.success("Car deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "" })
      getCars()
    }
  }

  const [type, setType] = useState("")

  const searchPagination = async (term, page = 1) => {
    try {
      setLoading(true)

      const data = await searchApi.getSearchResults({
        search: term,
        page,
        type,
        confirmed: false
      })
      setCars(data.cars)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const onSearchSubmit = async (term) => {
    try {
      setLoading({ state: true, message: "Loading cars..." })

      const data = await searchApi.getSearchResults({
        search: term,
        type,
        confirmed: false
      })
      setCars(data.cars)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "" })
    }
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(cars)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setCars(items)

    setLoading({ state: true, message: "Loading cars..." })
    await carApi.updatePriority({ cars: items, type })
    setLoading({ state: false, message: "" })
  }

  useEffect(() => {
    getCars()
  }, [type])

  const renderedCars = cars.map((c) => (
    <Col lg={6} md={12} sm={12}>
      <Car getCars={getCars} key={c._id} onDelete={deleteCar} carDetails={c} />
    </Col>
  ))

  return (
    <>
      <Row>
        <Col xs={12}>
          <SearchBar
            placeholder='Search for cars...'
            onSearchSubmit={(term) => {
              onSearchSubmit(term)
              setSearchText(term)
            }}
          />
        </Col>
      </Row>

      <Choose>
        <When condition={loading.state}>
          <h2 style={{ textAlign: "center" }}>{loading.message}</h2>
        </When>
        <Otherwise>
          <Choose>
            <When condition={cars.length == 0}>No cars found!</When>
            <Otherwise>
              <Row className='w-100'>{renderedCars}</Row>
              <Pagination className='mt-5'>
                {/* <Pagination.Item>Prev</Pagination.Item> */}
                {[...Array(pages)].map((p, index) => {
                  return (
                    <Pagination.Item
                      onClick={() => searchPagination(searchText, index + 1)}
                      active={currentPage === index + 1}
                    >
                      {index + 1}
                    </Pagination.Item>
                  )
                })}
              </Pagination>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </>
  )
}

export default CarList
