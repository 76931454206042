import axios from "axios"
import { API_URLS } from "../constants/routes"

const createModel = async (data) => {
  const res = await axios.post(API_URLS.CREATE_MODEL, data)
  return res.data
}

const deleteModel = async (data) => {
  const res = await axios.post(API_URLS.DELETE_MODEL, data)
  return res.data
}

const modelApi = {
  createModel,
  deleteModel
}

export default modelApi
