module.exports.purpose_category = {
  Fishing: [
    "Sport utility",
    "Sportfisherman",
    "Inflatable boat",
    "Houseboat",
    "Flybridge yacht",
    "Express cruiser",
    "Dual console",
    "Deck boat",
    "Cuddy cabin",
    "Bowrider",
    "Trawler",
    "Sportfishing yacht",
    "Skiff",
    "Pontoon boat",
    "Bay boat",
    "Walkaround",
    "Flats boat",
    "Jon boat",
    "Center console",
    "Bass boat"
  ],
  "Shallow water": [
    "Hovercraft",
    "Gheenoe",
    "Carolina Skiff",
    "Airboat",
    "Stand-up paddleboard",
    "Jet ski",
    "Tri-hull",
    "Catamaran",
    "Dinghy",
    "Water scooter",
    "Swamp boat",
    "Jon boat",
    "Airboat",
    "Paddleboard",
    "Kayak",
    "Canoe",
    "Jon boat",
    "Bay boat",
    "Skiff",
    "Flats boat"
  ],
  "Offshore fishing": [
    "Bluewater fishing boat",
    "Offshore fishing boat",
    "Yacht fisherman",
    "Sportfisherman",
    "Pilothouse",
    "Motor yacht",
    "Inboard diesel",
    "Downeast yacht",
    "Cuddy cabin",
    "Sailfish",
    "Catamaran",
    "Trawler",
    "Cabin cruiser",
    "Saltwater fishing boat",
    "Convertible yacht",
    "Flybridge yacht",
    "Express cruiser",
    "Walkaround",
    "Center console",
    "Sportfishing yacht"
  ],
  "Ocean cruising": [
    "Luxury yacht",
    "Cruiser",
    "Sloop",
    "Mega yacht",
    "Motorsailer",
    "Trimaran",
    "Tugboat",
    "Schooner",
    "Ketch",
    "Classic yacht",
    "Downeast yacht",
    "Pilothouse",
    "Explorer yacht",
    "Long-range cruiser",
    "Passagemaker",
    "Expedition yacht",
    "Catamaran",
    "Motor yacht",
    "Trawler",
    "Sailboat"
  ],
  Tubing: [
    "Skiff",
    "Jon boat",
    "Canoe",
    "Stand-up paddleboard",
    "Kayak",
    "Banana boat",
    "Airboat",
    "Pontoon boat",
    "Bass boat",
    "Trawler",
    "Dinghy",
    "Center console",
    "Inflatable boat",
    "Personal watercraft (PWC)",
    "Jet boat",
    "Runabout",
    "Wakeboard boat",
    "Ski boat",
    "Deck boat",
    "Bowrider"
  ],
  "Water skiing": [
    "Deck boat",
    "Cuddy cabin",
    "Skiff",
    "Jon boat",
    "Canoe",
    "Stand-up paddleboard",
    "Kayak",
    "Banana boat",
    "Airboat",
    "Pontoon boat",
    "Bass boat",
    "Trawler",
    "Dinghy",
    "Center console",
    "Inflatable boat",
    "Personal watercraft (PWC)",
    "Runabout",
    "Bowrider",
    "Wakeboard boat",
    "Ski boat"
  ],
  "Scuba diving": [
    "Expedition yacht",
    "Mega yacht",
    "Yacht",
    "Ketch",
    "Pilothouse",
    "Cruiser",
    "Pontoon boat",
    "Jet boat",
    "Sportfisherman",
    "Runabout",
    "Bowrider",
    "Cabin cruiser",
    "Trimaran",
    "Inflatable boat",
    "Sportfishing yacht",
    "Sailboat",
    "Catamaran",
    "Trawler",
    "Dive boat",
    "Center console"
  ],
  Rivers: [
    "Sport utility",
    "Bass boat",
    "Dinghy",
    "Runabout",
    "Bowrider",
    "Cuddy cabin",
    "Cabin cruiser",
    "Tugboat",
    "Skiff",
    "Pontoon boat",
    "Houseboat",
    "Flat-bottom boat",
    "Jet boat",
    "Raft",
    "Paddleboard",
    "Inflatable boat",
    "Airboat",
    "Kayak",
    "Canoe",
    "Jon boat"
  ],
  Lakes: [
    "Jon boat",
    "Bass boat",
    "Inflatable boat",
    "Sailing dinghy",
    "Houseboat",
    "Dinghy",
    "Pedal boat",
    "Kayak",
    "Canoe",
    "Center console",
    "Personal watercraft (PWC)",
    "Wakeboard boat",
    "Cuddy cabin",
    "Fishing boat",
    "Runabout",
    "Deck boat",
    "Ski boat",
    "Jet boat",
    "Pontoon",
    "Bowrider"
  ],
  "To live in": [
    "Pontoon houseboat",
    "Luxury yacht",
    "Tugboat",
    "Coastal cruiser",
    "Dutch barge",
    "Converted ferry",
    "Floating home",
    "Custom houseboat",
    "Riverboat",
    "Power catamaran",
    "Sailboat",
    "Cruiser",
    "Barge",
    "Narrowboat",
    "Liveaboard",
    "Catamaran",
    "Trawler",
    "Motor yacht",
    "Cabin cruiser",
    "Houseboat"
  ],
  Wakeboarding: [
    "Hydroslide boat",
    "Barefoot boat",
    "Slalom boat",
    "Air chair boat",
    "Hydrofoil boat",
    "High-performance boat",
    "Fishing boat",
    "Pontoon",
    "Center console",
    "Personal watercraft (PWC)",
    "Wake surf boat",
    "Cuddy cabin",
    "Deck boat",
    "Runabout",
    "Jet boat",
    "Bowrider",
    "V-drive boat",
    "Inboard boat",
    "Ski boat",
    "Wakeboard boat"
  ]
}
