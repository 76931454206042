import { Container } from "react-bootstrap"
import Header from "../layouts/Header"
import SellerCarList from "./SellerCarList"

function SellerCars() {
  return (
    <>
      <Header />
      <Container>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <SellerCarList />
        </div>
      </Container>
    </>
  )
}

export default SellerCars
