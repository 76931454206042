let brands = [
  "All American Racers",
  "AM General",
  "Anteros Coachworks",
  "Aptera",
  "Arcimoto",
  "Autocar",
  "Bremach",
  "Buick",
  "Cadillac",
  "Callaway",
  "Canoo",
  "Chevrolet",
  "Dacia",
  "Seat",
  "Daewoo",
  "Chrysler",
  "Detroit Electric",
  "Dodge",
  "E-Z-GO",
  "Falcon Motorsports",
  "Faraday",
  "FCA",
  "Fisker",
  "Ford",
  "Freightliner",
  "Gillig",
  "GM",
  "Google",
  "GEM",
  "GMC",
  "Hennessey",
  "Jeep",
  "Karma",
  "Kenworth",
  "Lincoln",
  "Lordstown Motors",
  "Lucid Motors",
  "Mack",
  "MCI",
  "Navistar",
  "Nikola Motor",
  "Oshkosh",
  "Panoz",
  "Peterbilt",
  "Pierce",
  "Polaris",
  "Proterra",
  "Ram",
  "RAESR",
  "Rezvani Motors",
  "Rivian",
  "Rossion",
  "Saleen",
  "SCG",
  "Shelby",
  "SRT",
  "SSC",
  "Tesla",
  "Thomas Built Buses",
  "Trion Supercars",
  "VLF Automotive",
  "Western Star",
  "Workhorse",
  "Adams-Farwell",
  "Alco",
  "AMC",
  "Amplex",
  "Apollo",
  "Apperson",
  "Aptera",
  "ArBenz",
  "Auburn",
  "Avanti Motor Company",
  "Baker Electric",
  "Bates",
  "Brush",
  "Cartercar",
  "Chalmers",
  "Chandler",
  "Chaparral Cars",
  "Checker",
  "Clénet",
  "Cole",
  "Columbia",
  "Continental",
  "Cord",
  "Crawford",
  "Crosley",
  "Cutting",
  "Daniels",
  "Davis",
  "Devon",
  "DeLorean",
  "DeSoto",
  "Diamond Reo Trucks",
  "Doble",
  "Dorris",
  "Dort",
  "Duesenberg",
  "Durant",
  "Dymaxion",
  "Eagle",
  "Edsel",
  "Elgin",
  "Essex",
  "Excalibur",
  "Flint",
  "Frazer",
  "Frontenac",
  "Gardner",
  "Geo",
  "Graham-Paige",
  "Grumman",
  "Haynes",
  "Hudson",
  "Hummer",
  "Hupmobile",
  "Hupp-Yeats",
  "Imperial",
  "International Harvester",
  "Inter-State",
  "Jackson",
  "Jeffery",
  "Kaiser",
  "King",
  "Kissel",
  "Kline Kar",
  "Knox",
  "LaFayette",
  "Laforza",
  "Lambert",
  "LaSalle",
  "Lexington",
  "Local",
  "Locomobile",
  "Lozier",
  "Lyons-Knight",
  "Marmon",
  "Marmon",
  "Marmon-Herrington",
  "Maxwell",
  "McFarlan",
  "Mercury",
  "Mitchell",
  "Monarch",
  "Monroe",
  "Moon",
  "Mosler",
  "Nash",
  "National",
  "Nyberg",
  "Oakland",
  "Oldsmobile",
  "Overland",
  "Packard",
  "Peerless",
  "Pierce-Arrow",
  "Pilot",
  "Plymouth",
  "Pontiac",
  "Pope-Toledo",
  "Pope-Tribune",
  "Premier",
  "Pungs Finch",
  "Rambler",
  "Rauch and Lang",
  "Regal",
  "REO",
  "Safari Coach",
  "Saturn",
  "Scion",
  "Scripps-Booth",
  "Simplex",
  "Speedwell",
  "Stanley",
  "Star",
  "Stearns-Knight",
  "Sterling",
  "Sterling Trucks",
  "Stevens-Duryea",
  "Stoddard-Dayton",
  "Studebaker",
  "Stutz",
  "TH!NK",
  "Thomas",
  "Tucker",
  "Vector",
  "VPG",
  "Velie",
  "Westcott",
  "White",
  "Willys",
  "Zimmer",
  "BAIC Group",
  "BYD",
  "Chang'an Motors",
  "Chery (Qirui)",
  "Dongfeng",
  "First Automobile Works",
  "Fudi",
  "Geely (Jili)",
  "Great Wall Motors",
  "GAC Group",
  "Hawtai (Huatai)",
  "Huachen (Brilliance)",
  "Hwanghai",
  "JAC Motors",
  "Jiangling",
  "Jonway",
  "Landwind",
  "Lifan",
  "Nio",
  "Qoros",
  "SAIC Motor",
  "Shaanxi Automobile Group",
  "Sichuan Tengzhong",
  "Soueast Motors",
  "Xinkai",
  "XPeng",
  "Yema Auto",
  "Yutong",
  "ZX Auto",
  "Dadi",
  "Baolong",
  "Polarsun Automobile",
  "Senova",
  "Shuanghuan",
  "Youngman",
  "Zotye",
  "Avia",
  "Jawa",
  "Kaipan",
  "Karosa",
  "MTX / Metalex",
  "MW Motors",
  "Praga",
  "Škoda",
  "Tatra",
  "Aero",
  "Aspa",
  "Gatter",
  "LIAZ",
  "Stelka",
  "Velorex",
  "Walter",
  "Wikov",
  "Zbrojovka Brno",
  "Derwayz",
  "GAZ",
  "Lada",
  "UAZ",
  "ZIL",
  "Izh",
  "Moskvitch",
  "Russo-Balt",
  "KamAZ",
  "Marussia",
  "AC",
  "Alexander Dennis",
  "Apex Motors",
  "Ariel",
  "Aston Martin",
  "Atalanta Motors",
  "BAC",
  "Bentley",
  "Bowler",
  "Caterham",
  "David Brown Automotive",
  "Ginetta",
  "GKD",
  "Grinnall",
  "Healey",
  "Jaguar",
  "Lagonda",
  "Larmar",
  "Land Rover",
  "Lister",
  "Lotus",
  "McLaren",
  "MG",
  "Mini",
  "Modec",
  "Morgan",
  "Noble",
  "Radical Sportscars",
  "Rolls Royce",
  "Ronart Cars",
  "TVR",
  "Ultima Sports",
  "Vauxhall",
  "Westfield",
  "Adam's Brothers",
  "AEC",
  "Allard",
  "Alvis",
  "Austin",
  "Austin-Healey",
  "British Leyland",
  "Bristol",
  "Daimler",
  "Donald Healey",
  "Elva",
  "Gordon Keeble",
  "Hillman",
  "Humber",
  "Jensen",
  "Jowett",
  "Lanchester",
  "Marcos",
  "Morris",
  "Panther Westwinds",
  "Reliant Motors",
  "Riley",
  "Rootes",
  "Rover",
  "Sharps Commercials Ltd",
  "Singer",
  "Standard",
  "Sunbeam",
  "Trident Cars",
  "Triumph",
  "Trojan",
  "Wolseley",
  "Aixam",
  "Alpine",
  "Auverland",
  "Bolloré",
  "Bugatti",
  "Citroen",
  "Exagon",
  "Hommell",
  "Ligier",
  "Microcar",
  "Mega",
  "Peugeot",
  "PGO",
  "Renault",
  "Venturi",
  "Aérocarène",
  "Bellanger",
  "Berliet",
  "Chenard-Walcker",
  "Decauville",
  "De Dion-Bouton",
  "Delage",
  "Delahaye",
  "Delaunay-Belleville",
  "Facel Vega",
  "Helicron",
  "Hommell",
  "Lorraine-Dietrich",
  "Matra",
  "Mors",
  "Panhard",
  "Rochet-Schneider",
  "Simca",
  "Talbot",
  "Talbot-Lago",
  "Alpina",
  "Apollo",
  "Artega",
  "Audi",
  "Bitter",
  "BMW",
  "Daimler Truck",
  "Fuso",
  "Isdera",
  "Lotec",
  "MAN",
  "Mercedes",
  "Multicar",
  "Neoplan",
  "Opel",
  "Ruf",
  "Porsche",
  "Setra",
  "Smart",
  "Volkswagen",
  "Wiesmann",
  "Adler",
  "AGA",
  "Auto Union",
  "AWS",
  "AWZ",
  "Barkas",
  "Borgward",
  "Brennabor",
  "Büssing",
  "Dauer",
  "DKW",
  "Glas",
  "Goliath",
  "Gumpert",
  "Hanomag",
  "Hansa",
  "Heinkel",
  "Horch",
  "Karmann",
  "Lloyd",
  "Maybach",
  "Melkus",
  "Messerschmitt",
  "NAG",
  "NSU",
  "Robur",
  "Simson",
  "Stoewer",
  "Tempo",
  "Trabant",
  "Wanderer",
  "Wartburg",
  "Zundapp",
  "Abarth",
  "Alfa Romeo",
  "Bremach",
  "Casalini",
  "Covini",
  "CTS",
  "DR Motor",
  "Ducati",
  "Ferrari",
  "Fiat",
  "Iso",
  "Italdesign",
  "Iveco",
  "Lancia",
  "Lamborghini",
  "Maserati",
  "Mazzanti",
  "Minardi",
  "Pagani",
  "Piaggio",
  "Pininfarina",
  "Vespa",
  "Zagato",
  "APIS",
  "Ansaldo",
  "Aquila",
  "A.S.A.",
  "A.T.S.",
  "Autobianchi",
  "Aurea",
  "Bandini",
  "Bertone",
  "Bizzarrini",
  "Ceirano",
  "Chiribiri",
  "Cisitalia",
  "Cizeta",
  "De Tomaso",
  "Diatto",
  "FCA",
  "F.L.A.G.",
  "Fornasari",
  "Innocenti",
  "Intermeccanica",
  "Iso Milano",
  "Isotta Fraschini",
  "Itala",
  "Junior",
  "Lambretta",
  "LMX",
  "Marca-Tre-Spade",
  "Moretti",
  "Nardi",
  "O.M.",
  "O.S.C.A.",
  "Qvale",
  "Scuderia Ferrari",
  "Rapid",
  "S.C.A.T.",
  "Siata",
  "S.P.A.",
  "Stanguellini",
  "Storero",
  "Vignale",
  "Volugrafo",
  "Zust",
  "Acura",
  "Aspark",
  "Autobacs Seven",
  "Daihatsu",
  "Dome",
  "GLM",
  "Hino",
  "Honda",
  "Infiniti",
  "Isuzu",
  "Kawasaki",
  "Lexus",
  "Mazda",
  "Mitsubishi Motors",
  "Mitsubishi Fuso",
  "Mitsubishi",
  "Mitsuoka",
  "Nissan",
  "Subaru",
  "Suzuki",
  "Tommykaira",
  "Toyota",
  "UD Trucks",
  "Yamaha",
  "Koenigsegg",
  "NEVS",
  "Polestar",
  "Scania",
  "Uniti",
  "Volvo Buses",
  "Volvo Cars",
  "Volvo Trucks",
  "Volvo",
  "Josse Car",
  "Saab"
]

brands = brands.sort()

module.exports = brands
