import axios from "axios"
import { API_URLS } from "../constants/routes"

const getDocuments = async (data = {}) => {
  const res = await axios.post(API_URLS.GET_DOCUMENTS, {})
  return res.data
}
const getDocument = async (data = {}) => {
  const res = await axios.post(API_URLS.GET_DOCUMENT, data)
  return res.data
}
const createDocument = async (data = {}) => {
  const res = await axios.post(API_URLS.CREATE_DOCUMENT, data)
  return res.data
}

const uploadImage = async (data = {}) => {
  const { image, documentId } = data
  const bodyFormData = new FormData()
  bodyFormData.append("image", image)
  bodyFormData.append("documentId", documentId)
  const res = await axios.post(API_URLS.CREATE_DOCUMENT_IMAGE, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
  return res.data
}
const searchDocuments = async (data = {}) => {
  const res = await axios.post(API_URLS.SEARCH_DOCUMENTS, data)
  return res.data
}
const saveDocument = async (data = {}) => {
  const res = await axios.post(API_URLS.SAVE_DOCUMENT, data)
  return res.data
}
const deleteDocument = async (data = {}) => {
  const res = await axios.post(API_URLS.DELETE_DOCUMENT, data)
  return res.data
}
const documentApi = {
  getDocuments,
  searchDocuments,
  uploadImage,
  createDocument,
  getDocument,
  saveDocument,
  deleteDocument
}

export default documentApi
