const BACKEND_URL = "https://autopia-search.herokuapp.com"
// const BACKEND_URL = "http://localhost:5000"
const API_URLS = {
  GET_CLASSIC_SELLERS_ROWS: `${BACKEND_URL}/classic-sellers`,
  GET_CLASSIC_BUYERS_ROWS: `${BACKEND_URL}/classic-buyers`,
  GET_MODERN_SELLERS_ROWS: `${BACKEND_URL}/modern-sellers`,
  GET_MODERN_BUYERS_ROWS: `${BACKEND_URL}/modern-buyers`,
  GET_LCS_ROWS: `${BACKEND_URL}/lcs`,
  GET_MODERN_SELLERS_ID: `${BACKEND_URL}/get-modern-sellers-id`,
  GET_MODERN_BUYERS_ID: `${BACKEND_URL}/get-modern-buyers-id`,
  GET_CLASSIC_SELLER_ID: `${BACKEND_URL}/get-classic-sellers-id`,
  GET_CLASSIC_BUYER_ID: `${BACKEND_URL}/get-classic-buyers-id`
}

export default API_URLS
