import { useEffect, useState } from "react"
import { Col, Container, Form, Pagination, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ipAddressApi from "../api/ipAddressApi"
import sellCarApi from "../api/sellCarApi"
import Choose from "../components/controlStatements/Choose"
import Otherwise from "../components/controlStatements/Otherwise"
import When from "../components/controlStatements/When"
import Main from "../components/Main/Main"
import SalesCard from "../components/Sales/SalesCard"
import SearchBar from "../components/SearchBar/SearchBar"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
function Sales() {
  const { employee } = useSelector((state) => state.employee)
  const navigate = useNavigate()
  const [sales, setSales] = useState([])
  const [loading, setLoading] = useState(false)
  const [sorted, setSorted] = useState("descending")
  const [checked, setChecked] = useState("notchecked")
  const [type, setType] = useState("")
  const [ips, setIps] = useState([])
  const [pages, setPages] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [loadingIps, setLoadingIps] = useState(false)
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.salesAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  const onSearchSubmit = async (term = "") => {
    try {
      setLoading(true)
      const data = await sellCarApi.searchForms({
        search: term,
        sorted,
        checked,
        page: currentPage
      })
      setSales(data.forms)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
      console.log(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      getIps()
      setLoading(false)
    }
  }

  const getIps = async () => {
    try {
      setLoadingIps(true)
      const data = await ipAddressApi.getBlockedUsers()

      setIps(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingIps(false)
    }
  }

  const blockIp = async (ip) => {
    try {
      setLoadingIps(true)
      console.log(ip)
      await ipAddressApi.BlockUser({ ip })
      notify.success("Ip blocked succesfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingIps(false)
      getIps()
    }
  }
  const unblock = async (ip) => {
    try {
      setLoadingIps(true)
      await ipAddressApi.unblockUser2({ ip })
      notify.success("Ip address unblocked succesfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingIps(false)
      getIps()
    }
  }

  const isBlocked = (ip) => {
    if (ip === undefined) return false
    for (let address of ips) {
      if (address?.address === ip) return true
    }
    return false
  }
  useEffect(() => {
    authorizeEmployee()
    onSearchSubmit()
  }, [sorted, checked, currentPage])

  return (
    <>
      <Main>
        {/* <h1 className='mt-3 text-center text-primary'>Sales</h1> */}
        <Container>
          <SearchBar
            placeholder={"Search sell car forms here..."}
            onSearchSubmit={(term) => onSearchSubmit(term)}
          />
          <Row className='mt-2'>
            <Col md={4} xs={12}>
              <Form.Select
                value={sorted}
                onChange={(e) => setSorted(e.target.value)}
              >
                <option value=''>Default Order</option>
                <option value='descending'>Latest</option>
                <option value='ascending'>Oldest </option>
              </Form.Select>
            </Col>
            <Col md={4} xs={12}>
              <Form.Select
                value={checked}
                onChange={(e) => setChecked(e.target.value)}
              >
                <option value=''>All Forms</option>
                <option value='checked'>Checked Forms</option>
                <option value='notChecked'>Not Checked Forms</option>
              </Form.Select>
            </Col>

            <Col md={4} xs={12}>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value=''>All Types</option>
                <option value='regular'>Regular</option>
                <option value='premium'>Premium</option>
                <option value='advanced'>Advanced</option>
              </Form.Select>
            </Col>
          </Row>

          <div className='d-flex justify-content-center align-items-center mt-5 flex-column gap-3 mb-3'>
            {loading ? (
              <h5>Loading forms...</h5>
            ) : (
              sales.map((s) => (
                <SalesCard
                  key={s._id}
                  blocked={isBlocked(s.ip)}
                  blockIp={() => {
                    blockIp(s.ip)
                  }}
                  unblockIp={() => {
                    unblock(s.ip)
                  }}
                  loadingIp={loadingIps}
                  form={s}
                />
              ))
            )}
          </div>
          <div className='w-100 d-flex justify-content-center align-items-center'>
            <Choose>
              <When condition={loading}>
                <>...</>
              </When>
              <Otherwise>
                <Pagination className='mt-5'>
                  {[...Array(pages)].map((p, index) => {
                    return (
                      <Pagination.Item
                        onClick={() => setCurrentPage(index + 1)}
                        active={currentPage === index + 1}
                      >
                        {index + 1}
                      </Pagination.Item>
                    )
                  })}
                </Pagination>
              </Otherwise>
            </Choose>
          </div>
        </Container>
      </Main>
    </>
  )
}

export default Sales
