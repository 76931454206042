import { autopia_backend } from "../../lib/axios"
import { API_ROUTES } from "./routes"

const getParts = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.GET_PARTS, data)
  return res.data
}

const createPart  = async (data={})=> {
  const formData = new FormData()
  formData.append("sellerID",data?.seller)
  formData.append("name",data?.name)
  formData.append("description",data?.description)
  formData.append("base_price",data?.base_price)
  formData.append("discount_price",data?.discount_price)
  if (Array.isArray(data?.images)) {
    for (let image of data.images) {
      formData.append("images", image?.file);
    }
  }

  const res = await autopia_backend.post(API_ROUTES.POST.CREATE_PART, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return res.data;
}

const getPartById = async(data={})=>{
  const res = await autopia_backend.get(API_ROUTES.GET.GET_BY_ID,{
    params:data
  })
  return res.data
}

const editPart = async(data={})=>{
  const formData = new FormData()
  formData.append("sellerID",data?.seller)
  formData.append("name",data?.name)
  formData.append("description",data?.description)
  formData.append("base_price",data?.base_price)
  formData.append("discount_price",data?.discount_price)
  formData.append("partID",data?.partID)
  if (Array.isArray(data?.images)) {
    for (let image of data.images) {
      formData.append("images", image?.file);
    }
  }
  const res = await autopia_backend.put(API_ROUTES.PUT.UPDATE_PART, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return res.data
}

const deletePart = async(data={})=>{
  const res = await autopia_backend.delete(API_ROUTES.DELETE.DELETE_PART,{
    params:data,
  })
  return res.data
}

const searchParts = async (data={})=>{
  const res = await autopia_backend.get(API_ROUTES.GET.SEARCH_PARTS, {
    params:data
  })
  return res.data
}

const checkPromoted = async (data={})=>{
  const res = await autopia_backend.post(API_ROUTES.POST.CHECK_PROMOTED,data)
  return res.data
}

const checkDiscount = async(data={})=>{
  const res = await autopia_backend.post(API_ROUTES.POST.CHECK_DISCOUNT,data)
  return res.data
}


const checkPrivate = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.CHECK_PRIVATE, data)
  return res.data
}


const partApi = {
  searchParts,
  createPart,
  getParts,
  checkPrivate,
  deletePart,
  getPartById,
  editPart,
  checkPromoted,
  checkDiscount,
}

export default partApi
