import { autopia_backend } from "../../lib/axios"
import BID_API_ROUTES from "./routes"
const getBids = async (data = {}) => {
  const res = await autopia_backend.post(BID_API_ROUTES.GET_BIDS)
  return res.data
}

const bidApi = {
  getBids
}

export default bidApi
