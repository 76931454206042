import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import LineChart from "../components/LineChart/LineChart"
import Header from "../layouts/Header"
import { generateRandomNumber } from "../utils/generateRandomNumber"
import * as notify from "../lib/notify"
import { useEffect } from "react"
// import Sidebar from "../components/Main/Sidebar"
import Main from "../components/Main/Main"
function Dashboard() {
  const { employee } = useSelector((state) => state.employee)
  const navigate = useNavigate()

  const authorizeEmployee = () => {
    console.log(employee)
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.dashboardAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  useEffect(() => {
    authorizeEmployee()
  }, [])
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: false,
        text: "Monthly Views"
      }
    }
  }

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July"
  ]

  const data1 = {
    labels,
    datasets: [
      {
        label: "Monthly Views",
        data: labels.map(() => generateRandomNumber(0, 1000)),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)"
      }
    ]
  }
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: false,
        text: "Daily Views"
      }
    }
  }

  const labels2 = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ]

  const data2 = {
    labels,
    datasets: [
      {
        label: "Daily Views",
        data: labels2.map(() => generateRandomNumber(0, 1000)),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)"
      }
    ]
  }

  return (
    <>
      {/* <Header/> */}
      <Main>
        {/* <Container> */}
        {/* <h1>Dashboard</h1> */}

        {/* <Row>
          <Col lg={6} xs={12}>
            <div style={{ maxWidth: "600px" }}>
              <LineChart options={options1} data={data1} />
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <div style={{ maxWidth: "600px" }}>
              <LineChart options={options2} data={data2} />
            </div>
          </Col>
        </Row> */}
        {/* </Container> */}
      </Main>
    </>
  )
}

export default Dashboard
