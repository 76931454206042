import { useNavigate } from "react-router-dom"
import Main from "../../components/Main/Main"
import boatApi from "../../api/boat"
import { useState } from "react"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import { useEffect } from "react"
import BoatCard from "../../components/Boat/BoatCard"
export default function DisplayBoats() {
  const navigate = useNavigate()

  const [boats, setBoats] = useState([])
  async function getAllBoats() {
    try {
      const data = await boatApi.getAllBoats()
      setBoats(data)
    } catch (error) {
      notify.error(errorMessage(error))
    }
  }

  async function deleteBoat(boatId) {
    try {
      const data = await boatApi.deleteBoat({ boatId })
      notify.success("Boat deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      getAllBoats()
    }
  }

  useEffect(() => {
    getAllBoats()
  }, [])
  return (
    <>
      <Main>
        <div className='container'>
          <button
            onClick={() => navigate("/boats/create")}
            className='btn btn-primary'
          >
            Create Boat
          </button>
        </div>
        <div className='row mx-2'>
          {boats?.map((boat) => {
            return (
              <div className='  col-md-6 col-12'>
                <BoatCard onDelete={() => deleteBoat(boat?._id)} boat={boat} />{" "}
              </div>
            )
          })}
        </div>
      </Main>
    </>
  )
}
