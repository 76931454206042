import { useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { batch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import ReactSwitch from "react-switch"
import restorerApi from "../../api/restorerApi"
import RestorerImages from "./RetorerImages"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
export default function RestorerCard({ restorer, onDelete }) {
  const [showImages, setShowImages] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  // const { id } = useParams()
  const [checkedPromoted, setCheckedPromoted] = useState(restorer?.promoted)
  const [loadingPromoted, setLoadingPromoted] = useState(false)
  const checkPromoted = async () => {
    try {
      setLoadingPromoted(true)
      const check = await restorerApi.checkPromotedRestorer({
        restorerId: restorer?._id
      })
      setCheckedPromoted(!checkedPromoted)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingPromoted(false)
    }
  }

  const navigate = useNavigate()

  const [loadingPrivate, setLoadingPrivate] = useState(false)
  const [checkedPrivate, setCheckedPrivate] = useState(restorer?.private)

  const checkPrivate = async () => {
    try {
      setLoadingPrivate(true)
      const check = await restorerApi.checkPrivate({
        restorerId: restorer?._id
      })
      setCheckedPrivate(!checkedPrivate)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingPrivate(false)
    }
  }

  return (
    <Card className='mt-3 '>
      <div className='p-3 '>
        <div className='d-flex justify-content-lg-center align-items-center mb-3 flex-column'>
          <div>
            <Card.Title>
              {restorer?.name + " " + restorer?.country}

              <span class='text-muted'></span>
            </Card.Title>

            <p className='text-secondary'>
              Last edit at {restorer?.updatedAt?.split("T")[0]}
            </p>
          </div>
          <div className='d-flex justify-content-center align-items-center gap-2'>
            <div>
              <h6>Private</h6>
              {loadingPrivate ? (
                "Loading..."
              ) : (
                <ReactSwitch
                  onChange={(e) => {
                    checkPrivate()
                  }}
                  checked={checkedPrivate}
                />
              )}
            </div>
            <div>
              <h6>Promoted</h6>
              {loadingPromoted ? (
                "Loading..."
              ) : (
                <ReactSwitch
                  onChange={(e) => {
                    checkPromoted()
                  }}
                  checked={checkedPromoted}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-center align-items-center mb-3 gap-2'>
        <Button
          onClick={(e) => {
            e.preventDefault()
            navigate(`/restorer/edit/${restorer._id}`)
          }}
        >
          Edit
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault()
            onDelete({ restorerId: restorer._id })
          }}
          variant='danger'
        >
          Delete
        </Button>
      </div>
      <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
        <Button
          variant='dark'
          size='lg'
          className='w-100'
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "Hide Details" : "Show Details"}
        </Button>
        {showDetails && (
          <div className='d-flex justify-content-center align-items-start gap-2 flex-column'>
            <div className='d-flex justify-content-center flex-column align-items-start gap-3'>
              <h6 className='mb-0 fw-bold'>Scope Of Work</h6>
              <div className='d-flex align-items-start flex-column justify-content-center gap-3'>
                {restorer?.scopeOfWork.map((work) => {
                  return <div>{work.name + " " + work.subName}</div>
                })}
              </div>
            </div>

            <div className='d-flex justify-content-start align-items-center gap-2'>
              <h6 className='fw-bold mb-0'>Parts Seller</h6>{" "}
              <div>{restorer?.partsStore ? "Yes" : "No"}</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'> Works With Classics </h6>
              <div>{restorer?.classics ? "Yes" : "No"}</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'> Works With Electrics </h6>
              <div>{restorer?.electrics ? "Yes" : "No"}</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'> Works With Electrics </h6>
              <div>{restorer?.modern ? "Yes" : "No"}</div>
            </div>
            <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
              <h6 className='fw-bold mb-0'>Phone </h6>
              <ul>
                {restorer?.phone?.map((phone) => {
                  return <li>{phone.countryCode + " " + phone?.phoneNumber}</li>
                })}
              </ul>
              {/* <div>{restorer?.countryCode + " " + restorer?.phone}</div> */}
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start gap-2'>
              <h6 className='fw-bold mb-0'> Vehicle Country Of Origin </h6>

              <ul>
                {restorer?.vehicleCountryOfOrigin?.map((v) => {
                  return <li>{v}</li>
                })}
              </ul>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'> Categories </h6>

              <ul>
                {restorer?.category?.map((c) => {
                  return <li>{c}</li>
                })}
              </ul>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'> brand </h6>

              <ul>
                {restorer?.brand?.map((b) => {
                  return <li>{b}</li>
                })}
              </ul>
            </div>

            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'>Tiktok</h6>
              <div>{restorer?.tiktok}</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'>Instagram</h6>
              <div>{restorer?.instagram}</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'>Facebook</h6>
              <div>{restorer?.facebook}</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'>Website</h6>
              <div>{restorer?.website}</div>
            </div>
            <div className='d-flex justify-content-center align-items-start  flex-column gap-2'>
              <h6 className='fw-bold mb-0'>Google Maps</h6>
              {/* <ul>
                {restorer?.googleMaps.map((googleUrl) => {
                  return (
                    <li>
                      <a href={googleUrl}>{googleUrl}</a>
                    </li>
                  )
                })}
              </ul> */}
              <ul className='p-3'>
                {restorer?.googleMaps.map((map) => {
                  return (
                    <li
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "250px"
                      }}
                    >
                      {" "}
                      <a href={map}>{map}</a>{" "}
                    </li>
                  )
                })}
              </ul>
              <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                <h6 className='fw-bold mb-0'>Coordinates</h6>
                <ul className='p-3'>
                  {restorer?.coordinates?.map((c) => {
                    return <li>{c.lng + " " + c.lat}</li>
                  })}
                </ul>
              </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
              <h6 className='fw-bold mb-0'>Description</h6>
              <div>{restorer?.description}</div>
            </div>
          </div>
        )}

        <Button
          variant='dark'
          size='lg'
          className='w-100'
          onClick={() => setShowImages(!showImages)}
        >
          {showImages ? "Hide Images" : "Show Images"}
        </Button>
        {/* {showImage && <button className='btn btn-primary'>Upload Images</button>} */}
        {showImages && (
          <RestorerImages restorerId={restorer?._id} imgs={restorer?.images} />
        )}
      </div>
    </Card>
  )
}
