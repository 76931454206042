module.exports.hull_shape = [
  "Round-bottom",
  "Flat-bottom",
  "V-bottom",
  "Multi-chine",
  "Catamaran",
  "Trimaran",
  "Pontoon",
  "Hydrofoil",
  "Displacement hull",
  "Planing hull",
  "Semi-displacement hull",
  "Deep-V hull",
  "Flatiron hull",
  "Tunnel hull",
  "Jon boat hull",
  "Delta hull",
  "Knuckle hull",
  "Cathedral hull",
  "Hard chine hull",
  "Soft chine hull",
  "Spherical hull",
  "Stepped hull",
  "Wave-piercing hull",
  "Lifting strake hull",
  "Planning keel hull",
  "Full keel hull",
  "Bilge keel hull",
  "Skeg hull",
  "Asymmetric hull",
  "Round-bilge hull",
  "Multi-hull ",
  "Dory hull",
  "Garvey hull",
  "Skiff hull",
  "Hydroplane hull",
  "Sweep hull",
  "Scow hull",
  "Double-ender hull",
  "Flared hull",
  "Box keel hull",
  "Semi-Vee hull",
  "Cathedral planing hull",
  "Canoe hull",
  "Kayak hull",
  "Pram hull",
  "Whaleback hull",
  "Punt hull",
  "Outrigger hull",
  "Sailing hydrofoil hull",
  "Monohull",
  "Center console hull",
  "Walkaround hull",
  "Cuddy cabin hull",
  "Trawler hull",
  "Express cruiser hull",
  "Downeast hull",
  "Deck boat hull",
  "Bowrider hull",
  "Catboat hull",
  "Gondola hull",
  "Barge hull",
  "Tugboat hull",
  "Landing craft hull",
  "Paddlewheel hull",
  "Houseboat hull",
  "Raft hull",
  "Pirogue hull",
  "Folding boat hull",
  "Inflatable boat hull",
  "Semi-submersible hull",
  "Hydrogen fuel cell boat hull",
  "Solar-powered boat hull",
  "Electric boat hull",
  "Autonomous surface vessel (ASV) hull",
  "Hybrid boat hull",
  "Amphibious boat hull",
  "Icebreaker hull",
  "Deep-sea research vessel hull",
  "Yacht hull",
  "Fishing boat hull",
  "Patrol boat hull",
  "Military vessel hull",
  "Research vessel hull",
  "Passenger ship hull",
  "Swath hull (Small Waterplane Area Twin Hull)",
  "Wave-slicing hull",
  "Hydrodynamic ramming hull",
  "Trim tab hull",
  "Wave-absorbing hull",
  "Constant deadrise hull",
  "Modified Vee hull",
  "High-speed catamaran hull",
  "Wave-piercing catamaran hull",
  "Dihedral catamaran hull",
  "Proa hull",
  "Wave-piercing trimaran hull",
  "Wave adaptive modular vessel (WAM-V) hull",
  "Hydrogen peroxide-powered boat hull",
  "Z-bow hull",
  "X-bow hull",
  "Inverted bow hull",
  "Wing-in-ground-effect (WIG) hull",
  "Double bubble hull",
  "Advanced marine vehicle (AMV) hull",
  "Intermarine trimaran hull",
  "Stabilized monohull",
  "Variable geometry ship hull",
  "Wave glider hull",
  "Autonomous underwater vehicle (AUV) hull",
  "Bathyscaphe hull",
  "Seaplane hull",
  "Hydrodynamic wing hull",
  "Toroidal hull",
  "Twin step hull",
  "Reverse chine hull",
  "Lifting body hull",
  "Displacement catamaran hull",
  "Inverted-bow hull",
  "Swept chine hull",
  "Advanced air lubrication hull",
  "Box-section hull",
  "Disappearing chine hull",
  "Inverted chine hull",
  "Shallow draft hull",
  "Fast displacement hull"
]
