import { useForm } from "react-hook-form"
import Main from "../../components/Main/Main"
import { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import ReactQuill from "react-quill"
import machineryApi from "../../api/machinery"
import { useNavigate, useParams } from "react-router-dom"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
export default function EditMachinery() {
  const [description, setDescription] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const { id } = useParams()
  const getMachineryById = async (data = {}) => {
    try {
      const data = await machineryApi.getMachineryById({ machineryId: id })
      reset({ ...data })
    } catch (error) {
      notify.error(errorMessage(error))
    }
  }
  const variables = [
    {
      col: {
        md: 6,
        sm: 12,
        lg: 4
      },
      label: "Type",
      value: "type"
    },
    {
      label: "Brand",
      value: "brand",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Model",
      value: "model",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Year",
      value: "year",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Color",
      value: "color",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Condition",
      value: "condition",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Engine Type",
      value: "engineType",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Number of Wheels",
      value: "numberOfWheels",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Number of Axes",
      value: "numberOfAxes",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Fuel Capacity (L)",
      value: "fuelCapacity",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Max Speed",
      value: "maxSpeed",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Weight",
      value: "weight",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Special Features",
      value: "specialFeatures",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    }
  ]

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const updateMachinery = async (data = {}) => {
    try {
      await machineryApi.editMachinery({
        ...data,
        machineryId: id
      })
      navigate("/machinery")
    } catch (error) {
      notify.error(errorMessage(error))
    }
  }
  useEffect(() => {
    getMachineryById()
  }, [])
  return (
    <Main>
      <div className='container'>
        <h1>Edit Machinery</h1>

        <Row>
          {variables?.map((variable) => {
            return (
              <Col
                key={variable.value}
                md={variable.col.md}
                sm={variable.col.sm}
                lg={variable.col.lg}
              >
                <Form.Group className='m-2'>
                  <Form.Label>{variable.label}</Form.Label>
                  <Form.Control
                    {...register(variable.value)}
                    placeholder={variable.label}
                  />
                </Form.Group>
              </Col>
            )
          })}
          <Col md={12}>
            <ReactQuill value={description} setValue={setDescription} />
          </Col>
          <Col md={12}>
            <button
              disabled={loading}
              onClick={handleSubmit((data) => {
                updateMachinery(data)
              })}
              className='btn btn-success'
            >
              {loading ? "Loading..." : "Edit"}
            </button>
          </Col>
        </Row>
      </div>
    </Main>
  )
}
