import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import plateNumberApi from "../../api/plateNumber"
import { Card } from "react-bootstrap"
import Choose from "../controlStatements/Choose"
import When from "../controlStatements/When"
import { Upload } from "react-bootstrap-icons"
import Otherwise from "../controlStatements/Otherwise"
export default function PlateNumberCard({ getPlateNumbers, plateNumber }) {
  const navigate = useNavigate()
  const [inputImage, setInputImage] = useState()
  const [displayedImage, setDisplayedImage] = useState(null)

  const readImageUrl = (img) => {
    try {
      return URL.createObjectURL(img)
    } catch (error) {
      //   notify.error(errorMessage(error))
      console.log(error)
      notify.error(errorMessage(error))
    } finally {
    }
  }

  const [loadingImage, setLoadingImage] = useState(false)
  const createImage = async () => {
    try {
      setLoadingImage(true)
      await plateNumberApi.uploadImage({
        plateNumberId: plateNumber._id,
        image: inputImage
      })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingImage(false)
      getPlateNumbers()
    }
  }
  const [uploadImage, setUploadImage] = useState(true)
  const [showImage, setShowImage] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const handleDelete = async (id) => {
    try {
      setLoadingDelete(true)
      await plateNumberApi.deletePlateNumber({ plateNumberId: plateNumber._id })
    } catch (error) {
    } finally {
      setLoadingDelete(false)
      getPlateNumbers()
    }
  }
  return (
    <Card style={{ width: "100%", maxWidth: "300px" }} className='my-2'>
      <Card.Body>
        <Card.Title>
          <h5 className='h6'>
            {plateNumber?.letter + " " + plateNumber?.plateNumber}
          </h5>
          <h5>
            {" "}
            <span className='text-success'>{plateNumber?.price + " $"}</span>
          </h5>
        </Card.Title>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <button
            disabled={loadingDelete}
            onClick={() => handleDelete()}
            className='btn btn-danger btn-sm mx-1'
          >
            {loadingDelete ? "Loading" : "Delete"}
          </button>
          <button
            onClick={() => setShowImage(!showImage)}
            className='btn btn-outline-danger mt-3 w-100'
          >
            {showImage ? "Hide  Image" : "Show  Image"}
          </button>
        </div>
        {showImage && (
          <>
            <div className='mt-3'>
              <div>
                <h6>Uploaded Image</h6>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    setUploadImage(!uploadImage)
                  }}
                >
                  {!uploadImage ? "Cancel" : "Upload Image"}
                </button>
              </div>
            </div>

            <div></div>

            {uploadImage ? (
              plateNumber?.image?.url ? (
                <img
                  src={plateNumber?.image?.url}
                  style={{
                    // height: "300px",
                    width: "100%",
                    objectFit: "contain"
                  }}
                  alt=''
                />
              ) : (
                <>No Uploaded Thumbnail</>
              )
            ) : (
              <Choose>
                <When condition={displayedImage === null}>
                  <div
                    className='d-flex  justify-content-center align-items-center '
                    style={{ height: "200px" }}
                  >
                    <label htmlFor='image' className='cursor-pointer'>
                      <div className='d-flex flex-column justify-content-center align-items-center gap-1 '>
                        <Upload size={"30px"} />
                        <h6>Add Image</h6>
                      </div>
                      <input
                        onChange={(e) => {
                          setInputImage(e.target.files[0])
                          setDisplayedImage(readImageUrl(e.target.files[0]))
                          // console.log(readImageUrl(e.target.files[0]))
                        }}
                        // onChange={(e) => {
                        //   handleCompressedUpload(e).then((data) => {})
                        //   setDisplayedImages([...displayedImages, ...e.target.files])
                        // }}
                        className='d-none'
                        id='image'
                        type='file'
                      />
                    </label>
                  </div>
                </When>
                <Otherwise>
                  <>
                    <img
                      src={displayedImage}
                      style={{
                        // height: "300px",
                        width: "100%",
                        objectFit: "contain"
                      }}
                      alt=''
                    />
                    <div className='flex items-center justify-center  mb-3'>
                      <button
                        onClick={() => {
                          setDisplayedImage("")
                          setInputImage("")
                        }}
                        className='btn btn-danger btn-sm mr-2'
                      >
                        X
                      </button>
                      <button
                        onClick={() => {
                          createImage()
                        }}
                        disabled={loadingImage}
                        className='btn btn-primary btn-sm '
                      >
                        {loadingImage ? "Loading..." : "Upload"}
                      </button>
                    </div>
                  </>
                </Otherwise>
              </Choose>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}
