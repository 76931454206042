import { useState } from "react"
import { Card } from "react-bootstrap"
import ReactSwitch from "react-switch"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import boatApi from "../../api/boat"
import BoatImages from "./BoatImages"
import { useNavigate } from "react-router-dom"
export default function BoatCard({ onDelete, boat }) {
  const [privateCheck, setPrivateCheck] = useState(boat?.private)
  const [showDescription, setShowDescription] = useState(false)

  const [showDetails, setShowDetails] = useState(false)
  const navigate = useNavigate()
  const [loadingCheckPrivate, setLoadingCheckPrivate] = useState(false)
  const [showImages, setShowImages] = useState(true)
  async function checkPrivate() {
    try {
      setLoadingCheckPrivate(true)
      await boatApi.checkBoatPrivate({ boatId: boat?._id })
      setPrivateCheck(!privateCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingCheckPrivate(false)
    }
  }
  return (
    <Card className='mt-3 w-100'>
      <div className='p-3'>
        <div className='d-flex justify-content-lg-center align-items-center'>
          <div>
            <Card.Title>{boat?.brand + " " + boat?.model}</Card.Title>
            <span class='text-muted'>{boat?.createdAt}</span>
            <div className='text-center my-2'>
              <h6>Private</h6>
              <ReactSwitch
                checked={privateCheck}
                onChange={(e) => {
                  checkPrivate()
                }}
              />
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <button
                className='btn btn-primary'
                onClick={() => navigate(`/boats/${boat?._id}/edit`)}
              >
                Edit
              </button>
              <button onClick={() => onDelete()} className='btn btn-danger'>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setShowDescription(!showDescription)
        }}
        className='mb-1 btn btn-outline btn-dark'
      >
        {!showDescription ? "Show Description" : "Hide Description"}
      </button>
      {showDescription && (
        <p dangerouslySetInnerHTML={{ __html: boat?.description }}></p>
      )}
      <button
        onClick={() => {
          setShowDetails(!showDetails)
        }}
        className='mb-1 btn btn-outline btn-dark'
      >
        {!showDetails ? "Show Details" : "Hide Details"}
      </button>
      {showDetails && (
        <div className='row row-cols-4'>
          <h6>Brand: {boat?.brand}</h6>
          <h6>Model: {boat?.model}</h6>
          <h6>Purpose: {boat?.purpose}</h6>
          <h6>Category: {boat?.category}</h6>
          <h6>Year: {boat?.year}</h6>
          <h6>Status: {boat?.status}</h6>
          <h6>Type: {boat?.type}</h6>
          <h6>Body: {boat?.body}</h6>
          <h6>Interior Color: {boat?.interiorColor}</h6>
          <h6>Exterior Color: {boat?.exteriorColor}</h6>
          <h6>Condition :{boat?.condition}</h6>
          <h6>Country: {boat?.country}</h6>
          <h6>Length: {boat["length"]}</h6>
          <h6>Width: {boat?.width}</h6>
          <h6>Depth: {boat?.depth}</h6>
          <h6>Draft: {boat?.draft}</h6>
          <h6>Fuel Type: {boat?.fuelType}</h6>
          <h6>Hull Material: {boat?.hullMaterial}</h6>
          <h6>Hull Shape: {boat?.hulLShape}</h6>
          <h6>Beam: {boat?.beam}</h6>
          <h6>Engine Type: {boat?.engineType}</h6>
          <h6>Number of Engine Type: {boat?.numberOfEngines}</h6>
          <h6>Power: {boat?.power}</h6>
          <h6>Mileage Hours: {boat?.mileageHours}</h6>
          <h6>Max Passengers: {boat?.maxPassengers}</h6>
          <h6>Fuel Tanks: {boat?.fuelTanks}</h6>
          <h6>Legal Papers: {boat?.legalPapers}</h6>
        </div>
      )}
      <button
        onClick={() => {
          setShowImages(!showImages)
        }}
        className='btn btn-outline btn-dark'
      >
        {showImages ? "Show Images" : "Hide Images"}
      </button>

      {!showImages && <BoatImages boatId={boat?._id} />}
    </Card>
  )
}
