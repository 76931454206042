import axios from "axios"
import { API_URLS } from "./routes"

const reverseGeocode = async (data = {}) => {
  const res = await axios.post(API_URLS.REVERSE_GEOCODE, data)
  return res.data
}

const geocodeApi = {
  reverseGeocode
}

export default geocodeApi
