module.exports.boat_brands = [
  "AB Inflatables",
  "ACM",
  "ATX Surf Boats",
  "Absolute",
  "Achilles",
  "Action Craft",
  "Adagio",
  "Advantage",
  "Aicon",
  "Alalunga",
  "Albemarle",
  "Albin",
  "Alerion",
  "Alfastreet Marine",
  "Alu Marine",
  "Alumacraft",
  "Alumaweld",
  "Alva Yachts",
  "Alweld",
  "American Tug",
  "Angler",
  "Angler Qwest",
  "Anka",
  "Antaris",
  "Apex",
  "Apreamare",
  "AquaPatio",
  "Aquador",
  "Aquasport",
  "Aquila",
  "Arcadia Yachts",
  "Archambault",
  "Arcoa",
  "Arcona",
  "Argos Nautic",
  "Arima",
  "Ascend",
  "Avalon",
  "Avenger",
  "Aviara",
  "Avid",
  "Avon",
  "Axis",
  "Azimut",
  "Back Cove",
  "Baja",
  "Barge",
  "Barletta",
  "Barracuda",
  "Bass Cat",
  "Bavaria",
  "Bay Craft",
  "Bayliner",
  "Belize",
  "Belzona",
  "Beneteau",
  "Beneteau America",
  "Bennington",
  "Bentley Pontoons",
  "Bering",
  "Berkshire",
  "Bertram",
  "Birchwood",
  "BlackJack",
  "BlackWater",
  "Blackfin",
  "Blazer",
  "Blue Wave",
  "Bluewater Sportfishing",
  "Bombard",
  "Bonita",
  "Boston Whaler",
  "Brig",
  "Brizo",
  "Broom",
  "Bruckmann",
  "Bryant",
  "Buddy Davis",
  "Bullet",
  "Bulls Bay",
  "C&C",
  "C-Dory",
  "C-Hawk",
  "CL Yachts",
  "CRN",
  "Cabo",
  "Cabo Rico",
  "Caliber",
  "Campion",
  "Canados",
  "Canal Boat",
  "Cape Horn",
  "Capelli",
  "Caravelle",
  "Carolina Classic",
  "Carolina Skiff",
  "Carver",
  "Catalina",
  "Catamaran",
  "Catana",
  "Caymas",
  "Center Console",
  "Centurion",
  "Century",
  "Champion",
  "Chaparral",
  "Checkmate",
  "Cheoy Lee",
  "Chris-Craft",
  "Cigarette",
  "Classic",
  "Clearwater",
  "Clipper Motor Yachts",
  "Coastal Craft",
  "Cobalt",
  "Cobia",
  "Cobra Ribs",
  "Colombo",
  "Columbia",
  "Com-Pac",
  "Compass",
  "Concept",
  "Conrad",
  "Contender",
  "Contest",
  "Cormorant Yachts",
  "Cornish Crabbers",
  "Correct Craft",
  "Corsair",
  "Cranchi",
  "Crescent",
  "Crest",
  "Crestliner",
  "Crevalle",
  "Crownline",
  "Cruisers Yachts",
  "Custom",
  "Custom Carolina",
  "Custom Line",
  "Cutwater",
  "Cypress Cay",
  "DK",
  "Dale",
  "De Birs",
  "DeFever",
  "Deep Impact",
  "Defiance",
  "Dehler",
  "Delphia",
  "Delta Powerboats",
  "Discovery",
  "Dominator",
  "Donzi",
  "Dorado",
  "Doral",
  "Draco",
  "Dromeas",
  "Duckworth",
  "Dufour",
  "Dutch Barge",
  "Dyer",
  "Dyna",
  "Eagle",
  "Eastbay",
  "Eastern",
  "Ebbtide",
  "Edgewater",
  "Egg Harbor",
  "Elan",
  "Elan Power",
  "Elegance",
  "Elling",
  "Eolo",
  "Epic",
  "Everglades",
  "Evo Yachts",
  "Evolve",
  "Excel",
  "Explorer",
  "Explorer Motor Yachts",
  "Extreme Boats",
  "Faeton",
  "Fairline",
  "Farr",
  "Feeling",
  "Ferretti Yachts",
  "Filippetti Yacht",
  "Finseeker",
  "Fisher",
  "Fjord",
  "Flats Cat",
  "Focus Motor Yachts",
  "Formula",
  "Fountain",
  "Fountaine Pajot",
  "Four Winns",
  "Fratelli Aprea",
  "Freedom",
  "Front Runner",
  "Frontier",
  "G3",
  "Gala",
  "Galeon",
  "Galia",
  "Gekko",
  "Gib'Sea",
  "Gibson",
  "Glacier Bay",
  "Glasstream",
  "Glastron",
  "Gobbi",
  "Godfrey",
  "Goldfish",
  "Grady-White",
  "Grand Banks",
  "Grand Harbour",
  "Grand Soleil",
  "Greenline",
  "Gulet",
  "Gulf Craft",
  "Gulf Crosser",
  "GulfStream Yachts",
  "HCB",
  "HH Catamarans",
  "Hallberg-Rassy",
  "Halvorsen",
  "Hampton",
  "Hans Christian",
  "Hanse",
  "Hardy",
  "Harris",
  "Harris FloteBote",
  "Harris-Kayot",
  "Hatteras",
  "Henriques",
  "Hewes",
  "Hewescraft",
  "Heyday",
  "Heysea",
  "Hi-Star",
  "Highfield",
  "Hinckley",
  "Hinckley Sport Boats",
  "Historic",
  "Hobie",
  "Holiday Mansion",
  "Horizon",
  "Houseboat",
  "Hunt Yachts",
  "Hunter",
  "Hunton",
  "Hurricane",
  "Hydra-Sports",
  "Hylas",
  "IMG",
  "Idea",
  "Iguana",
  "Inmar",
  "Integrity",
  "Interboat",
  "Intrepid",
  "Invincible",
  "Iron",
  "Island Gypsy",
  "Island Packet",
  "Itama",
  "J Boats",
  "JC",
  "Jarrett Bay",
  "Jarvis Newman",
  "Javelin",
  "Jeanneau",
  "Jefferson",
  "Jersey Cape",
  "Johnson",
  "Jongert",
  "Jupiter",
  "Karnic",
  "Kawasaki",
  "KenCraft",
  "Kenner",
  "Ketch",
  "Key Largo",
  "Key West",
  "KingFisher",
  "Kolibri",
  "Kotter",
  "Kruiser",
  "Lagoon",
  "Laguna",
  "Landau",
  "Langenberg",
  "Larson",
  "Latitude 46",
  "Lazzara",
  "Le Boat",
  "Legacy",
  "Legacy Yachts",
  "Legend",
  "Legend Boats",
  "Leisure",
  "Liberty",
  "Limitless",
  "Lindell",
  "Lion Yachts",
  "Llaut",
  "Lowe",
  "Luhrs",
  "Lund",
  "MB",
  "MJM",
  "Mag Bay",
  "Mainship",
  "Majek",
  "Majesty",
  "Mako",
  "Malibu",
  "Manitou",
  "Manta",
  "Marathon",
  "Marex",
  "Mariah",
  "Maritime",
  "Maritimo",
  "Marlago",
  "Marlin",
  "Marlow",
  "Marlow-Hunter",
  "Marquis",
  "Mason",
  "MasterCraft",
  "Maverick",
  "Maxi",
  "Maxum",
  "May-Craft",
  "McConaghy",
  "McKinna",
  "Mediterranean",
  "Mengi Yay",
  "Menorquin",
  "Mercury Inflatables",
  "Meridian",
  "Midnight Express",
  "Mirage",
  "MirroCraft",
  "Misty Harbor",
  "Mochi Craft",
  "Monark",
  "Monte Carlo",
  "Monte Carlo Yachts",
  "Monte Fino",
  "Montego Bay",
  "Monterey",
  "Moody",
  "Moomba",
  "Motor Yacht",
  "Motorsailer",
  "Mulder",
  "Mustang",
  "Myabca",
  "Mystic Powerboats",
  "NX Boats",
  "Najad",
  "Narrowboat",
  "NauticStar",
  "Nautique",
  "Nautitech",
  "Nautor Swan",
  "Naval Yachts",
  "Nepallo",
  "Neptunus",
  "Nerea Yacht",
  "Nimbus",
  "Nitro",
  "Nomad",
  "Nor-Tech",
  "Nord West",
  "Nordhavn",
  "Nordia",
  "Nordic Tug",
  "NorthCoast",
  "Northern Marine",
  "Northmaster",
  "Northstar",
  "Northwood",
  "Nova",
  "Novielli",
  "Novurania",
  "Ocean Alexander",
  "Ocean Explorer Catamarans",
  "Ocean Yachts",
  "Ocqueteau",
  "Offshore Yachts",
  "One Design",
  "Orion",
  "Orkney",
  "Osprey",
  "Outback Yachts",
  "Outer Reef Trident",
  "Outer Reef Yachts",
  "Outerlimits",
  "Outremer",
  "Oyster",
  "PDQ",
  "Pacific Mariner",
  "Pacific Seacraft",
  "Paddle King",
  "Palm Beach",
  "Palm Beach Motor Yachts",
  "Paragon",
  "Pardo Yachts",
  "Parker",
  "Parker Poland",
  "Passport",
  "Pathfinder",
  "Pearl",
  "Penn Yan",
  "Performance",
  "Pershing",
  "Phoenix",
  "Pioneer",
  "Piranha",
  "Playbuoy",
  "Polar",
  "Polar Kraft",
  "Polaris",
  "Predator",
  "Premier",
  "President",
  "Prestige",
  "Princecraft",
  "Princess",
  "Privateer",
  "Privilege",
  "Pro Sports",
  "Pro-Line",
  "ProKat",
  "Protector",
  "Pursuit",
  "Queenship",
  "Quicksilver",
  "Qwest",
  "R",
  "RIB",
  "RS",
  "RYCK",
  "Rampage",
  "Ranger",
  "Ranger Tugs",
  "Rapsody",
  "Recon",
  "Reflex",
  "Regal",
  "Regency",
  "Regulator",
  "Reinell",
  "Release",
  "Renegade",
  "Rhea",
  "Ribcraft",
  "Ribeye",
  "Rinker",
  "Rio Yachts",
  "Riva",
  "Riviera",
  "Robalo",
  "Rodman",
  "Ronautica",
  "Rosborough",
  "Rossiter",
  "Ruby",
  "Ryds",
  "SACS",
  "SCB",
  "SPX RIB",
  "Sabre",
  "Sabreline",
  "Saffier",
  "Sailboat",
  "Sailfish",
  "Sanger",
  "Sanlorenzo",
  "Sanpan",
  "Santa Cruz",
  "Sargo",
  "Sarnico",
  "Savannah",
  "Scarab",
  "Schaefer",
  "Schooner",
  "Sciallino",
  "Scout",
  "Sea Born",
  "Sea Boss",
  "Sea Cat",
  "Sea Chaser",
  "Sea Fox",
  "Sea Hunt",
  "Sea Pro",
  "Sea Ray",
  "Sea-Doo",
  "Sea-Doo Sport Boats",
  "SeaArk",
  "SeaCraft",
  "SeaHunter",
  "SeaSport",
  "Sealegs",
  "Sealine",
  "Seaswirl",
  "Seaswirl Striper",
  "Seaward",
  "Seawind",
  "Segue",
  "Selene",
  "Sessa Marine",
  "Shallow Sport",
  "Shallow Stalker",
  "Shamrock",
  "ShearWater",
  "Sheerline",
  "Shoalwater",
  "Silent",
  "Silver Wave",
  "Silverton",
  "Sinergia",
  "Skeeter",
  "Skipjack",
  "Skutsje",
  "Sleepboot",
  "Sloep",
  "Sloop",
  "Sly",
  "Smartliner",
  "Smartwave",
  "Smelne",
  "Smoker Craft",
  "Solace",
  "Solara",
  "Solaris",
  "Sonic",
  "South Bay",
  "SouthWind",
  "Southerly",
  "Southfork",
  "Southport",
  "Sport-Craft",
  "Sportsman",
  "Spyder",
  "Stamas",
  "Starcraft",
  "Stardust Cruisers",
  "Starweld",
  "Statement",
  "Steeler",
  "Steiger Craft",
  "Sterling",
  "Stingray",
  "Storebro",
  "Stratos",
  "Striper",
  "Sugar Sand",
  "Sumerset",
  "Sun Tracker",
  "SunCatcher",
  "SunChaser",
  "Sunbeam",
  "Sundance",
  "Sundeck Yachts",
  "Sunreef",
  "Sunsation",
  "Sunseeker",
  "Superyacht",
  "Supreme",
  "Sweetwater",
  "Sydney",
  "Sylvan",
  "Symbol",
  "Tahoe",
  "Tahoe Pontoon",
  "Tartan",
  "Tayana",
  "Tender",
  "Thunder Jet",
  "Tiara Sport",
  "Tiara Yachts",
  "Tidewater",
  "Tige",
  "Tofinou",
  "TomCat",
  "Topaz",
  "Tracker",
  "Trailer",
  "Trawler",
  "Trifecta",
  "Trimaran",
  "Trintella",
  "Triton",
  "Triumph",
  "Trojan",
  "Trophy",
  "True North",
  "Trumpy",
  "Tugboat",
  "Turner Boatworks",
  "Twin Vee",
  "UMS",
  "Uniesse",
  "VIP",
  "Valiant",
  "VanDutch",
  "Vanderbilt",
  "Velocity",
  "Venture",
  "Veranda",
  "Vexus",
  "Viaggio",
  "Vicem",
  "Viking",
  "Vlet",
  "Vortex",
  "Voyage Yachts",
  "Wajer",
  "Walker Bay",
  "War Eagle",
  "Warrior",
  "Wauquiez",
  "WeldBilt",
  "Weldcraft",
  "Wellcraft",
  "White Shark",
  "Williams Jet Tenders",
  "Windelo",
  "Windy",
  "World Cat",
  "WX Shore",
  "X-Yachts",
  "Xcursion",
  "Xpress",
  "Yamaha Boats",
  "Yamaha WaveRunner",
  "Yellowfin",
  "Zeelander",
  "Zeeschouw",
  "Zodiac"
]
