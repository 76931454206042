import { autopia_backend } from "../../lib/axios"
import { BOAT_API_ROUTES } from "./routes"

async function createBoat(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.CREATE, data)
  return res.data
}

async function getAllBoats(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.GET_ALL_BOATS, data)
  return res.data
}
async function getBoatBrands(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.GET_BOAT_BRANDS, data)
  return res.data
}

async function getBoatModels(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.GET_BOAT_MODELS, data)
  return res.data
}

async function deleteBoat(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.DELETE_BOAT, data)
  return res.data
}

async function checkBoatPrivate(data = {}) {
  const res = await autopia_backend.post(
    BOAT_API_ROUTES.CHECK_BOAT_PRIVATE,
    data
  )
  return res.data
}
async function switchImages(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.SWITCH, data)
  return res.data
}
async function editBoat(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.EDIT_BOAT, data)
  return res.data
}
async function getBoat(data = {}) {
  const res = await autopia_backend.post(BOAT_API_ROUTES.FIND_BOAT, data)
  return res.data
}
const boatApi = {
  createBoat,
  deleteBoat,
  getBoatBrands,
  getBoatModels,
  switchImages,
  checkBoatPrivate,
  getAllBoats,
  getBoat,
  editBoat
}

export default boatApi
