import { Container, Spinner, Table } from "react-bootstrap";
import Main from "../components/Main/Main";
import { useState } from "react";
import { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import performanceApi from "../api/performance";
import * as notify from "../lib/notify";
import { errorMessage } from "../utils/errorMessage";
import { useEffect } from "react";
import Creatable from "react-select/creatable";
import Select from "react-select";

export default function Performance() {
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  const [data, setData] = useState({});
  const [dataPerDay, setDataPerDay] = useState({});
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [loadingPaths, setLoadingPaths] = useState(false);
  const [paths, setPaths] = useState([]);

  function setFilters(from, to, path, country, city) {
    let filters = { from, to, path, country, city };

    if (filters.city === "" || filters.city == undefined) delete filters.city;
    if (filters.from === "") delete filters.from;
    if (filters.to === "") delete filters.to;
    if (filters.path === "") delete filters.path;
    if (filters.country === "") delete filters.country;
    console.log(filters);

    return filters;
  }

  const getPerformance = async () => {
    try {
      // GET /analitycs?from=2023-04-16T18:00:00.000Z&to=2023-04-17T00:00:00.000Z
      //
      let to = addDays(new Date(date), 7);
      to = to.toISOString().slice(0, 10);
      console.log(to);
      setLoading(true);
      let filters = setFilters(date, to, path, country, city);

      //const getAnalyticsWithDay = performanceApi.getAnalyticsWithDay(filters);
      //const dataForAllDays = getAnalyticsWithDay("data");
      //const dataForOneDay = getAnalyticsWithDay("dataPerDay");
      const fetchedData = await performanceApi.getAnalytics(filters);
      setData(fetchedData);
      console.log(fetchedData);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getFilters = async () => {
    try {
      setLoadingPaths(true);
      const data = await Promise.all([
        performanceApi.getPaths(),
        performanceApi.getCities(),
        performanceApi.getCountries(),
      ]);
      setPaths(data[0]);
      console.log(data[0]);
      setCountries(data[2]);
      setCities(data[1]);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoadingPaths(false);
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  function onChangePath(_path) {
    console.log("The path is: ", _path);
    setPath(_path.value);
  }
  function onChangeCountry(_country) {
    console.log("The country is ", _country.value);
    setCountry(_country.value);
  }
  function onChangeCity(_city) {
    console.log("The city is ", _city);
    setCity(_city.value);
  }
  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }
  const Example = (props) => {
    console.log(props.data);
    return (
      <LineChart width={1000} height={300} data={props.data}>
        <Line
          type="monotone"
          dataKey={props.dataKey}
          stroke="#2196F3"
          strokeWidth={3}
        />
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey={props.XAxis} />
        <YAxis tickCount={7} includeHidden minTickGap={10} />
        <Tooltip />
      </LineChart>
    );
  };
  return (
    <Main>
      <Container>
        <h1>Performance</h1>
        <div>
          <h4>Filters</h4>
          <div className="d-flex justify-content-center align-items-center flex-column gap-3">
            <input
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
            />
            {/*
            <select
              value={path}
              onChange={(e) => setPath(e.target.value)}
              disabled={loadingPaths}
              className="form-select"
            >
              {paths.map((path) => {
                return (
                  <option key={path} value={path}>
                    {path}
                  </option>
                );
              })}
            </select>
            */}
            <Select
              isClearable={true}
              styles={{ width: "100vh" }}
              placeholder="select a path ..."
              options={paths}
              className="form-control"
              onChange={onChangePath}
              value={{ label: path, value: path }}
              noOptionsMessage={() => "Path Not Found"}
            />

            <Select
              isClearable={true}
              styles={{ width: "100vh" }}
              placeholder="select a country..."
              options={countries}
              className="form-control"
              noOptionsMessage={() => "Country Not Found"}
              value={{ label: country, value: country }}
              onChange={onChangeCountry}
            />
            <Select
              isClearable={true}
              styles={{ width: "100vh" }}
              placeholder="Select a city..."
              options={cities}
              className="form-control"
              onChange={onChangeCity}
              value={{ label: city, value: city }}
              noOptionsMessage={() => "City Not Found"}
            />
            <button
              onClick={getPerformance}
              className="btn btn-primary"
              disabled={loading || loadingPaths}
            >
              Submit
            </button>
          </div>
          {loading ? (
            <div className="mt-5 flex-column gap-2 d-flex justify-content-center align-items-center gap-1">
              <Spinner animation="border" />
              <h6>Loading</h6>
            </div>
          ) : (
            <>
              <h3 className="mt-5 ml-5">Average Response Time (per week)</h3>
              <Example
                data={data.data}
                dataKey="averageResponseTime"
                XAxis="day"
              />
              <br />
              <h3>Average Response Time (per day)</h3>
              <Example
                data={data.dataPerDay}
                dataKey="averageResponseTime"
                XAxis="hour"
              />
              <br />
              <h3>Number of requests (per week)</h3>
              <Example data={data.data} dataKey="count" XAxis="day" />
              <br />
              <h3>Number of requests (per day)</h3>
              <Example data={data.dataPerDay} dataKey="count" XAxis="hour" />
            </>
          )}
        </div>
      </Container>
    </Main>
  );
}
