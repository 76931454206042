// import axios from "axios"
import { autopia_backend } from "../../lib/axios";
import { PERFORMANCE_API_ROUTES } from "./routes";

const getAnalytics = async (data = {}) => {
  const res = await autopia_backend.get(
    PERFORMANCE_API_ROUTES.GET.GET_ANALYTICS + "?" + objectToQueryString(data)
  );

  return res.data;
};

async function getAnalyticsWithDay(data = {}) {
  const res = await autopia_backend.get(
    PERFORMANCE_API_ROUTES.GET.GET_ANALYTICS + "?" + objectToQueryString(data)
  );

  return function(whatData) {
   return res.data[whatData];
  }
}

const getPaths = async (data = {}) => {
  const res = await autopia_backend.get(
    PERFORMANCE_API_ROUTES.GET.GET_PATHS,
    data
  );
  return res.data;
};

const getFilters = async (data = {}) => {
  const res = await autopia_backend.get(
    PERFORMANCE_API_ROUTES.GET.GET_FILTERS,
    data
  );
  return res.data;
};

const getCountries = async (data = {}) => {
  const res = await autopia_backend.get(
    PERFORMANCE_API_ROUTES.GET.GET_COUNTRIES,
    data
  );
  return res.data;
};

const getCities = async (data = {}) => {
  const res = await autopia_backend.get(
    PERFORMANCE_API_ROUTES.GET.GET_CITIES,
    data
  );
  return res.data;
};

function objectToQueryString(data) {
  let obj = Object.entries(data)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  return obj;
}

const performanceApi = {
  getAnalytics,
  getPaths,
  getFilters,
  getCountries,
  getCities,
};

export default performanceApi;

