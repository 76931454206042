import { autopia_backend } from "../../lib/axios"
import { PLATE_NUMBER_ROUTES } from "./routes"
const getPlateNumbers = async (data = {}) => {
  const res = await autopia_backend.post(PLATE_NUMBER_ROUTES.GET_PLATE_NUMBERS)
  return res.data
}

const getPlateNumber = async (data = {}) => {
  const res = await autopia_backend.post(
    PLATE_NUMBER_ROUTES.GET_PLATE_NUMBER_BY_ID,
    data
  )
  return res.data
}

const createPlateNumber = async (data = {}) => {
  const res = await autopia_backend.post(
    PLATE_NUMBER_ROUTES.CREATE_PLATE_NUMBER,
    data
  )
  return res.data
}

const uploadImage = async (data = {}) => {
  const { image, plateNumberId } = data
  const bodyFormData = new FormData()
  bodyFormData.append("image", image)
  bodyFormData.append("plateNumberId", plateNumberId)
  const res = await autopia_backend.post(
    PLATE_NUMBER_ROUTES.UPLOAD_IMAGE,
    bodyFormData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
  return res.data
}

const deletePlateNumber = async (data = {}) => {
  const res = await autopia_backend.post(
    PLATE_NUMBER_ROUTES.DELETE_PLATE_NUMBER,
    data
  )
  return res.data
}
const plateNumberApi = {
  getPlateNumber,
  getPlateNumbers,
  createPlateNumber,
  uploadImage,
  deletePlateNumber
}

export default plateNumberApi
