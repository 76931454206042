import axios from "axios"
import { API_URLS } from "../constants/routes"

const BlockUser = async (data) => {
  const res = await axios.post(API_URLS.BLOCK_IP, data)
  return res.data
}

const getBlockedUsers = async () => {
  const res = await axios.post(API_URLS.GET_BLOCKED_IPS)
  return res.data
}
const unblockUser2 = async (data) => {
  const res = await axios.post(API_URLS.UNBLOCK_IP2, data)
  return res.data
}
const unblockUser = async (data) => {
  const res = await axios.post(API_URLS.UNBLOCK_IP, data)
  return res.data
}
const ipAddressApi = {
  BlockUser,
  getBlockedUsers,
  unblockUser,
  unblockUser2
}

export default ipAddressApi
