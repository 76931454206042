import axios from "axios"
import { API_URLS } from "../constants/routes"

const searchForms = async (data) => {
  const res = await axios.post(API_URLS.SEARCH_FEEDBACK, data)
  return res.data
}

const checkForm = async (data) => {
  const res = await axios.post(API_URLS.CHECK_FEEDBACK_FORM, data)
  return res.data
}

const feedbackApi = {
  searchForms,
  checkForm
}

export default feedbackApi
