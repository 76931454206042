import { Button, Col, Form, Row } from "react-bootstrap"
import { Container } from "react-bootstrap"
import Header from "../layouts/Header"
import Select from "react-select"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

import sellerApi from "../api/sellerApi"
import { FileXFill, Upload } from "react-bootstrap-icons"
import axios from "axios"
import { API_URLS } from "../constants/routes"
// import "react-quill/dist/quill.snow.css"
import colorApi from "../api/colorApi"
import brandApi from "../api/brandApi"
import modelApi from "../api/modelApi"
import Main from "../components/Main/Main"
import ReactQuill from "react-quill"
import "quill/dist/quill.snow.css"
function CreateCarForm() {
  const [city, setCity] = useState("")
  const [internalId, setInternalId] = useState("")
  const [status, setStatus] = useState("")
  const [chassisNumber, setChassisNumber] = useState("")
  const [engineNumber, setEngineNumber] = useState("")
  const [brand, setBrand] = useState("")
  const [model, setModel] = useState("")
  const [year, setYear] = useState("")
  const [price, setPrice] = useState(0)
  const [priceStatus, setPriceStatus] = useState()
  // const [type, setType] = useState("")
  const [body, setBody] = useState("")
  const [exteriorColor, setExteriorColor] = useState("")
  const [interiorColor, setInteriorColor] = useState("")
  const [gears, setGears] = useState("")
  const [doors, setDoors] = useState("")
  const [category, setCategory] = useState("")
  const [cylinders, setCylinders] = useState(0)
  const [condition, setCondition] = useState("")
  const [horsepower, setHorsepower] = useState(0)
  const [engineSize, setEngineSize] = useState(0)
  const [transmission, setTransmission] = useState("")
  const [legalPapers, setLegalPapers] = useState("")
  const [originalEngine, setOriginalEngine] = useState("")
  const [originalParts, setOriginalParts] = useState("")
  const [odometer, setOdometer] = useState("")
  const [steeringWheel, setSteeringWheel] = useState("")
  const [driveWheels, setDriveWheels] = useState("")
  const [fuelType, setFuelType] = useState("")
  const [country, setCountry] = useState("")
  const [address, setAddress] = useState("")
  const [rentStartingPrice, setRentStartingPrice] = useState("")
  const [description, setDescription] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [sellerType, setSellerType] = useState("")
  const [sellerId, setSellerId] = useState("")
  const [firstRegisteredCountry, setFirstRegisteredCountry] = useState("")
  const [showInteriorColorForm, setShowInteriorColorForm] = useState(false)
  const [showExteriorColorForm, setShowExteriorColorForm] = useState(false)
  const [badge, setBadge] = useState([])
  const [badgeInput, setBadgeInput] = useState("")

  const readImageUrl = (img) => {
    try {
      return URL.createObjectURL(img)
    } catch (error) {
      notify.error(errorMessage(error))
      setInputImages([])
    } finally {
    }
  }

  const [loading, setLoading] = useState(false)

  const [inputImages, setInputImages] = useState([])

  const navigate = useNavigate()

  const [showCreateSeller, setShowCreateSeller] = useState(false)

  const createCar = async () => {
    try {
      setLoading(true)

      const bodyFormData = new FormData()
      bodyFormData.append("firstRegisteredCountry", firstRegisteredCountry)
      bodyFormData.append("chassisNumber", chassisNumber)
      bodyFormData.append("engineNumber", engineNumber)
      bodyFormData.append("brand", brand)
      bodyFormData.append("model", model)
      bodyFormData.append("year", year)
      bodyFormData.append("price", price)
      bodyFormData.append("priceStatus", priceStatus)
      bodyFormData.append("status", status)
      bodyFormData.append("city", city)
      bodyFormData.append("body", body)
      bodyFormData.append("exteriorColor", exteriorColor)
      bodyFormData.append("interiorColor", interiorColor)
      bodyFormData.append("category", category)
      bodyFormData.append("gears", gears)
      bodyFormData.append("doors", doors)
      bodyFormData.append("cylinders", cylinders)
      bodyFormData.append("horsepower", horsepower)
      bodyFormData.append("engineSize", engineSize)
      bodyFormData.append("transmission", transmission)
      bodyFormData.append("legalPapers", legalPapers)
      bodyFormData.append("originalEngine", originalEngine)
      bodyFormData.append("originalParts", originalParts)
      bodyFormData.append("odometer", odometer)
      bodyFormData.append("steeringWheel", steeringWheel)
      bodyFormData.append("driveWheels", driveWheels)
      bodyFormData.append("fuelType", fuelType)
      bodyFormData.append("condition", condition)
      bodyFormData.append("country", country)
      bodyFormData.append("address", address)
      bodyFormData.append("rentStartingPrice", rentStartingPrice)
      bodyFormData.append("description", description)
      bodyFormData.append("sellerId", seller.value)

      for (let img of inputImages) {
        bodyFormData.append("image", img)
      }

      await axios.post(API_URLS.CREATE_CAR, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      notify.success("Car created succesfully")
      navigate("/cars")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const [sellerLoading, setSellerLoading] = useState(false)
  const [sellers, setSellers] = useState([])

  const clearSellerFormInputs = () => {
    setFirstName("")
    setLastName("")
    setPhone("")
    setSellerType("")
  }
  const createSeller = async () => {
    try {
      setSellerLoading(true)
      await sellerApi.createSeller({
        firstName,
        lastName,
        phone,
        type: sellerType,
        countryCode
      })
      notify.success("seller created succesfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setSellerLoading(false)
      clearSellerFormInputs()
      getSellers()
    }
  }

  const [sellersOptions, setSellersOptions] = useState([])
  const getSellers = async () => {
    try {
      setSellerLoading(true)
      setSellersOptions([])
      const data = await sellerApi.getSellers()
      //i need to just kill myself
      for (let seller of data) {
        setSellersOptions((sellersOptions) => [
          ...sellersOptions,
          { value: seller._id, label: `${seller.firstName} ${seller.lastName}` }
        ])
      }

      setSellers(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setSellerLoading(false)
    }
  }

  const [colorsLoading, setColorsLoading] = useState(false)
  const [colorsData, setColorsData] = useState([])
  const getColors = async () => {
    try {
      setColorsLoading(true)
      const data = await colorApi.getColors()
      setColorsData(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setColorsLoading(false)
    }
  }

  const [colorName, setColorName] = useState("")
  const createColor = async () => {
    try {
      if (!colorName) {
        notify.error("Please fill color name input")
        return
      }
      setColorsLoading(true)
      await colorApi.addColor({ name: colorName })
      setColorName("")
      notify.success("Color Created Successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setColorsLoading(false)
      getColors()
    }
  }

  const [colorId, setColorId] = useState("")
  const deleteColor = async () => {
    try {
      if (!colorId) {
        notify.error("Please choose color to delete")
        return
      }
      setColorsLoading(true)
      await colorApi.deleteColor({ id: colorId })
      notify.success("Color deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setColorsLoading(false)
      getColors()
    }
  }

  const [brandsData, setBrandsData] = useState([])
  const [loadingBrands, setLoadingBrands] = useState(false)
  const [showBrandForm, setShowBrandForm] = useState(false)
  const getBrands = async () => {
    try {
      setLoadingBrands(true)
      const data = await brandApi.getBrands()
      if (data) setBrandsData(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingBrands(false)
    }
  }
  const [brandName, setBrandName] = useState("")
  const createBrand = async () => {
    try {
      setLoadingBrands(true)
      await brandApi.createBrand({ name: brandName })
      setBrandName("")
      setModelName("")
      setShowBrandForm(false)
      setShowModelForm(false)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingBrands(false)
      getBrands()
    }
  }

  const [brandId, setBrandId] = useState()
  const [modelName, setModelName] = useState("")
  const [seller, setSeller] = useState()
  const [showModelForm, setShowModelForm] = useState(false)
  const [modelsData, setModelsData] = useState([])
  const createModel = async () => {
    try {
      setLoadingBrands(true)
      await modelApi.createModel({ name: modelName, brandId: brandId })
      setModel("")
      setBrand("")
      setShowModelForm(false)
      setShowBrandForm(false)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingBrands(false)
      getBrands()
    }
  }
  useEffect(() => {
    getSellers()
    getColors()
    getBrands()
  }, [])

  return (
    <>
      <Main>
        <Container>
          {loading ? (
            <h3 style={{ textAlign: "center" }}>Creating car...</h3>
          ) : (
            <>
              <h1 className='text-left mb-4 mt-1'>
                Create <span className='text-primary'>Car</span>
              </h1>

              <div className='w-100 bg-white'>
                <div className='p-3 shadow mb-3'>
                  {sellerLoading ? (
                    "Loading..."
                  ) : (
                    <>
                      <Form.Group>
                        <Form.Label>Choose Seller</Form.Label>
                        <Select
                          options={sellersOptions}
                          value={seller}
                          onChange={(e) => setSeller(e)}
                          placeholder={"Search for sellers here..."}
                        />
                      </Form.Group>
                      <Button
                        onClick={() => setShowCreateSeller(!showCreateSeller)}
                        className='w-100 mt-3'
                        variant='outline-success'
                      >
                        Create Seller
                      </Button>
                      {showCreateSeller && (
                        <>
                          <h3 className='text-left mb-2 mt-3'>
                            Create <span className='text-success'>Seller</span>
                          </h3>
                          <Form.Group className='mb-2'>
                            <Form.Label>FirstName</Form.Label>
                            <Form.Control
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              placeholder='First Name'
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group className='mb-2'>
                            <Form.Label>LastName</Form.Label>
                            <Form.Control
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              placeholder='Last Name'
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control placeholder='Email' />
                          </Form.Group>

                          <Form.Group className='mb-2'>
                            <Form.Label>Phone</Form.Label>
                            <Row>
                              <Col xs={4}>
                                <Form.Select
                                  value={countryCode}
                                  onChange={(e) =>
                                    setCountryCode(e.target.value)
                                  }
                                >
                                  <option value=''>Country Code</option>
                                  <option value='93'>Afghanistan +93</option>
                                  <option value='358'>
                                    Aland Islands +358
                                  </option>
                                  <option value='355'>Albania +355</option>
                                  <option value='213'>Algeria +213</option>
                                  <option value='1684'>
                                    American Samoa +1684
                                  </option>
                                  <option value='376'>Andorra +376</option>
                                  <option value='244'>Angola +244</option>
                                  <option value='1264'>Anguilla +1264</option>
                                  <option value='672'>Antarctica +672</option>
                                  <option value='1268'>
                                    Antigua and Barbuda +1268
                                  </option>
                                  <option value='54'>Argentina +54</option>
                                  <option value='374'>Armenia +374</option>
                                  <option value='297'>Aruba +297</option>
                                  <option value='61'>Australia +61</option>
                                  <option value='43'>Austria +43</option>
                                  <option value='994'>Azerbaijan +994</option>
                                  <option value='1242'>Bahamas +1242</option>
                                  <option value='973'>Bahrain +973</option>
                                  <option value='880'>Bangladesh +880</option>
                                  <option value='1246'>Barbados +1246</option>
                                  <option value='375'>Belarus +375</option>
                                  <option value='32'>Belgium +32</option>
                                  <option value='501'>Belize +501</option>
                                  <option value='229'>Benin +229</option>
                                  <option value='1441'>Bermuda +1441</option>
                                  <option value='975'>Bhutan +975</option>
                                  <option value='591'>Bolivia +591</option>
                                  <option value='599'>
                                    Bonaire, Sint Eustatius and Saba +599
                                  </option>
                                  <option value='387'>
                                    Bosnia and Herzegovina +387
                                  </option>
                                  <option value='267'>Botswana +267</option>
                                  <option value='55'>Bouvet Island +55</option>
                                  <option value='55'>Brazil +55</option>
                                  <option value='246'>
                                    British Indian Ocean Territory +246
                                  </option>
                                  <option value='673'>
                                    Brunei Darussalam +673
                                  </option>
                                  <option value='359'>Bulgaria +359</option>
                                  <option value='226'>Burkina Faso +226</option>
                                  <option value='257'>Burundi +257</option>
                                  <option value='855'>Cambodia +855</option>
                                  <option value='237'>Cameroon +237</option>
                                  <option value='1'>Canada +1</option>
                                  <option value='238'>Cape Verde +238</option>
                                  <option value='1345'>
                                    Cayman Islands +1345
                                  </option>
                                  <option value='236'>
                                    Central African Republic +236
                                  </option>
                                  <option value='235'>Chad +235</option>
                                  <option value='56'>Chile +56</option>
                                  <option value='86'>China +86</option>
                                  <option value='61'>
                                    Christmas Island +61
                                  </option>
                                  <option value='672'>
                                    Cocos (Keeling) Islands +672
                                  </option>
                                  <option value='57'>Colombia +57</option>
                                  <option value='269'>Comoros +269</option>
                                  <option value='242'>Congo +242</option>
                                  <option value='242'>
                                    Congo, Democratic Republic of the Congo +242
                                  </option>
                                  <option value='682'>Cook Islands +682</option>
                                  <option value='506'>Costa Rica +506</option>
                                  <option value='225'>
                                    Cote D'Ivoire +225
                                  </option>
                                  <option value='385'>Croatia +385</option>
                                  <option value='53'>Cuba +53</option>
                                  <option value='599'>Curacao +599</option>
                                  <option value='357'>Cyprus +357</option>
                                  <option value='420'>
                                    Czech Republic +420
                                  </option>
                                  <option value='45'>Denmark +45</option>
                                  <option value='253'>Djibouti +253</option>
                                  <option value='1767'>Dominica +1767</option>
                                  <option value='1809'>
                                    Dominican Republic +1809
                                  </option>
                                  <option value='593'>Ecuador +593</option>
                                  <option value='20'>Egypt +20</option>
                                  <option value='503'>El Salvador +503</option>
                                  <option value='240'>
                                    Equatorial Guinea +240
                                  </option>
                                  <option value='291'>Eritrea +291</option>
                                  <option value='372'>Estonia +372</option>
                                  <option value='251'>Ethiopia +251</option>
                                  <option value='500'>
                                    Falkland Islands (Malvinas) +500
                                  </option>
                                  <option value='298'>
                                    Faroe Islands +298
                                  </option>
                                  <option value='679'>Fiji +679</option>
                                  <option value='358'>Finland +358</option>
                                  <option value='33'>France +33</option>
                                  <option value='594'>
                                    French Guiana +594
                                  </option>
                                  <option value='689'>
                                    French Polynesia +689
                                  </option>
                                  <option value='262'>
                                    French Southern Territories +262
                                  </option>
                                  <option value='241'>Gabon +241</option>
                                  <option value='220'>Gambia +220</option>
                                  <option value='995'>Georgia +995</option>
                                  <option value='49'>Germany +49</option>
                                  <option value='233'>Ghana +233</option>
                                  <option value='350'>Gibraltar +350</option>
                                  <option value='30'>Greece +30</option>
                                  <option value='299'>Greenland +299</option>
                                  <option value='1473'>Grenada +1473</option>
                                  <option value='590'>Guadeloupe +590</option>
                                  <option value='1671'>Guam +1671</option>
                                  <option value='502'>Guatemala +502</option>
                                  <option value='44'>Guernsey +44</option>
                                  <option value='224'>Guinea +224</option>
                                  <option value='245'>
                                    Guinea-Bissau +245
                                  </option>
                                  <option value='592'>Guyana +592</option>
                                  <option value='509'>Haiti +509</option>
                                  <option value='0'>
                                    Heard Island and Mcdonald Islands +0
                                  </option>
                                  <option value='39'>
                                    Holy See (Vatican City State) +39
                                  </option>
                                  <option value='504'>Honduras +504</option>
                                  <option value='852'>Hong Kong +852</option>
                                  <option value='36'>Hungary +36</option>
                                  <option value='354'>Iceland +354</option>
                                  <option value='91'>India +91</option>
                                  <option value='62'>Indonesia +62</option>
                                  <option value='98'>
                                    Iran, Islamic Republic of +98
                                  </option>
                                  <option value='964'>Iraq +964</option>
                                  <option value='353'>Ireland +353</option>
                                  <option value='44'>Isle of Man +44</option>
                                  <option value='972'>Israel +972</option>
                                  <option value='39'>Italy +39</option>
                                  <option value='1876'>Jamaica +1876</option>
                                  <option value='81'>Japan +81</option>
                                  <option value='44'>Jersey +44</option>
                                  <option value='962'>Jordan +962</option>
                                  <option value='7'>Kazakhstan +7</option>
                                  <option value='254'>Kenya +254</option>
                                  <option value='686'>Kiribati +686</option>
                                  <option value='850'>
                                    Korea, Democratic People's Republic of +850
                                  </option>
                                  <option value='82'>
                                    Korea, Republic of +82
                                  </option>
                                  <option value='381'>Kosovo +381</option>
                                  <option value='965'>Kuwait +965</option>
                                  <option value='996'>Kyrgyzstan +996</option>
                                  <option value='856'>
                                    Lao People's Democratic Republic +856
                                  </option>
                                  <option value='371'>Latvia +371</option>
                                  <option value='961'>Lebanon +961</option>
                                  <option value='266'>Lesotho +266</option>
                                  <option value='231'>Liberia +231</option>
                                  <option value='218'>
                                    Libyan Arab Jamahiriya +218
                                  </option>
                                  <option value='423'>
                                    Liechtenstein +423
                                  </option>
                                  <option value='370'>Lithuania +370</option>
                                  <option value='352'>Luxembourg +352</option>
                                  <option value='853'>Macao +853</option>
                                  <option value='389'>
                                    Macedonia, the Former Yugoslav Republic of
                                    +389
                                  </option>
                                  <option value='261'>Madagascar +261</option>
                                  <option value='265'>Malawi +265</option>
                                  <option value='60'>Malaysia +60</option>
                                  <option value='960'>Maldives +960</option>
                                  <option value='223'>Mali +223</option>
                                  <option value='356'>Malta +356</option>
                                  <option value='692'>
                                    Marshall Islands +692
                                  </option>
                                  <option value='596'>Martinique +596</option>
                                  <option value='222'>Mauritania +222</option>
                                  <option value='230'>Mauritius +230</option>
                                  <option value='262'>Mayotte +262</option>
                                  <option value='52'>Mexico +52</option>
                                  <option value='691'>
                                    Micronesia, Federated States of +691
                                  </option>
                                  <option value='373'>
                                    Moldova, Republic of +373
                                  </option>
                                  <option value='377'>Monaco +377</option>
                                  <option value='976'>Mongolia +976</option>
                                  <option value='382'>Montenegro +382</option>
                                  <option value='1664'>Montserrat +1664</option>
                                  <option value='212'>Morocco +212</option>
                                  <option value='258'>Mozambique +258</option>
                                  <option value='95'>Myanmar +95</option>
                                  <option value='264'>Namibia +264</option>
                                  <option value='674'>Nauru +674</option>
                                  <option value='977'>Nepal +977</option>
                                  <option value='31'>Netherlands +31</option>
                                  <option value='599'>
                                    Netherlands Antilles +599
                                  </option>
                                  <option value='687'>
                                    New Caledonia +687
                                  </option>
                                  <option value='64'>New Zealand +64</option>
                                  <option value='505'>Nicaragua +505</option>
                                  <option value='227'>Niger +227</option>
                                  <option value='234'>Nigeria +234</option>
                                  <option value='683'>Niue +683</option>
                                  <option value='672'>
                                    Norfolk Island +672
                                  </option>
                                  <option value='1670'>
                                    Northern Mariana Islands +1670
                                  </option>
                                  <option value='47'>Norway +47</option>
                                  <option value='968'>Oman +968</option>
                                  <option value='92'>Pakistan +92</option>
                                  <option value='680'>Palau +680</option>
                                  <option value='970'>
                                    Palestinian Territory, Occupied +970
                                  </option>
                                  <option value='507'>Panama +507</option>
                                  <option value='675'>
                                    Papua New Guinea +675
                                  </option>
                                  <option value='595'>Paraguay +595</option>
                                  <option value='51'>Peru +51</option>
                                  <option value='63'>Philippines +63</option>
                                  <option value='64'>Pitcairn +64</option>
                                  <option value='48'>Poland +48</option>
                                  <option value='351'>Portugal +351</option>
                                  <option value='1787'>
                                    Puerto Rico +1787
                                  </option>
                                  <option value='974'>Qatar +974</option>
                                  <option value='262'>Reunion +262</option>
                                  <option value='40'>Romania +40</option>
                                  <option value='70'>
                                    Russian Federation +70
                                  </option>
                                  <option value='250'>Rwanda +250</option>
                                  <option value='590'>
                                    Saint Barthelemy +590
                                  </option>
                                  <option value='290'>Saint Helena +290</option>
                                  <option value='1869'>
                                    Saint Kitts and Nevis +1869
                                  </option>
                                  <option value='1758'>
                                    Saint Lucia +1758
                                  </option>
                                  <option value='590'>Saint Martin +590</option>
                                  <option value='508'>
                                    Saint Pierre and Miquelon +508
                                  </option>
                                  <option value='1784'>
                                    Saint Vincent and the Grenadines +1784
                                  </option>
                                  <option value='684'>Samoa +684</option>
                                  <option value='378'>San Marino +378</option>
                                  <option value='239'>
                                    Sao Tome and Principe +239
                                  </option>
                                  <option value='966'>Saudi Arabia +966</option>
                                  <option value='221'>Senegal +221</option>
                                  <option value='381'>Serbia +381</option>
                                  <option value='381'>
                                    Serbia and Montenegro +381
                                  </option>
                                  <option value='248'>Seychelles +248</option>
                                  <option value='232'>Sierra Leone +232</option>
                                  <option value='65'>Singapore +65</option>
                                  <option value='1'>Sint Maarten +1</option>
                                  <option value='421'>Slovakia +421</option>
                                  <option value='386'>Slovenia +386</option>
                                  <option value='677'>
                                    Solomon Islands +677
                                  </option>
                                  <option value='252'>Somalia +252</option>
                                  <option value='27'>South Africa +27</option>
                                  <option value='500'>
                                    South Georgia and the South Sandwich Islands
                                    +500
                                  </option>
                                  <option value='211'>South Sudan +211</option>
                                  <option value='34'>Spain +34</option>
                                  <option value='94'>Sri Lanka +94</option>
                                  <option value='249'>Sudan +249</option>
                                  <option value='597'>Suriname +597</option>
                                  <option value='47'>
                                    Svalbard and Jan Mayen +47
                                  </option>
                                  <option value='268'>Swaziland +268</option>
                                  <option value='46'>Sweden +46</option>
                                  <option value='41'>Switzerland +41</option>
                                  <option value='963'>
                                    Syrian Arab Republic +963
                                  </option>
                                  <option value='886'>
                                    Taiwan, Province of China +886
                                  </option>
                                  <option value='992'>Tajikistan +992</option>
                                  <option value='255'>
                                    Tanzania, United Republic of +255
                                  </option>
                                  <option value='66'>Thailand +66</option>
                                  <option value='670'>Timor-Leste +670</option>
                                  <option value='228'>Togo +228</option>
                                  <option value='690'>Tokelau +690</option>
                                  <option value='676'>Tonga +676</option>
                                  <option value='1868'>
                                    Trinidad and Tobago +1868
                                  </option>
                                  <option value='216'>Tunisia +216</option>
                                  <option value='90'>Turkey +90</option>
                                  <option value='7370'>
                                    Turkmenistan +7370
                                  </option>
                                  <option value='1649'>
                                    Turks and Caicos Islands +1649
                                  </option>
                                  <option value='688'>Tuvalu +688</option>
                                  <option value='256'>Uganda +256</option>
                                  <option value='380'>Ukraine +380</option>
                                  <option value='971'>
                                    United Arab Emirates +971
                                  </option>
                                  <option value='44'>United Kingdom +44</option>
                                  <option value='1'>United States +1</option>
                                  <option value='1'>
                                    United States Minor Outlying Islands +1
                                  </option>
                                  <option value='598'>Uruguay +598</option>
                                  <option value='998'>Uzbekistan +998</option>
                                  <option value='678'>Vanuatu +678</option>
                                  <option value='58'>Venezuela +58</option>
                                  <option value='84'>Viet Nam +84</option>
                                  <option value='1284'>
                                    Virgin Islands, British +1284
                                  </option>
                                  <option value='1340'>
                                    Virgin Islands, U.s. +1340
                                  </option>
                                  <option value='681'>
                                    Wallis and Futuna +681
                                  </option>
                                  <option value='212'>
                                    Western Sahara +212
                                  </option>
                                  <option value='967'>Yemen +967</option>
                                  <option value='260'>Zambia +260</option>
                                  <option value='263'>Zimbabwe +263</option>
                                </Form.Select>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  placeholder='Phone'
                                ></Form.Control>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className='mb-2'>
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                              value={sellerType}
                              onChange={(e) => setSellerType(e.target.value)}
                            >
                              <option value=''>Seller Type</option>
                              <option value='Free'>Free</option>
                              <option value='Unloaded'>Unloaded</option>
                              <option value='Commission'>Commission</option>
                              <option value='filtered'>Filtered</option>
                            </Form.Select>
                          </Form.Group>

                          <Button
                            className='btn-success mt-3'
                            onClick={(e) => {
                              e.preventDefault()
                              createSeller()
                            }}
                          >
                            Create Seller
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className='d-flex flex-column justify-content-center align-items-start gap-2 p-2'>
                  <h1 class='display-3'>Details</h1>
                  <Row>
                    {/* <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Internal ID</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Internal ID'
                          onChange={(e) => setInternalId(e.target.value)}
                          value={internalId}
                        />
                      </Form.Group>
                    </Col> */}

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Brand</Form.Label>
                        <Form.Select
                          disabled={loadingBrands}
                          onChange={(e) => {
                            if (e.target.value == "") {
                              setBrand("")
                              setBrandId("")
                              setModelsData([])
                            } else {
                              let br = JSON.parse(e.target.value)
                              setModelsData(br.models)
                              setBrand(br.name)
                              setBrandId(br._id)
                            }
                          }}
                        >
                          {loadingBrands ? (
                            <option value=''>Loading...</option>
                          ) : (
                            <>
                              <option value=''>Brand</option>

                              {brandsData.map((b) => {
                                return (
                                  <option value={JSON.stringify(b)}>
                                    {b.name}
                                  </option>
                                )
                              })}
                            </>
                          )}
                        </Form.Select>
                        {!loadingBrands && (
                          <>
                            <Button
                              variant='outline-success'
                              className='w-100'
                              disabled={loadingBrands}
                              onClick={() => setShowBrandForm(!showBrandForm)}
                            >
                              Create Brand
                            </Button>
                            {showBrandForm && (
                              <Form.Group className='shadow p-2'>
                                <Form.Control
                                  value={brandName}
                                  onChange={(e) => setBrandName(e.target.value)}
                                  placeholder='Brand Name'
                                />
                                <Button onClick={createBrand} variant='success'>
                                  Add Brand
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Model</Form.Label>

                        <Form.Select
                          value={model}
                          onChange={(e) => setModel(e.target.value)}
                          disabled={brand === "" || loadingBrands}
                        >
                          {loadingBrands ? (
                            <option value=''>Loading...</option>
                          ) : (
                            <>
                              <option>Model</option>
                              {modelsData?.map((m) => (
                                <option value={m.name}>{m.name}</option>
                              ))}
                            </>
                          )}
                        </Form.Select>
                        {!loadingBrands && (
                          <>
                            <Button
                              variant='outline-success'
                              className='w-100'
                              disabled={loadingBrands}
                              onClick={() => setShowModelForm(!showModelForm)}
                            >
                              Create Model
                            </Button>
                            {showModelForm && (
                              <Form.Group className='shadow p-2'>
                                <Form.Control
                                  value={modelName}
                                  onChange={(e) => setModelName(e.target.value)}
                                  placeholder='Model Name'
                                />
                                <Button onClick={createModel} variant='success'>
                                  Add Model
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Year</Form.Label>
                        <Form.Select
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                        >
                          <option value=''>Year</option>
                          <option value='1890'>1890</option>
                          <option value='1891'>1891</option>
                          <option value='1892'>1892</option>
                          <option value='1893'>1893</option>
                          <option value='1894'>1894</option>
                          <option value='1895'>1895</option>
                          <option value='1896'>1896</option>
                          <option value='1897'>1897</option>
                          <option value='1898'>1898</option>
                          <option value='1899'>1899</option>
                          <option value='1900'>1900</option>
                          <option value='1901'>1901</option>
                          <option value='1902'>1902</option>
                          <option value='1903'>1903</option>
                          <option value='1904'>1904</option>
                          <option value='1905'>1905</option>
                          <option value='1906'>1906</option>
                          <option value='1907'>1907</option>
                          <option value='1908'>1908</option>
                          <option value='1909'>1909</option>
                          <option value='1910'>1910</option>
                          <option value='1911'>1911</option>
                          <option value='1912'>1912</option>
                          <option value='1913'>1913</option>
                          <option value='1914'>1914</option>
                          <option value='1915'>1915</option>
                          <option value='1916'>1916</option>
                          <option value='1917'>1917</option>
                          <option value='1918'>1918</option>
                          <option value='1919'>1919</option>
                          <option value='1920'>1920</option>
                          <option value='1921'>1921</option>
                          <option value='1922'>1922</option>
                          <option value='1923'>1923</option>
                          <option value='1924'>1924</option>
                          <option value='1925'>1925</option>
                          <option value='1926'>1926</option>
                          <option value='1927'>1927</option>
                          <option value='1928'>1928</option>
                          <option value='1929'>1929</option>
                          <option value='1930'>1930</option>
                          <option value='1931'>1931</option>
                          <option value='1932'>1932</option>
                          <option value='1933'>1933</option>
                          <option value='1934'>1934</option>
                          <option value='1935'>1935</option>
                          <option value='1936'>1936</option>
                          <option value='1937'>1937</option>
                          <option value='1938'>1938</option>
                          <option value='1939'>1939</option>
                          <option value='1940'>1940</option>
                          <option value='1941'>1941</option>
                          <option value='1942'>1942</option>
                          <option value='1943'>1943</option>
                          <option value='1944'>1944</option>
                          <option value='1945'>1945</option>
                          <option value='1946'>1946</option>
                          <option value='1947'>1947</option>
                          <option value='1948'>1948</option>
                          <option value='1949'>1949</option>
                          <option value='1950'>1950</option>
                          <option value='1951'>1951</option>
                          <option value='1952'>1952</option>
                          <option value='1953'>1953</option>
                          <option value='1954'>1954</option>
                          <option value='1955'>1955</option>
                          <option value='1956'>1956</option>
                          <option value='1957'>1957</option>
                          <option value='1958'>1958</option>
                          <option value='1959'>1959</option>
                          <option value='1960'>1960</option>
                          <option value='1961'>1961</option>
                          <option value='1962'>1962</option>
                          <option value='1963'>1963</option>
                          <option value='1964'>1964</option>
                          <option value='1965'>1965</option>
                          <option value='1966'>1966</option>
                          <option value='1967'>1967</option>
                          <option value='1968'>1968</option>
                          <option value='1969'>1969</option>
                          <option value='1970'>1970</option>
                          <option value='1971'>1971</option>
                          <option value='1972'>1972</option>
                          <option value='1973'>1973</option>
                          <option value='1974'>1974</option>
                          <option value='1975'>1975</option>
                          <option value='1976'>1976</option>
                          <option value='1977'>1977</option>
                          <option value='1978'>1978</option>
                          <option value='1979'>1979</option>
                          <option value='1980'>1980</option>
                          <option value='1981'>1981</option>
                          <option value='1982'>1982</option>
                          <option value='1983'>1983</option>
                          <option value='1984'>1984</option>
                          <option value='1985'>1985</option>
                          <option value='1986'>1986</option>
                          <option value='1987'>1987</option>
                          <option value='1988'>1988</option>
                          <option value='1989'>1989</option>
                          <option value='1990'>1990</option>
                          <option value='1991'>1991</option>
                          <option value='1992'>1992</option>
                          <option value='1993'>1993</option>
                          <option value='1994'>1994</option>
                          <option value='1995'>1995</option>
                          <option value='1996'>1996</option>
                          <option value='1997'>1997</option>
                          <option value='1998'>1998</option>
                          <option value='1999'>1999</option>
                          <option value='2000'>2000</option>
                          <option value='2001'>2001</option>
                          <option value='2002'>2002</option>
                          <option value='2003'>2003</option>
                          <option value='2004'>2004</option>
                          <option value='2005'>2005</option>
                          <option value='2006'>2006</option>
                          <option value='2007'>2007</option>
                          <option value='2008'>2008</option>
                          <option value='2009'>2009</option>
                          <option value='2010'>2010</option>
                          <option value='2011'>2011</option>
                          <option value='2012'>2012</option>
                          <option value='2013'>2013</option>
                          <option value='2014'>2014</option>
                          <option value='2015'>2015</option>
                          <option value='2016'>2016</option>
                          <option value='2017'>2017</option>
                          <option value='2018'>2018</option>
                          <option value='2019'>2019</option>
                          <option value='2020'>2020</option>
                          <option value='2021'>2021</option>
                          <option value='2022'>2022</option>
                          <option value='2023'>2023</option>
                          <option value='2024'>2024</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value=''>Status</option>
                          <option value='For Sale'>For Sale</option>
                          <option value='For Rent'>For Rent</option>
                          <option value='For Sale and Rent'>
                            For Sale and Rent
                          </option>
                          <option value='On Hold'>On Hold</option>
                          <option value='Sold'>Sold</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Price ($)</Form.Label>

                        <Form.Select
                          value={priceStatus}
                          onChange={(e) => setPriceStatus(e.target.value)}
                          type='text'
                        >
                          <option value=''>Price Status</option>
                          <option value={"Request Price"}>Request Price</option>
                          <option value={"Negotiable"}>Negotiable</option>
                          <option value={"Last Price"}>Last Price</option>
                        </Form.Select>

                        <Form.Control
                          defaultValue={0}
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                          type='number'
                          placeholder='Price'
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Category</Form.Label>
                        <Form.Select
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option>Category</option>
                          <option value='Muscle Car'>Muscle Car</option>
                          <option value='Motorcycle'>Motorcyle</option>
                          <option value='Luxury Performance'>
                            Luxury Performance
                          </option>
                          <option value='Future Classics'>
                            Future Classics
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Rent Starting Price ($)</Form.Label>
                        <Form.Control
                          value={rentStartingPrice}
                          onChange={(e) => setRentStartingPrice(e.target.value)}
                          placeholder='Rent Starting Price'
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {/* <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Car Type</Form.Label>
                        <Form.Select
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value=''>Type</option>
                          <option value='Classic'>Classic</option>
                          <option value='Modern'>Modern</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Body</Form.Label>
                        <Form.Select
                          value={body}
                          onChange={(e) => setBody(e.target.value)}
                        >
                          <option value=''>Body</option>
                          <option value='Convertible'>Convertible</option>
                          <option value='Roadster'>Roadster</option>
                          <option value='Coupe'>Coupe</option>
                          <option value='Estate Car'>Estate Car</option>
                          <option value='Hatchback'>Hatchback</option>
                          <option value='Minivan'>Minivian</option>
                          <option value='MPV'>MPV</option>
                          <option value='Off-Road'>Off-Road</option>
                          <option value='Pickup'>Pickup</option>
                          <option value='Sedan'>Sedan </option>
                          <option value='Saloon'>Saloon</option>
                          <option value='Single Seater'>Single Seater</option>
                          <option value='SUV'>SUV</option>
                          <option value='Limousine'>Limousine</option>
                          <option value='Landualet'>Landualet</option>
                          <option value='Other'>Other</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Exterior Color</Form.Label>
                        <Form.Select
                          disabled={colorsLoading}
                          value={exteriorColor}
                          onChange={(e) => setExteriorColor(e.target.value)}
                        >
                          {colorsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            <>
                              <option value=''>Exterior Color</option>
                              {/* <option value='Black'>Black</option>
                              <option value='Blue'>Blue</option>
                              <option value='Brown'>Brown</option>
                              <option value='Burgundy'>Burgundy</option>
                              <option value='Gold'>Gold</option>
                              <option value='Green'>Green</option>
                              <option value='Grey'>Grey</option>
                              <option value='Multicolor'>Multicolor</option>
                              <option value='Orange'>Orange</option>
                              <option value='Other'>Other</option>
                              <option value='Purple'>Purple</option>
                              <option value='Red'>Red</option>
                              <option value='Sandy'>Sandy</option>
                              <option value='Silver'>Silver</option>
                              <option value='Turquoise'>Turquiose</option>
                              <option value='White'>White</option>
                              <option value='Yellow'>Yellow</option> */}
                              {colorsData.map((c) => {
                                return <option value={c.name}>{c.name}</option>
                              })}
                            </>
                          )}
                        </Form.Select>
                        {!colorsLoading && (
                          <>
                            <Button
                              variant='outline-success'
                              className='w-100'
                              onClick={() =>
                                setShowExteriorColorForm(!showExteriorColorForm)
                              }
                            >
                              Create Color
                            </Button>
                            {showExteriorColorForm && (
                              <Form.Group className='shadow p-2'>
                                <Form.Control
                                  value={colorName}
                                  onChange={(e) => setColorName(e.target.value)}
                                  placeholder='Color Name'
                                />
                                <Button onClick={createColor} variant='success'>
                                  Add Color
                                </Button>

                                <Form.Select
                                  value={colorId}
                                  onChange={(e) => setColorId(e.target.value)}
                                >
                                  <option value=''>Color To Delete</option>
                                  {colorsData.map((c) => {
                                    return (
                                      <option value={c._id}>{c.name}</option>
                                    )
                                  })}
                                </Form.Select>
                                <Button onClick={deleteColor} variant='danger'>
                                  Delete Color
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Interior Color</Form.Label>

                        <Form.Select
                          disabled={colorsLoading}
                          value={interiorColor}
                          onChange={(e) => setInteriorColor(e.target.value)}
                        >
                          {colorsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            <>
                              <option value=''>Interior Color</option>
                            </>
                          )}

                          {colorsData.map((c) => {
                            return <option value={c.name}>{c.name}</option>
                          })}
                        </Form.Select>
                        {!colorsLoading && (
                          <>
                            <Button
                              variant='outline-success'
                              className='w-100'
                              onClick={() =>
                                setShowInteriorColorForm(!showInteriorColorForm)
                              }
                            >
                              Create Color
                            </Button>
                            {showInteriorColorForm && (
                              <Form.Group className='shadow p-2'>
                                <Form.Control
                                  value={colorName}
                                  onChange={(e) => setColorName(e.target.value)}
                                  placeholder='Color Name'
                                />
                                <Button onClick={createColor} variant='success'>
                                  Add Color
                                </Button>
                                <Form.Select
                                  value={colorId}
                                  onChange={(e) => setColorId(e.target.value)}
                                >
                                  <option value=''>Color To Delete</option>
                                  {colorsData.map((c) => {
                                    return (
                                      <option value={c._id}>{c.name}</option>
                                    )
                                  })}
                                </Form.Select>
                                <Button onClick={deleteColor} variant='danger'>
                                  Delete Color
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Doors</Form.Label>
                        <Form.Select
                          value={doors}
                          onChange={(e) => setDoors(e.target.value)}
                          placeholder='Doors'
                        >
                          <option value='0'>Doors</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group>
                        <Form.Label>Condition</Form.Label>
                        <Form.Select
                          value={condition}
                          onChange={(e) => setCondition(e.target.value)}
                        >
                          <option value=''>Condition</option>
                          <option value='Damaged'>Damaged</option>
                          <option value='For Parts'>For Parts</option>
                          <option value='New'>New</option>
                          <option value='Original'>Original</option>
                          <option value='Other'>Other</option>
                          <option value='Replica'>Replica</option>
                          <option value='Restored'>Restored</option>
                          <option value='Used'>Used</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>First Registered Country</Form.Label>
                        <Form.Select
                          value={firstRegisteredCountry}
                          onChange={(e) =>
                            setFirstRegisteredCountry(e.target.value)
                          }
                        >
                          <option value=''>First Registered Country</option>
                          <option value='Afghanistan'>Afghanistan</option>
                          <option value='Aland Islands'>Aland Islands</option>
                          <option value='Albania'>Albania</option>
                          <option value='Algeria'>Algeria</option>
                          <option value='American Samoa'>American Samoa</option>
                          <option value='Andorra'>Andorra</option>
                          <option value='Angola'>Angola</option>
                          <option value='Anguilla'>Anguilla</option>
                          <option value='Antarctica'>Antarctica</option>
                          <option value='Antigua and Barbuda'>
                            Antigua and Barbuda
                          </option>
                          <option value='Argentina'>Argentina</option>
                          <option value='Armenia'>Armenia</option>
                          <option value='Aruba'>Aruba</option>
                          <option value='Australia'>Australia</option>
                          <option value='Austria'>Austria</option>
                          <option value='Azerbaijan'>Azerbaijan</option>
                          <option value='Bahamas'>Bahamas</option>
                          <option value='Bahrain'>Bahrain</option>
                          <option value='Bangladesh'>Bangladesh</option>
                          <option value='Barbados'>Barbados</option>
                          <option value='Belarus'>Belarus</option>
                          <option value='Belgium'>Belgium</option>
                          <option value='Belize'>Belize</option>
                          <option value='Benin'>Benin</option>
                          <option value='Bermuda'>Bermuda</option>
                          <option value='Bhutan'>Bhutan</option>
                          <option value='Bolivia'>Bolivia</option>
                          <option value='Bonaire, Sint Eustatius and Saba'>
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value='Bosnia and Herzegovina'>
                            Bosnia and Herzegovina
                          </option>
                          <option value='Botswana'>Botswana</option>
                          <option value='Bouvet Island'>Bouvet Island</option>
                          <option value='Brazil'>Brazil</option>
                          <option value='British Indian Ocean Territory'>
                            British Indian Ocean Territory
                          </option>
                          <option value='Brunei Darussalam'>
                            Brunei Darussalam
                          </option>
                          <option value='Bulgaria'>Bulgaria</option>
                          <option value='Burkina Faso'>Burkina Faso</option>
                          <option value='Burundi'>Burundi</option>
                          <option value='Cambodia'>Cambodia</option>
                          <option value='Cameroon'>Cameroon</option>
                          <option value='Canada'>Canada</option>
                          <option value='Cape Verde'>Cape Verde</option>
                          <option value='Cayman Islands'>Cayman Islands</option>
                          <option value='Central African Republic'>
                            Central African Republic
                          </option>
                          <option value='Chad'>Chad</option>
                          <option value='Chile'>Chile</option>
                          <option value='China'>China</option>
                          <option value='Christmas Island'>
                            Christmas Island
                          </option>
                          <option value='Cocos (Keeling) Islands'>
                            Cocos (Keeling) Islands
                          </option>
                          <option value='Colombia'>Colombia</option>
                          <option value='Comoros'>Comoros</option>
                          <option value='Congo'>Congo</option>
                          <option value='Congo, Democratic Republic of the Congo'>
                            Congo, Democratic Republic of the Congo
                          </option>
                          <option value='Cook Islands'>Cook Islands</option>
                          <option value='Costa Rica'>Costa Rica</option>
                          <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                          <option value='Croatia'>Croatia</option>
                          <option value='Cuba'>Cuba</option>
                          <option value='Curacao'>Curacao</option>
                          <option value='Cyprus'>Cyprus</option>
                          <option value='Czech Republic'>Czech Republic</option>
                          <option value='Denmark'>Denmark</option>
                          <option value='Djibouti'>Djibouti</option>
                          <option value='Dominica'>Dominica</option>
                          <option value='Dominican Republic'>
                            Dominican Republic
                          </option>
                          <option value='Ecuador'>Ecuador</option>
                          <option value='Egypt'>Egypt</option>
                          <option value='El Salvador'>El Salvador</option>
                          <option value='Equatorial Guinea'>
                            Equatorial Guinea
                          </option>
                          <option value='Eritrea'>Eritrea</option>
                          <option value='Estonia'>Estonia</option>
                          <option value='Ethiopia'>Ethiopia</option>
                          <option value='Falkland Islands (Malvinas)'>
                            Falkland Islands (Malvinas)
                          </option>
                          <option value='Faroe Islands'>Faroe Islands</option>
                          <option value='Fiji'>Fiji</option>
                          <option value='Finland'>Finland</option>
                          <option value='France'>France</option>
                          <option value='French Guiana'>French Guiana</option>
                          <option value='French Polynesia'>
                            French Polynesia
                          </option>
                          <option value='French Southern Territories'>
                            French Southern Territories
                          </option>
                          <option value='Gabon'>Gabon</option>
                          <option value='Gambia'>Gambia</option>
                          <option value='Georgia'>Georgia</option>
                          <option value='Germany'>Germany</option>
                          <option value='Ghana'>Ghana</option>
                          <option value='Gibraltar'>Gibraltar</option>
                          <option value='Greece'>Greece</option>
                          <option value='Greenland'>Greenland</option>
                          <option value='Grenada'>Grenada</option>
                          <option value='Guadeloupe'>Guadeloupe</option>
                          <option value='Guam'>Guam</option>
                          <option value='Guatemala'>Guatemala</option>
                          <option value='Guernsey'>Guernsey</option>
                          <option value='Guinea'>Guinea</option>
                          <option value='Guinea-Bissau'>Guinea-Bissau</option>
                          <option value='Guyana'>Guyana</option>
                          <option value='Haiti'>Haiti</option>
                          <option value='Heard Island and Mcdonald Islands'>
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value='Holy See (Vatican City State)'>
                            Holy See (Vatican City State)
                          </option>
                          <option value='Honduras'>Honduras</option>
                          <option value='Hong Kong'>Hong Kong</option>
                          <option value='Hungary'>Hungary</option>
                          <option value='Iceland'>Iceland</option>
                          <option value='India'>India</option>
                          <option value='Indonesia'>Indonesia</option>
                          <option value='Iran, Islamic Republic of'>
                            Iran, Islamic Republic of
                          </option>
                          <option value='Iraq'>Iraq</option>
                          <option value='Ireland'>Ireland</option>
                          <option value='Isle of Man'>Isle of Man</option>
                          <option value='Israel'>Israel</option>
                          <option value='Italy'>Italy</option>
                          <option value='Jamaica'>Jamaica</option>
                          <option value='Japan'>Japan</option>
                          <option value='Jersey'>Jersey</option>
                          <option value='Jordan'>Jordan</option>
                          <option value='Kazakhstan'>Kazakhstan</option>
                          <option value='Kenya'>Kenya</option>
                          <option value='Kiribati'>Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value='Korea, Republic of'>
                            Korea, Republic of
                          </option>
                          <option value='Kosovo'>Kosovo</option>
                          <option value='Kuwait'>Kuwait</option>
                          <option value='Kyrgyzstan'>Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value='Latvia'>Latvia</option>
                          <option value='Lebanon'>Lebanon</option>
                          <option value='Lesotho'>Lesotho</option>
                          <option value='Liberia'>Liberia</option>
                          <option value='Libyan Arab Jamahiriya'>
                            Libyan Arab Jamahiriya
                          </option>
                          <option value='Liechtenstein'>Liechtenstein</option>
                          <option value='Lithuania'>Lithuania</option>
                          <option value='Luxembourg'>Luxembourg</option>
                          <option value='Macao'>Macao</option>
                          <option value='Macedonia, the Former Yugoslav Republic of'>
                            Macedonia, the Former Yugoslav Republic of
                          </option>
                          <option value='Madagascar'>Madagascar</option>
                          <option value='Malawi'>Malawi</option>
                          <option value='Malaysia'>Malaysia</option>
                          <option value='Maldives'>Maldives</option>
                          <option value='Mali'>Mali</option>
                          <option value='Malta'>Malta</option>
                          <option value='Marshall Islands'>
                            Marshall Islands
                          </option>
                          <option value='Martinique'>Martinique</option>
                          <option value='Mauritania'>Mauritania</option>
                          <option value='Mauritius'>Mauritius</option>
                          <option value='Mayotte'>Mayotte</option>
                          <option value='Mexico'>Mexico</option>
                          <option value='Micronesia, Federated States of'>
                            Micronesia, Federated States of
                          </option>
                          <option value='Moldova, Republic of'>
                            Moldova, Republic of
                          </option>
                          <option value='Monaco'>Monaco</option>
                          <option value='Mongolia'>Mongolia</option>
                          <option value='Montenegro'>Montenegro</option>
                          <option value='Montserrat'>Montserrat</option>
                          <option value='Morocco'>Morocco</option>
                          <option value='Mozambique'>Mozambique</option>
                          <option value='Myanmar'>Myanmar</option>
                          <option value='Namibia'>Namibia</option>
                          <option value='Nauru'>Nauru</option>
                          <option value='Nepal'>Nepal</option>
                          <option value='Netherlands'>Netherlands</option>
                          <option value='Netherlands Antilles'>
                            Netherlands Antilles
                          </option>
                          <option value='New Caledonia'>New Caledonia</option>
                          <option value='New Zealand'>New Zealand</option>
                          <option value='Nicaragua'>Nicaragua</option>
                          <option value='Niger'>Niger</option>
                          <option value='Nigeria'>Nigeria</option>
                          <option value='Niue'>Niue</option>
                          <option value='Norfolk Island'>Norfolk Island</option>
                          <option value='Northern Mariana Islands'>
                            Northern Mariana Islands
                          </option>
                          <option value='Norway'>Norway</option>
                          <option value='Oman'>Oman</option>
                          <option value='Pakistan'>Pakistan</option>
                          <option value='Palau'>Palau</option>
                          <option value='Palestinian Territory, Occupied'>
                            Palestinian Territory, Occupied
                          </option>
                          <option value='Panama'>Panama</option>
                          <option value='Papua New Guinea'>
                            Papua New Guinea
                          </option>
                          <option value='Paraguay'>Paraguay</option>
                          <option value='Peru'>Peru</option>
                          <option value='Philippines'>Philippines</option>
                          <option value='Pitcairn'>Pitcairn</option>
                          <option value='Poland'>Poland</option>
                          <option value='Portugal'>Portugal</option>
                          <option value='Puerto Rico'>Puerto Rico</option>
                          <option value='Qatar'>Qatar</option>
                          <option value='Reunion'>Reunion</option>
                          <option value='Romania'>Romania</option>
                          <option value='Russian Federation'>
                            Russian Federation
                          </option>
                          <option value='Rwanda'>Rwanda</option>
                          <option value='Saint Barthelemy'>
                            Saint Barthelemy
                          </option>
                          <option value='Saint Helena'>Saint Helena</option>
                          <option value='Saint Kitts and Nevis'>
                            Saint Kitts and Nevis
                          </option>
                          <option value='Saint Lucia'>Saint Lucia</option>
                          <option value='Saint Martin'>Saint Martin</option>
                          <option value='Saint Pierre and Miquelon'>
                            Saint Pierre and Miquelon
                          </option>
                          <option value='Saint Vincent and the Grenadines'>
                            Saint Vincent and the Grenadines
                          </option>
                          <option value='Samoa'>Samoa</option>
                          <option value='San Marino'>San Marino</option>
                          <option value='Sao Tome and Principe'>
                            Sao Tome and Principe
                          </option>
                          <option value='Saudi Arabia'>Saudi Arabia</option>
                          <option value='Senegal'>Senegal</option>
                          <option value='Serbia'>Serbia</option>
                          <option value='Serbia and Montenegro'>
                            Serbia and Montenegro
                          </option>
                          <option value='Seychelles'>Seychelles</option>
                          <option value='Sierra Leone'>Sierra Leone</option>
                          <option value='Singapore'>Singapore</option>
                          <option value='Sint Maarten'>Sint Maarten</option>
                          <option value='Slovakia'>Slovakia</option>
                          <option value='Slovenia'>Slovenia</option>
                          <option value='Solomon Islands'>
                            Solomon Islands
                          </option>
                          <option value='Somalia'>Somalia</option>
                          <option value='South Africa'>South Africa</option>
                          <option value='South Georgia and the South Sandwich Islands'>
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value='South Sudan'>South Sudan</option>
                          <option value='Spain'>Spain</option>
                          <option value='Sri Lanka'>Sri Lanka</option>
                          <option value='Sudan'>Sudan</option>
                          <option value='Suriname'>Suriname</option>
                          <option value='Svalbard and Jan Mayen'>
                            Svalbard and Jan Mayen
                          </option>
                          <option value='Swaziland'>Swaziland</option>
                          <option value='Sweden'>Sweden</option>
                          <option value='Switzerland'>Switzerland</option>
                          <option value='Syrian Arab Republic'>
                            Syrian Arab Republic
                          </option>
                          <option value='Taiwan, Province of China'>
                            Taiwan, Province of China
                          </option>
                          <option value='Tajikistan'>Tajikistan</option>
                          <option value='Tanzania, United Republic of'>
                            Tanzania, United Republic of
                          </option>
                          <option value='Thailand'>Thailand</option>
                          <option value='Timor-Leste'>Timor-Leste</option>
                          <option value='Togo'>Togo</option>
                          <option value='Tokelau'>Tokelau</option>
                          <option value='Tonga'>Tonga</option>
                          <option value='Trinidad and Tobago'>
                            Trinidad and Tobago
                          </option>
                          <option value='Tunisia'>Tunisia</option>
                          <option value='Turkey'>Turkey</option>
                          <option value='Turkmenistan'>Turkmenistan</option>
                          <option value='Turks and Caicos Islands'>
                            Turks and Caicos Islands
                          </option>
                          <option value='Tuvalu'>Tuvalu</option>
                          <option value='Uganda'>Uganda</option>
                          <option value='Ukraine'>Ukraine</option>
                          <option value='United Arab Emirates'>
                            United Arab Emirates
                          </option>
                          <option value='United Kingdom'>United Kingdom</option>
                          <option value='United States'>United States</option>
                          <option value='United States Minor Outlying Islands'>
                            United States Minor Outlying Islands
                          </option>
                          <option value='Uruguay'>Uruguay</option>
                          <option value='Uzbekistan'>Uzbekistan</option>
                          <option value='Vanuatu'>Vanuatu</option>
                          <option value='Venezuela'>Venezuela</option>
                          <option value='Viet Nam'>Viet Nam</option>
                          <option value='Virgin Islands, British'>
                            Virgin Islands, British
                          </option>
                          <option value='Virgin Islands, U.s.'>
                            Virgin Islands, U.s.
                          </option>
                          <option value='Wallis and Futuna'>
                            Wallis and Futuna
                          </option>
                          <option value='Western Sahara'>Western Sahara</option>
                          <option value='Yemen'>Yemen</option>
                          <option value='Zambia'>Zambia</option>
                          <option value='Zimbabwe'>Zimbabwe</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          value={address}
                          placeholder='Address'
                          onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Select
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          placeholder='City'
                        >
                          <option value=''>City</option>
                          <option value='Baabda'>Baabda</option>
                          <option value='Chouf'>Chouf</option>
                          <option value='Aley'>Aley</option>
                          <option value='Bekaa'>Bekaa</option>
                          <option value='Metn'>Men</option>
                          <option value='Beirut'>Beirut</option>
                          <option value='South'>South</option>
                          <option value='North'>North</option>
                          <option value='Jbeil'>Jbeil</option>
                          <option value='Keserouan'>Keserouan</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value=''>Country</option>
                          <option value='Afghanistan'>Afghanistan</option>
                          <option value='Aland Islands'>Aland Islands</option>
                          <option value='Albania'>Albania</option>
                          <option value='Algeria'>Algeria</option>
                          <option value='American Samoa'>American Samoa</option>
                          <option value='Andorra'>Andorra</option>
                          <option value='Angola'>Angola</option>
                          <option value='Anguilla'>Anguilla</option>
                          <option value='Antarctica'>Antarctica</option>
                          <option value='Antigua and Barbuda'>
                            Antigua and Barbuda
                          </option>
                          <option value='Argentina'>Argentina</option>
                          <option value='Armenia'>Armenia</option>
                          <option value='Aruba'>Aruba</option>
                          <option value='Australia'>Australia</option>
                          <option value='Austria'>Austria</option>
                          <option value='Azerbaijan'>Azerbaijan</option>
                          <option value='Bahamas'>Bahamas</option>
                          <option value='Bahrain'>Bahrain</option>
                          <option value='Bangladesh'>Bangladesh</option>
                          <option value='Barbados'>Barbados</option>
                          <option value='Belarus'>Belarus</option>
                          <option value='Belgium'>Belgium</option>
                          <option value='Belize'>Belize</option>
                          <option value='Benin'>Benin</option>
                          <option value='Bermuda'>Bermuda</option>
                          <option value='Bhutan'>Bhutan</option>
                          <option value='Bolivia'>Bolivia</option>
                          <option value='Bonaire, Sint Eustatius and Saba'>
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value='Bosnia and Herzegovina'>
                            Bosnia and Herzegovina
                          </option>
                          <option value='Botswana'>Botswana</option>
                          <option value='Bouvet Island'>Bouvet Island</option>
                          <option value='Brazil'>Brazil</option>
                          <option value='British Indian Ocean Territory'>
                            British Indian Ocean Territory
                          </option>
                          <option value='Brunei Darussalam'>
                            Brunei Darussalam
                          </option>
                          <option value='Bulgaria'>Bulgaria</option>
                          <option value='Burkina Faso'>Burkina Faso</option>
                          <option value='Burundi'>Burundi</option>
                          <option value='Cambodia'>Cambodia</option>
                          <option value='Cameroon'>Cameroon</option>
                          <option value='Canada'>Canada</option>
                          <option value='Cape Verde'>Cape Verde</option>
                          <option value='Cayman Islands'>Cayman Islands</option>
                          <option value='Central African Republic'>
                            Central African Republic
                          </option>
                          <option value='Chad'>Chad</option>
                          <option value='Chile'>Chile</option>
                          <option value='China'>China</option>
                          <option value='Christmas Island'>
                            Christmas Island
                          </option>
                          <option value='Cocos (Keeling) Islands'>
                            Cocos (Keeling) Islands
                          </option>
                          <option value='Colombia'>Colombia</option>
                          <option value='Comoros'>Comoros</option>
                          <option value='Congo'>Congo</option>
                          <option value='Congo, Democratic Republic of the Congo'>
                            Congo, Democratic Republic of the Congo
                          </option>
                          <option value='Cook Islands'>Cook Islands</option>
                          <option value='Costa Rica'>Costa Rica</option>
                          <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                          <option value='Croatia'>Croatia</option>
                          <option value='Cuba'>Cuba</option>
                          <option value='Curacao'>Curacao</option>
                          <option value='Cyprus'>Cyprus</option>
                          <option value='Czech Republic'>Czech Republic</option>
                          <option value='Denmark'>Denmark</option>
                          <option value='Djibouti'>Djibouti</option>
                          <option value='Dominica'>Dominica</option>
                          <option value='Dominican Republic'>
                            Dominican Republic
                          </option>
                          <option value='Ecuador'>Ecuador</option>
                          <option value='Egypt'>Egypt</option>
                          <option value='El Salvador'>El Salvador</option>
                          <option value='Equatorial Guinea'>
                            Equatorial Guinea
                          </option>
                          <option value='Eritrea'>Eritrea</option>
                          <option value='Estonia'>Estonia</option>
                          <option value='Ethiopia'>Ethiopia</option>
                          <option value='Falkland Islands (Malvinas)'>
                            Falkland Islands (Malvinas)
                          </option>
                          <option value='Faroe Islands'>Faroe Islands</option>
                          <option value='Fiji'>Fiji</option>
                          <option value='Finland'>Finland</option>
                          <option value='France'>France</option>
                          <option value='French Guiana'>French Guiana</option>
                          <option value='French Polynesia'>
                            French Polynesia
                          </option>
                          <option value='French Southern Territories'>
                            French Southern Territories
                          </option>
                          <option value='Gabon'>Gabon</option>
                          <option value='Gambia'>Gambia</option>
                          <option value='Georgia'>Georgia</option>
                          <option value='Germany'>Germany</option>
                          <option value='Ghana'>Ghana</option>
                          <option value='Gibraltar'>Gibraltar</option>
                          <option value='Greece'>Greece</option>
                          <option value='Greenland'>Greenland</option>
                          <option value='Grenada'>Grenada</option>
                          <option value='Guadeloupe'>Guadeloupe</option>
                          <option value='Guam'>Guam</option>
                          <option value='Guatemala'>Guatemala</option>
                          <option value='Guernsey'>Guernsey</option>
                          <option value='Guinea'>Guinea</option>
                          <option value='Guinea-Bissau'>Guinea-Bissau</option>
                          <option value='Guyana'>Guyana</option>
                          <option value='Haiti'>Haiti</option>
                          <option value='Heard Island and Mcdonald Islands'>
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value='Holy See (Vatican City State)'>
                            Holy See (Vatican City State)
                          </option>
                          <option value='Honduras'>Honduras</option>
                          <option value='Hong Kong'>Hong Kong</option>
                          <option value='Hungary'>Hungary</option>
                          <option value='Iceland'>Iceland</option>
                          <option value='India'>India</option>
                          <option value='Indonesia'>Indonesia</option>
                          <option value='Iran, Islamic Republic of'>
                            Iran, Islamic Republic of
                          </option>
                          <option value='Iraq'>Iraq</option>
                          <option value='Ireland'>Ireland</option>
                          <option value='Isle of Man'>Isle of Man</option>
                          <option value='Israel'>Israel</option>
                          <option value='Italy'>Italy</option>
                          <option value='Jamaica'>Jamaica</option>
                          <option value='Japan'>Japan</option>
                          <option value='Jersey'>Jersey</option>
                          <option value='Jordan'>Jordan</option>
                          <option value='Kazakhstan'>Kazakhstan</option>
                          <option value='Kenya'>Kenya</option>
                          <option value='Kiribati'>Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value='Korea, Republic of'>
                            Korea, Republic of
                          </option>
                          <option value='Kosovo'>Kosovo</option>
                          <option value='Kuwait'>Kuwait</option>
                          <option value='Kyrgyzstan'>Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value='Latvia'>Latvia</option>
                          <option value='Lebanon'>Lebanon</option>
                          <option value='Lesotho'>Lesotho</option>
                          <option value='Liberia'>Liberia</option>
                          <option value='Libyan Arab Jamahiriya'>
                            Libyan Arab Jamahiriya
                          </option>
                          <option value='Liechtenstein'>Liechtenstein</option>
                          <option value='Lithuania'>Lithuania</option>
                          <option value='Luxembourg'>Luxembourg</option>
                          <option value='Macao'>Macao</option>
                          <option value='Macedonia, the Former Yugoslav Republic of'>
                            Macedonia, the Former Yugoslav Republic of
                          </option>
                          <option value='Madagascar'>Madagascar</option>
                          <option value='Malawi'>Malawi</option>
                          <option value='Malaysia'>Malaysia</option>
                          <option value='Maldives'>Maldives</option>
                          <option value='Mali'>Mali</option>
                          <option value='Malta'>Malta</option>
                          <option value='Marshall Islands'>
                            Marshall Islands
                          </option>
                          <option value='Martinique'>Martinique</option>
                          <option value='Mauritania'>Mauritania</option>
                          <option value='Mauritius'>Mauritius</option>
                          <option value='Mayotte'>Mayotte</option>
                          <option value='Mexico'>Mexico</option>
                          <option value='Micronesia, Federated States of'>
                            Micronesia, Federated States of
                          </option>
                          <option value='Moldova, Republic of'>
                            Moldova, Republic of
                          </option>
                          <option value='Monaco'>Monaco</option>
                          <option value='Mongolia'>Mongolia</option>
                          <option value='Montenegro'>Montenegro</option>
                          <option value='Montserrat'>Montserrat</option>
                          <option value='Morocco'>Morocco</option>
                          <option value='Mozambique'>Mozambique</option>
                          <option value='Myanmar'>Myanmar</option>
                          <option value='Namibia'>Namibia</option>
                          <option value='Nauru'>Nauru</option>
                          <option value='Nepal'>Nepal</option>
                          <option value='Netherlands'>Netherlands</option>
                          <option value='Netherlands Antilles'>
                            Netherlands Antilles
                          </option>
                          <option value='New Caledonia'>New Caledonia</option>
                          <option value='New Zealand'>New Zealand</option>
                          <option value='Nicaragua'>Nicaragua</option>
                          <option value='Niger'>Niger</option>
                          <option value='Nigeria'>Nigeria</option>
                          <option value='Niue'>Niue</option>
                          <option value='Norfolk Island'>Norfolk Island</option>
                          <option value='Northern Mariana Islands'>
                            Northern Mariana Islands
                          </option>
                          <option value='Norway'>Norway</option>
                          <option value='Oman'>Oman</option>
                          <option value='Pakistan'>Pakistan</option>
                          <option value='Palau'>Palau</option>
                          <option value='Palestinian Territory, Occupied'>
                            Palestinian Territory, Occupied
                          </option>
                          <option value='Panama'>Panama</option>
                          <option value='Papua New Guinea'>
                            Papua New Guinea
                          </option>
                          <option value='Paraguay'>Paraguay</option>
                          <option value='Peru'>Peru</option>
                          <option value='Philippines'>Philippines</option>
                          <option value='Pitcairn'>Pitcairn</option>
                          <option value='Poland'>Poland</option>
                          <option value='Portugal'>Portugal</option>
                          <option value='Puerto Rico'>Puerto Rico</option>
                          <option value='Qatar'>Qatar</option>
                          <option value='Reunion'>Reunion</option>
                          <option value='Romania'>Romania</option>
                          <option value='Russian Federation'>
                            Russian Federation
                          </option>
                          <option value='Rwanda'>Rwanda</option>
                          <option value='Saint Barthelemy'>
                            Saint Barthelemy
                          </option>
                          <option value='Saint Helena'>Saint Helena</option>
                          <option value='Saint Kitts and Nevis'>
                            Saint Kitts and Nevis
                          </option>
                          <option value='Saint Lucia'>Saint Lucia</option>
                          <option value='Saint Martin'>Saint Martin</option>
                          <option value='Saint Pierre and Miquelon'>
                            Saint Pierre and Miquelon
                          </option>
                          <option value='Saint Vincent and the Grenadines'>
                            Saint Vincent and the Grenadines
                          </option>
                          <option value='Samoa'>Samoa</option>
                          <option value='San Marino'>San Marino</option>
                          <option value='Sao Tome and Principe'>
                            Sao Tome and Principe
                          </option>
                          <option value='Saudi Arabia'>Saudi Arabia</option>
                          <option value='Senegal'>Senegal</option>
                          <option value='Serbia'>Serbia</option>
                          <option value='Serbia and Montenegro'>
                            Serbia and Montenegro
                          </option>
                          <option value='Seychelles'>Seychelles</option>
                          <option value='Sierra Leone'>Sierra Leone</option>
                          <option value='Singapore'>Singapore</option>
                          <option value='Sint Maarten'>Sint Maarten</option>
                          <option value='Slovakia'>Slovakia</option>
                          <option value='Slovenia'>Slovenia</option>
                          <option value='Solomon Islands'>
                            Solomon Islands
                          </option>
                          <option value='Somalia'>Somalia</option>
                          <option value='South Africa'>South Africa</option>
                          <option value='South Georgia and the South Sandwich Islands'>
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value='South Sudan'>South Sudan</option>
                          <option value='Spain'>Spain</option>
                          <option value='Sri Lanka'>Sri Lanka</option>
                          <option value='Sudan'>Sudan</option>
                          <option value='Suriname'>Suriname</option>
                          <option value='Svalbard and Jan Mayen'>
                            Svalbard and Jan Mayen
                          </option>
                          <option value='Swaziland'>Swaziland</option>
                          <option value='Sweden'>Sweden</option>
                          <option value='Switzerland'>Switzerland</option>
                          <option value='Syrian Arab Republic'>
                            Syrian Arab Republic
                          </option>
                          <option value='Taiwan, Province of China'>
                            Taiwan, Province of China
                          </option>
                          <option value='Tajikistan'>Tajikistan</option>
                          <option value='Tanzania, United Republic of'>
                            Tanzania, United Republic of
                          </option>
                          <option value='Thailand'>Thailand</option>
                          <option value='Timor-Leste'>Timor-Leste</option>
                          <option value='Togo'>Togo</option>
                          <option value='Tokelau'>Tokelau</option>
                          <option value='Tonga'>Tonga</option>
                          <option value='Trinidad and Tobago'>
                            Trinidad and Tobago
                          </option>
                          <option value='Tunisia'>Tunisia</option>
                          <option value='Turkey'>Turkey</option>
                          <option value='Turkmenistan'>Turkmenistan</option>
                          <option value='Turks and Caicos Islands'>
                            Turks and Caicos Islands
                          </option>
                          <option value='Tuvalu'>Tuvalu</option>
                          <option value='Uganda'>Uganda</option>
                          <option value='Ukraine'>Ukraine</option>
                          <option value='United Arab Emirates'>
                            United Arab Emirates
                          </option>
                          <option value='United Kingdom'>United Kingdom</option>
                          <option value='United States'>United States</option>
                          <option value='United States Minor Outlying Islands'>
                            United States Minor Outlying Islands
                          </option>
                          <option value='Uruguay'>Uruguay</option>
                          <option value='Uzbekistan'>Uzbekistan</option>
                          <option value='Vanuatu'>Vanuatu</option>
                          <option value='Venezuela'>Venezuela</option>
                          <option value='Viet Nam'>Viet Nam</option>
                          <option value='Virgin Islands, British'>
                            Virgin Islands, British
                          </option>
                          <option value='Virgin Islands, U.s.'>
                            Virgin Islands, U.s.
                          </option>
                          <option value='Wallis and Futuna'>
                            Wallis and Futuna
                          </option>
                          <option value='Western Sahara'>Western Sahara</option>
                          <option value='Yemen'>Yemen</option>
                          <option value='Zambia'>Zambia</option>
                          <option value='Zimbabwe'>Zimbabwe</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h1 class='display-3'>Specifications</h1>

                  <Row>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Gears</Form.Label>
                        <Form.Select
                          value={gears}
                          onChange={(e) => setGears(e.target.value)}
                          placeholder='Gears'
                        >
                          <option value='0'>Gears</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Cylinders</Form.Label>
                        <Form.Select
                          value={cylinders}
                          onChange={(e) => setCylinders(e.target.value)}
                          placeholder='Cylinder'
                        >
                          <option value='-'>Cylinders </option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Chassis Number (VIN)</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Chassis Number (VIN)'
                          value={chassisNumber}
                          onChange={(e) => setChassisNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Horsepower</Form.Label>
                        <Form.Control
                          defaultValue={0}
                          onWheel={(e) => e.target.blur()}
                          type='number'
                          value={horsepower}
                          onChange={(e) => setHorsepower(e.target.value)}
                          placeholder='Horsepower'
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Engine Size(L)</Form.Label>
                        <Form.Control
                          defaultValue={0}
                          onWheel={(e) => e.target.blur()}
                          type='number'
                          value={engineSize}
                          onChange={(e) => setEngineSize(e.target.value)}
                          placeholder='Engine Size'
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Transmission</Form.Label>
                        <Form.Select
                          value={transmission}
                          onChange={(e) => setTransmission(e.target.value)}
                        >
                          <option value=''>Transmission</option>
                          <option value='Automatic'>Automatic</option>
                          <option value='Manual'>Manual</option>
                          <option value='Semi-Automatic'>Semi-Automatic</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Engine Number</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Engine Number'
                          value={engineNumber}
                          onChange={(e) => setEngineNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Legal Papers</Form.Label>
                        <Form.Select
                          value={legalPapers}
                          onChange={(e) => setLegalPapers(e.target.value)}
                        >
                          <option value=''>Legal Papers</option>
                          <option value='Yes'>Available</option>
                          <option value='No'>Not Available</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Odometer(Km)</Form.Label>
                        <Form.Control
                          defaultValue={0}
                          placeholder='Odometer'
                          value={odometer}
                          onWheel={(e) => e.target.blur()}
                          type='number'
                          onChange={(e) => setOdometer(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Steering Wheel</Form.Label>
                        <Form.Select
                          value={steeringWheel}
                          onChange={(e) => setSteeringWheel(e.target.value)}
                        >
                          <option value=''>Steering Wheel</option>
                          <option value='LHD'>LHD</option>
                          <option value='RHD'>RHD</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Drive Wheel</Form.Label>
                        <Form.Select
                          value={driveWheels}
                          onChange={(e) => setDriveWheels(e.target.value)}
                        >
                          <option value=''>Drive Wheel</option>
                          <option value='FWD'>FWD</option>
                          <option value='RWD'>RWD</option>
                          <option value='AWD'>AWD</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Fuel Type</Form.Label>
                        <Form.Select
                          value={fuelType}
                          onChange={(e) => setFuelType(e.target.value)}
                        >
                          <option value=''>Fuel Type</option>
                          <option value='Petrol'>Petrol</option>
                          <option value='Diesel'>Diesel</option>
                          <option value='Electric'>Electric</option>
                          <option value='Hybrid'>Hybrid</option>
                          <option value='Other'>Other</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h1 className='display-3'>Badges</h1>
                  <div className='row w-100'>
                    <div className='col-8'>
                      <Form.Group className='mb-3 w-100'>
                        <Form.Select
                          value={badgeInput}
                          onChange={(e) => setBadgeInput(e.target.value)}
                        >
                          <option value=''>Badge</option>
                          <option value='Luxury'>Luxury</option>
                          <option value='Like-New'>Like-New</option>
                          <option value='Bargain'>Bargain</option>
                          <option value='Rare'>Rare</option>
                          <option value='Popular'>Popular</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className='col-4'>
                      <button
                        className='w-100 btn btn-success'
                        onClick={() => {
                          if (badgeInput) {
                            setBadge((badge) => [...badge, badgeInput])
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>

                    <div className='col-12'>
                      <ul>
                        {badge.map((b) => {
                          return (
                            <li>
                              {b}{" "}
                              <button
                                onClick={() => {
                                  let temp = badge.filter((item) => item !== b)
                                  setBadge(temp)
                                }}
                                className='btn btn-danger btn-sm'
                              >
                                <i className='fa-solid fa-x'></i>
                              </button>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className='col-12'>
                    <h1 className='display-3'>Description</h1>
                    <div
                      style={{
                        width: "100%"
                      }}
                    >
                      <ReactQuill
                        className='w-100'
                        value={description}
                        onChange={setDescription}
                        theme='snow'
                      />
                    </div>
                  </div>
                  <h1 className='display-3'>Images</h1>

                  <label
                    htmlFor='image'
                    className='cursor-pointer border border-dark p-3'
                  >
                    <div className='d-flex flex-column justify-content-center align-items-center gap-1 '>
                      <Upload size={"30px"} />
                      <h6>Add Image</h6>
                    </div>
                    <input
                      onChange={(e) =>
                        setInputImages([...inputImages, e.target.files[0]])
                      }
                      className='d-none'
                      id='image'
                      type='file'
                    />
                  </label>

                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      createCar()
                    }}
                    variant='primary'
                    className='w-100'
                  >
                    Add Car
                  </Button>
                </div>
              </div>
            </>
          )}
        </Container>
      </Main>
    </>
  )
}

export default CreateCarForm
