import { useForm } from "react-hook-form";
import Main from "../../components/Main/Main";
import { Col, Form, Row } from "react-bootstrap";
import partApi from "../../api/part";
import * as notify from "../../lib/notify";
import { errorMessage } from "../../utils/errorMessage";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploader from "../../components/SharedComponents/ImageUploader";
import ReactSelect from "react-select";
import useSellers from "../../hooks/useSellers";
import useAsync from "../../hooks/useAsync";

export default function EditPart() {
  const { id } = useParams();  // Get part ID from URL parameters
  const navigate = useNavigate();
  const { sellers } = useSellers();
  const [seller, setSeller] = useState(null);
  const [images, setImages] = useState([]);


  const { register, handleSubmit, setValue, formState: { errors, isValid }, trigger, setError } = useForm({ mode: "onSubmit" });
  const [loading, setLoading] = useState(false);


  const variables = [
    {
      label: "Name",
      value: "name",
      validation: { required: "Name is required" },
    },
    {
      label: "Base Price ($)",
      value: "base_price",
      validation: {
        required: "Price is required",
        valueAsNumber: true,
        validate: {
          isNumber: v => !isNaN(v) || "Price must be a number",
          positive: v => parseFloat(v) > 0 || "Price must be greater than zero",

        }
      },
    },
    {
      label: "Discount Price ($)",
      value: "discount_price",
      validation: {
        valueAsNumber: true,
        validate: {
          positive: v => parseFloat(v) > 0 || "Price must be greater than zero",
          isNumber: v => !isNaN(v) || "Price must be a number",
        }
      },
    },
    {
      label: "Description",
      value: "description",
      as: "textarea",
      rows: 5,
      validation: { required: "Description is required" },
    },
  ];


  // Function to convert URLs to blobs
  async function convertToBlob(files) {
    const filePromises = files.map(async (file) => {
      const response = await fetch(file.url);
      const blob = await response.blob();
      // Create a new File object from the Blob
      const fileWithBlob = new File([blob], file.name || "default-name", { type: blob.type });
      return { id:file?._id, chosen:false,selected:false,status:1, file: fileWithBlob ,url:file?.url};  // Adding the File to the file object
    });

    const filesWithFiles = await Promise.all(filePromises);

    setImages(filesWithFiles);


    // Setting the images state with files
    // return filesWithFiles;
  }





  const getPartByID = useAsync({
    fn: partApi.getPartById,
    onSuccess: (data = {}) => {
      setValue('name', data?.name);
      setValue('base_price', data?.price?.base);
      setValue('discount_price', data?.price?.discount);
      setValue('description', data?.description);
      if(data?.images?.length >0 ){
        convertToBlob(data.images);
      }
      setSeller({
        value: data?.seller?._id,
        label: data?.seller?.firstName + " " + data?.seller?.lastName,
      })

   trigger();

    },
    onError: (error) => {
      notify.error(errorMessage(error));
    },
  });



  useEffect(()=>{
      console.log('images',images)
  },[images])




  useEffect(()=>{
    if(id)
    getPartByID({ partID:id});
  },[id])

  const updatePart = useAsync({
    fn: partApi.editPart,
    onSuccess: (data = {}) => {
      navigate(-1);
      notify.success("Part updated successfully");
    },
    onError: (error) => {

      notify.error(errorMessage(error));
    },
  });

  const onSubmit = (data) => {
    console.log('is valid',isValid)
    if (isValid) {
      updatePart({ ...data, images, seller: seller?.value, partID:id});
    } else {
      Object.keys(errors).forEach((key) =>
          setError(key, { type: "manual", message: errors[key]?.message })
      );
    }
  };

  return (
      <Main>
        <div className="p-3">
          <Row>
            {variables.map(({ label, value, as, rows, validation }) => (
                <Col
                    key={value}
                    lg={as === "textarea" ? 12 : 4}
                    md={as === "textarea" ? 12 : 6}
                    sm={12}
                >
                  <Form.Group className="m-2">
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                        {...register(value, validation)}
                        as={as || "input"}
                        rows={rows || undefined}
                        placeholder={label}
                        isInvalid={!!errors[value]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[value]?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
            ))}

            <Col
                md={6}
                style={{ zIndex: 5 }}
            >
              <Form.Group className="m-2">
                <Form.Label>Seller</Form.Label>
                <ReactSelect
                    value={seller}
                    onChange={(value) => setSeller(value)}
                    className="mb-5"
                    placeholder="Select Seller"
                    options={sellers.map(({ _id, firstName, lastName }) => ({
                      value: _id,
                      label: `${firstName} ${lastName}`,
                    }))}
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <ImageUploader
                  header={"Images"}
                  title={""}
                  subTitle={""}
                  files={images}
                  setFiles={setImages}
              />
            </Col>

            <Col md={12}>
              <button
                  onClick={handleSubmit(onSubmit)}
                  className="mt-2 btn btn-primary"
                  disabled={loading}
              >
                {loading ? "Updating Part..." : "Update Part"}
              </button>
            </Col>
          </Row>
        </div>
      </Main>
  );
}
