import axios from "axios"
import API_URLS from "../constants/search_tool_routes"

const getClassicSellerRows = async () => {
  const res = await axios.post(API_URLS.GET_CLASSIC_SELLERS_ROWS)
  return res.data
}
const getClassicBuyerRows = async () => {
  const res = await axios.post(API_URLS.GET_CLASSIC_BUYERS_ROWS)
  return res.data
}
const getLCSRows = async () => {
  const res = await axios.post(API_URLS.GET_LCS_ROWS)
  return res.data
}
const getClassicSellerID = async () => {
  const res = await axios.post(API_URLS.GET_CLASSIC_SELLER_ID)

  return res.data
}
const getClassicBuyerID = async () => {
  const res = await axios.post(API_URLS.GET_CLASSIC_BUYER_ID)
  console.log(res)
  return res.data
}
const getModernSellersRows = async () => {
  const res = await axios.post(API_URLS.GET_MODERN_SELLERS_ROWS)

  return res.data
}
const getModernBuyersRows = async () => {
  const res = await axios.post(API_URLS.GET_MODERN_BUYERS_ROWS)

  return res.data
}
const getModernSellersID = async () => {
  const res = await axios.post(API_URLS.GET_MODERN_SELLERS_ID)
  return res.data
}
const getModernBuyersID = async () => {
  const res = await axios.post(API_URLS.GET_MODERN_BUYERS_ID)
  return res.data
}
const searchToolApi = {
  getLCSRows,
  getClassicSellerRows,
  getClassicBuyerRows,
  getModernBuyersRows,
  getModernSellersRows,
  getModernBuyersID,
  getModernSellersID,
  getClassicSellerID,
  getClassicBuyerID
}

export default searchToolApi
