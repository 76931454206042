import { Col, Row } from "react-bootstrap"

function CarMetrics() {
  return (
    <>
      <h3>Coming soon...</h3>
    </>
    // <Row className="mt-3">
    //     <Col md={4} xs={6}>
    //         <h6>Views</h6>
    //         <p>6000</p>
    //     </Col>
    //     <Col md={4} xs={6}>
    //         <h6>Countries</h6>
    //         <p>5</p>
    //     </Col>
    //     <Col md={4} xs={6}>
    //         <h6>Total Time Viewed Car</h6>
    //         <p>5 hours</p>
    //     </Col>
    //     <Col md={4}  xs={6}>
    //         <h6>Average Time Viewed Car</h6>
    //         <p>10 minutes</p>
    //     </Col>
    // </Row>
  )
}

export default CarMetrics
