import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
const SearchBar = ({ onSearchSubmit, placeholder }) => {
  const [term, setTerm] = useState("")
  const [debouncedTerm, setDebouncedTerm] = useState(term)

  useEffect(() => {
    const timer = setTimeout(() => setTerm(debouncedTerm), 1000)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  // submit a new search
  useEffect(() => {
    onSearchSubmit(term)
  }, [term])

  return (
    <div>
      <Form.Group>
        <Form.Control
          placeholder={placeholder}
          value={debouncedTerm}
          onChange={(e) => setDebouncedTerm(e.target.value)}
        ></Form.Control>
      </Form.Group>
    </div>
  )
}

export default SearchBar
