import { useEffect, useState } from "react";

const errorMessage = (error) => {
  return (
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  );
};

export default function useAsync({
  fn = () => {},
  onSuccess = (data = {}, requestData = {}) => {},
  onError = (data = {}) => {},
  onFinally = () => {},
  onLoading = (data = {}) => {},
}) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState(null);

  async function main(data) {
    try {
      setLoading(true);
      setRequestData(data);
      const res = await fn(data);
      setResult(res);
      setSuccess(true);
    } catch (err) {
      setError(errorMessage(err));
    } finally {
      onFinally()
      setLoading(false);
    }
  }

  function reset() {
    setError(null);
    setSuccess(false);
    setResult(null);
    setLoading(false);
  }

  useEffect(() => {
    if (success) {
      onSuccess(result, requestData);
    }
    if (error) {
      onError(error);
    }
    if (loading) {
      onLoading(loading);
    }
    return () => {
      reset();
    };
  }, [success, error, loading]);

  return (data) => main(data);
}
