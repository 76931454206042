const Date = ({ time }) => {
  return (
    <>
      {" "}
      Sent at {time.split("T")[0]} Time
      {" " + time.split("T")[1].split(":")[0]}:
      {time.split("T")[1].split(":")[1]}:
      {time.split("T")[1].split(":")[2].split(".")[0]}
    </>
  )
}
export default Date
