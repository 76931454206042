import Sidebar from "./Sidebar"
import "./Main.css"
import Navbar from "./Navbar"
import { useState } from "react"
import { useEffect } from "react"
const Main = ({ children }) => {
  const [active, setActive] = useState("")
  const toggleActive = () => {
    if (active) setActive("")
    else setActive("active")
  }

  return (
    <>
      <Sidebar active={active} />
      <section className='home-section'>
        <Navbar toggleActive={toggleActive} />
        <main className='home-content'>{children}</main>
      </section>
    </>
  )
}

export default Main
