import { useEffect, useState } from "react"
import Main from "../../components/Main/Main"
import partApi from "../../api/part"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import PartCard from "../../components/Parts/Card"
import { useNavigate } from "react-router-dom"
import useAsync from "../../hooks/useAsync";
import SearchBar from "../../components/SearchBar/SearchBar";
export default function ViewParts() {
  const [parts, setParts] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()



  const [page,setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [searchText, setSearchText] = useState("")

  const searchParts = useAsync({
    fn: partApi.searchParts,
    onSuccess:(result)=>{
      setParts(result?.parts)
      setPage(result?.currentPage)
      setTotalPages(result?.totalPages)
    },
    onError:(error)=>{
      notify.error(errorMessage(error))
    },
    onLoading:(value)=>{
      setLoading(value)
    },
  })


  useEffect(() => {
    searchParts({ query: searchText, page })
  }, [searchText, page])



  const deletePart = useAsync({
    fn: partApi.deletePart,
    onSuccess:(result,requestData)=>{
      setParts((prev) => prev.filter((p) => p?._id!== requestData?.partID))
      notify.success("Part deleted successfully")
    },
    onError:(error)=>{
      notify.error(errorMessage(error))
    },
    onLoading:(value)=>{
      setLoading(value)
    },
  })

  return (
    <Main>
      <div className='p-2'>
        <h1>Parts</h1>

        <div>


        <button
          onClick={() => navigate("/part/create")}
          className='btn btn-success mb-3'
        >
          Create Part
        </button>

          <SearchBar
            placeholder='Search for parts...'
            onSearchSubmit={(term) => {
              setSearchText(term)
            }}
          />


        </div>
        <div className='row'>
          {parts?.map((part) => {
            return (
              <div className='col-4'>
                <PartCard onDelete={() => {
                  deletePart({ partID: part._id })
                }} part={part} />
              </div>
            )
          })}
        </div>
      </div>
    </Main>
  )
}
