import axios from "axios"
import { API_URLS } from "../constants/routes"

const createBrand = async (data) => {
  const res = await axios.post(API_URLS.CREATE_BRAND, data)
  return res.data
}

const deleteBrand = async (data) => {
  const res = await axios.post(API_URLS.DELETE_BRAND)
  return res.data
}

const getBrands = async () => {
  const res = await axios.post(API_URLS.GET_BRANDS)
  return res.data
}

const brandApi = {
  createBrand,
  deleteBrand,
  getBrands
}

export default brandApi
