import Main from "../../components/Main/Main"
import { useForm } from "react-hook-form"
import * as Yup from "yup"
import { Col, Form, Row } from "react-bootstrap"
import { useState } from "react"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import machineryApi from "../../api/machinery"
import ReactQuill from "react-quill"
const schema = Yup.object().shape({
  brand: Yup.string().required()
})
export default function CreateMachinery() {
  const [description, setDescription] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const variables = [
    {
      col: {
        md: 6,
        sm: 12,
        lg: 4
      },
      label: "Type",
      value: "type"
    },
    {
      label: "Brand",
      value: "brand",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Model",
      value: "model",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Year",
      value: "year",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Color",
      value: "color",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Condition",
      value: "condition",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Engine Type",
      value: "engineType",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Number of Wheels",
      value: "numberOfWheels",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Number of Axes",
      value: "numberOfAxes",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Fuel Capacity (L)",
      value: "fuelCapacity",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Max Speed",
      value: "maxSpeed",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Weight",
      value: "weight",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    },
    {
      label: "Special Features",
      value: "specialFeatures",
      col: {
        md: 6,
        sm: 12,
        lg: 4
      }
    }
  ]

  const [loading, setLoading] = useState(false)
  const createMachinery = async (data) => {
    try {
      setLoading(true)
      await machineryApi.createMachinery({ ...data, description })
      notify.success("Machinery Created Successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  return (
    <Main>
      <div className='container'>
        <h1>Create Machinery</h1>

        <Row>
          {variables?.map((variable) => {
            return (
              <Col
                key={variable.value}
                md={variable.col.md}
                sm={variable.col.sm}
                lg={variable.col.lg}
              >
                <Form.Group className='m-2'>
                  <Form.Label>{variable.label}</Form.Label>
                  <Form.Control
                    {...register(variable.value)}
                    placeholder={variable.label}
                  />
                </Form.Group>
              </Col>
            )
          })}
          <Col md={12}>
            <ReactQuill value={description} setValue={setDescription} />
          </Col>
          <Col md={12}>
            <button
              disabled={loading}
              onClick={handleSubmit((data) => {
                createMachinery(data)
              })}
              className='btn btn-primary'
            >
              {loading ? "Loading..." : "Create"}
            </button>
          </Col>
        </Row>
      </div>
    </Main>
  )
}
