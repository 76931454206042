import { useEffect, useState } from "react"
// import searchToolApi from "./api"
// import searchApi from "../api/searchApi"
import searchToolApi from "../api/searchTool"
import CryptoJs from "crypto-js"
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap"
// import { errorMessage } from "./utils/errorMessage"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
import Main from "../components/Main/Main"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

function SearchTool() {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [classicSellersData, setClassicSellersData] = useState([])
  const [classicBuyersData, setClassicBuyersData] = useState([])
  const [modernSellersData, setModernSellersData] = useState([])
  const [LCSData, setLCSData] = useState([])

  const [classicSellersResults, setClassicSellersResults] = useState([])
  const [classicBuyersResults, setClassicBuyersResults] = useState([])
  const [modernSellersResults, setModernSellersResults] = useState([])
  const [LCSResults, setLCSResults] = useState([])

  const [loadingClassicSellers, setLoadingClassicSellers] = useState()
  const [loadingClassicBuyers, setLoadingClassicBuyers] = useState()
  const [loadingModernSellers, setLoadingModernSellers] = useState()
  const [loadingLCS, setLoadingLCS] = useState(false)

  const [loadingClassicSellerId, setLoadingClassicSellerId] = useState(false)
  const [loadingClassicBuyerId, setLoadingClassicBuyerId] = useState(false)
  const [loadingModernSellerId, setLoadingModernSellerId] = useState(false)
  const synchronize = () => {
    localStorage.removeItem("modern_sellers")
    localStorage.removeItem("classic_sellers")
    localStorage.removeItem("classic_buyers")
    localStorage.removeItem("lcs")
    getClassicBuyerRows()
    getClassicSellerRows()
    getModernSellerRows()
    getLCSRows()
  }

  const { employee } = useSelector((state) => state.employee)
  const navigate = useNavigate()
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.searchToolAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }
  const getModernSellerId = async () => {
    try {
      setLoadingModernSellerId(true)
      const { id } = await searchToolApi.getModernSellersID()
      localStorage.setItem("modernSellerId", id)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingModernSellerId(false)
    }
  }

  const getClassicSellerId = async () => {
    try {
      setLoadingClassicSellerId(true)
      const { id } = await searchToolApi.getClassicSellerID()
      localStorage.setItem("classicSellerId", id)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingClassicSellerId(false)
    }
  }
  const getClassicBuyerId = async () => {
    try {
      setLoadingClassicBuyerId(true)
      const { id } = await searchToolApi.getClassicBuyerID()
      localStorage.setItem("classicBuyerId", id)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingClassicBuyerId(false)
    }
  }
  const getLCSRows = async () => {
    try {
      setLoadingLCS(true)
      const cache = localStorage.getItem("lcs")
      if (cache) {
        let bytes = CryptoJs.AES.decrypt(cache, "secret")
        let decrypt = JSON.parse(bytes.toString(CryptoJs.enc.Utf8))
        setLCSData(decrypt)
      } else {
        const data = await searchToolApi.getLCSRows()
        setLCSData(data)
        let encryption = CryptoJs.AES.encrypt(
          JSON.stringify(data),
          "secret"
        ).toString()
        localStorage.setItem("lcs", encryption)
      }
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingLCS(false)
    }
  }

  const getModernSellerRows = async () => {
    try {
      setLoadingModernSellers(true)
      const cache = localStorage.getItem("modern_sellers")
      if (cache) {
        let bytes = CryptoJs.AES.decrypt(cache, "secret")
        let decrypt = JSON.parse(bytes.toString(CryptoJs.enc.Utf8))
        setModernSellersData(decrypt)
      } else {
        const data = await searchToolApi.getModernSellersRows()
        setModernSellersData(data)
        let encryption = CryptoJs.AES.encrypt(
          JSON.stringify(data),
          "secret"
        ).toString()
        localStorage.setItem("modern_sellers", encryption)
      }
    } catch (error) {
      notify.error(error)
    } finally {
      setLoadingModernSellers(false)
    }
  }
  const getClassicSellerRows = async () => {
    try {
      setLoadingClassicSellers(true)
      const cache = localStorage.getItem("classic_sellers")
      if (cache) {
        let bytes = CryptoJs.AES.decrypt(cache, "secret")
        let decrypt = JSON.parse(bytes.toString(CryptoJs.enc.Utf8))
        setClassicSellersData(decrypt)
      } else {
        const data = await searchToolApi.getClassicSellerRows()
        setClassicSellersData(data)
        let encryption = CryptoJs.AES.encrypt(
          JSON.stringify(data),
          "secret"
        ).toString()
        localStorage.setItem("classic_sellers", encryption)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingClassicSellers(false)
    }
  }

  const getClassicBuyerRows = async () => {
    try {
      setLoadingClassicBuyers(true)

      const cache = localStorage.getItem("classic_buyers")
      if (cache) {
        let bytes = CryptoJs.AES.decrypt(cache, "secret")
        let decrypt = JSON.parse(bytes.toString(CryptoJs.enc.Utf8))
        setClassicBuyersData(decrypt)
      } else {
        const data = await searchToolApi.getClassicBuyerRows()
        setClassicBuyersData(data)
        let encryption = CryptoJs.AES.encrypt(
          JSON.stringify(data),
          "secret"
        ).toString()
        localStorage.setItem("classic_buyers", encryption)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingClassicBuyers(false)
    }
  }

  useEffect(() => {
    getClassicSellerRows()
    getClassicBuyerRows()
    getModernSellerRows()
    getLCSRows()
    authorizeEmployee()
  }, [])

  const clearResults = () => {
    setClassicSellersResults([])
    setClassicBuyersResults([])
    setModernSellersResults([])
    setLCSResults([])
  }

  useEffect(() => {
    clearResults([])
    if (search) {
      let str = search.replaceAll(" ", "")
      if (classicSellersData) {
        if (classicSellersData.hasOwnProperty(str))
          setClassicSellersResults(classicSellersData[str])
      }
      if (classicBuyersData) {
        if (classicBuyersData.hasOwnProperty(str))
          setClassicBuyersResults(classicBuyersData[str])
      }
      if (modernSellersData) {
        if (modernSellersData.hasOwnProperty(str)) {
          setModernSellersResults(modernSellersData[str])
        }
      }
      if (LCSData) {
        if (LCSData.hasOwnProperty(str)) {
          setLCSResults(LCSData[str])
        }
      }
    }
  }, [search])

  return (
    <>
      <Main>
        <Container>
          <h1 className='mt-5'>Autopia Database Tool</h1>

          <div className='border border-dark bg-white p-3'>
            <h2 className='display-5'>Instructions</h2>
            <ol>
              <li>
                Click Synchronize to get latest data else you will recieve
                cached data
              </li>
              <li>Search for a phone number</li>
              <li>
                If phone number is available check the customer's info in the
                below databases and use his ID for data entry
              </li>
              <li>
                If it is not available, request an ID in the appropriate
                database
              </li>
            </ol>
          </div>
          <div className='mt-5 border border-dark p-5 bg-white'>
            <Row>
              <Col md={6}>
                <h1 className='display-6'>
                  Latest classic seller id requested{" "}
                  <span className='text-success'>
                    {localStorage.getItem("classicSellerId") || "N/A"}
                  </span>
                </h1>
              </Col>

              <Col md={6}>
                <Button
                  variant='danger'
                  className='w-100'
                  onClick={(e) => {
                    e.preventDefault()
                    getClassicSellerId()
                  }}
                  disabled={loadingClassicSellerId}
                >
                  {loadingClassicSellerId
                    ? "Loading..."
                    : "Request Classic Sellers Id"}
                </Button>
              </Col>
              <Col md={6}>
                <h1 className='display-6'>
                  Latest classic buyer id requested{" "}
                  <span className='text-success'>
                    {localStorage.getItem("classicBuyerId") || "N/A"}
                  </span>
                </h1>
              </Col>
              <Col md={6}>
                <Button
                  variant='danger'
                  className='w-100'
                  onClick={(e) => {
                    e.preventDefault()
                    getClassicBuyerId()
                  }}
                  disabled={loadingClassicBuyerId}
                >
                  {loadingClassicBuyerId
                    ? "Loading..."
                    : "Request Classic Buyers Id"}
                </Button>
              </Col>

              <Col md={6}>
                <h1 className='display-6'>
                  Latest modern seller id requested{" "}
                  <span className='text-success'>
                    {localStorage.getItem("modernSellerId") || "N/A"}
                  </span>
                </h1>
              </Col>
              <Col md={6}>
                <Button
                  variant='danger'
                  className='w-100'
                  onClick={(e) => {
                    e.preventDefault()
                    getModernSellerId()
                  }}
                  disabled={loadingModernSellerId}
                >
                  {loadingModernSellerId
                    ? "Loading..."
                    : "Request Modern Sellers Id"}
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Form.Group className='mt-3'>
              <Form.Control
                type='text'
                placeholder='Search here...'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>

            <Button
              variant='success'
              disabled={
                loadingClassicSellers ||
                loadingClassicBuyers ||
                loadingModernSellers ||
                loadingLCS
              }
              onClick={(e) => {
                e.preventDefault()
                synchronize()
              }}
            >
              Synchronize
            </Button>
          </div>

          <h1 className='display-3 mt-3'>Classic Sellers</h1>
          {loadingClassicSellers ? (
            "Loading classic sellers..."
          ) : (
            <>
              <div className='bg-white' style={{ overflowX: "auto" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Mobile Number</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Year</th>
                      <th>Other Number</th>
                      <th>Color Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {classicSellersResults &&
                      classicSellersResults.map((r) => (
                        <>
                          <tr>
                            <td>{r.id}</td>
                            <td>{r.firstName ? r.firstName : "N/A"}</td>
                            <td>{r.lastName ? r.lastName : "N/A"}</td>
                            <td>{r.mobileNumber ? r.mobileNumber : "N/A"}</td>
                            <td>{r.brand ? r.brand : "N/A"}</td>
                            <td>{r.model ? r.model : "N/A"}</td>
                            <td>{r.year ? r.year : "N/A"}</td>
                            <td>{r.otherNumber ? r.otherNumber : "N/A"}</td>
                            <td>{r.colorCode ? r.colorCode : "N/A"}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          <h1 className='display-3 mt-3'>Classic Buyers</h1>
          {loadingClassicBuyers ? (
            "Loading classic buyers..."
          ) : (
            <>
              <div className='bg-white' style={{ overflowX: "auto" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Mobile Number</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Year</th>
                      <th>Other Number</th>
                      <th>Color Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {classicBuyersResults &&
                      classicBuyersResults.map((r) => (
                        <>
                          <tr>
                            <td>{r.id}</td>
                            <td>{r.firstName ? r.firstName : "N/A"}</td>
                            <td>{r.lastName ? r.lastName : "N/A"}</td>
                            <td>{r.mobileNumber ? r.mobileNumber : "N/A"}</td>
                            <td>{r.brand ? r.brand : "N/A"}</td>
                            <td>{r.model ? r.model : "N/A"}</td>
                            <td>{r.year ? r.year : "N/A"}</td>
                            <td>{r.otherNumber ? r.otherNumber : "N/A"}</td>
                            <td>{r.colorCode ? r.colorCode : "N/A"}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          <h1 className='display-3 mt-3'>Modern Sellers</h1>
          {loadingModernSellers ? (
            "Loading modern sellers..."
          ) : (
            <>
              <div style={{ overflowX: "auto" }}>
                <Table className='bg-white' striped bordered hover>
                  <thead>
                    <tr>
                      <th>#ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Mobile Number</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Year</th>
                      <th>Other Number</th>
                      <th>Color Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modernSellersResults &&
                      modernSellersResults.map((r) => (
                        <>
                          <tr>
                            <td>{r.id}</td>
                            <td>{r.firstName ? r.firstName : "N/A"}</td>
                            <td>{r.lastName ? r.lastName : "N/A"}</td>
                            <td>{r.mobileNumber ? r.mobileNumber : "N/A"}</td>
                            <td>{r.brand ? r.brand : "N/A"}</td>
                            <td>{r.model ? r.model : "N/A"}</td>
                            <td>{r.year ? r.year : "N/A"}</td>
                            <td>{r.otherNumber ? r.otherNumber : "N/A"}</td>
                            <td>{r.colorCode ? r.colorCode : "N/A"}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          <h1 className='display-3 mt-3'>LCS</h1>
          {loadingLCS ? (
            "Loading LCS..."
          ) : (
            <>
              <div style={{ overflowX: "auto" }}>
                <Table className='bg-white' striped bordered hover>
                  <thead>
                    <tr>
                      <th>#ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Mobile Number</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Year</th>
                      <th>Type Of Customer</th>
                      <th>Type Of Car</th>
                      <th>Color Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {LCSResults &&
                      LCSResults.map((r) => (
                        <>
                          <tr>
                            <td>{r.id}</td>
                            <td>{r.firstName ? r.firstName : "N/A"}</td>
                            <td>{r.lastName ? r.lastName : "N/A"}</td>
                            <td>{r.mobileNumber ? r.mobileNumber : "N/A"}</td>
                            <td>{r.brand ? r.brand : "N/A"}</td>
                            <td>{r.model ? r.model : "N/A"}</td>
                            <td>{r.year ? r.year : "N/A"}</td>
                            <td>
                              {r.typeOfCustomer ? r.typeOfCustomer : "N/A"}
                            </td>
                            <td>{r.typeOfCar ? r.typeOfCar : "N/A"}</td>
                            <td>{r.colorCode ? r.colorCode : "N/A"}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Container>
      </Main>
    </>
  )
}

export default SearchTool
