import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/employeeSlice";
import logo from "../../assets/logo.png";
import If from "../controlStatements/If";
// import "./Main.css"
const Sidebar = ({ active }) => {
  const route = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { employee } = useSelector((state) => state.employee);
  const onDashboardPage = route.pathname === "/dashboard";
  const onCarsPage = route.pathname === "/cars";
  const onSubmittedCarsPage = route.pathname === "/submitted-cars";

  const onSellersPage = route.pathname === "/sellers";
  const onSalesPage = route.pathname === "/sales";
  const onFeedbackPage = route.pathname === "/feedback";
  const onContactUsPage = route.pathname === "/contact-us";
  const onCareersPage = route.pathname === "/careers";
  const onUsersPage = route.pathname === "/users";
  const onPartsPage = route.pathname === "/part"
  const onMetricsPage = route.pathname === "/metrics";
  const onSearchToolPage = route.pathname === "/search-tool";

  useEffect(() => {
    if (!employee) {
      navigate("/login");
    }
  }, []);
  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };
  return (
    <div className={`sidebar ${active}`}>
      <div className="logo-details">
        {/* <i className='bx bxl-c-plus-plus'></i> */}
        <img src={logo} alt="" />
        <span className="logo_name ps-3">Autopia </span>
      </div>
      <ul className="navlinks">
        <If condition={employee.dashboardAccess}>
          <li>
            <div
              onClick={() => navigate("/dashboard")}
              className={`${onDashboardPage && "active"}`}
            >
              <i className="bx bx-grid-alt"></i>
              <span className="links_name">Dashboard</span>
            </div>
          </li>
        </If>

        <If condition={employee.carsAccess}>
          <li>
            <div
              onClick={() => navigate("/cars")}
              className={`${onCarsPage && "active"}`}
            >
              <i className="bx bx-car"></i>
              <span className="links_name">Cars</span>
            </div>
          </li>
        </If>
        <li>
          <div
            onClick={() => navigate("/submitted-cars")}
            className={`${onSubmittedCarsPage && "active"}`}
          >
            <i className="fa-solid fa-car-on"></i>
            <span className="links_name">Submitted Cars</span>
          </div>
        </li>

        <If condition={employee.sellersAccess}>
          <li>
            <div
              onClick={() => navigate("/sellers")}
              className={`${onSellersPage && "active"}`}
            >
              <i className="bx bx-group"></i>
              <span className="links_name">Sellers</span>
            </div>
          </li>
        </If>

        <If condition={employee.careersAccess}>
          <li>
            <div
              onClick={() => navigate("/careers")}
              className={`${onCareersPage && "active"}`}
            >
              <i className="bx bx-briefcase"></i>
              <span className="links_name">Careers</span>
            </div>
          </li>
        </If>

        <If condition={true}>
          <li>
            <div
              onClick={() => navigate("/part")}
              className={`${onPartsPage && "active"}`}
            >
              <i className="bx bx-shield"></i>
              <span className="links_name">Parts</span>
            </div>
          </li>
        </If>

        {/*<If condition={employee.metricsAccess}>*/}
        {/*  <li>*/}
        {/*    <div*/}
        {/*      className={`${onMetricsPage && "active"}`}*/}
        {/*      onClick={() => navigate("/metrics")}*/}
        {/*    >*/}
        {/*      <i className="bx bx-line-chart"></i>*/}
        {/*      <span className="links_name">Metrics</span>*/}
        {/*    </div>*/}
        {/*  </li>*/}
        {/*</If>*/}

        <If condition={employee.userAccess}>
          <li>
            <div
              onClick={() => navigate("/users")}
              className={`${onUsersPage && "active"}`}
            >
              <i className="bx bx-user"></i>

              <span className="links_name">Users</span>
            </div>
          </li>
        </If>

        <li>
          <div onClick={() => navigate("/document")}>
            {/* <i className='bx bx-log-out'></i> */}
            {/* <i className='fa-solid fa-file'></i> */}
            <i className="bx bx-file"></i>
            <span className="links_name">Documents</span>
          </div>
        </li>

        <li>
          <div onClick={() => navigate("/restorer")}>
            {/* <i className='bx bx-log-out'></i> */}
            {/* <i className='fa-solid fa-file'></i> */}
            <i className="fa-solid fa-screwdriver-wrench"></i>
            <span className="links_name">Restorers</span>
          </div>
        </li>
        <li>
          <div onClick={() => navigate("/plate-number")}>
            {/* <i className='bx bx-log-out'></i> */}
            {/* <i className='fa-solid fa-file'></i> */}
            <i className="fa-solid fa-car-rear"></i>
            <span className="links_name">Plate Numbers</span>
          </div>
        </li>
        <li>
          <div onClick={() => navigate("/machinery")}>
            {/* <i className='bx bx-log-out'></i> */}
            {/* <i className='fa-solid fa-file'></i> */}
            <i className="fa-solid fa-gears"></i>
            <span className="links_name">Machinery</span>
          </div>
        </li>
        <li>
          <div onClick={() => navigate("/part")}>
            {/* <i className='bx bx-log-out'></i> */}
            {/* <i className='fa-solid fa-file'></i> */}
            <i className="fa-solid fa-car-battery"></i>
            <span className="links_name">Part</span>
          </div>
        </li>
        <li>
          <div onClick={() => navigate("/bids")}>
            <i className="fa-solid fa-gavel"></i>
            <span className="links_name">Bids</span>
          </div>
        </li>
        <li>
          <div onClick={() => navigate("/performance")}>
            <i className="fa-solid fa-rocket"></i>
            <span className="links_name">Performance</span>
          </div>
        </li>
        <li>
          <div onClick={() => navigate("/boats")}>
            {/* <i className='fa-solid fa-gavel'></i> */}
            <i className="fa-solid fa-ship"></i>
            <span className="links_name">Boats</span>
          </div>
        </li>
        <li className="log_out">
          <div onClick={handleLogout}>
            <i className="bx bx-log-out"></i>
            <span className="links_name">Logout</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
