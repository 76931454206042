import { useEffect, useState } from "react"
import { Button, Container } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import employeeApi from "../api/employeeApi"
import Main from "../components/Main/Main"
import UserCard from "../components/User/UserCard"
import Header from "../layouts/Header"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

function Users() {
  const [users, setUsers] = useState([])
  const { employee } = useSelector((state) => state.employee)
  const [loading, setLoading] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const navigate = useNavigate()
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.userAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  const getUsers = async () => {
    try {
      setLoading(true)
      const users = await employeeApi.getAllEmployees()
      setUsers(users)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const deleteUser = async (id) => {
    try {
      await employeeApi.deleteEmployee({ id })
      notify.success("User deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      getUsers()
    }
  }

  useEffect(() => {
    authorizeEmployee()
    getUsers()
    console.log(users)
  }, [navigate])

  return (
    <>
      <Main>
        <Container>
          {/* <h1 className='text-primary text-center'>Users</h1> */}
          <Button onClick={() => navigate("/users/create")}>Add User</Button>
          {loading ? (
            <h1>Loading users...</h1>
          ) : (
            users.map((user) => (
              <UserCard onDelete={deleteUser} key={user._id} employee={user} />
            ))
          )}
        </Container>
      </Main>
    </>
  )
}

export default Users
