import "bootstrap/dist/css/bootstrap.min.css";
import "./components/Main/Main.css";
import "react-multi-carousel/lib/styles.css";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "react-select-search/style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Careers from "./pages/Careers";
import Cars from "./pages/Cars";
import ContactUs from "./pages/ContactUs";
import CreateCarForm from "./pages/CreateCar";
import CreateCareer from "./pages/CreateCareer";
import Dashboard from "./pages/Dashboard";
import Feedback from "./pages/Feedback";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Sales from "./pages/Sales";
import Users from "./pages/Users";
import { ToastContainer } from "react-toastify";
import CreateUser from "./pages/CreateUser";
import VerifyUser from "./pages/VerifyUser";
import UsersSetInfo from "./pages/UsersSetInfo";
import Profile from "./pages/Profile";
import Redirect from "./components/Redirect/Redirect";
import EditCarForm from "./pages/EditCarForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sellers from "./pages/Sellers";
import SellerCars from "./pages/SellerCars";
import SellerMessages from "./pages/SellerMessages";
import PageNotFound from "./pages/404";
import Security from "./pages/Security";
import Metrics from "./pages/Metrics";
import SearchTool from "./pages/SearchTool";
// import ShowDocument from "./pages/ShowDocument"
import Document from "./pages/Documents";
// import CreateDocument from "./pages/CreateDocument"
import { v4 as uuidV4 } from "uuid";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import TextEditor from "./components/TextEditor";
import Restorer from "./pages/Restorer";
import CreateRestorer from "./pages/CreateRestorer";
import EditRestorer from "./pages/EditRestorer";
import Bids from "./pages/Bids";
import Performance from "./pages/Performance";
import PathPerformanceInfo from "./pages/PathPerformanceInfo";
import DisplayBoats from "./pages/Boats/DisplayBoats";
import CreateBoat from "./pages/Boats/CreateBoat";
import EditBoat from "./pages/Boats/EditBoat";
import DisplayPlateNumbers from "./pages/PlateNumber";
import CreatePlateNumber from "./pages/PlateNumber/Create";
import SubmittedCars from "./pages/SubmittedCars/SubmittedCars";
import Machinery from "./pages/Machinery";
import CreateMachinery from "./pages/Machinery/Create";
import EditMachinery from "./pages/Machinery/Edit";
import ViewParts from "./pages/Parts";
import CreatePart from "./pages/Parts/Create";
import EditParts from "./pages/Parts/Edit";
import Test from "./pages/Test";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/test" element={<Test />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Redirect path={"/login"} />} />
        <Route path="/part" element={<ViewParts />} />
        <Route path="/part/:id/edit" element={<EditParts />} />
        <Route path="/part/create" element={<CreatePart />} />
        <Route path="/machinery" element={<Machinery />} />{" "}
        <Route path="/machinery/create" element={<CreateMachinery />} />
        {/* <Route path='/machinery/:id' element={<EditMachinery />} /> */}
        <Route path="/machinery/:id/edit" element={<EditMachinery />} />
        <Route path="/search-tool" element={<SearchTool />} />
        <Route path="/performance" element={<Performance />} />
        <Route path="/performance/:path" element={<PathPerformanceInfo />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/sellers" element={<Sellers />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/submitted-cars" element={<SubmittedCars />} />
        <Route path="/cars/create" element={<CreateCarForm />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/users" element={<Users />} />
        <Route path="/seller/:sellerId/messages" element={<SellerMessages />} />
        <Route path="/seller/:sellerId/cars" element={<SellerCars />} />
        <Route path="/metrics" element={<Metrics />} />
        <Route path="/restorer" element={<Restorer />} />
        <Route path="/restorer/create" element={<CreateRestorer />} />
        <Route path="/restorer/edit/:id" element={<EditRestorer />} />
        <Route
          path="/users/:id/verify/:verificationId"
          element={<VerifyUser />}
        />
        <Route
          path="/document/create"
          element={<Redirect path={`/document/${uuidV4()}`} />}
          exact
        ></Route>
        <Route path="/document/:id" element={<TextEditor />}></Route>
        <Route path="/document" element={<Document />} />
        <Route path="/security" element={<Security />} />
        <Route path="/users/:id/set-info" element={<UsersSetInfo />} />
        <Route path="/users/create" element={<CreateUser />} />
        <Route path="/careers/create" element={<CreateCareer />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/car/edit/:id" element={<EditCarForm />} />
        <Route path="/bids" element={<Bids />} />
        <Route path="/boats" element={<DisplayBoats />} />
        <Route path="/boats/:id/edit" element={<EditBoat />} />
        <Route path="/boats/create" element={<CreateBoat />} />
        <Route path="/plate-number/" element={<DisplayPlateNumbers />} />
        <Route path="/plate-number/create" element={<CreatePlateNumber />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
