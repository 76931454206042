import axios from "axios"
import { METRICS_API_ROUTES } from "../constants/metrics_routes"

const createEvent = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.CREATE_EVENT, data)
  return res.data
}
const getSMSVisits = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_SMS_VISITS, data)
  return res.data
}
const getEvents = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_EVENTS, data)
  return res.data
}

const getAllEventCategories = async (data) => {
  const res = await axios.post(
    METRICS_API_ROUTES.GET_ALL_EVENTS_CATEGORIES,
    data
  )
  return res.data
}

const getAllMetricsActions = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_ALL_EVENTS_ACTIONS, data)
  return res.data
}

const getAllMetricLabels = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_ALL_EVENTS_LABELS, data)
  return res.data
}

const getAllUniqueVisits = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_ALL_UNIQUE_VISITS, data)
  return res.data
}

const getAllVisits = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_ALL_VISITS, data)
  return res.data
}

const getAllCountries = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_ALL_COUNTRIES, data)
  return res.data
}

const getAllCountriesUniqueVisits = async (data) => {
  const res = await axios.post(
    METRICS_API_ROUTES.GET_ALL_COUNTRIES_UNIQUE_VISITS,
    data
  )
  return res.data
}
const getRetentionRate = async (data) => {
  const res = await axios.post(METRICS_API_ROUTES.GET_RETENTION, data)
  return res.data
}
const metricsApi = {
  createEvent,
  getEvents,
  getAllEventCategories,
  getAllMetricsActions,
  getAllMetricLabels,
  getAllUniqueVisits,
  getAllVisits,
  getAllCountries,
  getAllCountriesUniqueVisits,
  getRetentionRate,
  getSMSVisits
}

export default metricsApi
