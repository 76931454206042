module.exports.body_data = [
  "Monohull",
  "Catamaran",
  "Trimaran",
  "Jon boat",
  "Skiff",
  "Dinghy",
  "Rigid inflatable boat (RIB)",
  "Trawler",
  "Cruiser",
  "Fishing boat",
  "Bass boat",
  "Pontoon boat",
  "Houseboat",
  "Canoe",
  "Kayak",
  "Stand-up paddleboard (SUP)",
  "Barge",
  "Yacht",
  "Sailboat",
  "Speedboat",
  "Jet boat",
  "Cabin cruiser",
  "Runabout",
  "Deck boat",
  "Bowrider",
  "Cuddy cabin",
  "Express cruiser",
  "Fishing trawler",
  "Flat bottom boat",
  "Gondola",
  "House barge",
  "Hydrofoil boat",
  "Icebreaker",
  "Jangada",
  "Jolly boat",
  "Ketch",
  "Launch",
  "Longboat",
  "Lugger",
  "Motor boat",
  "Narrowboat",
  "Paddle boat",
  "Pirogue",
  "Punt",
  "Raft",
  "Rowboat",
  "Schooner",
  "Scow",
  "Shikara",
  "Sloop",
  "Steamboat",
  "Sternwheeler",
  "Surf boat",
  "Tanker",
  "Tugboat",
  "U-boat",
  "Water taxi",
  "Whaler",
  "Work boat",
  "Zodiac boat",
  "Adirondack guideboat",
  "Airboat",
  "Alligator boat",
  "Amphibious vehicle",
  "Banana boat",
  "Barracuda",
  "Bay boat",
  "Billfish boat",
  "Bowpicker",
  "Breadth-first search (BFS) boat",
  "Cabin sailboat",
  "Cape Islander",
  "Car-top boat",
  "Carolina skiff",
  "Catboat",
  "Center console boat",
  "Chesapeake Bay deadrise",
  "Classic wooden boat",
  "Clipper",
  "Coastal cruiser",
  "Coble",
  "Coracle",
  "Corinthian sailboat",
  "Crab boat",
  "Cutter",
  "Day sailer",
  "Decked canoe",
  "Dhow",
  "Dragon boat",
  "Drakkar",
  "Duck boat",
  "Electric boat",
  "Experimental sailboat",
  "Ferrocement boat",
  "Fireboat",
  "Flattie",
  "Flybridge",
  "Folding boat",
  "Glass-bottom boat",
  "Gulet",
  ""
]
