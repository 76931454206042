import { Button, Container } from "react-bootstrap"
import { Form } from "react-bootstrap"
import Header from "../layouts/Header"
import { useState } from "react"
import jobApi from "../api/jobApi"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
import { useNavigate } from "react-router-dom"
import Choose from "../components/controlStatements/Choose"
import When from "../components/controlStatements/When"
import Otherwise from "../components/controlStatements/Otherwise"
function CreateCareer() {
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [jobLink, setJobLink] = useState("")
  const [loading, setLoading] = useState(false)

  const formValidated = name && description && jobLink
  const createJob = async () => {
    try {
      if (!formValidated) {
        notify.error("Please fill all inputs")
      } else {
        setLoading(true)
        await jobApi.createJob({ name, description, jobLink })
        notify.success("Job created successfully")
      }
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
      navigate("/careers")
    }
  }
  return (
    <>
      <Header />
      <Container>
        <h1 className='text-left mb-4 mt-5'>
          Add <span className='text-primary'>Job</span>
        </h1>
        <Form className='w-100'>
          <Form.Group className='mb-3'>
            <Form.Label>Job Name</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Job Name'
            ></Form.Control>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Job Description</Form.Label>
            <Form.Control
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder='Job Description'
              as='textarea'
              rows={3}
            ></Form.Control>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Job Link</Form.Label>
            <Form.Control
              value={jobLink}
              onChange={(e) => setJobLink(e.target.value)}
              placeholder='Job Link'
            ></Form.Control>
          </Form.Group>
          <Button
            onClick={(e) => {
              e.preventDefault()
              createJob()
            }}
          >
            <Choose>
              <When condition={loading}>Loading...</When>
              <Otherwise>Add Job</Otherwise>
            </Choose>
          </Button>
        </Form>
      </Container>
    </>
  )
}

export default CreateCareer
