import axios from "axios";
import { useState } from "react";
import { errorMessage } from "../utils/errorMessage";
import { useEffect } from "react";

export default function Test() {
  const [file, setFile] = useState(null);

  const uploadFile = async () => {
    try {
      if (!file) return;
      const formData = new FormData();
      formData.append("imgfile", file);

      const res = await axios.post(
        "http://localhost:5000/google/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("successful");
    } catch (error) {
      alert(errorMessage(error));
    }
  };

  useEffect(() => {
    console.log("file", file);
  }, [file]);
  return (
    <div>
      <input type="file" onChange={(e) => setFile(e.target.files[0])} />

      <button
        onClick={() => {
          uploadFile();
        }}
      >
        Submit
      </button>
    </div>
  );
}
