import { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import ipAddressApi from "../api/ipAddressApi"
import Choose from "../components/controlStatements/Choose"
import When from "../components/controlStatements/When"
import Otherwise from "../components/controlStatements/Otherwise"
import Main from "../components/Main/Main"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

export default function Security() {
  const [ips, setIps] = useState([])
  const [loading, setLoading] = useState(false)
  const getIpAddresses = async () => {
    try {
      setLoading(true)
      const data = await ipAddressApi.getBlockedUsers()
      // console.log(data)
      setIps(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const unblockUser = async (id) => {
    try {
      setLoading(true)
      await ipAddressApi.unblockUser({ id })
      notify.success("Ip address unblocked successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
      getIpAddresses()
    }
  }

  useEffect(() => {
    getIpAddresses()
  }, [])
  return (
    <Main>
      {" "}
      <Container className='px-5'>
        <h1>Blocked Ip Addresses</h1>
        <Choose>
          <When condition={loading}>
            <h4>loading...</h4>
          </When>
          <Otherwise>
            <table class='table table-striped'>
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>Ip Address</th>
                  <th scope='col'>Blocked At</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {ips.map((ip, index) => (
                  <tr>
                    <th scope='row'>{index + 1}</th>
                    <td>{ip.address}</td>
                    <td>{ip.createdAt}</td>
                    <td>
                      <Button
                        variant='danger'
                        onClick={(e) => {
                          e.preventDefault()
                          unblockUser(ip._id)
                        }}
                      >
                        Unblock
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Otherwise>
        </Choose>
      </Container>
    </Main>
  )
}
