import Header from "../layouts/Header";

function Profile(){
    return(
        <>
            <Header/>
        </>
    )
}

export default Profile;