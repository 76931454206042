import axios from "axios"
import { API_URLS } from "../constants/routes"

const getColors = async () => {
  const res = await axios.post(API_URLS.GET_COLORS)
  return res.data
}

const deleteColor = async (data) => {
  const res = await axios.post(API_URLS.DELETE_COLOR, data)
  return res.data
}

const addColor = async (data) => {
  const res = await axios.post(API_URLS.CREATE_COLOR, data)
  return res.data
}

const colorApi = {
  getColors,
  addColor,
  deleteColor
}

export default colorApi
