import { Form } from "react-bootstrap"
import Main from "../../components/Main/Main"
import { useState } from "react"

import { boat_engine } from "../../constants/boats/boat_engine"
import { body_data } from "../../constants/boats/body"
import { fuel_type } from "../../constants/boats/fuel_type"
import { hull_material } from "../../constants/boats/hull_materials"
import { hull_shape } from "../../constants/boats/hull_shape"
import { purpose_category } from "../../constants/boats/purpose_category"
import Select from "react-select"
import boatApi from "../../api/boat"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import { useNavigate } from "react-router-dom"
import { boat_brands } from "../../constants/boats/boat_brands"
import { boat_type } from "../../constants/boats/boat_type"
import ReactQuill from "react-quill"
export default function CreateBoat() {
  const navigate = useNavigate()
  const [description, setDescription] = useState("")
  const [brand, setBrand] = useState("")
  const [model, setModel] = useState("")
  const [year, setYear] = useState("")
  const [status, setStatus] = useState("")
  const [purpose, setPurpose] = useState("")

  const [type, setType] = useState("")
  const [body, setBody] = useState("")
  const [interiorColor, setInteriorColor] = useState("")
  const [exteriorColor, setExteriorColor] = useState("")
  const [condition, setCondition] = useState("")
  const [country, setCountry] = useState("")

  const [length, setLength] = useState("")
  const [width, setWidth] = useState("")
  const [depth, setDepth] = useState("")
  const [draft, setDraft] = useState("")
  const [fuelType, setFuelType] = useState("")
  const [hullMaterial, setHullMaterial] = useState("")
  const [hullShape, setHullShape] = useState("")
  const [beam, setBeam] = useState("")
  const [engineType, setEngineType] = useState("")
  const [numberOfEngines, setNumberOfEngines] = useState("")
  const [power, setPower] = useState("")
  const [mileageHours, setMileageHours] = useState("")
  const [maxPassengers, setMaxPassengers] = useState("")
  const [fuelTanks, setFuelTanks] = useState("")
  const [legalPapers, setLegalPapers] = useState("")
  const [category, setCategory] = useState("")
  const [price, setPrice] = useState(0)
  async function createBoat() {
    try {
      const res = await boatApi.createBoat({
        brand: brand.value,
        model: model,
        purpose: purpose?.value,
        category: category?.value,
        price,
        year,
        status,
        type: type.value,
        body: body?.value,
        interiorColor: interiorColor,
        exteriorColor: exteriorColor,
        condition: condition,
        country: country,
        length,
        width,
        depth,
        draft,
        fuelType: fuelType?.value,
        hullMaterial: hullMaterial?.value,
        hullShape: hullShape?.value,
        beam: beam,
        engineType: engineType?.value,
        numberOfEngines: numberOfEngines,
        power: power,
        mileageHours: mileageHours,
        maxPassengers: maxPassengers,
        fuelTanks,
        legalPapers,
        description
      })
      navigate("/boats")
      notify.success("Boat created successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
    }
  }
  function transformToOptions(arr) {
    let temp = []
    for (let el of arr) {
      temp.push({ label: el, value: el })
    }

    return temp
  }
  return (
    <Main>
      <h3 className='mx-2 text-center text-primary display-3'>Create Boat</h3>
      <div className='d-flex justify-content-center align-items-center w-100'>
        <div className='gap-2 row row-cols-md-4 row-cols-sm-2 w-100'>
          <Form.Group className='mb-3'>
            <Form.Label>Brand</Form.Label>
            <Select
              options={transformToOptions(boat_brands)}
              onChange={(e) => setBrand(e)}
              placeholder='Brand'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Model</Form.Label>
            <Form.Control
              value={model}
              onChange={(e) => setModel(e.target.value)}
              placeholder='Enter model'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Price</Form.Label>
            <Form.Control
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder='Enter model'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Purpose</Form.Label>
            <Select
              options={transformToOptions(Object.keys(purpose_category))}
              placeholder='Enter purpose'
              value={purpose}
              onChange={(e) => setPurpose(e)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Category</Form.Label>
            <Select
              isDisabled={purpose?.value === ""}
              options={
                purpose?.value
                  ? transformToOptions(purpose_category[purpose.value])
                  : []
              }
              placeholder='Enter category'
              value={category}
              onChange={(e) => setCategory(e)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Year</Form.Label>
            <Form.Control
              value={year}
              onChange={(e) => setYear(e.target.value)}
              placeholder='Enter year'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value=''>Status</option>
              <option value='For Sale'>For Sale</option>
              <option value='For Rent'>For Rent</option>
              <option value='For Sale and Rent'>For Sale and Rent</option>
              <option value='On Hold'>On Hold</option>
              <option value='Sold'>Sold</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Select
              options={transformToOptions(boat_type)}
              onChange={(e) => setType(e)}
              value={type}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Body</Form.Label>
            <Select
              value={body}
              onChange={(e) => setBody(e)}
              options={transformToOptions(body_data)}
              placeholder='Search for body...'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Interior Color</Form.Label>
            <Form.Control
              value={interiorColor}
              onChange={(e) => setInteriorColor(e.target.value)}
              placeholder='Enter interior color'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Exterior Color</Form.Label>
            <Form.Control
              value={exteriorColor}
              onChange={(e) => setExteriorColor(e.target.value)}
              placeholder='Enter exterior color'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Condition</Form.Label>
            <Form.Control
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              placeholder='Enter condition'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Country</Form.Label>

            <Form.Control
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder='Enter country'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Length</Form.Label>
            <Form.Control
              value={length}
              onChange={(e) => setLength(e.target.value)}
              placeholder='Enter length'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Width</Form.Label>
            <Form.Control
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              placeholder='Enter width'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Depth</Form.Label>
            <Form.Control
              value={depth}
              onChange={(e) => setDepth(e.target.value)}
              placeholder='Enter depth'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Draft </Form.Label>
            <Form.Control
              value={draft}
              onChange={(e) => setDraft(e.target.value)}
              placeholder='Enter draft'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Fuel Type</Form.Label>
            <Select
              placeholder='Search for fuel type...'
              options={transformToOptions(fuel_type)}
              value={fuelType}
              onChange={(e) => setFuelType(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Hull Material</Form.Label>

            <Select
              placeholder='Search for hull material...'
              options={transformToOptions(hull_material)}
              value={hullMaterial}
              onChange={(e) => setHullMaterial(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Hull Shape</Form.Label>
            <Select
              options={transformToOptions(hull_shape)}
              value={hullShape}
              onChange={(e) => setHullShape(e)}
              placeholder='Search for hull shape...'
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Beam</Form.Label>
            <Form.Control
              value={beam}
              onChange={(e) => setBeam(e.target.value)}
              placeholder='Enter beam'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Engine Type</Form.Label>

            <Select
              isClearable
              value={engineType}
              onChange={(e) => {
                setEngineType(e)
              }}
              placeholder='Choose engine type'
              options={transformToOptions(boat_engine)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Number Of Engines</Form.Label>
            <Form.Control
              value={numberOfEngines}
              onChange={(e) => setNumberOfEngines(e.target.value)}
              placeholder='Enter number of engines'
            ></Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Power</Form.Label>
            <Form.Control
              value={power}
              onChange={(e) => setPower(e.target.value)}
              placeholder='Enter power'
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Mileage Hours</Form.Label>
            <Form.Control
              value={mileageHours}
              onChange={(e) => setMileageHours(e.target.value)}
              placeholder='Enter mileage hours'
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Max Passengers</Form.Label>
            <Form.Control
              value={maxPassengers}
              onChange={(e) => setMaxPassengers(e.target.value)}
              placeholder='Enter max passengers'
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Fuel Tanks</Form.Label>
            <Form.Control
              value={fuelTanks}
              onChange={(e) => setFuelTanks(e.target.value)}
              placeholder='Enter fuel tanks'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Legal Papers</Form.Label>
            <Form.Control
              placeholder='Enter legal papers'
              value={legalPapers}
              onChange={(e) => setLegalPapers(e.target.value)}
            />
          </Form.Group>

          <div
            style={{
              width: "100%"
            }}
          >
            <ReactQuill
              className='w-100'
              value={description}
              onChange={setDescription}
              theme='snow'
            />
          </div>
        </div>
      </div>
      <div className='mx-2 my-2'>
        <button
          onClick={() => createBoat()}
          className='btn btn-primary mx-2 btn-lg mt-3 w-100 mb-5'
        >
          Create Boat{" "}
        </button>
      </div>
    </Main>
  )
}
