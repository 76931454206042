import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import employeeApi from "../api/employeeApi";
import { errorMessage } from "../utils/errorMessage";

const employee = JSON.parse(localStorage.getItem("employee"));

const initialState = {
  employee: employee ? employee : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// export const register = createAsyncThunk(
//     "employee/register",
//     async(data,thunkAPI)=>{
//        try {
//         return await employeeApi.registerEmployee(data)
//        } catch (error) {
//             return thunkAPI.rejectWithValue(errorMessage(error))
//        }
//     }
// )

export const create = createAsyncThunk(
  "employee/create",
  async (data, thunkAPI) => {
    try {
      return await employeeApi.createEmployee(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorMessage(error));
    }
  }
);

export const login = createAsyncThunk(
  "employee/login",
  async ({ email, password }, thunkAPI) => {
    try {
      return await employeeApi.loginEmployee({ email, password });
    } catch (error) {
      return thunkAPI.rejectWithValue(errorMessage(error));
    }
  }
);

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    logout: (state) => {
      employeeApi.logoutEmployee();
      state.employee = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employee = payload;
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.employee = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employee = payload;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.employee = null;
      });
  },
});

export const { reset, logout } = employeeSlice.actions;
export default employeeSlice.reducer;
