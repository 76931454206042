import { useEffect } from "react"
import { useState } from "react"
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import metricsApi from "../api/metricsApi"
import Choose from "../components/controlStatements/Choose"
import Otherwise from "../components/controlStatements/Otherwise"
import When from "../components/controlStatements/When"
import Main from "../components/Main/Main"
import * as notify from "../lib/notify"
import { getCountryName } from "../utils/countryCodeToCountry"
import { errorMessage } from "../utils/errorMessage"

export default function Metrics() {
  const [sorted, setSorted] = useState("")

  const [data, setData] = useState([])

  const { employee } = useSelector((state) => state.employee)
  const navigate = useNavigate()
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.metricsAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }
  const [loadingMetrics, setLoadingMetrics] = useState(false)
  const getMetrics = async () => {
    try {
      setLoadingMetrics(true)
      const metrics = await metricsApi.getEvents()
      setData(metrics)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingMetrics(false)
    }
  }

  const [categories, setCategories] = useState([])
  const getAllMetricsCategories = async () => {
    const data = await metricsApi.getAllEventCategories({ last: sorted })
    setCategories(data)
  }

  const [actions, setActions] = useState([])
  //   const [category,setCategory] = useState()
  const [actionCategory, setActionCategory] = useState()
  const getActions = async () => {
    const data = await metricsApi.getAllMetricsActions({
      category: actionCategory,
      last: sorted
    })
    setActions(data)
  }

  const [labelAction, setLabelAction] = useState()
  const [labels, setLabels] = useState([])
  const getLabels = async () => {
    const data = await metricsApi.getAllMetricLabels({
      category: actionCategory,
      action: labelAction,
      last: sorted
    })
    setLabels(data)
  }

  const goToCategories = () => {
    setLabelAction()
    setActionCategory()
  }

  const goToActions = () => {
    setLabelAction()
  }

  const [uniqueVisits, setUniqueVisits] = useState(0)
  const [loadingUniqueVisits, setLoadingUniqueVists] = useState(false)
  const getUniqueVisits = async () => {
    try {
      setLoadingUniqueVists(true)
      const data = await metricsApi.getAllUniqueVisits({ last: sorted })
      setUniqueVisits(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingUniqueVists(false)
    }
  }

  const [visits, setVisits] = useState(0)
  const [loadingVisits, setLoadingVisits] = useState(false)
  const getVisits = async () => {
    try {
      setLoadingVisits(true)
      const data = await metricsApi.getAllVisits({ last: sorted })
      setVisits(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingVisits(false)
    }
  }
  const [loadingSms, setLoadingSms] = useState(false)
  const [smsCount, setSmsCount] = useState(0)
  const getSmsVisists = async () => {
    try {
      setLoadingSms(true)
      const sms = await metricsApi.getSMSVisits({ last: sorted })
      setSmsCount(sms)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingSms(false)
    }
  }
  const [countries, setCountries] = useState([])
  const [loadingCountries, setLoadingCountries] = useState(false)
  const getCountries = async () => {
    try {
      setLoadingCountries(true)
      const data = await metricsApi.getAllCountries({ last: sorted })
      setCountries(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingCountries(false)
    }
  }

  const [uniqueCountries, setUniqueCountries] = useState([])
  const [loadingUniqueCountries, setLoadingUniqueCountries] = useState(false)
  const getUniqueCountry = async () => {
    try {
      setLoadingUniqueCountries(true)
      const data = await metricsApi.getAllCountriesUniqueVisits({
        last: sorted
      })
      setUniqueCountries(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingUniqueCountries(false)
    }
  }

  const [oldUsers, setOldUsers] = useState(0)
  const [newUsers, setNewUsers] = useState(0)
  const [loadingRetention, setLoadingRetention] = useState(false)
  const getRetention = async () => {
    try {
      setLoadingRetention(true)
      const data = await metricsApi.getRetentionRate({ last: sorted })
      setOldUsers(data.oldUsers)
      setNewUsers(data.newUsers)
    } catch (error) {
    } finally {
      setLoadingRetention(false)
    }
  }
  useEffect(() => {
    if (actionCategory) getActions()
  }, [actionCategory])

  useEffect(() => {
    if (labelAction) getLabels()
  }, [labelAction])
  useEffect(() => {
    getMetrics()
    getAllMetricsCategories()
    getUniqueVisits()
    getVisits()
    getCountries()
    getUniqueCountry()
    authorizeEmployee()
    getRetention()
    getSmsVisists()
  }, [sorted])
  return (
    <>
      <Main>
        <Container>
          <h1>Metrics</h1>

          <select value={sorted} onChange={(e) => setSorted(e.target.value)}>
            <option value=''>Default</option>
            <option value='hour'>Last Hour</option>
            <option value='week'>Last Week</option>
            <option value='day'>Last Day</option>
            <option value='five_minutes'>Last Five Minutes</option>
          </select>

          <Row>
            <Col md={4}>
              <Card>
                <Card.Header className='bg-primary text-white'>
                  Unique Visits
                </Card.Header>

                <Card.Body>
                  <Choose>
                    <When condition={loadingUniqueVisits}>
                      <div className='text-center'>
                        <Spinner animation='border' />
                      </div>
                    </When>
                    <Otherwise>
                      <Card.Text as='h5'>{uniqueVisits} visits</Card.Text>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Header className='bg-primary text-white'>
                  Visits
                </Card.Header>

                <Card.Body>
                  <Choose>
                    <When condition={loadingVisits}>
                      <div className='text-center'>
                        <Spinner animation='border' />
                      </div>
                    </When>
                    <Otherwise>
                      <Card.Text as='h5'>{visits} visits</Card.Text>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <Card.Header className='bg-primary text-white'>
                  Total Countries
                </Card.Header>

                <Card.Body>
                  <Choose>
                    <When condition={loadingCountries}>
                      <div className='text-center'>
                        <Spinner animation='border'></Spinner>
                      </div>
                    </When>
                    <Otherwise>
                      <Card.Text as='h5'>
                        {countries.length} countries
                      </Card.Text>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Header className='bg-primary text-white'>
                  Unique Sms Visits
                </Card.Header>

                <Card.Body>
                  <Choose>
                    <When condition={loadingSms}>
                      <div className='text-center'>
                        <Spinner animation='border'></Spinner>
                      </div>
                    </When>
                    <Otherwise>
                      <Card.Text as='h5'>{smsCount} visits</Card.Text>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header className='bg-primary text-white'>
                  Old Users
                </Card.Header>
                <Card.Body>
                  <Choose>
                    <When condition={loadingRetention}>
                      <div className='text-center'>
                        <Spinner animation='border' />
                      </div>
                    </When>
                    <Otherwise>
                      <Card.Text as='h5'>{oldUsers} users</Card.Text>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header className='bg-primary text-white'>
                  New Users
                </Card.Header>
                <Card.Body>
                  <Choose>
                    <When condition={loadingRetention}>
                      <div className='text-center'>
                        <Spinner animation='border' />
                      </div>
                    </When>
                    <Otherwise>
                      <Card.Text as='h5'>{newUsers} users</Card.Text>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className='my-3'>
            <Col md={6}>
              <Card>
                <Card.Header className='bg-success text-white'>
                  Countries Visits
                </Card.Header>
                <Card.Body>
                  <Choose>
                    <When condition={loadingCountries}>
                      <div className='text-center'>
                        <Spinner animation='border' />
                      </div>
                    </When>
                    <Otherwise>
                      <div>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Country</th>
                              <th>Visits</th>
                            </tr>
                          </thead>
                          <tbody>
                            {countries.map((c) => {
                              return (
                                <tr>
                                  <td>{getCountryName(c.country)}</td>
                                  <td>{c.count}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header className='bg-success text-white'>
                  Countries Unique Visits
                </Card.Header>
                <Card.Body>
                  <Choose>
                    <When condition={loadingUniqueCountries}>
                      <div className='text-center'>
                        <Spinner animation='border' />
                      </div>
                    </When>
                    <Otherwise>
                      <div>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Country</th>
                              <th>Visits</th>
                            </tr>
                          </thead>
                          <tbody>
                            {uniqueCountries.map((c) => {
                              return (
                                <tr>
                                  <td>{getCountryName(c.country)}</td>
                                  <td>{c.count}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Otherwise>
                  </Choose>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className=''>
            <Card>
              <Card.Header className='bg-danger text-white'>Events</Card.Header>
              <Card.Body>
                <Choose>
                  <When condition={!labelAction && !actionCategory}>
                    {/* <h5 className='text-primary'>All Categories</h5> */}

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((a) => {
                          return (
                            <tr>
                              <td
                                className='link-primary cursor-pointer'
                                onClick={() => setActionCategory(a.category)}
                              >
                                {a.category}
                              </td>
                              <td>{a.count}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </When>
                  <When condition={actionCategory && !labelAction}>
                    <h5 className=''>
                      <span className='text-secondary'>/</span>
                      <span
                        onClick={() => goToCategories()}
                        className='link-primary cursor-pointer'
                      >
                        Categories
                      </span>
                    </h5>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {actions.map((a) => {
                          return (
                            <tr>
                              <td
                                className=' link-primary cursor-pointer'
                                onClick={() => setLabelAction(a.action)}
                              >
                                {a.action}
                              </td>
                              <td>{a.count}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </When>
                  <Otherwise>
                    <>
                      <h5 className='bg-white p-2'>
                        <span className='text-secondary'>/</span>
                        <span
                          onClick={() => goToCategories()}
                          className='link-primary cursor-pointer'
                        >
                          Categories
                        </span>
                        <span className='text-secondary'>/</span>
                        <span
                          onClick={() => goToActions()}
                          className='link-primary cursor-pointer'
                        >
                          {actionCategory}
                        </span>
                      </h5>
                    </>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Label</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {labels.map((a) => {
                          return (
                            <tr>
                              <td>{a.label}</td>
                              <td>{a.count}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Otherwise>
                </Choose>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </Main>
    </>
  )
}
