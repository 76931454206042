import { useNavigate } from "react-router-dom"
import Main from "../../components/Main/Main"
import plateNumberApi from "../../api/plateNumber"
import { useState } from "react"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import { useEffect } from "react"
import PlateNumberCard from "../../components/Plate/PlateNumberCard"
export default function DisplayPlateNumbers() {
  const navigate = useNavigate()
  const [loadingPlates, setLoadingPlates] = useState(false)

  const [plates, setPlates] = useState([])
  async function getPlateNumbers() {
    try {
      setLoadingPlates(true)
      const data = await plateNumberApi.getPlateNumbers()
      setPlates(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingPlates(false)
    }
  }
  useEffect(() => {
    getPlateNumbers()
  }, [])
  return (
    <Main>
      <div className='container'>
        <button
          onClick={() => {
            navigate("/plate-number/create")
          }}
          className='btn btn-primary'
        >
          Create
        </button>
        {loadingPlates ? (
          "Loading..."
        ) : (
          <>
            <div className='mt-5 row '>
              {plates.map((plate, index) => {
                return (
                  <div className='col-6'>
                    <PlateNumberCard
                      key={index}
                      getPlateNumbers={() => getPlateNumbers()}
                      plateNumber={plate}
                    />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </Main>
  )
}
