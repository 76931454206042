import { useEffect, useState } from "react"
import { Container, Form, Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import employeeApi from "../api/employeeApi"
import Main from "../components/Main/Main"
import Header from "../layouts/Header"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

function CreateUser() {
  // const dispatch =useDispatch()

  const navigate = useNavigate()
  const [position, setPosition] = useState()
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const formValidated = position && email

  const createEmployee = async () => {
    await employeeApi.createEmployee({ email, position })
  }
  const handleSubmitButton = async (e) => {
    e.preventDefault()
    if (!formValidated) {
      notify.error("Please fill all inputs")
    } else {
      try {
        createEmployee()
        navigate("/users")
      } catch (error) {
        notify.error(errorMessage(error))
      } finally {
        setLoading(false)
      }
    }
    // await employeeApi.createEmployee
  }

  return (
    <>
      <Main>
        <Container>
          <h1 className='mt-4'>Create Employee</h1>
          <Form className='w-100'>
            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Email"}
                type={"text"}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Position</Form.Label>
              <Form.Control
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                placeholder={"Position"}
                type={"text"}
              />
            </Form.Group>
            <Button onClick={handleSubmitButton}>Submit</Button>
          </Form>
        </Container>
      </Main>
    </>
  )
}

export default CreateUser
