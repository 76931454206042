import { useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { Upload, FileXFill, PencilSquare, Repeat } from "react-bootstrap-icons"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import Choose from "../controlStatements/Choose"
import When from "../controlStatements/When"
import Otherwise from "../controlStatements/Otherwise"
import Compressor from "compressorjs"

import restorerApi from "../../api/restorerApi"
import boatApi from "../../api/boat"
import { BOAT_API_ROUTES } from "../../api/boat/routes"
import { autopia_backend } from "../../lib/axios"
// import { upload } from "@testing-library/user-event/dist/upload"
function BoatImages({ imgs, boatId }) {
  const [images, setImages] = useState([])
  const [displayedImages, setDisplayedImages] = useState([])
  const [inputImages, setInputImages] = useState([])
  const [image, setImage] = useState("")
  const [loadingImage, setLoadingImage] = useState()
  const [showCropModal, setShowCropModal] = useState(false)

  const handleCloseCropModal = () => setShowCropModal(false)
  const handleShowCropModal = () => setShowCropModal(true)
  const [imageIndex, setImageIndex] = useState("")
  const [imageSecondIndex, setImageSecondIndex] = useState("")
  const [imageCarId, setImageCarId] = useState("")

  const [loading, setLoading] = useState({
    state: false,
    message: "",
    type: ""
  })

  const readImageUrl = (img) => {
    try {
      return URL.createObjectURL(img)
    } catch (error) {
      notify.error(errorMessage(error))
      setInputImages([])
      setDisplayedImages([])
    } finally {
    }
  }

  const [compressedFile, setCompressedFile] = useState(null)

  const [loadingSwitchImage, setLoadingSwitchImage] = useState(false)
  const switchCarImages = async () => {
    try {
      setLoadingSwitchImage(true)
      if (!imageSecondIndex) {
        notify.error("Please select an index")
        return
      }
      await boatApi.switchImages({
        index1: imageIndex,
        index2: imageSecondIndex,
        boatId
      })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setImageSecondIndex()
      // setLoading({ state: false, message: "", type: "" })
      setLoadingSwitchImage(false)
      handleCloseSwitchModal()
      getImages()
    }
  }

  const handleCompressedUpload = (e) => {
    return new Promise((resolve, reject) => {
      const images = e.target.files
      let result = []
      for (let image of images) {
        new Compressor(image, {
          quality: 1,

          success: (compressedResult) => {
            // results.push(compressedResult)
            console.log(compressedResult)
            // setCompressedFile(compressedResult)
            result.push(compressedResult)
            if (result.length == images.length) {
              setInputImages([...inputImages, ...result])
            }
            // setInputImages([...inputImages, compressedResult])
          }
        })
      }
      resolve(result)
      // resolve(results)
    })
  }
  const uploadImages = async () => {
    try {
      setLoading({ state: true, message: "Uploading...", type: "upload" })
      const bodyFormData = new FormData()
      bodyFormData.append("boatId", boatId)
      for (let i = 0; i < inputImages.length; i++) {
        bodyFormData.append("image", inputImages[i])
      }

      const res = await autopia_backend.post(
        BOAT_API_ROUTES.UPLOAD_BOAT_IMAGES,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      notify.success("Image uploaded successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "", type: "" })
      setInputImages([])
      setDisplayedImages([])
      getImages()
    }
  }
  const getImages = async () => {
    try {
      setLoading({ state: true, message: "Loading images...", type: "load" })
      const res = await autopia_backend.post(BOAT_API_ROUTES.GET_BOAT_IMAGES, {
        boatId
      })
      setImages(res.data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "", type: "" })
    }
  }
  const deleteImage = async (filename) => {
    try {
      setLoading({ state: true, message: "Deleting image...", type: "delete" })
      const res = await autopia_backend.post(
        BOAT_API_ROUTES.DELETE_BOAT_IMAGE,
        {
          filename,
          boatId
        }
      )

      notify.success("Image deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "", type: "" })
      getImages()
    }
  }
  useEffect(() => {
    getImages()
  }, [])

  const [showSwitchModal, setShowSwitchModal] = useState(false)

  const handleCloseSwitchModal = () => setShowSwitchModal(false)
  const handleShowSwitchModal = () => setShowSwitchModal(true)
  return (
    <>
      <h4 className=' mt-3 text-bold fw-bold'>Uploaded Images</h4>
      <Choose>
        <When condition={loading.state && loading.type === "load"}>
          {loading.message}
        </When>
        <When condition={loading.state && loading.type === "delete"}>
          {loading.message}
        </When>

        <Otherwise>
          <Row>
            {images.map((img, idx) => (
              <Col lg={6} md={4} sm={6} xs={12}>
                <div
                  key={idx}
                  className='d-flex justify-content-center align-items-start flex-column'
                >
                  <div className='d-flex justify-content-start align-items-center gap-2'>
                    <div className='bg-dark text-white p-2 rounded-circle'>
                      {idx}
                    </div>
                    <Repeat
                      style={{ cursor: "pointer" }}
                      size={"30px"}
                      onClick={() => {
                        setImageIndex(idx)
                        handleShowSwitchModal()
                      }}
                    ></Repeat>

                    <FileXFill
                      onClick={(e) => {
                        e.preventDefault()
                        deleteImage(img.filename)
                      }}
                      className='text-danger cursor-pointer'
                      size={"30px"}
                    />
                  </div>

                  <img
                    key={idx}
                    style={{
                      width: "200px",
                      objectFit: "contain"
                    }}
                    src={img.url.replace("/upload", "/upload/w_400")}
                    alt=''
                    className='p-1'
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Otherwise>
      </Choose>
      {/* <AddImage setInputImages={setInputImages} /> */}
      <div className='my-3 d-flex justify-content-start gap-3 align-items-center'>
        <h2 className='display-6 fw-bold'>Upload Images</h2>
        <Button
          variant='primary'
          disabled={loading.state && loading.type === "upload"}
          onClick={(e) => {
            e.preventDefault()
            uploadImages()
          }}
        >
          <Choose>
            <When condition={loading.state && loading.type === "upload"}>
              {loading.message}
            </When>
            <Otherwise>Upload</Otherwise>
          </Choose>
        </Button>
      </div>

      <Choose>
        <When condition={loading.state && loading.type === "upload"}></When>
        <Otherwise>
          <div className='bg-white shadow-lg border border-dark d-flex justify-content-center align-items-center p-1'>
            <label htmlFor='image' className='cursor-pointer'>
              <div className='d-flex flex-column justify-content-center align-items-center gap-1 '>
                <Upload size={"30px"} />
                <h6>Add Image</h6>
              </div>
              <input
                onChange={(e) => {
                  handleCompressedUpload(e).then((data) => {})
                  setDisplayedImages([...displayedImages, ...e.target.files])
                }}
                className='d-none'
                id='image'
                type='file'
                multiple
              />
            </label>
          </div>
          <Row className='w-100'>
            {displayedImages?.map((img, idx) => (
              <Col lg={6} md={4} sm={6} xs={12}>
                <div className='d-flex justify-content-center align-items-start flex-column'>
                  <div className='d-flex justify-content-start align-items-center gap-1'>
                    <PencilSquare
                      onClick={() => {
                        setImage(img)
                        handleShowCropModal()
                      }}
                      size={"30px"}
                      className='text-primary cursor-pointer'
                    />

                    <FileXFill
                      onClick={(e) => {
                        e.preventDefault()
                        setInputImages(
                          inputImages.filter((image) => image.name !== img.name)
                        )
                        setDisplayedImages(
                          displayedImages.filter(
                            (image) => image.name !== img.name
                          )
                        )
                      }}
                      className='text-danger cursor-pointer'
                      size={"30px"}
                    />
                  </div>
                  <img
                    key={idx}
                    style={{
                      width: "200px",
                      objectFit: "cover"
                    }}
                    src={readImageUrl(img)}
                    className='p-1'
                    alt=''
                  />

                  <p>{"image size: " + img.size / 1000000 + " mb"}</p>
                  <p>
                    {"image compressed size: " +
                      (img.size / 1000000) * 0.6799 +
                      " mb"}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Otherwise>
      </Choose>

      <Modal show={showSwitchModal} onHide={handleCloseSwitchModal}>
        <Modal.Header closeButton>
          <Modal.Title>Switch Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Switch Image {imageIndex} with: </h3>
          <Form.Group>
            <Form.Select
              onChange={(e) => {
                setImageSecondIndex(e.target.value)
              }}
            >
              <option>Choose Image Number</option>
              {[...Array(images.length)].map((_, idx) => {
                return idx !== imageIndex && <option value={idx}>{idx}</option>
              })}
            </Form.Select>
          </Form.Group>
          <Button
            disabled={loadingSwitchImage}
            className='mt-2'
            onClick={switchCarImages}
          >
            {loadingSwitchImage ? "Loading..." : "Switch"}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BoatImages
