
const BACKEND_URL = "https://api.autopiaworld.com"
// const BACKEND_URL = "https://autopia-api-a82a6f160979.herokuapp.com";

export const METRICS_API_ROUTES = {
  CREATE_EVENT: `${BACKEND_URL}/events/create`,
  GET_EVENTS: `${BACKEND_URL}/events`,
  GET_ALL_EVENTS_CATEGORIES: `${BACKEND_URL}/events/categories`,
  GET_ALL_EVENTS_ACTIONS: `${BACKEND_URL}/events/actions`,
  GET_ALL_EVENTS_LABELS: `${BACKEND_URL}/events/labels`,
  GET_ALL_UNIQUE_VISITS: `${BACKEND_URL}/visits/unique`,
  GET_ALL_VISITS: `${BACKEND_URL}/visits`,
  GET_ALL_COUNTRIES: `${BACKEND_URL}/visits/countries`,
  GET_ALL_COUNTRIES_UNIQUE_VISITS: `${BACKEND_URL}/visits/countries/unique`,
  GET_RETENTION: `${BACKEND_URL}/visits/retention`,
  GET_SMS_VISITS: `${BACKEND_URL}/visits/sms`,
};
