import axios from "axios"
import { API_URLS } from "../constants/routes"

const createScopeOfWork = async (data = {}) => {
  const res = await axios.post(API_URLS.CREATE_SCOPE_OF_WORK, data)
  return res.data
}
const editScopeOfWork = async (data = {}) => {
  const res = await axios.post(API_URLS.EDIT_SCOPE_OF_WORK, data)
  return res.data
}
const getScopeOfWorks = async (data = {}) => {
  const res = await axios.post(API_URLS.GET_SCOPE_OF_WORKS, data)
  return res.data
}
const deleteScopeOfWork = async (data = {}) => {
  const res = await axios.post(API_URLS.DELETE_SCOPE_OF_WORK, data)
  return res.data
}

const scopeOfWorkApi = {
  createScopeOfWork,
  getScopeOfWorks,
  deleteScopeOfWork,
  editScopeOfWork
}

export default scopeOfWorkApi
