import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import Choose from "../controlStatements/Choose"
import When from "../controlStatements/When"
import "./Main.css"
const Navbar = ({ toggleActive }) => {
  const { employee } = useSelector((state) => state.employee)
  const route = useLocation()
  const onDashboardPage = route.pathname === "/dashboard"
  const onCarsPage = route.pathname === "/cars"
  const onSellersPage = route.pathname === "/sellers"
  const onSalesPage = route.pathname === "/sales"
  const onFeedbackPage = route.pathname === "/feedback"
  const onContactUsPage = route.pathname === "/contact-us"
  const onCareersPage = route.pathname === "/careers"
  const onUsersPage = route.pathname === "/users"
  return (
    <nav>
      <div
        className='sidebar-button'
        style={{ cursor: "pointer" }}
        onClick={toggleActive}
      >
        <i className='bx bx-menu sidebarBtn'></i>
      </div>

      <div className='sidebar-button'>
        <span className='dashboard'>
          <Choose>
            <When condition={onDashboardPage}>Dashboard</When>
            <When condition={onCarsPage}>Cars</When>
            <When condition={onSalesPage}>Sales</When>
            <When condition={onSellersPage}>Sellers</When>
            <When condition={onFeedbackPage}>Feedback</When>
            <When condition={onContactUsPage}>Contact Us</When>
            <When condition={onCareersPage}>Careers</When>
            <When condition={onUsersPage}>Users</When>
          </Choose>
        </span>
      </div>

      {/* <div className='search-box'>
        <input type='text' placeholder='Search...' />
        <i className='bx bx-search'></i>
      </div> */}
      <div className='profile-details'>
        <i className='bx bx-user ps-2'></i>
        <span className='admin_name'>{employee.fullName}</span>
      </div>
    </nav>
  )
}

export default Navbar
