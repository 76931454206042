import axios from "axios"

import { API_URLS } from "../constants/routes"

const createEmployee = async (data) => {
  const response = await axios.post(API_URLS.CREATE_EMPLOYEE, data)
  return response.data
}

const verifyEmployee = async (data) => {
  const response = await axios.post(API_URLS.VERIFY_EMPLOYEE, data)
  return response.data
}

const setInfoEmployee = async (data) => {
  const response = await axios.post(API_URLS.ADDINFO_EMPLOYEE, data)
  return response.data
}

const loginEmployee = async (data) => {
  const response = await axios.post(API_URLS.LOGIN_EMPLOYEE, data)
  if (response.data) {
    localStorage.setItem("employee", JSON.stringify(response.data))
  }

  return response.data
}

const deleteEmployee = async (data) => {
  const response = await axios.post(API_URLS.DELETE_EMPLOYEE, data)
  return response.data
}

const getAllEmployees = async () => {
  const response = await axios.post(API_URLS.GET_EMPLOYEES)
  return response.data
}
const checkDashboard = async (id) => {
  const response = await axios.post(API_URLS.CHECK_DASHBOARD, { id })
  return response.data
}
const checkCars = async (id) => {
  const response = await axios.post(API_URLS.CHECK_CARS, { id })
  return response.data
}
const checkCareers = async (id) => {
  const response = await axios.post(API_URLS.CHECK_CAREERS, { id })
  return response.data
}
const checkSales = async (id) => {
  const response = await axios.post(API_URLS.CHECK_SALES, { id })
  return response.data
}
const checkFeedback = async (id) => {
  const response = await axios.post(API_URLS.CHECK_FEEDBACK, { id })
  return response.data
}
const checkContactUs = async (id) => {
  const response = await axios.post(API_URLS.CHECK_CONTACTUS, { id })
  return response.data
}
const checkMetrics = async (id) => {
  const res = await axios.post(API_URLS.CHECK_METRICS, { id })
  return res.data
}
const checkSecurity = async (id) => {
  const res = await axios.post(API_URLS.CHECK_SECURITY, { id })
  return res.data
}

const checkSellers = async (id) => {
  const res = await axios.post(API_URLS.CHECK_SELLERS, { id })
  return res.data
}

const checkSearchTool = async (id) => {
  const res = await axios.post(API_URLS.CHECK_SEARCH_TOOL, { id })
  return res.data
}
const logoutEmployee = () => {
  localStorage.removeItem("employee")
}

const employeeApi = {
  // registerEmployee,
  logoutEmployee,
  getAllEmployees,
  loginEmployee,
  checkDashboard,
  checkSales,
  checkCareers,
  checkContactUs,
  checkFeedback,
  checkSecurity,
  checkSellers,
  checkSearchTool,
  checkMetrics,
  checkCars,
  createEmployee,
  verifyEmployee,
  setInfoEmployee,
  deleteEmployee
}

export default employeeApi
