import { useEffect } from "react"
import { Container } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as notify from "../lib/notify"
import SellerList from "./SellerList"
import Main from "../components/Main/Main"
function Sellers() {
  const navigate = useNavigate()
  const { employee } = useSelector((state) => state.employee)
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.sellersAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  useEffect(() => {
    authorizeEmployee()
  }, [])

  return (
    <>
      <Main>
        <Container>
          <div className='d-flex justify-content-center align-items-center flex-column bg-white'>
            <SellerList />
          </div>
        </Container>
      </Main>
    </>
  )
}

export default Sellers
