import { Container, Table } from "react-bootstrap"
import Main from "../components/Main/Main"
import * as notify from "../lib/notify"
import bidApi from "../api/bids"
import { errorMessage } from "../utils/errorMessage"
import { useState } from "react"
import { useEffect } from "react"

export default function Bids() {
  const [bids, setBids] = useState([])
  const [loading, setLoading] = useState(false)
  async function getBids() {
    try {
      setLoading(true)
      const data = await bidApi.getBids()
      setBids(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBids()
  }, [])
  return (
    <>
      <Main>
        <Container>
          <h1>Bids</h1>

          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Bid</th>
                <th>Car</th>
                <th>User</th>
                <th>Phone</th>
                <th>At</th>
              </tr>
            </thead>
            <tbody>
              {bids?.map((bid, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{bid?.bid}</td>
                    <td>{bid?.car?.brand + " " + bid?.car?.model}</td>
                    <td>{bid?.user?.fullName}</td>
                    <td>{bid?.user?.phone}</td>
                    <td>{bid?.createdAt}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Container>
      </Main>
    </>
  )
}
