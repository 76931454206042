import { useState } from "react"
import { Button, Form, Card, Col, Modal, Row, Table } from "react-bootstrap"
import ReactSwitch from "react-switch"
import carApi from "../../api/carApi"
import CarDetails from "./CarDetails"
import CarMetrics from "./CarMetrics"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import { useNavigate } from "react-router-dom"
import CarImages from "./CarImages"
import PriorityModal from "./Modal/PriorityModal"
function Car({ onDelete, carDetails, getCars }) {
  const [Details, setDetails] = useState(false)
  const [Metrics, setMetrics] = useState(false)
  const [Images, setImages] = useState(false)

  const [privateCheck, setPrivateCheck] = useState(carDetails.private)
  const [featuredCheck, setFeaturedCheck] = useState(carDetails.featured)
  const [offerCheck, setOfferChecked] = useState(carDetails.hasOffer)
  const [phoneCheck, setPhoneChecked] = useState(carDetails.showPhone)
  const [promotedCheck, setPromotedCheck] = useState(carDetails.promoted)

  const [deleteSentence, setDeleteSentence] = useState("")

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const handleShowDeleteModal = () => setShowDeleteModal(true)

  const [showDiscountModal, setShowDiscountModal] = useState(false)
  const handleCloseDiscountModal = () => setShowDiscountModal(false)
  const handleShowDiscountModal = () => setShowDiscountModal(true)

  const [showPriorityModal, setShowPriorityModal] = useState(false)

  const handleClosePriorityModal = () => {
    getCars()
    setShowPriorityModal(false)
  }
  const handleOpenPriorityModal = () => setShowPriorityModal(true)

  const [loading, setLoading] = useState({
    state: false,
    type: "",
    message: ""
  })
  const navigate = useNavigate()
  const checkPrivate = async () => {
    try {
      setLoading({ state: true, type: "private", message: "Loading..." })
      await carApi.checkPrivate({ id: carDetails._id })
      setPrivateCheck(!privateCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }

  const addNewPrice = async () => {
    try {
      setLoading({ state: true, type: "newPrice", message: "Loading..." })
      await carApi.addDiscount({ carId: carDetails._id, newPrice: newPrice })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
      handleCloseDiscountModal()
    }
  }

  const [discountCheck, setDiscountCheck] = useState(carDetails.discount)
  const [newPrice, setNewPrice] = useState(carDetails?.newPrice)
  const checkPhone = async () => {
    try {
      setLoading({ state: true, type: "phone", message: "Loading..." })
      await carApi.checkPhone({ id: carDetails._id })
      setPhoneChecked(!phoneCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }
  const checkOffer = async () => {
    try {
      setLoading({ state: true, type: "offer", message: "Loading..." })
      await carApi.checkOffer({ id: carDetails._id })
      setOfferChecked(!offerCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }

  const checkFeatured = async () => {
    try {
      setLoading({ state: true, type: "featured", message: "Loading..." })
      await carApi.checkFeatured({ id: carDetails._id })
      setFeaturedCheck(!featuredCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }

  const checkDiscount = async () => {
    try {
      setLoading({ state: true, type: "discount", message: "Loading..." })
      await carApi.checkDiscount({ carId: carDetails._id, newPrice: newPrice })
      setDiscountCheck(!discountCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }

  const checkPromoted = async () => {
    try {
      setLoading({ state: true, type: "promoted", message: "Loading..." })
      await carApi.checkPromoted({ carId: carDetails._id })
      setPromotedCheck(!promotedCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }

  const [rentPromoted, setRentPromoted] = useState(carDetails?.rentPromoted)
  const checkRentPromoted = async () => {
    try {
      setLoading({ state: true, type: "promoted_rent", message: "Loading..." })
      await carApi.checkRentPromoted({ carId: carDetails._id })
      setRentPromoted(!rentPromoted)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "", message: "" })
    }
  }

  const [rentFeatured, setRentFeatured] = useState(carDetails?.rentFeatured)
  const checkRentFeatured = async () => {
    try {
      setLoading({ state: true, type: "featured_rent", message: "Loading..." })
      await carApi.checkRentFeatured({ carId: carDetails._id })
      setRentFeatured(!rentFeatured)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "featured_rent", message: "Loading..." })
    }
  }

  const [verified, setVerified] = useState(carDetails?.verified)
  const checkVerified = async () => {
    try {
      setLoading({ state: true, type: "verified", message: "Loading..." })
      await carApi.checkVerified({ carId: carDetails._id })
      setVerified(!verified)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, type: "verified", message: "Loading..." })
    }
  }

  const [loadingBestOffer, setLoadingBestOffer] = useState(false)
  const [bestOffer, setBestOffer] = useState(carDetails?.bestOffer)
  const checkBestOffer = async (data = {}) => {
    try {
      setLoadingBestOffer(true)
      await carApi.checkBestOffer({ carId: carDetails._id })
      setBestOffer(!bestOffer)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingBestOffer(false)
    }
  }

  const [loadingCarConfirmed, setLoadingCarConfirmed] = useState(false)
  const confirmCar = async (data = {}) => {
    try {
      setLoadingCarConfirmed(true)

      await carApi.confirmCar({ carId: carDetails._id })
      notify.success("Car confirmed successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingCarConfirmed(false)
      getCars()
    }
  }
  return (
    <>
      <Card className='mt-3 w-100'>
        <div className='p-3 '>
          <div className='d-flex justify-content-lg-center align-items-center mb-3 flex-column'>
            <div className='w-100'>
              <div className='d-flex justify-content-between align-items-center gap-2 w-100'>
                <Card.Title>
                  {carDetails.brand + " " + carDetails.model}{" "}
                  <span class='text-muted'>{carDetails.year}</span>
                </Card.Title>
                {!carDetails.confirmed && (
                  <button
                    disabled={loadingCarConfirmed}
                    onClick={() => {
                      confirmCar()
                    }}
                    className='btn btn-success'
                  >
                    Confirm
                  </button>
                )}
              </div>

              <>
                {discountCheck ? (
                  <h5 className='text-center'>
                    <span style={{ textDecoration: "line-through" }}>
                      {carDetails.price + "$"}
                    </span>{" "}
                    <span className='text-danger'>
                      {carDetails.newPrice + "$"}
                    </span>
                  </h5>
                ) : (
                  <h5 className='text-center'>
                    <span>{carDetails.price + "$"}</span>
                  </h5>
                )}
              </>
              <p className='text-secondary'>
                Last edit at {carDetails.updatedAt.split("T")[0]}
              </p>
            </div>
            <div className='row'>
              <div className='col-3 text-center'>
                <h6>Private</h6>
                {loading.state && loading.type === "private" ? (
                  loading.message
                ) : (
                  <ReactSwitch
                    onChange={(e) => {
                      checkPrivate()
                    }}
                    checked={privateCheck}
                  />
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Featured</h6>
                {loading.state && loading.type === "featured" ? (
                  loading.message
                ) : (
                  <ReactSwitch
                    onChange={() => {
                      checkFeatured()
                    }}
                    checked={featuredCheck}
                  />
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Latest </h6>
                {loading.state && loading.type === "offer" ? (
                  loading.message
                ) : (
                  <ReactSwitch
                    onChange={() => {
                      checkOffer()
                    }}
                    checked={offerCheck}
                  />
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Show Phone</h6>
                {loading.state && loading.type === "phone" ? (
                  loading.message
                ) : (
                  <ReactSwitch
                    onChange={() => {
                      checkPhone()
                    }}
                    checked={phoneCheck}
                  />
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Discount</h6>
                {loading.state && loading.type === "discount" ? (
                  loading.message
                ) : (
                  <>
                    <ReactSwitch
                      onChange={() => {
                        checkDiscount()
                      }}
                      checked={discountCheck}
                    />
                  </>
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Promoted</h6>
                {loading.state && loading.type === "promoted" ? (
                  loading.message
                ) : (
                  <>
                    <ReactSwitch
                      onChange={() => {
                        checkPromoted()
                      }}
                      checked={promotedCheck}
                    />
                  </>
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Rent Featured</h6>
                {loading.state && loading.type === "featured_rent" ? (
                  loading.message
                ) : (
                  <>
                    <ReactSwitch
                      onChange={() => {
                        checkRentFeatured()
                      }}
                      checked={rentFeatured}
                    />
                  </>
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Rent Promoted</h6>
                {loading.state && loading.type === "promoted_rent" ? (
                  loading.message
                ) : (
                  <>
                    <ReactSwitch
                      onChange={() => {
                        checkRentPromoted()
                      }}
                      checked={rentPromoted}
                    />
                  </>
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Verified</h6>
                {loading.state && loading.type === "verified" ? (
                  loading.message
                ) : (
                  <>
                    <ReactSwitch
                      onChange={() => {
                        checkVerified()
                      }}
                      checked={verified}
                    />
                  </>
                )}
              </div>
              <div className='col-3 text-center'>
                <h6>Best Offer</h6>
                {loadingBestOffer ? (
                  "Loading..."
                ) : (
                  <ReactSwitch
                    onChange={() => {
                      checkBestOffer()
                    }}
                    checked={bestOffer}
                  />
                )}
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-center align-items-center mb-3 gap-2'>
            {/* <Button variant='success' onClick={handleOpenPriorityModal}>
              Car Rank
            </Button> */}
            <Button variant='success' onClick={handleShowDiscountModal}>
              Discount Price
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault()
                navigate(`/car/edit/${carDetails._id}`)
              }}
            >
              Edit
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault()
                handleShowDeleteModal()
                // onDelete({ id: carDetails._id })
              }}
              variant='danger'
            >
              Delete
            </Button>
          </div>
          <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
            <Button
              variant='dark'
              size='lg'
              className='w-100'
              onClick={() => setDetails(!Details)}
            >
              {Details ? "Hide Details" : "Show Details"}
            </Button>
            {Details && <CarDetails carDetails={carDetails} />}

            <Button
              variant='dark'
              size='lg'
              className='w-100'
              onClick={() => setMetrics(!Metrics)}
            >
              {Metrics ? "Hide Metrics" : "Show Metrics"}
            </Button>
            {Metrics && <CarMetrics />}

            <Button
              variant='dark'
              size='lg'
              className='w-100'
              onClick={() => setImages(!Images)}
            >
              {Images ? "Hide Images" : "Show Images"}
            </Button>

            {Images && <CarImages carId={carDetails._id} />}
          </div>
        </div>
      </Card>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header>
          <Modal.Title>
            <h5> Are you sure you want to delete this car</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>
            Please type the following sentence:{" "}
            <span className='fw-bold'>Delete this car</span>
          </Form.Label>
          <Form.Control
            placeholder='Type the sentence here...'
            value={deleteSentence}
            onChange={(e) => setDeleteSentence(e.target.value)}
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setDeleteSentence("")
              handleCloseDeleteModal()
            }}
          >
            Close
          </Button>
          <Button
            disabled={deleteSentence !== "Delete this car"}
            variant='danger'
            onClick={(e) => {
              e.preventDefault()
              onDelete({ id: carDetails._id })
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDiscountModal} onHide={handleCloseDiscountModal}>
        <Modal.Body>
          <Form.Label>
            {/* Please type the following sentence:{" "} */}
            <span className='fw-bold'>Enter discount price ($): </span>
          </Form.Label>
          <Form.Control
            placeholder='Enter price here...'
            type='number'
            value={newPrice}
            onChange={(e) => setNewPrice(e.target.value)}
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseDiscountModal()
            }}
          >
            Close
          </Button>

          <Button
            // disabled={deleteSentence !== "Delete this car"}
            disabled={loading.state === true && loading.type === "newPrice"}
            variant='danger'
            onClick={(e) => {
              e.preventDefault()
              // onDelete({ id: carDetails._id })
              addNewPrice()
            }}
          >
            {loading.state && loading.type === "newPrice"
              ? "Loading..."
              : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>

      <PriorityModal
        carId={carDetails._id}
        handleClosePriorityModal={handleClosePriorityModal}
        showPriorityModal={showPriorityModal}
      />
    </>
  )
}

export default Car
