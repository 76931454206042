import Choose from "../components/controlStatements/Choose"
import When from "../components/controlStatements/When"
import Otherwise from "../components/controlStatements/Otherwise"

import { useEffect } from "react"
import Car from "../components/Car/Car"
import { useState } from "react"
import carApi from "../api/carApi"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
import SearchBar from "../components/SearchBar/SearchBar"
import axios from "axios"
import { API_URLS } from "../constants/routes"
import { render } from "@testing-library/react"
import searchApi from "../api/searchApi"
import {
  Col,
  Pagination,
  Row,
  Form,
  ListGroup,
  Card,
  Spinner
} from "react-bootstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
function CarList() {
  const [cars, setCars] = useState([])
  const [loading, setLoading] = useState({ state: false, message: "" })
  const [pages, setPages] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [searchText, setSearchText] = useState("")

  const getCars = async () => {
    onSearchSubmit("")
  }

  const deleteCar = async (id) => {
    try {
      setLoading({ state: true, message: "Deleting car..." })
      await carApi.deleteCar(id)
      notify.success("Car deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "" })
      getCars()
    }
  }

  const [type, setType] = useState("")

  const searchPagination = async (term, page = 1) => {
    try {
      setLoading(true)

      const data = await searchApi.getSearchResults({
        search: term,
        page,
        type
      })
      setCars(data.cars)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const onSearchSubmit = async (term) => {
    // console.log("new search submit", term)
    try {
      setLoading({ state: true, message: "Loading cars..." })

      const data = await searchApi.getSearchResults({
        search: term,
        type
      })
      setCars(data.cars)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "" })
    }
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(cars)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setCars(items)

    setLoading({ state: true, message: "Loading cars..." })
    await carApi.updatePriority({ cars: items, type })
    setLoading({ state: false, message: "" })
  }

  useEffect(() => {
    getCars()
  }, [type])

  const renderedCars = cars.map((c) => (
    <Col lg={6} md={12} sm={12}>
      <Car getCars={getCars} key={c._id} onDelete={deleteCar} carDetails={c} />
    </Col>
  ))

  return (
    <>
      <Row>
        <Col xs={6}>
          <SearchBar
            placeholder='Search for cars...'
            onSearchSubmit={(term) => {
              onSearchSubmit(term)
              setSearchText(term)
            }}
          />
        </Col>
        <Col xs={6}>
          <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
            <option value=''>Default</option>
            <option value='featured'>Featured</option>
            <option value='latest'>Latest</option>
            <option value='promoted'>Promoted</option>
            <option value='rentFeatured'>Rent Featured</option>
            <option value='rentPromoted'>Rent Promoted</option>
          </Form.Select>
        </Col>
        <Col xs={12}>
          <Choose>
            <When condition={type === "featured"}>
              <Card>
                <Card.Header>Featured</Card.Header>
                <Choose>
                  <When condition={loading.state}>
                    <div className='text-center p-2'>
                      <Spinner animation='border' />
                    </div>
                  </When>
                  <Otherwise>
                    {/* <ListGroup variant='flush'>
                      {cars.map((c) => {
                        return (
                          <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                            <span>
                              {c.brand + " " + c.model + " " + c.year}
                            </span>{" "}
                            <span className='text-danger'>
                              {c.featuredPriority}
                            </span>
                          </ListGroup.Item>
                        )
                      })}
                    </ListGroup> */}

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='cars'>
                        {(provided) => (
                          <ListGroup
                            variant='flush'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {cars.map((car, index) => {
                              return (
                                <Draggable
                                  key={car._id}
                                  draggableId={car._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListGroup.Item
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className='characters-thumb'>
                                        <span>
                                          {car.brand +
                                            " " +
                                            car.model +
                                            " " +
                                            car.year}
                                        </span>
                                      </div>
                                    </ListGroup.Item>
                                  )}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </ListGroup>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Otherwise>
                </Choose>
              </Card>
            </When>
            <When condition={type === "latest"}>
              <Card>
                <Card.Header>Latest</Card.Header>
                <Choose>
                  <When condition={loading.state}>
                    <div className='text-center p-2'>
                      <Spinner animation='border' />
                    </div>
                  </When>
                  <Otherwise>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='cars'>
                        {(provided) => (
                          <ListGroup
                            variant='flush'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {cars.map((car, index) => {
                              return (
                                <Draggable
                                  key={car._id}
                                  draggableId={car._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListGroup.Item
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className='characters-thumb'>
                                        <span>
                                          {car.brand +
                                            " " +
                                            car.model +
                                            " " +
                                            car.year}
                                        </span>
                                      </div>
                                    </ListGroup.Item>
                                  )}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </ListGroup>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Otherwise>
                </Choose>
              </Card>
            </When>
            <When condition={type === "promoted"}>
              <Card>
                <Card.Header>Promoted</Card.Header>
                <Choose>
                  <When condition={loading.state}>
                    <div className='text-center p-2'>
                      <Spinner animation='border' />
                    </div>
                  </When>
                  <Otherwise>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='cars'>
                        {(provided) => (
                          <ListGroup
                            variant='flush'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {cars.map((car, index) => {
                              return (
                                <Draggable
                                  key={car._id}
                                  draggableId={car._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListGroup.Item
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className='characters-thumb'>
                                        <span>
                                          {car.brand +
                                            " " +
                                            car.model +
                                            " " +
                                            car.year}
                                        </span>
                                      </div>
                                    </ListGroup.Item>
                                  )}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </ListGroup>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Otherwise>
                </Choose>
              </Card>
            </When>
            <When condition={type === "rentPromoted"}>
              <Card>
                <Card.Header>Rent Promoted</Card.Header>
                <Choose>
                  <When condition={loading.state}>
                    <div className='text-center p-2'>
                      <Spinner animation='border' />
                    </div>
                  </When>
                  <Otherwise>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='cars'>
                        {(provided) => (
                          <ListGroup
                            variant='flush'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {cars.map((car, index) => {
                              return (
                                <Draggable
                                  key={car._id}
                                  draggableId={car._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListGroup.Item
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className='characters-thumb'>
                                        <span>
                                          {car.brand +
                                            " " +
                                            car.model +
                                            " " +
                                            car.year}
                                        </span>
                                      </div>
                                    </ListGroup.Item>
                                  )}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </ListGroup>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Otherwise>
                </Choose>
              </Card>
            </When>
            <When condition={type === "rentFeatured"}>
              <Card>
                <Card.Header>Rent Featured</Card.Header>
                <Choose>
                  <When condition={loading.state}>
                    <div className='text-center p-2'>
                      <Spinner animation='border' />
                    </div>
                  </When>
                  <Otherwise>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='cars'>
                        {(provided) => (
                          <ListGroup
                            variant='flush'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {cars.map((car, index) => {
                              return (
                                <Draggable
                                  key={car._id}
                                  draggableId={car._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListGroup.Item
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className='characters-thumb'>
                                        <span>
                                          {car.brand +
                                            " " +
                                            car.model +
                                            " " +
                                            car.year}
                                        </span>
                                      </div>
                                    </ListGroup.Item>
                                  )}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </ListGroup>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Otherwise>
                </Choose>
              </Card>
            </When>
            <Otherwise>
              <></>
            </Otherwise>
          </Choose>
        </Col>
      </Row>

      <Choose>
        <When condition={loading.state}>
          <h2 style={{ textAlign: "center" }}>{loading.message}</h2>
        </When>
        <Otherwise>
          <Choose>
            <When condition={cars.length == 0}>No cars found!</When>
            <Otherwise>
              <Row className='w-100'>{renderedCars}</Row>
              <Pagination className='mt-5'>
                {/* <Pagination.Item>Prev</Pagination.Item> */}
                {[...Array(pages)].map((p, index) => {
                  return (
                    <Pagination.Item
                      onClick={() => searchPagination(searchText, index + 1)}
                      active={currentPage === index + 1}
                    >
                      {index + 1}
                    </Pagination.Item>
                  )
                })}
              </Pagination>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </>
  )
}

export default CarList
