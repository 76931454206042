import { useCallback, useEffect, useState } from "react"
import Quill from "quill"
import "quill/dist/quill.snow.css"
import { useNavigate, useParams } from "react-router-dom"
import ImageResize from "quill-image-resize-module-react"
import Main from "../Main/Main"
import axios from "axios"
import documentApi from "../../api/documentApi"

Quill.register("modules/imageResize", ImageResize)

const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["image", "blockquote", "code-block", "link"],
  ["clean"]
]

export default function TextEditor() {
  const { id: documentId } = useParams()

  const [quill, setQuill] = useState()
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState("")
  const navigate = useNavigate()

  const [loadingSaveDocument, setLoadingSaveDocument] = useState(false)

  async function handleSave() {
    try {
      setLoadingSaveDocument(true)
      await documentApi.saveDocument({
        title,
        data: quill.getContents(),
        documentId
      })
    } catch (error) {
    } finally {
      setLoadingSaveDocument(false)
    }
  }

  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null) return

    wrapper.innerHTML = ""
    const editor = document.createElement("div")
    wrapper.append(editor)
    const q = new Quill(editor, {
      theme: "snow",
      modules: {
        toolbar: TOOLBAR_OPTIONS,
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"]
        }
      }
    })
    q.disable()
    q.setText("Loading...")

    documentApi.getDocument({ documentId }).then((data) => {
      setTitle(data.title)
      q.setContents(data.data)
      setQuill(q)
      q.enable()
    })
  }, [])
  return (
    <Main>
      <div className='container'>
        <div className='d-flex justify-content-start align-items-start gap-2 mb-2'>
          <button
            className=' btn btn-primary'
            onClick={() => navigate("/document")}
          >
            Back
          </button>
          <button
            className='btn btn-success'
            disabled={loadingSaveDocument}
            onClick={() => {
              handleSave()
            }}
          >
            {loadingSaveDocument ? "Loading..." : "Save Document"}
          </button>
        </div>

        <div>
          <h4>Title</h4>
          <div className='row'>
            <div className='col-8 col-sm-6 col-xs-6'>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className='form-control mb-3'
                placeholder='Title'
              />
            </div>
          </div>
        </div>

        <div className=' bg-white' ref={wrapperRef}></div>
      </div>
    </Main>
  )
}
