import axios from "axios"
import { API_URLS } from "../constants/routes"

const searchForms = async (data) => {
  const res = await axios.post(API_URLS.SEARCH_CONTACT_US, data)
  return res.data
}
const checkForm = async (data) => {
  const res = await axios.post(API_URLS.CHECK_CONTACT_US_FORM, data)
  return res.data
}

const contactUsApi = {
  searchForms,
  checkForm
}

export default contactUsApi
