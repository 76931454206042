import { Card } from "react-bootstrap";
import ReactSwitch from "react-switch";
import { errorMessage } from "../../utils/errorMessage";
import * as notify from "../../lib/notify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import partApi from "../../api/part";
import Carousel from "react-multi-carousel";
import Choose from "../controlStatements/Choose";
import When from "../controlStatements/When";
import Otherwise from "../controlStatements/Otherwise";
import useAsync from "../../hooks/useAsync";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function PartCard({ onDelete, part }) {
  const [showDescription, setShowDescription] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [isPrivate, setIsPrivate] = useState(part?.private);
  const [isPromoted, setIsPromoted] = useState(part?.promoted);
  const [hasDiscount, setHasDiscount] = useState(
    part?.price?.showDiscount,
  );
  const navigate = useNavigate();

  const [checkPrivateLoading, setCheckPrivateLoading] = useState(false);
  const checkPrivate = useAsync({
    fn: partApi.checkPrivate,
    onSuccess: () => {
      setIsPrivate(!isPrivate);
    },
    onError: (error) => {
      notify.error(errorMessage(error));
    },
    onLoading: (loading) => {
      setCheckPrivateLoading(loading);
    },
    onFinally:()=>{
      setCheckPrivateLoading(false)
    }
  });

  const [checkPromotedLoading, setCheckPromotedLoading] = useState(false);
  const checkPromoted = useAsync({
    fn: partApi.checkPromoted,
    onSuccess: () => {
      setIsPromoted(!isPromoted);
    },
    onError: (error) => {
      notify.error(errorMessage(error));
    },
    onLoading: (loading) => {
      setCheckPromotedLoading(loading);
    },
    onFinally:()=>{
      setCheckPromotedLoading(false)
    }
  });

  const [checkDiscountLoading, setCheckDiscountLoading] = useState(false);
  const checkDiscount = useAsync({
    fn: partApi.checkDiscount,
    onSuccess: () => {
      setHasDiscount(!hasDiscount);
    },
    onError: (error) => {
      notify.error(errorMessage(error));
    },
    onLoading: (loading) => {
      setCheckDiscountLoading(loading);
    },
    onFinally:()=>{
      setCheckDiscountLoading(false)
    }
  });
  return (
    <Card className="mt-3 w-100">
      <div className="p-3">
        <div className="d-flex justify-content-lg-center align-items-center">
          <div>
            <Card.Title className={"text-center"}>{part?.name}</Card.Title>
            <Choose>
              <When condition={hasDiscount}>
                <Card.Text
                    style={{
                      fontSize:18,
                    }}
                  className={
                    "d-flex justify-content-center align-items-center gap-2"
                  }
                >
                  <div
                    style={{
                      textDecoration: "line-through",
                      fontWeight: "500",
                    }}
                  >
                    {part?.price?.base}$
                  </div>
                  <div
                    className={"text-danger"}
                    style={{
                      fontWeight: "500",
                      fontSize:18,
                    }}
                  >
                    {part?.price?.discount}$
                  </div>
                </Card.Text>
              </When>
              <Otherwise>
                <Card.Text
                     style={{
                       fontWeight:"500",
                       fontSize:18,
                     }}
                    className={
                      "d-flex justify-content-center align-items-center gap-2"
                    }
                >
                  {part?.price?.base}$
                </Card.Text>
              </Otherwise>
            </Choose>

            <div
              className={
                "d-flex justify-content-center align-items-center gap-4 flex-wrap"
              }
            >
              <div className="text-center my-2">
                <h6>Private</h6>
                {checkPrivateLoading ? (
                  "Loading..."
                ) : (
                  <ReactSwitch
                    checked={isPrivate}
                    onChange={(e) => {
                      checkPrivate({partID: part?._id});
                    }}
                  />
                )}
              </div>

              <div className={"text-center my-2"}>
                <h6>Promoted</h6>
                {checkPromotedLoading ? (
                  "Loading..."
                ) : (
                  <ReactSwitch
                    checked={isPromoted}
                    onChange={(e) => {
                      checkPromoted({partID: part?._id});
                    }}
                  />
                )}
              </div>

              <div>
                <h6>Show Discount</h6>
                {checkDiscountLoading ? (
                  "Loading..."
                ) : (
                  <ReactSwitch
                    checked={hasDiscount}
                    onChange={(e) => {
                      checkDiscount({partID: part?._id});
                    }}
                  />
                )}
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center gap-2">
              <button
                className="btn btn-primary"
                onClick={() => navigate(`/part/${part?._id}/edit`)}
              >
                Edit
              </button>
              <button
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete this part ${part?.name}`,
                    )
                  ) {
                    onDelete();
                  }
                }}
                className="btn btn-danger"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            "d-flex justify-content-center align-items-center gap-1 flex-column mt-2"
          }
        >
          <span className="text-muted">Created at {part?.createdAt}</span>
          <span className="text-muted">Edited at {part?.updatedAt}</span>
        </div>
      </div>

      <button
        onClick={() => {
          setShowDescription(!showDescription);
        }}
        className="mb-1 btn btn-outline btn-dark"
      >
        {!showDescription ? "Show Description" : "Hide Description"}
      </button>
      {showDescription && (
        <p dangerouslySetInnerHTML={{ __html: part?.description }}></p>
      )}
      <button
        onClick={() => {
          setShowImages(!showImages);
        }}
        className="mb-1 btn btn-outline btn-dark"
      >
        {!showImages ? "Show Images" : "Hide Images"}
      </button>

      {showImages && (
        <Carousel infinite={true} swipeable={true} responsive={responsive}>
          {part?.images?.map((image, index) => {
            return (
              <img
                key={image?.filename}
                src={image?.url}
                alt={part?.filename}
                style={{ maxHeight: "200px", objectFit: "contain" }}
              />
            );
          })}
        </Carousel>
      )}
    </Card>
  );
}
