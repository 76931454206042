import axios from "axios"
import { API_URLS } from "../constants/routes"

const createRestorer = async (data = {}) => {
  const {
    name,
    scopeOfWork,
    images,
    phone,
    instagram,
    facebook,
    website,
    tiktok,
    googleMaps,
    description,
    coordinates,
    category,
    modern,
    electric,
    location,
    classic,
    partsStore,
    brand,
    vehicleCountryOfOrigin
  } = data
  const bodyFormData = new FormData()
  bodyFormData.append("location", location)
  bodyFormData.append("name", name)
  bodyFormData.append("phone", JSON.stringify(phone))
  bodyFormData.append("description", description)
  bodyFormData.append("coordinates", JSON.stringify(coordinates))
  bodyFormData.append("electric", electric)
  bodyFormData.append("classic", classic)
  bodyFormData.append("modern", modern)
  bodyFormData.append("tiktok", tiktok)
  bodyFormData.append("instagram", instagram)
  bodyFormData.append("facebook", facebook)
  bodyFormData.append("website", website)
  bodyFormData.append("partsStore", partsStore)
  for (let i = 0; i < googleMaps.length; i++) {
    bodyFormData.append("googleMaps", googleMaps[i])
  }
  for (let i = 0; i < scopeOfWork.length; i++) {
    bodyFormData.append("scopeOfWork", scopeOfWork[i])
  }

  for (let i = 0; i < vehicleCountryOfOrigin.length; i++) {
    bodyFormData.append("vehicleCountryOfOrigin", vehicleCountryOfOrigin[i])
  }

  for (let i = 0; i < images.length; i++) {
    bodyFormData.append("image", images[i])
  }

  for (let i = 0; i < brand.length; i++) {
    bodyFormData.append("brand", brand[i].value)
  }

  for (let i = 0; i < category.length; i++) {
    bodyFormData.append("category", category[i])
  }
  const res = await axios.post(API_URLS.CREATE_RESTORER, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
  return res.data
}
const editRestorer = async (data = {}) => {
  const res = await axios.post(API_URLS.EDIT_RESTORER, data)
  return res.data
}

const checkPrivate = async (data = {}) => {
  const res = await axios.post(API_URLS.CHECK_PRIVATE_RESTORER, data)
  return res.data
}
const switchRestorerImages = async (data = {}) => {
  const res = await axios.post(API_URLS.SWITCH_RESTORER_IMAGES, data)
  return res.data
}

const getRestorerId = async (data = {}) => {
  const res = await axios.post(API_URLS.GET_RESTORER_BY_ID, data)
  return res.data
}
const getRestorers = async (data = {}) => {
  const res = await axios.post(API_URLS.GET_RESTORERS, data)
  return res.data
}
const searchRestorers = async (data = {}) => {
  const res = await axios.post(API_URLS.SEARCH_RESTORES, data)
  return res.data
}
const deleteRestorer = async (data = {}) => {
  const res = await axios.post(API_URLS.DELETE_RESTORER, data)
  return res.data
}
const checkPromotedRestorer = async (data = {}) => {
  const res = await axios.post(API_URLS.CHECK_PROMOTED_RESTORER, data)
  return res.data
}
const updateRestorerPriority = async (data = {}) => {
  const res = await axios.post(API_URLS.RESTORERS_UPDATE_PRIORITY, data)
  return res.data
}
const restorerApi = {
  createRestorer,
  getRestorers,
  deleteRestorer,
  getRestorerId,
  editRestorer,
  searchRestorers,
  switchRestorerImages,
  checkPrivate,
  checkPromotedRestorer,
  updateRestorerPriority
}

export default restorerApi
