import { useState } from "react"
import { Button, Card, Col, Container, NavLink, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import sellerApi from "../api/sellerApi"
import Header from "../layouts/Header"
import { errorMessage } from "../utils/errorMessage"
import * as notify from "../lib/notify"
import { useEffect } from "react"
import When from "../components/controlStatements/When"
import Choose from "../components/controlStatements/Choose"
import Otherwise from "../components/controlStatements/Otherwise"
import If from "../components/controlStatements/If"

const SellerMessages = () => {
  const [messages, setMessages] = useState([])
  const { sellerId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const getSellerInfo = async () => {
    try {
      setLoading(true)
      const data = await sellerApi.getSellerById({ sellerId })
      console.log(data)
      setMessages(data.messages)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSellerInfo()
  }, [])
  return (
    <>
      <Header />

      <Container className='mt-3'>
        <div className='d-flex align-items-start w-100'>
          <Button onClick={() => navigate(-1)}>Back</Button>
        </div>
        <h1 className=' text-center text-success'>Messages</h1>
        <Row>
          <Choose>
            <When condition={loading}>
              <h3 className='text-center mt-3'>Loading messages...</h3>
            </When>
            <When condition={messages}>
              {messages &&
                messages.map((m) => {
                  return (
                    <Col xs={12}>
                      <Card className='mt-3 mb-3'>
                        <div className='p-3'>
                          <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className='px-3 pt-3'>
                              <Card.Title>{m.name} (Customer) </Card.Title>
                              <Card.Subtitle className='text-muted mb-3'>
                                Sent At {m.createdAt}
                              </Card.Subtitle>
                            </div>
                            <Card.Body>
                              <p>{m.description}</p>
                            </Card.Body>
                          </div>
                        </div>
                        <Card.Footer>
                          Car: {m?.car?.brand + " " + m?.car?.model}
                        </Card.Footer>
                        <Card.Footer className='d-flex justify-content-start align-items-center'>
                          Phone: {m.phone}
                        </Card.Footer>
                      </Card>
                    </Col>
                  )
                })}
            </When>
            <Otherwise>
              <span className='text-center'>No Messages Found</span>
            </Otherwise>
          </Choose>
        </Row>
      </Container>
    </>
  )
}

export default SellerMessages
