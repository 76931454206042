import { useEffect } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import employeeApi from "../api/employeeApi"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
// import "bootstrap/dist/css/bootstrap.min.css"
function VerifyUser() {
  const { id, verificationId } = useParams()

  const navigate = useNavigate()
  const verify = async () => {
    try {
      const employee = await employeeApi.verifyEmployee({ id, verificationId })
      if (employee.verified) {
        navigate(`/users/${id}/set-info`)
        notify.success("Email Verified")
      } else {
        navigate("/login")
        notify.error("Email Not Verified")
      }
    } catch (error) {
      notify.error(errorMessage(error))
      navigate("/login")
    } finally {
    }
  }

  useEffect(() => {
    verify()
  }, [])

  return <>Verifying email...</>
}

export default VerifyUser
