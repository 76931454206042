import axios from "axios"
import _ from "lodash"
import { API_URLS } from "../constants/routes"

const getSearchResults = async (data) => {
  const res = await axios.post(API_URLS.SEARCH_CAR, data)
  return res.data
}

const searchApi = {
  getSearchResults
}

export default searchApi
