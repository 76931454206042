import React, { useState, useRef, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import reject from "lodash/reject";
import { FaTrash, FaImage } from "react-icons/fa";
import styles from "./style.module.css";
import isEqual from "lodash/isEqual";
import filter from "lodash/filter";

const PhotoUploaderComponent = ({
  Identifier = null,
  AdditionalIdentifierValue = null,
  header = "Photo Gallery",
  title = "Upload at least 1 photo",
  subTitle = "You`ll also be able to upload more after registration",
  theme = "blue",
  files = [],
  setFiles = () => {},
}) => {
  const [sortCounter, setSortCounter] = useState(0);
  const fileInput = useRef(null);

  const handleImageChange = (e) => {
    e.preventDefault();

    const files = Array.from(e.target.files); // Convert FileList to Array
    const newFiles = files.map((file) => {
      const reader = new FileReader();
      const fakeId = "FAKE-" + Math.floor(100000 + Math.random() * 900000);

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve({ file, url: reader.result, status: 1, id: fakeId });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(newFiles).then((filesWithUrls) => {
      setFiles((prevFiles) => [...prevFiles, ...filesWithUrls]);
    });

    fileInput.current.value = "";
  };

  const selectImage = () => {
    const elementName = AdditionalIdentifierValue
      ? `selectImage-${AdditionalIdentifierValue}`
      : `selectImage-${Identifier}`;
    document.getElementById(elementName).click();
  };

  const handleRemove = (id) => {
    const updatedFiles = reject(files, (o) => o.id === id);
    setFiles(updatedFiles);
  };

  const changeOrder = (newState) => {
    if (!isEqual(files, newState)) {
      if (sortCounter) {
        const results = filter(newState, (obj) => !obj.id.startsWith("FAKE"));
        setFiles(newState);
      } else {
        setSortCounter(sortCounter + 1);
      }
    }
  };

  const renderThemeButton = (theme, type) => {
    switch (theme) {
      case "blue":
        return type ? styles.uploaderBtnPrimaryBig : styles.uploaderBtnLink;
      case "red":
        return type
          ? styles.uploaderBtnPrimaryBigRED
          : styles.uploaderBtnLinkRED;
      default:
        return type ? styles.uploaderBtnPrimaryBig : styles.uploaderBtnLink;
    }
  };

  return (
    <div>
      <div>
        <input
          className="fileInput"
          ref={fileInput}
          style={{ display: "none" }}
          id={`${AdditionalIdentifierValue ? `selectImage-${AdditionalIdentifierValue}` : `selectImage-${Identifier}`}`}
          type="file"
          onChange={handleImageChange}
          multiple={true}
        />

        <div className={styles.displayInlineFlex}>
          <h5>{header}</h5>&nbsp;&nbsp;&nbsp;
        </div>

        <div
          style={{
            backgroundColor: "white",
          }}
        >
          {files.length ? (
            <div
              className={`${styles.smallPhotoUploaderBox} ${styles.marginBottom20}`}
            >
              <button
                onClick={selectImage}
                type="button"
                className={`${styles.btn} ${renderThemeButton(theme, 1)}`}
              >
                <FaImage className="icon-class" />
                &nbsp; Add Photos
              </button>
            </div>
          ) : (
            <div
              className={`${styles.photoUploaderBox} ${styles.marginBottom20}`}
            >
              <div>
                <h4 style={{ marginTop: 80 }}>{title}</h4>
                <h6>{subTitle}</h6>
                <button
                  onClick={selectImage}
                  type="button"
                  className={`${styles.btn} ${renderThemeButton(theme, 1)} ${styles.marginTop20}`}
                >
                  <FaImage className={styles.iconClass} />
                  &nbsp; Add Photos
                </button>
              </div>
            </div>
          )}

          <div className={styles.imageUploaderPhotoList}>
            {files.length ? (
              <ReactSortable
                swapThreshold={1}
                filter=".filtered"
                animation={150}
                list={files}
                delayOnTouchStart={true}
                delay={50}
                setList={changeOrder}
              >
                {files.map((file, index) => (
                  <div
                    key={index}
                    className={`${styles.imageUploaderImagePreview}`}
                  >
                    <div className={styles.centerizedBox}></div>
                    <img alt="pic" src={file.url} />
                    <div className={styles.imageButtonActionsPlace}>
                      <button
                        className={renderThemeButton(theme, 0)}
                        onClick={() => handleRemove(file.id)}
                      >
                        <FaTrash />
                        &nbsp;Delete
                      </button>
                    </div>
                    {index + 1 === 1 && (
                      <div className={styles.mainPhotoLabel}> Main Photo</div>
                    )}
                  </div>
                ))}
              </ReactSortable>
            ) : (
              <span />
            )}
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoUploaderComponent;
