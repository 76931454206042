import { useEffect } from "react"
import { useState } from "react"
import { Button, Container, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import restorerApi from "../api/restorerApi"
import scopeOfWorkApi from "../api/scopeOfWorkApi"
import Main from "../components/Main/Main"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
import Select from "react-select"
import * as brands_data from "../constants/brands"
import geocodeApi from "../api/geocode"

// import Map from "../components/Map/Create"

export default function Restorer() {
  const navigate = useNavigate()

  const [phone, setPhone] = useState([])
  const [phoneInput, setPhoneInput] = useState("")
  const [countryCodeInput, setCountryCodeInput] = useState("")
  const [brandInput, setBrandInput] = useState("")
  const [brands, setBrands] = useState([])
  const [googleMaps, setGoogleMaps] = useState([])
  const [googleMapsInput, setGoogleMapsInput] = useState()
  const [coordinates, setCoordinates] = useState([])
  const [lngInput, setLngInput] = useState("")
  const [latInput, setLatInput] = useState("")
  const [name, setName] = useState("")
  const [vehicleCountryOfOrigin, setVehicleCountryOfOrigin] = useState([])
  const [vehicleCountryOfOriginInput, setVehicleCountryOfOriginInput] =
    useState("")
  const [scopeOfWork, setScopeOfWork] = useState([])
  const [displayedScopeOfWork, setDisplayedScopeOfWork] = useState([])

  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [tiktok, setTiktok] = useState("")
  const [instagram, setInstagram] = useState("")
  const [facebook, setFacebook] = useState("")
  const [website, setWebsite] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState([])
  const [categoryInput, setCategoryInput] = useState("")
  const [scopeOfWorks, setScopeOfWorks] = useState([])
  const [electric, setElectric] = useState(false)
  const [modern, setModern] = useState(false)
  const [classic, setClassic] = useState(false)
  const [partsStore, setPartsStore] = useState(false)

  const getScopeOfWorks = async () => {
    try {
      const data = await scopeOfWorkApi.getScopeOfWorks()
      setScopeOfWorks(data)
    } catch (error) {
      notify.error(errorMessage(error))
    }
  }
  const [scopeOfWorkName, setScopeOfWorkName] = useState("")
  const [scopeOfWorkSubName, setScopeOfWorkSubName] = useState("")
  const [showCreateScopeOfWork, setShowCreateScopeOfWork] = useState(false)

  const createScopeOfWork = async () => {
    try {
      await scopeOfWorkApi.createScopeOfWork({
        name: scopeOfWorkName,
        subName: scopeOfWorkSubName
      })
      notify.success("Scope Of Work Created Successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setScopeOfWorkName("")
      setScopeOfWorkSubName("")
      getScopeOfWorks()
    }
  }

  const transformToOptions = (data) => {
    let temp = []
    for (let d of data) {
      temp.push({ value: d, label: d })
    }
    return temp
  }
  const createRestorer = async (data = {}) => {
    try {
      setLoading(true)
      await restorerApi.createRestorer({
        name,
        scopeOfWork,
        images,
        phone,
        instagram,
        facebook,
        website,
        tiktok,
        googleMaps,
        location,
        description,
        coordinates,
        category,
        modern,
        electric,
        classic,
        partsStore,
        vehicleCountryOfOrigin,
        brand: brands
      })
      notify.success("Restorer Shop Created Succesfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const [showDeleteScopeOfWork, setShowDeleteScopeOfWork] = useState(false)
  const [scopeOfWorkId, setScopeOfWorkId] = useState("")

  const deleteScopeOfWork = async () => {
    try {
      await scopeOfWorkApi.deleteScopeOfWork({ scopeOfWorkId })
      notify.success("Scope Of Work Deleted Successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setScopeOfWorkId("")
      getScopeOfWorks()
    }
  }
  const [location, setLocation] = useState("")
  useEffect(() => {
    getScopeOfWorks()
  }, [])

  const [loadingRev, setLoadingRev] = useState(false)
  const [locationDesription, setLocationDescription] = useState("")
  const [revLng, setRevLng] = useState("")
  const [revLat, setRevLat] = useState("")
  const reverseGeocode = async () => {
    try {
      setLoadingRev(true)
      const res = await geocodeApi.reverseGeocode({ lng: revLng, lat: revLat })
      setLocation(res[0].city)
      setLocationDescription(JSON.stringify(res))
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setRevLat("")
      setRevLng("")
      setLoadingRev(false)
    }
  }

  return (
    <Main>
      <Container>
        <button
          className='btn btn-primary'
          onClick={() => navigate("/restorer")}
        >
          Back
        </button>

        <div className='w-100  p-3'>
          <Form.Group className='mt-3'>
            <Form.Label className='fw-bold'>Name</Form.Label>
            <Form.Control
              placeholder='Restorer Shop Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <div className='row mt-5'>
            <div className='col-4'>
              <Form.Group>
                <Form.Label className='fw-bold'>Brands </Form.Label>
                <div className='row'>
                  <div className='col-10'>
                    <Select
                      isClearable={true}
                      value={brandInput}
                      onChange={(e) => setBrandInput(e)}
                      options={transformToOptions(brands_data)}
                    />
                  </div>
                  <div className='col-2'>
                    <button
                      className='btn btn-sm btn-success w-100 text-center'
                      onClick={() => {
                        if (brandInput)
                          setBrands((brands) => [...brands, brandInput])
                        setBrandInput(null)
                      }}
                    >
                      <i className='fa-solid fa-plus'></i>
                    </button>
                  </div>
                  <div className='col-12'>
                    <ul>
                      {brands.map((b) => {
                        return (
                          <li className='mt-1'>
                            {b.value}{" "}
                            <button
                              onClick={(e) => {
                                let temp = brands.filter((br) => br !== b)
                                setBrands(temp)
                              }}
                              className='btn btn-danger btn-sm'
                            >
                              <i className='fa-solid fa-trash' />
                            </button>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className='col-4'>
              <Form.Group>
                <Form.Label className='fw-bold'>Category</Form.Label>
                <div className='row'>
                  <div className='col-10'>
                    <Form.Select
                      value={categoryInput}
                      onChange={(e) => setCategoryInput(e.target.value)}
                    >
                      <option value={""}>Category</option>
                      {["Bikes", "Cars", "SUVs", "Heavy Trucks"].map((c) => {
                        return <option value={c}>{c}</option>
                      })}
                    </Form.Select>
                  </div>
                  <div className='col-2'>
                    <Button
                      className='btn btn-success btn-sm w-100'
                      onClick={(e) => {
                        setCategory((category) => [...category, categoryInput])
                        setCategoryInput("")
                      }}
                    >
                      <i className='fa-solid fa-plus'></i>
                    </Button>
                  </div>
                  <div className='col-12'>
                    <ul className='d-flex justify-content-center flex-column align-items-start gap-2 mt-3'>
                      {category.map((c) => {
                        return (
                          <li className='mt-1'>
                            {c}{" "}
                            <button
                              onClick={() => {
                                let temp = category.filter(
                                  (category) => category !== c
                                )
                                setCategory(temp)
                              }}
                              className='btn btn-danger btn-sm'
                            >
                              {" "}
                              <i className='fa-solid fa-trash' />
                            </button>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className='col-4'>
              <Form.Group>
                <Form.Label className='fw-bold'>
                  Vehicle Country Of Origin
                </Form.Label>
                <div className='row'>
                  <div className='col-10'>
                    <Form.Select
                      value={vehicleCountryOfOriginInput}
                      onChange={(e) =>
                        setVehicleCountryOfOriginInput(e.target.value)
                      }
                    >
                      <option value=''>Vehicle country of origin </option>
                      <option value='All'>All</option>
                      <option value='United States'>United States</option>
                      <option value='United Kingdom'>United Kingdom</option>
                      <option value='South Korea'>South Korea</option>
                      <option vlaue='Russia'>Russia</option>
                      <option value='Czech Republic'>Czech Republic</option>
                      <option value='China'>China</option>
                      <option value='French'>French</option>
                      <option value='Germany'>Germany</option>
                      <option value='Italic'>Italic</option>
                      <option value='Japan'>Japan</option>
                      <option value='Sweden'>Sweden</option>
                    </Form.Select>
                  </div>
                  <div className='col-2'>
                    <Button
                      className='btn btn-success btn-sm w-100'
                      onClick={(e) => {
                        if (vehicleCountryOfOriginInput)
                          setVehicleCountryOfOrigin(
                            (vehicleCountryOfOrigin) => [
                              ...vehicleCountryOfOrigin,
                              vehicleCountryOfOriginInput
                            ]
                          )
                        setVehicleCountryOfOriginInput("")
                      }}
                    >
                      <i className='fa-solid fa-plus'></i>
                    </Button>
                  </div>
                  <div className='col-12'>
                    {vehicleCountryOfOrigin.map((v) => {
                      return (
                        <li className='mt-1'>
                          {v}{" "}
                          <button
                            onClick={() => {
                              let temp = vehicleCountryOfOrigin.filter(
                                (el) => el !== v
                              )
                              setVehicleCountryOfOrigin(temp)
                            }}
                            className='btn btn-danger btn-sm'
                          >
                            {" "}
                            <i className=' fa-solid fa-trash' />
                          </button>
                        </li>
                      )
                    })}
                  </div>
                </div>
              </Form.Group>
            </div>

            <div className='col-12'>
              <Form.Group className='mt-3'>
                <Form.Label className='fw-bold'>Phone</Form.Label>
                <div className='row'>
                  <div className='col-2'>
                    <Form.Select
                      value={countryCodeInput}
                      onChange={(e) => setCountryCodeInput(e.target.value)}
                    >
                      <option value=''>Country Code</option>
                      <option value='93'>Afghanistan +93</option>
                      <option value='358'>Aland Islands +358</option>
                      <option value='355'>Albania +355</option>
                      <option value='213'>Algeria +213</option>
                      <option value='1684'>American Samoa +1684</option>
                      <option value='376'>Andorra +376</option>
                      <option value='244'>Angola +244</option>
                      <option value='1264'>Anguilla +1264</option>
                      <option value='672'>Antarctica +672</option>
                      <option value='1268'>Antigua and Barbuda +1268</option>
                      <option value='54'>Argentina +54</option>
                      <option value='374'>Armenia +374</option>
                      <option value='297'>Aruba +297</option>
                      <option value='61'>Australia +61</option>
                      <option value='43'>Austria +43</option>
                      <option value='994'>Azerbaijan +994</option>
                      <option value='1242'>Bahamas +1242</option>
                      <option value='973'>Bahrain +973</option>
                      <option value='880'>Bangladesh +880</option>
                      <option value='1246'>Barbados +1246</option>
                      <option value='375'>Belarus +375</option>
                      <option value='32'>Belgium +32</option>
                      <option value='501'>Belize +501</option>
                      <option value='229'>Benin +229</option>
                      <option value='1441'>Bermuda +1441</option>
                      <option value='975'>Bhutan +975</option>
                      <option value='591'>Bolivia +591</option>
                      <option value='599'>
                        Bonaire, Sint Eustatius and Saba +599
                      </option>
                      <option value='387'>Bosnia and Herzegovina +387</option>
                      <option value='267'>Botswana +267</option>
                      <option value='55'>Bouvet Island +55</option>
                      <option value='55'>Brazil +55</option>
                      <option value='246'>
                        British Indian Ocean Territory +246
                      </option>
                      <option value='673'>Brunei Darussalam +673</option>
                      <option value='359'>Bulgaria +359</option>
                      <option value='226'>Burkina Faso +226</option>
                      <option value='257'>Burundi +257</option>
                      <option value='855'>Cambodia +855</option>
                      <option value='237'>Cameroon +237</option>
                      <option value='1'>Canada +1</option>
                      <option value='238'>Cape Verde +238</option>
                      <option value='1345'>Cayman Islands +1345</option>
                      <option value='236'>Central African Republic +236</option>
                      <option value='235'>Chad +235</option>
                      <option value='56'>Chile +56</option>
                      <option value='86'>China +86</option>
                      <option value='61'>Christmas Island +61</option>
                      <option value='672'>Cocos (Keeling) Islands +672</option>
                      <option value='57'>Colombia +57</option>
                      <option value='269'>Comoros +269</option>
                      <option value='242'>Congo +242</option>
                      <option value='242'>
                        Congo, Democratic Republic of the Congo +242
                      </option>
                      <option value='682'>Cook Islands +682</option>
                      <option value='506'>Costa Rica +506</option>
                      <option value='225'>Cote D'Ivoire +225</option>
                      <option value='385'>Croatia +385</option>
                      <option value='53'>Cuba +53</option>
                      <option value='599'>Curacao +599</option>
                      <option value='357'>Cyprus +357</option>
                      <option value='420'>Czech Republic +420</option>
                      <option value='45'>Denmark +45</option>
                      <option value='253'>Djibouti +253</option>
                      <option value='1767'>Dominica +1767</option>
                      <option value='1809'>Dominican Republic +1809</option>
                      <option value='593'>Ecuador +593</option>
                      <option value='20'>Egypt +20</option>
                      <option value='503'>El Salvador +503</option>
                      <option value='240'>Equatorial Guinea +240</option>
                      <option value='291'>Eritrea +291</option>
                      <option value='372'>Estonia +372</option>
                      <option value='251'>Ethiopia +251</option>
                      <option value='500'>
                        Falkland Islands (Malvinas) +500
                      </option>
                      <option value='298'>Faroe Islands +298</option>
                      <option value='679'>Fiji +679</option>
                      <option value='358'>Finland +358</option>
                      <option value='33'>France +33</option>
                      <option value='594'>French Guiana +594</option>
                      <option value='689'>French Polynesia +689</option>
                      <option value='262'>
                        French Southern Territories +262
                      </option>
                      <option value='241'>Gabon +241</option>
                      <option value='220'>Gambia +220</option>
                      <option value='995'>Georgia +995</option>
                      <option value='49'>Germany +49</option>
                      <option value='233'>Ghana +233</option>
                      <option value='350'>Gibraltar +350</option>
                      <option value='30'>Greece +30</option>
                      <option value='299'>Greenland +299</option>
                      <option value='1473'>Grenada +1473</option>
                      <option value='590'>Guadeloupe +590</option>
                      <option value='1671'>Guam +1671</option>
                      <option value='502'>Guatemala +502</option>
                      <option value='44'>Guernsey +44</option>
                      <option value='224'>Guinea +224</option>
                      <option value='245'>Guinea-Bissau +245</option>
                      <option value='592'>Guyana +592</option>
                      <option value='509'>Haiti +509</option>
                      <option value='0'>
                        Heard Island and Mcdonald Islands +0
                      </option>
                      <option value='39'>
                        Holy See (Vatican City State) +39
                      </option>
                      <option value='504'>Honduras +504</option>
                      <option value='852'>Hong Kong +852</option>
                      <option value='36'>Hungary +36</option>
                      <option value='354'>Iceland +354</option>
                      <option value='91'>India +91</option>
                      <option value='62'>Indonesia +62</option>
                      <option value='98'>Iran, Islamic Republic of +98</option>
                      <option value='964'>Iraq +964</option>
                      <option value='353'>Ireland +353</option>
                      <option value='44'>Isle of Man +44</option>
                      <option value='972'>Israel +972</option>
                      <option value='39'>Italy +39</option>
                      <option value='1876'>Jamaica +1876</option>
                      <option value='81'>Japan +81</option>
                      <option value='44'>Jersey +44</option>
                      <option value='962'>Jordan +962</option>
                      <option value='7'>Kazakhstan +7</option>
                      <option value='254'>Kenya +254</option>
                      <option value='686'>Kiribati +686</option>
                      <option value='850'>
                        Korea, Democratic People's Republic of +850
                      </option>
                      <option value='82'>Korea, Republic of +82</option>
                      <option value='381'>Kosovo +381</option>
                      <option value='965'>Kuwait +965</option>
                      <option value='996'>Kyrgyzstan +996</option>
                      <option value='856'>
                        Lao People's Democratic Republic +856
                      </option>
                      <option value='371'>Latvia +371</option>
                      <option value='961'>Lebanon +961</option>
                      <option value='266'>Lesotho +266</option>
                      <option value='231'>Liberia +231</option>
                      <option value='218'>Libyan Arab Jamahiriya +218</option>
                      <option value='423'>Liechtenstein +423</option>
                      <option value='370'>Lithuania +370</option>
                      <option value='352'>Luxembourg +352</option>
                      <option value='853'>Macao +853</option>
                      <option value='389'>
                        Macedonia, the Former Yugoslav Republic of +389
                      </option>
                      <option value='261'>Madagascar +261</option>
                      <option value='265'>Malawi +265</option>
                      <option value='60'>Malaysia +60</option>
                      <option value='960'>Maldives +960</option>
                      <option value='223'>Mali +223</option>
                      <option value='356'>Malta +356</option>
                      <option value='692'>Marshall Islands +692</option>
                      <option value='596'>Martinique +596</option>
                      <option value='222'>Mauritania +222</option>
                      <option value='230'>Mauritius +230</option>
                      <option value='262'>Mayotte +262</option>
                      <option value='52'>Mexico +52</option>
                      <option value='691'>
                        Micronesia, Federated States of +691
                      </option>
                      <option value='373'>Moldova, Republic of +373</option>
                      <option value='377'>Monaco +377</option>
                      <option value='976'>Mongolia +976</option>
                      <option value='382'>Montenegro +382</option>
                      <option value='1664'>Montserrat +1664</option>
                      <option value='212'>Morocco +212</option>
                      <option value='258'>Mozambique +258</option>
                      <option value='95'>Myanmar +95</option>
                      <option value='264'>Namibia +264</option>
                      <option value='674'>Nauru +674</option>
                      <option value='977'>Nepal +977</option>
                      <option value='31'>Netherlands +31</option>
                      <option value='599'>Netherlands Antilles +599</option>
                      <option value='687'>New Caledonia +687</option>
                      <option value='64'>New Zealand +64</option>
                      <option value='505'>Nicaragua +505</option>
                      <option value='227'>Niger +227</option>
                      <option value='234'>Nigeria +234</option>
                      <option value='683'>Niue +683</option>
                      <option value='672'>Norfolk Island +672</option>
                      <option value='1670'>
                        Northern Mariana Islands +1670
                      </option>
                      <option value='47'>Norway +47</option>
                      <option value='968'>Oman +968</option>
                      <option value='92'>Pakistan +92</option>
                      <option value='680'>Palau +680</option>
                      <option value='970'>
                        Palestinian Territory, Occupied +970
                      </option>
                      <option value='507'>Panama +507</option>
                      <option value='675'>Papua New Guinea +675</option>
                      <option value='595'>Paraguay +595</option>
                      <option value='51'>Peru +51</option>
                      <option value='63'>Philippines +63</option>
                      <option value='64'>Pitcairn +64</option>
                      <option value='48'>Poland +48</option>
                      <option value='351'>Portugal +351</option>
                      <option value='1787'>Puerto Rico +1787</option>
                      <option value='974'>Qatar +974</option>
                      <option value='262'>Reunion +262</option>
                      <option value='40'>Romania +40</option>
                      <option value='70'>Russian Federation +70</option>
                      <option value='250'>Rwanda +250</option>
                      <option value='590'>Saint Barthelemy +590</option>
                      <option value='290'>Saint Helena +290</option>
                      <option value='1869'>Saint Kitts and Nevis +1869</option>
                      <option value='1758'>Saint Lucia +1758</option>
                      <option value='590'>Saint Martin +590</option>
                      <option value='508'>
                        Saint Pierre and Miquelon +508
                      </option>
                      <option value='1784'>
                        Saint Vincent and the Grenadines +1784
                      </option>
                      <option value='684'>Samoa +684</option>
                      <option value='378'>San Marino +378</option>
                      <option value='239'>Sao Tome and Principe +239</option>
                      <option value='966'>Saudi Arabia +966</option>
                      <option value='221'>Senegal +221</option>
                      <option value='381'>Serbia +381</option>
                      <option value='381'>Serbia and Montenegro +381</option>
                      <option value='248'>Seychelles +248</option>
                      <option value='232'>Sierra Leone +232</option>
                      <option value='65'>Singapore +65</option>
                      <option value='1'>Sint Maarten +1</option>
                      <option value='421'>Slovakia +421</option>
                      <option value='386'>Slovenia +386</option>
                      <option value='677'>Solomon Islands +677</option>
                      <option value='252'>Somalia +252</option>
                      <option value='27'>South Africa +27</option>
                      <option value='500'>
                        South Georgia and the South Sandwich Islands +500
                      </option>
                      <option value='211'>South Sudan +211</option>
                      <option value='34'>Spain +34</option>
                      <option value='94'>Sri Lanka +94</option>
                      <option value='249'>Sudan +249</option>
                      <option value='597'>Suriname +597</option>
                      <option value='47'>Svalbard and Jan Mayen +47</option>
                      <option value='268'>Swaziland +268</option>
                      <option value='46'>Sweden +46</option>
                      <option value='41'>Switzerland +41</option>
                      <option value='963'>Syrian Arab Republic +963</option>
                      <option value='886'>
                        Taiwan, Province of China +886
                      </option>
                      <option value='992'>Tajikistan +992</option>
                      <option value='255'>
                        Tanzania, United Republic of +255
                      </option>
                      <option value='66'>Thailand +66</option>
                      <option value='670'>Timor-Leste +670</option>
                      <option value='228'>Togo +228</option>
                      <option value='690'>Tokelau +690</option>
                      <option value='676'>Tonga +676</option>
                      <option value='1868'>Trinidad and Tobago +1868</option>
                      <option value='216'>Tunisia +216</option>
                      <option value='90'>Turkey +90</option>
                      <option value='7370'>Turkmenistan +7370</option>
                      <option value='1649'>
                        Turks and Caicos Islands +1649
                      </option>
                      <option value='688'>Tuvalu +688</option>
                      <option value='256'>Uganda +256</option>
                      <option value='380'>Ukraine +380</option>
                      <option value='971'>United Arab Emirates +971</option>
                      <option value='44'>United Kingdom +44</option>
                      <option value='1'>United States +1</option>
                      <option value='1'>
                        United States Minor Outlying Islands +1
                      </option>
                      <option value='598'>Uruguay +598</option>
                      <option value='998'>Uzbekistan +998</option>
                      <option value='678'>Vanuatu +678</option>
                      <option value='58'>Venezuela +58</option>
                      <option value='84'>Viet Nam +84</option>
                      <option value='1284'>
                        Virgin Islands, British +1284
                      </option>
                      <option value='1340'>Virgin Islands, U.s. +1340</option>
                      <option value='681'>Wallis and Futuna +681</option>
                      <option value='212'>Western Sahara +212</option>
                      <option value='967'>Yemen +967</option>
                      <option value='260'>Zambia +260</option>
                      <option value='263'>Zimbabwe +263</option>
                    </Form.Select>
                  </div>
                  <div className='col-8'>
                    <Form.Control
                      placeholder='Phone '
                      value={phoneInput}
                      onChange={(e) => setPhoneInput(e.target.value)}
                    />
                  </div>
                  <div className='col-2'>
                    <button
                      className='btn btn-success w-100'
                      onClick={() => {
                        setPhone((phone) => [
                          ...phone,
                          {
                            phoneNumber: phoneInput,
                            countryCode: countryCodeInput
                          }
                        ])
                        setPhoneInput("")
                        setCountryCodeInput("")
                      }}
                    >
                      Add
                    </button>
                  </div>
                  <div className='p-3'>
                    <ul>
                      {phone.map((p) => {
                        return (
                          <li className='mt-1'>
                            {p.countryCode + " " + p.phoneNumber}{" "}
                            <button
                              className='btn btn-danger btn-sm'
                              onClick={() => {
                                let arr = phone
                                arr = arr.filter(
                                  (ph) => ph.phoneNumber !== p.phoneNumber
                                )

                                setPhone(arr)
                              }}
                            >
                              <i className='fa-solid fa-trash'></i>
                            </button>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <Form.Group>
                <Form.Label className='fw-bold'>Facebook Url</Form.Label>
                <Form.Control
                  placeholder='Facebook'
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className='col-3'>
              <Form.Group>
                <Form.Label className='fw-bold'>Instagram Url</Form.Label>
                <Form.Control
                  placeholder='Instagram'
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className='col-3'>
              <Form.Group>
                <Form.Label className='fw-bold'>Website Url</Form.Label>
                <Form.Control
                  placeholder='Website'
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className='col-3'>
              <Form.Group>
                <Form.Label className='fw-bold'>Tiktok Url</Form.Label>
                <Form.Control
                  placeholder='Website'
                  value={tiktok}
                  onChange={(e) => setTiktok(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Form.Group className='mt-3'>
                <div className='row'>
                  <div className='col-10'>
                    <Form.Control
                      placeholder='Google Maps'
                      value={googleMapsInput}
                      onChange={(e) => setGoogleMapsInput(e.target.value)}
                    ></Form.Control>
                  </div>
                  <div className='col-2'>
                    <button
                      className='btn btn-success w-100'
                      onClick={(e) => {
                        setGoogleMapsInput("")
                        setGoogleMaps((googleMaps) => [
                          ...googleMaps,
                          googleMapsInput
                        ])
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <ul className='p-3'>
                  {googleMaps.map((map) => {
                    return (
                      <li>
                        {map}{" "}
                        <button
                          onClick={() => {
                            let arr = googleMaps
                            arr = arr.filter((m) => m !== map)
                            setGoogleMaps(arr)
                          }}
                          className='btn btn-danger btn-sm'
                        >
                          <i className='fa-solid fa-trash'></i>
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </Form.Group>
            </div>
            <div className='col-6'>
              <Form.Group className='mt-3'>
                <div className='row'>
                  <div className='col-5'>
                    <Form.Control
                      value={lngInput}
                      placeholder='Longitude'
                      onChange={(e) => setLngInput(e.target.value)}
                    ></Form.Control>
                  </div>
                  <div className='col-5'>
                    <Form.Control
                      placeholder='Latitude'
                      value={latInput}
                      onChange={(e) => setLatInput(e.target.value)}
                    ></Form.Control>
                  </div>
                  <div className='col-2'>
                    <button
                      onClick={() => {
                        setCoordinates((coordinates) => [
                          ...coordinates,
                          { lng: lngInput, lat: latInput }
                        ])
                        setLngInput("")
                        setLatInput("")
                      }}
                      className='w-100 btn btn-success'
                    >
                      Add
                    </button>
                  </div>
                </div>
                <ul>
                  {coordinates.map((coordinate) => {
                    return (
                      <li>
                        {coordinate.lng + " " + coordinate.lat}{" "}
                        <button
                          className='btn btn-danger'
                          onClick={() => {
                            setCoordinates((coordinates) => {
                              return coordinates.filter((c) => {
                                return (
                                  c.lng !== coordinate.lng &&
                                  c.lat !== coordinate.lat
                                )
                              })
                            })
                          }}
                        >
                          <i className='fa fa-trash' />
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </Form.Group>
            </div>
            <div className='col-12'>
              <h5>Reverse Geocode</h5>
              <div className='row'>
                {/* <div className='col-12'>
              <input placeholder='Longitude'></input>
              <input placeholder='Latitude'></input>
            </div> */}
                <div className='col-4'>
                  <Form.Control
                    value={revLng}
                    onChange={(e) => setRevLng(e.target.value)}
                    placeholder='Longitude'
                  ></Form.Control>
                </div>
                <div className='col-4'>
                  <Form.Control
                    value={revLat}
                    onChange={(e) => setRevLat(e.target.value)}
                    placeholder='Latitude'
                  ></Form.Control>
                </div>
                <div className='col-4'>
                  <Button
                    variant='success'
                    onClick={reverseGeocode}
                    disabled={loadingRev}
                  >
                    {loadingRev ? "Loading... " : "Submit"}
                  </Button>
                </div>
                <div className='col-12'>
                  <Form.Control
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder='Location'
                  />
                </div>
                <div className='col-12'>{locationDesription}</div>
              </div>
            </div>
          </div>

          <div className='shadow p-3 mt-3'>
            <Form.Group className='mt-3'>
              <Form.Label className='fw-bold mb-0'>Scope Of Work</Form.Label>
              <Form.Select
                onChange={(e) => {
                  let scope = JSON.parse(e.target.value)
                  if (!scopeOfWork.includes(scope._id)) {
                    setScopeOfWork((scopeOfWork) => [...scopeOfWork, scope._id])
                    setDisplayedScopeOfWork((displayedScopeOfWork) => [
                      ...displayedScopeOfWork,
                      scope
                    ])
                  }
                }}
              >
                <option value='' selected>
                  Choose Scope
                </option>
                {scopeOfWorks.map((scope) => {
                  return (
                    <option value={JSON.stringify(scope)}>
                      {scope.name + " - " + scope.subName}
                    </option>
                  )
                })}
              </Form.Select>
              <ul className='p-3'>
                {displayedScopeOfWork.map((work) => {
                  return (
                    <li>
                      {work.name + " " + work.subName}{" "}
                      <button
                        className='btn  btn-sm btn-danger'
                        onClick={() => {
                          let filteredScopeOfWork = scopeOfWork.filter(
                            (scope) => scope !== work._id
                          )
                          let filteredDisplayedScopeOFWork =
                            displayedScopeOfWork.filter(
                              (scope) => scope !== work
                            )

                          setScopeOfWork(filteredScopeOfWork)
                          setDisplayedScopeOfWork(filteredDisplayedScopeOFWork)
                        }}
                      >
                        <i class='fa-solid fa-trash'></i>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </Form.Group>
            <button
              className='btn btn-outline-primary w-100 mt-3'
              onClick={() => setShowCreateScopeOfWork(!showCreateScopeOfWork)}
            >
              {showCreateScopeOfWork
                ? "Hide Create Scope Of Work"
                : "Create Scope Of Work"}
            </button>
            {showCreateScopeOfWork && (
              <div className='mt-3'>
                <h4>
                  Create <span className='text-primary'>Scope Of Work</span>
                </h4>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={scopeOfWorkName}
                    onChange={(e) => setScopeOfWorkName(e.target.value)}
                    placeholder='Scope Of Work Name'
                  ></Form.Control>
                </Form.Group>
                <Form.Group className='mt-3'>
                  <Form.Label>Subname</Form.Label>
                  <Form.Control
                    value={scopeOfWorkSubName}
                    onChange={(e) => setScopeOfWorkSubName(e.target.value)}
                    placeholder='Scope Of Work Subname'
                  ></Form.Control>
                </Form.Group>
                <button
                  onClick={createScopeOfWork}
                  className='mt-3 btn btn-primary'
                >
                  Create Scope Of Work
                </button>
              </div>
            )}

            <button
              className='btn btn-outline-danger w-100 mt-3'
              onClick={() => setShowDeleteScopeOfWork(!showDeleteScopeOfWork)}
            >
              {showDeleteScopeOfWork
                ? "Hide Delete Scope Of Work"
                : "Delete Scope Of Work"}
            </button>
            {showDeleteScopeOfWork && (
              <div className='mt-3'>
                <h4>
                  Delete <span className='text-danger'>Scope Of Work</span>
                </h4>
                <Form.Group className='mt-3'>
                  <Form.Label className='fw-bold mb-0'>
                    Scope Of Work
                  </Form.Label>
                  <Form.Select
                    value={scopeOfWorkId}
                    onChange={(e) => {
                      setScopeOfWorkId(e.target.value)
                    }}
                  >
                    <option value='' selected>
                      Choose Scope
                    </option>
                    {scopeOfWorks.map((scope) => {
                      return (
                        <option value={scope._id}>
                          {scope.name + " - " + scope.subName}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>

                <button
                  onClick={deleteScopeOfWork}
                  className='mt-3 btn btn-danger'
                >
                  Delete Scope Of Work
                </button>
              </div>
            )}
          </div>
          {/* 
          <Form.Group className='mt-3'>
            <Form.Label className='fw-bold'>Vehicle Type</Form.Label>
            <Form.Control
              value={typeOfCars}
              onChange={(e) => setTypeOfCars(e.target.value)}
              placeholder='Vehicle Type'
            ></Form.Control>
          </Form.Group> */}
          <div className='row  my-5'>
            <div className='col-6'>
              <Form.Group className=' w-100'>
                <h5>Type Of Vehicle</h5>
                <Form.Check
                  style={{ fontSize: "23px" }}
                  checked={classic}
                  onChange={(e) => setClassic(!classic)}
                  type='checkbox'
                  label='Classic'
                />
                <Form.Check
                  style={{ fontSize: "23px" }}
                  checked={modern}
                  onChange={(e) => setModern(!modern)}
                  type='checkbox'
                  label='Modern'
                />
                <Form.Check
                  style={{ fontSize: "23px" }}
                  checked={electric}
                  onChange={(e) => setElectric(!electric)}
                  type='checkbox'
                  label='Electric'
                />
              </Form.Group>
            </div>
            <div className='col-6'>
              <Form.Group className=' w-100'>
                <h5>Parts Store</h5>
                <Form.Check
                  checked={partsStore}
                  onChange={() => {
                    setPartsStore(!partsStore)
                  }}
                  style={{ fontSize: "23px" }}
                  type='checkbox'
                  label='Parts Seller'
                />
              </Form.Group>
            </div>
          </div>

          {/* <Form.Group className='mt-3'>
            <Form.Label className='fw-bold'>Country</Form.Label>
            <Form.Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value=''>Country</option>
              <option value='Afghanistan'>Afghanistan</option>
              <option value='Aland Islands'>Aland Islands</option>
              <option value='Albania'>Albania</option>
              <option value='Algeria'>Algeria</option>
              <option value='American Samoa'>American Samoa</option>
              <option value='Andorra'>Andorra</option>
              <option value='Angola'>Angola</option>
              <option value='Anguilla'>Anguilla</option>
              <option value='Antarctica'>Antarctica</option>
              <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
              <option value='Argentina'>Argentina</option>
              <option value='Armenia'>Armenia</option>
              <option value='Aruba'>Aruba</option>
              <option value='Australia'>Australia</option>
              <option value='Austria'>Austria</option>
              <option value='Azerbaijan'>Azerbaijan</option>
              <option value='Bahamas'>Bahamas</option>
              <option value='Bahrain'>Bahrain</option>
              <option value='Bangladesh'>Bangladesh</option>
              <option value='Barbados'>Barbados</option>
              <option value='Belarus'>Belarus</option>
              <option value='Belgium'>Belgium</option>
              <option value='Belize'>Belize</option>
              <option value='Benin'>Benin</option>
              <option value='Bermuda'>Bermuda</option>
              <option value='Bhutan'>Bhutan</option>
              <option value='Bolivia'>Bolivia</option>
              <option value='Bonaire, Sint Eustatius and Saba'>
                Bonaire, Sint Eustatius and Saba
              </option>
              <option value='Bosnia and Herzegovina'>
                Bosnia and Herzegovina
              </option>
              <option value='Botswana'>Botswana</option>
              <option value='Bouvet Island'>Bouvet Island</option>
              <option value='Brazil'>Brazil</option>
              <option value='British Indian Ocean Territory'>
                British Indian Ocean Territory
              </option>
              <option value='Brunei Darussalam'>Brunei Darussalam</option>
              <option value='Bulgaria'>Bulgaria</option>
              <option value='Burkina Faso'>Burkina Faso</option>
              <option value='Burundi'>Burundi</option>
              <option value='Cambodia'>Cambodia</option>
              <option value='Cameroon'>Cameroon</option>
              <option value='Canada'>Canada</option>
              <option value='Cape Verde'>Cape Verde</option>
              <option value='Cayman Islands'>Cayman Islands</option>
              <option value='Central African Republic'>
                Central African Republic
              </option>
              <option value='Chad'>Chad</option>
              <option value='Chile'>Chile</option>
              <option value='China'>China</option>
              <option value='Christmas Island'>Christmas Island</option>
              <option value='Cocos (Keeling) Islands'>
                Cocos (Keeling) Islands
              </option>
              <option value='Colombia'>Colombia</option>
              <option value='Comoros'>Comoros</option>
              <option value='Congo'>Congo</option>
              <option value='Congo, Democratic Republic of the Congo'>
                Congo, Democratic Republic of the Congo
              </option>
              <option value='Cook Islands'>Cook Islands</option>
              <option value='Costa Rica'>Costa Rica</option>
              <option value="Cote D'Ivoire">Cote D'Ivoire</option>
              <option value='Croatia'>Croatia</option>
              <option value='Cuba'>Cuba</option>
              <option value='Curacao'>Curacao</option>
              <option value='Cyprus'>Cyprus</option>
              <option value='Czech Republic'>Czech Republic</option>
              <option value='Denmark'>Denmark</option>
              <option value='Djibouti'>Djibouti</option>
              <option value='Dominica'>Dominica</option>
              <option value='Dominican Republic'>Dominican Republic</option>
              <option value='Ecuador'>Ecuador</option>
              <option value='Egypt'>Egypt</option>
              <option value='El Salvador'>El Salvador</option>
              <option value='Equatorial Guinea'>Equatorial Guinea</option>
              <option value='Eritrea'>Eritrea</option>
              <option value='Estonia'>Estonia</option>
              <option value='Ethiopia'>Ethiopia</option>
              <option value='Falkland Islands (Malvinas)'>
                Falkland Islands (Malvinas)
              </option>
              <option value='Faroe Islands'>Faroe Islands</option>
              <option value='Fiji'>Fiji</option>
              <option value='Finland'>Finland</option>
              <option value='France'>France</option>
              <option value='French Guiana'>French Guiana</option>
              <option value='French Polynesia'>French Polynesia</option>
              <option value='French Southern Territories'>
                French Southern Territories
              </option>
              <option value='Gabon'>Gabon</option>
              <option value='Gambia'>Gambia</option>
              <option value='Georgia'>Georgia</option>
              <option value='Germany'>Germany</option>
              <option value='Ghana'>Ghana</option>
              <option value='Gibraltar'>Gibraltar</option>
              <option value='Greece'>Greece</option>
              <option value='Greenland'>Greenland</option>
              <option value='Grenada'>Grenada</option>
              <option value='Guadeloupe'>Guadeloupe</option>
              <option value='Guam'>Guam</option>
              <option value='Guatemala'>Guatemala</option>
              <option value='Guernsey'>Guernsey</option>
              <option value='Guinea'>Guinea</option>
              <option value='Guinea-Bissau'>Guinea-Bissau</option>
              <option value='Guyana'>Guyana</option>
              <option value='Haiti'>Haiti</option>
              <option value='Heard Island and Mcdonald Islands'>
                Heard Island and Mcdonald Islands
              </option>
              <option value='Holy See (Vatican City State)'>
                Holy See (Vatican City State)
              </option>
              <option value='Honduras'>Honduras</option>
              <option value='Hong Kong'>Hong Kong</option>
              <option value='Hungary'>Hungary</option>
              <option value='Iceland'>Iceland</option>
              <option value='India'>India</option>
              <option value='Indonesia'>Indonesia</option>
              <option value='Iran, Islamic Republic of'>
                Iran, Islamic Republic of
              </option>
              <option value='Iraq'>Iraq</option>
              <option value='Ireland'>Ireland</option>
              <option value='Isle of Man'>Isle of Man</option>
              <option value='Israel'>Israel</option>
              <option value='Italy'>Italy</option>
              <option value='Jamaica'>Jamaica</option>
              <option value='Japan'>Japan</option>
              <option value='Jersey'>Jersey</option>
              <option value='Jordan'>Jordan</option>
              <option value='Kazakhstan'>Kazakhstan</option>
              <option value='Kenya'>Kenya</option>
              <option value='Kiribati'>Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value='Korea, Republic of'>Korea, Republic of</option>
              <option value='Kosovo'>Kosovo</option>
              <option value='Kuwait'>Kuwait</option>
              <option value='Kyrgyzstan'>Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value='Latvia'>Latvia</option>
              <option value='Lebanon'>Lebanon</option>
              <option value='Lesotho'>Lesotho</option>
              <option value='Liberia'>Liberia</option>
              <option value='Libyan Arab Jamahiriya'>
                Libyan Arab Jamahiriya
              </option>
              <option value='Liechtenstein'>Liechtenstein</option>
              <option value='Lithuania'>Lithuania</option>
              <option value='Luxembourg'>Luxembourg</option>
              <option value='Macao'>Macao</option>
              <option value='Macedonia, the Former Yugoslav Republic of'>
                Macedonia, the Former Yugoslav Republic of
              </option>
              <option value='Madagascar'>Madagascar</option>
              <option value='Malawi'>Malawi</option>
              <option value='Malaysia'>Malaysia</option>
              <option value='Maldives'>Maldives</option>
              <option value='Mali'>Mali</option>
              <option value='Malta'>Malta</option>
              <option value='Marshall Islands'>Marshall Islands</option>
              <option value='Martinique'>Martinique</option>
              <option value='Mauritania'>Mauritania</option>
              <option value='Mauritius'>Mauritius</option>
              <option value='Mayotte'>Mayotte</option>
              <option value='Mexico'>Mexico</option>
              <option value='Micronesia, Federated States of'>
                Micronesia, Federated States of
              </option>
              <option value='Moldova, Republic of'>Moldova, Republic of</option>
              <option value='Monaco'>Monaco</option>
              <option value='Mongolia'>Mongolia</option>
              <option value='Montenegro'>Montenegro</option>
              <option value='Montserrat'>Montserrat</option>
              <option value='Morocco'>Morocco</option>
              <option value='Mozambique'>Mozambique</option>
              <option value='Myanmar'>Myanmar</option>
              <option value='Namibia'>Namibia</option>
              <option value='Nauru'>Nauru</option>
              <option value='Nepal'>Nepal</option>
              <option value='Netherlands'>Netherlands</option>
              <option value='Netherlands Antilles'>Netherlands Antilles</option>
              <option value='New Caledonia'>New Caledonia</option>
              <option value='New Zealand'>New Zealand</option>
              <option value='Nicaragua'>Nicaragua</option>
              <option value='Niger'>Niger</option>
              <option value='Nigeria'>Nigeria</option>
              <option value='Niue'>Niue</option>
              <option value='Norfolk Island'>Norfolk Island</option>
              <option value='Northern Mariana Islands'>
                Northern Mariana Islands
              </option>
              <option value='Norway'>Norway</option>
              <option value='Oman'>Oman</option>
              <option value='Pakistan'>Pakistan</option>
              <option value='Palau'>Palau</option>
              <option value='Palestinian Territory, Occupied'>
                Palestinian Territory, Occupied
              </option>
              <option value='Panama'>Panama</option>
              <option value='Papua New Guinea'>Papua New Guinea</option>
              <option value='Paraguay'>Paraguay</option>
              <option value='Peru'>Peru</option>
              <option value='Philippines'>Philippines</option>
              <option value='Pitcairn'>Pitcairn</option>
              <option value='Poland'>Poland</option>
              <option value='Portugal'>Portugal</option>
              <option value='Puerto Rico'>Puerto Rico</option>
              <option value='Qatar'>Qatar</option>
              <option value='Reunion'>Reunion</option>
              <option value='Romania'>Romania</option>
              <option value='Russian Federation'>Russian Federation</option>
              <option value='Rwanda'>Rwanda</option>
              <option value='Saint Barthelemy'>Saint Barthelemy</option>
              <option value='Saint Helena'>Saint Helena</option>
              <option value='Saint Kitts and Nevis'>
                Saint Kitts and Nevis
              </option>
              <option value='Saint Lucia'>Saint Lucia</option>
              <option value='Saint Martin'>Saint Martin</option>
              <option value='Saint Pierre and Miquelon'>
                Saint Pierre and Miquelon
              </option>
              <option value='Saint Vincent and the Grenadines'>
                Saint Vincent and the Grenadines
              </option>
              <option value='Samoa'>Samoa</option>
              <option value='San Marino'>San Marino</option>
              <option value='Sao Tome and Principe'>
                Sao Tome and Principe
              </option>
              <option value='Saudi Arabia'>Saudi Arabia</option>
              <option value='Senegal'>Senegal</option>
              <option value='Serbia'>Serbia</option>
              <option value='Serbia and Montenegro'>
                Serbia and Montenegro
              </option>
              <option value='Seychelles'>Seychelles</option>
              <option value='Sierra Leone'>Sierra Leone</option>
              <option value='Singapore'>Singapore</option>
              <option value='Sint Maarten'>Sint Maarten</option>
              <option value='Slovakia'>Slovakia</option>
              <option value='Slovenia'>Slovenia</option>
              <option value='Solomon Islands'>Solomon Islands</option>
              <option value='Somalia'>Somalia</option>
              <option value='South Africa'>South Africa</option>
              <option value='South Georgia and the South Sandwich Islands'>
                South Georgia and the South Sandwich Islands
              </option>
              <option value='South Sudan'>South Sudan</option>
              <option value='Spain'>Spain</option>
              <option value='Sri Lanka'>Sri Lanka</option>
              <option value='Sudan'>Sudan</option>
              <option value='Suriname'>Suriname</option>
              <option value='Svalbard and Jan Mayen'>
                Svalbard and Jan Mayen
              </option>
              <option value='Swaziland'>Swaziland</option>
              <option value='Sweden'>Sweden</option>
              <option value='Switzerland'>Switzerland</option>
              <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
              <option value='Taiwan, Province of China'>
                Taiwan, Province of China
              </option>
              <option value='Tajikistan'>Tajikistan</option>
              <option value='Tanzania, United Republic of'>
                Tanzania, United Republic of
              </option>
              <option value='Thailand'>Thailand</option>
              <option value='Timor-Leste'>Timor-Leste</option>
              <option value='Togo'>Togo</option>
              <option value='Tokelau'>Tokelau</option>
              <option value='Tonga'>Tonga</option>
              <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
              <option value='Tunisia'>Tunisia</option>
              <option value='Turkey'>Turkey</option>
              <option value='Turkmenistan'>Turkmenistan</option>
              <option value='Turks and Caicos Islands'>
                Turks and Caicos Islands
              </option>
              <option value='Tuvalu'>Tuvalu</option>
              <option value='Uganda'>Uganda</option>
              <option value='Ukraine'>Ukraine</option>
              <option value='United Arab Emirates'>United Arab Emirates</option>
              <option value='United Kingdom'>United Kingdom</option>
              <option value='United States'>United States</option>
              <option value='United States Minor Outlying Islands'>
                United States Minor Outlying Islands
              </option>
              <option value='Uruguay'>Uruguay</option>
              <option value='Uzbekistan'>Uzbekistan</option>
              <option value='Vanuatu'>Vanuatu</option>
              <option value='Venezuela'>Venezuela</option>
              <option value='Viet Nam'>Viet Nam</option>
              <option value='Virgin Islands, British'>
                Virgin Islands, British
              </option>
              <option value='Virgin Islands, U.s.'>Virgin Islands, U.s.</option>
              <option value='Wallis and Futuna'>Wallis and Futuna</option>
              <option value='Western Sahara'>Western Sahara</option>
              <option value='Yemen'>Yemen</option>
              <option value='Zambia'>Zambia</option>
              <option value='Zimbabwe'>Zimbabwe</option>
            </Form.Select>
          
          </Form.Group> */}

          <h5 className='display-6'>Description</h5>
          <Form.Group className='mb-3 w-100'>
            <Form.Control
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              as='textarea'
              rows={8}
              placeholder='Description'
            >
              Description
            </Form.Control>
          </Form.Group>

          {/* <CreateMap setLat={setLat} setLng={setLng} /> */}
          <label>Images </label>
          <input
            className='mt-3'
            type='file'
            onChange={(e) => setImages(e.target.files)}
            multiple
          />
        </div>

        <button
          className='btn btn-primary'
          onClick={createRestorer}
          disabled={loading}
        >
          {loading ? "Loading..." : "Create Restorer"}
        </button>
      </Container>
    </Main>
  )
}
