import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as notify from "../lib/notify"
import { login } from "../features/employeeSlice"
import { reset } from "../features/employeeSlice"

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isSuccess, isError, employee, isLoading, message } = useSelector(
    (state) => state.employee
  )

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const loginFormValidated = email && password
  const handleLoginButton = (e) => {
    e.preventDefault()
    console.log("email" + email + " password " + password)
    if (!loginFormValidated) notify.error("Please fill all the input!")
    else dispatch(login({ email, password }))
  }

  useEffect(() => {
    if (isError) {
      notify.error(message)
    }
    if (isSuccess || employee) navigate("/home")
    console.log("hello world")
    // console.log(employee)
    return () => {
      dispatch(reset())
    }
  }, [isError, isSuccess, employee, message, dispatch])

  return (
    <Container
      className='d-flex justify-content-center align-items-start flex-column'
      style={{ height: "100vh" }}
    >
      <h1 className='text-left mb-4'>
        {" "}
        Autopia Admin <span className='text-primary'>Login</span>
      </h1>

      <Form className='w-100'>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
            placeholder='Enter email'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            placeholder='Password'
          />
        </Form.Group>

        <Button
          disabled={isLoading}
          variant='primary'
          onClick={(e) => handleLoginButton(e)}
        >
          {isLoading ? "Loading..." : "Login"}
        </Button>
      </Form>
    </Container>
  )
}

export default Login
