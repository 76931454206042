
const BACKEND_URL = "https://api.autopiaworld.com";


export const API_URLS = {
  REGISTER_EMPLOYEE: `${BACKEND_URL}/admin/register`,
  LOGIN_EMPLOYEE: `${BACKEND_URL}/admin/login`,
  GET_EMPLOYEES: `${BACKEND_URL}/admin/employees`,
  CREATE_EMPLOYEE: `${BACKEND_URL}/admin/employees/create`,
  VERIFY_EMPLOYEE: `${BACKEND_URL}/admin/employees/verify`,
  ADDINFO_EMPLOYEE: `${BACKEND_URL}/admin/employees/add-info`,
  DELETE_EMPLOYEE: `${BACKEND_URL}/admin/employees/delete`,
  CHECK_DASHBOARD: `${BACKEND_URL}/admin/check/dashboard`,
  CHECK_FEEDBACK: `${BACKEND_URL}/admin/check/feedback`,
  CHECK_SALES: `${BACKEND_URL}/admin/check/sales`,
  CHECK_CONTACTUS: `${BACKEND_URL}/admin/check/contact-us`,
  CHECK_CAREERS: `${BACKEND_URL}/admin/check/careers`,
  CHECK_CARS: `${BACKEND_URL}/admin/check/cars`,
  CHECK_SELLERS: `${BACKEND_URL}/admin/check/sellers`,
  CHECK_METRICS: `${BACKEND_URL}/admin/check/metrics`,
  CHECK_SECURITY: `${BACKEND_URL}/admin/check/security`,
  CHECK_SEARCH_TOOL: `${BACKEND_URL}/admin/check/search-tool`,
  CHECK_PHONE: `${BACKEND_URL}/car/check/phone`,
  CHECK_PROMOTED: `${BACKEND_URL}/car/check/promoted`,
  CHECK_VERIFIED: `${BACKEND_URL}/car/check/verify`,
  GET_CAR_IMAGES: `${BACKEND_URL}/car/images`,
  CONFIRM_CAR: `${BACKEND_URL}/car/confirm`,
  EDIT_IMAGE: `${BACKEND_URL}/car/edit-image`,
  CHECK_BEST_OFFER: `${BACKEND_URL}/car/check/best-offer`,
  DELETE_IMAGE: `${BACKEND_URL}/car/image/delete`,
  UPLOAD_CAR_IMAGES: `${BACKEND_URL}/car/upload`,
  CREATE_CAR: `${BACKEND_URL}/car/create`,
  CHECK_CAR_OFFER: `${BACKEND_URL}/car/check/offer`,
  CHECK_CAR_FEATURED_RENT: `${BACKEND_URL}/car/check/featured/rent`,
  CHECK_CAR_PROMOTED_RENT: `${BACKEND_URL}/car/check/promoted/rent`,
  CHECK_CAR_FEATURED: `${BACKEND_URL}/car/check/featured`,
  CHECK_CAR_PRIVATE: `${BACKEND_URL}/car/check/private`,
  GET_CAR_PRIORITY: `${BACKEND_URL}/car/priority`,
  SET_CAR_FEATURED_PRIORITY: `${BACKEND_URL}/car/featured/priority`,
  SET_CAR_LATEST_PRIORITY: `${BACKEND_URL}/car/latest/priority`,
  SET_CAR_PROMOTED_PRIORITY: `${BACKEND_URL}/car/promoted/priority`,
  SET_CAR_RENT_PROMOTED_PRIORITY: `${BACKEND_URL}/car/promoted/rent/priority`,
  SET_CAR_RENT_FEATURED_PRIORITY: `${BACKEND_URL}/car/featured/rent/priority`,
  DELETE_CAR: `${BACKEND_URL}/car/delete`,
  GET_CARS: `${BACKEND_URL}/car/`,
  GET_CAR_BY_ID: `${BACKEND_URL}/car/id`,
  UPDATE_CAR_BY_ID: `${BACKEND_URL}/car/id`,
  CHECK_DISCOUNT: `${BACKEND_URL}/car/check/discount`,
  ADD_DISCOUNT: `${BACKEND_URL}/car/discount`,
  CREATE_JOB: `${BACKEND_URL}/job/create`,
  UPDATE_JOB: `${BACKEND_URL}/job/update`,
  GET_JOB: `${BACKEND_URL}/job/`,
  DELETE_JOB: `${BACKEND_URL}/job/delete`,
  SEARCH_CAR: `${BACKEND_URL}/search/text`,
  CREATE_SELLER: `${BACKEND_URL}/seller/create`,
  SWITCH_IMAGES: `${BACKEND_URL}/car/switch`,
  GET_SELLERS: `${BACKEND_URL}/seller/`,
  GET_SELLER_CARS: `${BACKEND_URL}/seller/cars`,
  GET_SELLER_BY_ID: `${BACKEND_URL}/seller/id`,
  EDIT_SELLER: `${BACKEND_URL}/seller/edit`,
  SEARCH_CONTACT_US: `${BACKEND_URL}/contact/search`,
  CHECK_CONTACT_US_FORM: `${BACKEND_URL}/contact/check`,
  DELETE_SELLER: `${BACKEND_URL}/seller/delete`,
  SEARCH_SELL_CAR: `${BACKEND_URL}/sell/search`,
  CHECK_SELL_CAR_FORM: `${BACKEND_URL}/sell/check`,
  SEARCH_FEEDBACK: `${BACKEND_URL}/feedback/search`,
  CHECK_FEEDBACK_FORM: `${BACKEND_URL}/feedback/check`,
  CREATE_COLOR: `${BACKEND_URL}/color/create`,
  DELETE_COLOR: `${BACKEND_URL}/color/delete`,
  GET_COLORS: `${BACKEND_URL}/color/`,
  CREATE_BRAND: `${BACKEND_URL}/brand/create`,
  GET_BRANDS: `${BACKEND_URL}/brand/`,
  DELETE_BRAND: `${BACKEND_URL}/brand/delete`,
  CREATE_MODEL: `${BACKEND_URL}/model/create`,
  DELETE_MODEL: `${BACKEND_URL}/model/delete`,
  GET_BLOCKED_IPS: `${BACKEND_URL}/ip/`,
  BLOCK_IP: `${BACKEND_URL}/ip/block`,
  UNBLOCK_IP: `${BACKEND_URL}/ip/unblock`,
  UNBLOCK_IP2: `${BACKEND_URL}/ip/unblock/ip`,
  GET_DOCUMENTS: `${BACKEND_URL}/document`,
  GET_DOCUMENT: `${BACKEND_URL}/document/id`,
  SAVE_DOCUMENT: `${BACKEND_URL}/document/save`,
  DELETE_DOCUMENT: `${BACKEND_URL}/document/delete`,
  CREATE_DOCUMENT: `${BACKEND_URL}/document/create`,
  CREATE_DOCUMENT_IMAGE: `${BACKEND_URL}/document/image/upload`,
  DELETE_DOCUMENT_IMAGE: `${BACKEND_URL}/document/image/delete`,
  SEARCH_DOCUMENTS: `${BACKEND_URL}/document/search`,
  UPDATE_PRIORITY: `${BACKEND_URL}/car/priority/update`,
  CREATE_RESTORER: `${BACKEND_URL}/restorer/create`,
  GET_RESTORERS: `${BACKEND_URL}/restorer`,
  GET_RESTORER_BY_ID: `${BACKEND_URL}/restorer/find`,
  EDIT_RESTORER: `${BACKEND_URL}/restorer/edit`,
  CHECK_PRIVATE_RESTORER: `${BACKEND_URL}/restorer/check/private`,
  CHECK_PROMOTED_RESTORER: `${BACKEND_URL}/restorer/check/promoted`,
  SEARCH_RESTORES: `${BACKEND_URL}/restorer/text`,
  DELETE_RESTORER: `${BACKEND_URL}/restorer/delete`,
  CREATE_SCOPE_OF_WORK: `${BACKEND_URL}/scope_of_work/create`,
  GET_SCOPE_OF_WORKS: `${BACKEND_URL}/scope_of_work/`,
  EDIT_SCOPE_OF_WORK: `${BACKEND_URL}/scope_of_work/edit`,
  DELETE_SCOPE_OF_WORK: `${BACKEND_URL}/scope_of_work/delete`,
  UPLOAD_RESTORER_IMAGES: `${BACKEND_URL}/restorer/upload`,
  GET_RESTORER_IMAGES: `${BACKEND_URL}/restorer/images`,
  DELETE_RESTORER_IMAGE: `${BACKEND_URL}/restorer/image/delete`,
  SWITCH_RESTORER_IMAGES: `${BACKEND_URL}/restorer/switch`,
  RESTORERS_UPDATE_PRIORITY: `${BACKEND_URL}/restorer/priority/update`,
};
