import axios from "axios"
import { API_URLS } from "../constants/routes"

const createJob = async (data) => {
  const response = await axios.post(API_URLS.CREATE_JOB, data)
  return response.data
}

const getJobs = async (data) => {
  const response = await axios.post(API_URLS.GET_JOB)
  return response.data
}

const updateJob = async (data) => {
  const response = await axios.post(API_URLS.UPDATE_JOB, data)
  return response.data
}
const deleteJob = async (data) => {
  const response = await axios.post(API_URLS.DELETE_JOB, data)
  return response.data
}
const jobApi = {
  createJob,
  getJobs,
  deleteJob,
  updateJob
}

export default jobApi
