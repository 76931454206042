import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'

import {Line} from "react-chartjs-2"
// import faker from 'faker'



Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  
  function LineChart({options,data}) {
    return <Line options={options} data={data} />;
  }

  export default LineChart;