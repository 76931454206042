import { useEffect } from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logout, reset } from "../features/employeeSlice"
function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { employee } = useSelector((state) => state.employee)
  useEffect(() => {
    if (!employee) {
      navigate("/login")
    }
  }, [])
  const handleLogoutButton = () => {
    dispatch(logout())
    dispatch(reset())
    navigate("/login")
  }
  return (
    <>
      <Navbar bg='dark' fixed='top' variant='dark' expand='lg'>
        <Container>
          <Navbar.Brand
            className='cursor-pointer'
            onClick={() => navigate("/dashboard")}
          >
            Autopia Admin
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {employee && employee.carsAccess && (
                <Nav.Link onClick={() => navigate("/cars")}>Cars</Nav.Link>
              )}
              <Nav.Link onClick={() => navigate("/sellers")}>Sellers</Nav.Link>

              {employee && employee.salesAccess && (
                <Nav.Link onClick={() => navigate("/sales")}>Sales</Nav.Link>
              )}
              {employee && employee.feedbackAccess && (
                <Nav.Link onClick={() => navigate("/feedback")}>
                  Feedback
                </Nav.Link>
              )}
              {employee && employee.contactUsAccess && (
                <Nav.Link onClick={() => navigate("/contact-us")}>
                  Contact Us
                </Nav.Link>
              )}
              {employee && employee.careersAccess && (
                <Nav.Link onClick={() => navigate("/careers")}>
                  Careers
                </Nav.Link>
              )}
              {employee && employee.userAccess && (
                <Nav.Link onClick={() => navigate("/users")}>Users</Nav.Link>
              )}
            </Nav>
            <Nav>
              <Nav.Link onClick={handleLogoutButton}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ marginTop: "80px" }}></div>
    </>
  )
}

export default Header
