export const API_ROUTES = {
  POST: {
    CREATE_PART:"/part",
    CHECK_PRIVATE:"/part/check/private",
    CHECK_PROMOTED:"/part/check/promoted",
    CHECK_DISCOUNT:"/part/check/discount"
  },
  GET:{
    GET_BY_ID:"/part/id",
    SEARCH_PARTS: "/part/search"
  },
  DELETE:{
    DELETE_PART:"/part"
  },
  PUT:{
    UPDATE_PART:"/part"
  }
}
