module.exports.fuel_type = [
  "Gasoline",
  "Diesel",
  "Propane",
  "Electric",
  "Hybrid",
  "Biodiesel ",
  "Ethanol",
  "Methanol",
  "Liquified natural gas (LNG)",
  "Compressed Natural Gas ( CNG)",
  "Hydrogen",
  "Fuel cells",
  "Diesel-Electric",
  "Gasoline-Electric",
  "Solar-Electric",
  "Wind-Electric",
  "Hydrogen-Electric",
  "Biofuels",
  ""
]
