import { Button, Col, Form, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import carApi from "../api/carApi";
import { useNavigate, useParams } from "react-router-dom";
import * as notify from "../lib/notify";
import { errorMessage } from "../utils/errorMessage";
import brandApi from "../api/brandApi";
import modelApi from "../api/modelApi";
import colorApi from "../api/colorApi";
import Main from "../components/Main/Main";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function EditCarForm() {
  const [car, setCar] = useState({});

  const [internalId, setInternalId] = useState("");
  const [status, setStatus] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [engineNumber, setEngineNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState(0);
  const [priceStatus, setPriceStatus] = useState();
  // const [type, setType] = useState("")
  const [body, setBody] = useState("");
  const [city, setCity] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [interiorColor, setInteriorColor] = useState("");
  const [gears, setGears] = useState("");
  const [doors, setDoors] = useState("");
  const [cylinders, setCylinders] = useState(0);
  const [condition, setCondition] = useState("");
  const [horsepower, setHorsepower] = useState(0);
  const [engineSize, setEngineSize] = useState(0);
  const [transmission, setTransmission] = useState("");
  const [VIN, setVIN] = useState("");
  const [legalPapers, setLegalPapers] = useState("");
  const [originalEngine, setOriginalEngine] = useState("");
  const [originalParts, setOriginalParts] = useState("");
  const [odometer, setOdometer] = useState("");
  const [steeringWheel, setSteeringWheel] = useState("");
  const [driveWheels, setDriveWheels] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [rentStartingPrice, setRentStartingPrice] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [badge, setBadge] = useState([]);

  const [badgeInput, setBadgeInput] = useState("");
  const [firstRegisteredCountry, setFirstRegisteredCountry] = useState("");

  const navigate = useNavigate();

  const setCarInfo = async (id) => {
    try {
      setLoading(true);
      const data = await carApi.getCarById({ id });
      setInternalId(data.internalId);
      // setSellerId(data.seller._id)
      setChassisNumber(data.chassisNumber);
      setBrand(data.brand);
      setCategory(data.category);
      setModel(data.model);
      setCity(data.city);
      setYear(data.year);
      setCylinders(data.cylinders);
      setOdometer(data.odometer);
      setFirstRegisteredCountry(data.firstRegisteredCountry);
      setSteeringWheel(data.steeringWheel);
      setFuelType(data.fuelType);
      setCountry(data.country);
      setAddress(data.address);
      setDriveWheels(data.driveWheels);
      setPrice(data.price);
      setCondition(data.condition);
      // setType(data.type)
      setBody(data.body);
      setExteriorColor(data.exteriorColor);
      setInteriorColor(data.interiorColor);
      setGears(data.gears);
      setDoors(data.doors);
      setHorsepower(data.horsepower);
      setEngineSize(data.engineSize);
      setEngineNumber(data.engineNumber);
      setTransmission(data.transmission);
      setVIN(data.VIN);
      setRentStartingPrice(data.rentStartingPrice);
      setLegalPapers(data.legalPapers);
      setBadge(data?.badge);
      setStatus(data.status);
      setPriceStatus(data.priceStatus);
      setDescription(data.description);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const { id } = useParams();

  const updateCar = async () => {
    try {
      setLoading(true);
      notify.success("Car updated successfully");
      await carApi.updateCarById({
        internalId,
        chassisNumber,
        engineNumber,
        brand,
        model,
        firstRegisteredCountry,
        year,
        price,
        category,
        status,
        priceStatus,
        // type,
        badge: badge,
        body,
        exteriorColor,
        interiorColor,
        gears,
        doors,
        cylinders,
        condition,
        horsepower,
        engineSize,
        transmission,
        VIN,
        legalPapers,
        originalEngine,
        originalParts,
        odometer,
        steeringWheel,
        driveWheels,
        fuelType,
        country,
        city,
        address,
        rentStartingPrice,
        description,
        // sellerId,
        carId: id,
      });
      navigate("/cars");
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const [colorsLoading, setColorsLoading] = useState(false);
  const [colorsData, setColorsData] = useState([]);
  const [showInteriorColorForm, setShowInteriorColorForm] = useState(false);
  const [showExteriorColorForm, setShowExteriorColorForm] = useState(false);
  const getColors = async () => {
    try {
      setColorsLoading(true);
      const data = await colorApi.getColors();
      console.log("colors", data);
      setColorsData(data);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setColorsLoading(false);
    }
  };

  const [colorName, setColorName] = useState("");
  const createColor = async () => {
    try {
      if (!colorName) {
        notify.error("Please fill color name input");
        return;
      }
      setColorsLoading(true);
      await colorApi.addColor({ name: colorName });
      setColorName("");
      notify.success("Color Created Successfully");
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setColorsLoading(false);
      getColors();
    }
  };

  const [colorId, setColorId] = useState("");
  const deleteColor = async () => {
    try {
      if (!colorId) {
        notify.error("Please choose color to delete");
        return;
      }
      setColorsLoading(true);
      await colorApi.deleteColor({ id: colorId });
      notify.success("Color deleted successfully");
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setColorsLoading(false);
      getColors();
    }
  };

  const [brandsData, setBrandsData] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [showBrandForm, setShowBrandForm] = useState(false);
  const getBrands = async () => {
    try {
      setLoadingBrands(true);
      const data = await brandApi.getBrands();
      console.log("brand data: ", data);
      if (data) setBrandsData(data);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoadingBrands(false);
    }
  };
  const [brandName, setBrandName] = useState("");
  const createBrand = async () => {
    try {
      setLoadingBrands(true);
      await brandApi.createBrand({ name: brandName });
      setBrandName("");
      setModelName("");
      setShowBrandForm(false);
      setShowModelForm(false);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoadingBrands(false);
      getBrands();
    }
  };

  const [brandId, setBrandId] = useState();
  const [modelName, setModelName] = useState("");
  const [seller, setSeller] = useState();
  const [loadingModelData, setLoadingModelData] = useState(false);
  const [showModelForm, setShowModelForm] = useState(false);
  const [modelsData, setModelsData] = useState([]);
  const createModel = async () => {
    try {
      setLoadingBrands(true);
      await modelApi.createModel({ name: modelName, brandId: brandId });
      setModel("");
      setBrand("");
      setShowModelForm(false);
      setShowBrandForm(false);
    } catch (error) {
      notify.error(errorMessage(error));
    } finally {
      setLoadingBrands(false);
      getBrands();
    }
  };

  useEffect(() => {
    getColors();
    getBrands();
    setCarInfo(id);
  }, []);

  return (
    <>
      <Main>
        <Container className="bg-white m-2">
          {loading ? (
            <h3 style={{ textAlign: "center" }}>Loading car...</h3>
          ) : (
            <>
              <h1 className="text-left mb-4 mt-5">
                Edit <span className="text-primary">Car</span>
              </h1>

              <div className="w-100">
                <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                  <h1 class="display-3">Details</h1>
                  <Row>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Internal Id</Form.Label>
                        <Form.Control
                          value={internalId}
                          onChange={(e) => setInternalId(e.target.value)}
                          placeholder="Rent Starting Price"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Brand</Form.Label>
                        <Form.Select
                          disabled={loadingBrands}
                          onChange={(e) => {
                            if (e.target.value == "") {
                              setBrand("");
                              setBrandId("");
                              setModelsData([]);
                            } else {
                              let br = JSON.parse(e.target.value);
                              setModelsData(br.models);
                              setBrand(br.name);
                              setBrandId(br._id);
                            }
                          }}
                        >
                          {loadingBrands ? (
                            <option value="">Loading...</option>
                          ) : (
                            <>
                              <option value="">Brand</option>

                              {brandsData.map((b) => {
                                return (
                                  <option value={JSON.stringify(b)}>
                                    {b.name}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </Form.Select>
                        {!loadingBrands && (
                          <>
                            <Button
                              variant="outline-success"
                              className="w-100"
                              disabled={loadingBrands}
                              onClick={() => setShowBrandForm(!showBrandForm)}
                            >
                              Create Brand
                            </Button>
                            {showBrandForm && (
                              <Form.Group className="shadow p-2">
                                <Form.Control
                                  value={brandName}
                                  onChange={(e) => setBrandName(e.target.value)}
                                  placeholder="Brand Name"
                                />
                                <Button onClick={createBrand} variant="success">
                                  Add Brand
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col lg={3} md={4} xs={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>Model</Form.Label>
                      <Form.Select
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        disabled={brand === ""}
                      >
                        <option value=''>Model</option>

                        {models[brand].map((m) => (
                          <option value={m}>{m}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Model</Form.Label>

                        <Form.Select
                          value={model}
                          onChange={(e) => setModel(e.target.value)}
                          disabled={brand === "" || loadingBrands}
                        >
                          {loadingBrands ? (
                            <option value="">Loading...</option>
                          ) : (
                            <>
                              <option>Model</option>
                              {modelsData?.map((m) => (
                                <option value={m.name}>{m.name}</option>
                              ))}
                            </>
                          )}
                        </Form.Select>
                        {!loadingBrands && (
                          <>
                            <Button
                              variant="outline-success"
                              className="w-100"
                              disabled={loadingBrands}
                              onClick={() => setShowModelForm(!showModelForm)}
                            >
                              Create Model
                            </Button>
                            {showModelForm && (
                              <Form.Group className="shadow p-2">
                                <Form.Control
                                  value={modelName}
                                  onChange={(e) => setModelName(e.target.value)}
                                  placeholder="Model Name"
                                />
                                <Button onClick={createModel} variant="success">
                                  Add Model
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Year</Form.Label>
                        <Form.Select
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                        >
                          <option value="">Year</option>
                          <option value="1890">1890</option>
                          <option value="1891">1891</option>
                          <option value="1892">1892</option>
                          <option value="1893">1893</option>
                          <option value="1894">1894</option>
                          <option value="1895">1895</option>
                          <option value="1896">1896</option>
                          <option value="1897">1897</option>
                          <option value="1898">1898</option>
                          <option value="1899">1899</option>
                          <option value="1900">1900</option>
                          <option value="1901">1901</option>
                          <option value="1902">1902</option>
                          <option value="1903">1903</option>
                          <option value="1904">1904</option>
                          <option value="1905">1905</option>
                          <option value="1906">1906</option>
                          <option value="1907">1907</option>
                          <option value="1908">1908</option>
                          <option value="1909">1909</option>
                          <option value="1910">1910</option>
                          <option value="1911">1911</option>
                          <option value="1912">1912</option>
                          <option value="1913">1913</option>
                          <option value="1914">1914</option>
                          <option value="1915">1915</option>
                          <option value="1916">1916</option>
                          <option value="1917">1917</option>
                          <option value="1918">1918</option>
                          <option value="1919">1919</option>
                          <option value="1920">1920</option>
                          <option value="1921">1921</option>
                          <option value="1922">1922</option>
                          <option value="1923">1923</option>
                          <option value="1924">1924</option>
                          <option value="1925">1925</option>
                          <option value="1926">1926</option>
                          <option value="1927">1927</option>
                          <option value="1928">1928</option>
                          <option value="1929">1929</option>
                          <option value="1930">1930</option>
                          <option value="1931">1931</option>
                          <option value="1932">1932</option>
                          <option value="1933">1933</option>
                          <option value="1934">1934</option>
                          <option value="1935">1935</option>
                          <option value="1936">1936</option>
                          <option value="1937">1937</option>
                          <option value="1938">1938</option>
                          <option value="1939">1939</option>
                          <option value="1940">1940</option>
                          <option value="1941">1941</option>
                          <option value="1942">1942</option>
                          <option value="1943">1943</option>
                          <option value="1944">1944</option>
                          <option value="1945">1945</option>
                          <option value="1946">1946</option>
                          <option value="1947">1947</option>
                          <option value="1948">1948</option>
                          <option value="1949">1949</option>
                          <option value="1950">1950</option>
                          <option value="1951">1951</option>
                          <option value="1952">1952</option>
                          <option value="1953">1953</option>
                          <option value="1954">1954</option>
                          <option value="1955">1955</option>
                          <option value="1956">1956</option>
                          <option value="1957">1957</option>
                          <option value="1958">1958</option>
                          <option value="1959">1959</option>
                          <option value="1960">1960</option>
                          <option value="1961">1961</option>
                          <option value="1962">1962</option>
                          <option value="1963">1963</option>
                          <option value="1964">1964</option>
                          <option value="1965">1965</option>
                          <option value="1966">1966</option>
                          <option value="1967">1967</option>
                          <option value="1968">1968</option>
                          <option value="1969">1969</option>
                          <option value="1970">1970</option>
                          <option value="1971">1971</option>
                          <option value="1972">1972</option>
                          <option value="1973">1973</option>
                          <option value="1974">1974</option>
                          <option value="1975">1975</option>
                          <option value="1976">1976</option>
                          <option value="1977">1977</option>
                          <option value="1978">1978</option>
                          <option value="1979">1979</option>
                          <option value="1980">1980</option>
                          <option value="1981">1981</option>
                          <option value="1982">1982</option>
                          <option value="1983">1983</option>
                          <option value="1984">1984</option>
                          <option value="1985">1985</option>
                          <option value="1986">1986</option>
                          <option value="1987">1987</option>
                          <option value="1988">1988</option>
                          <option value="1989">1989</option>
                          <option value="1990">1990</option>
                          <option value="1991">1991</option>
                          <option value="1992">1992</option>
                          <option value="1993">1993</option>
                          <option value="1994">1994</option>
                          <option value="1995">1995</option>
                          <option value="1996">1996</option>
                          <option value="1997">1997</option>
                          <option value="1998">1998</option>
                          <option value="1999">1999</option>
                          <option value="2000">2000</option>
                          <option value="2001">2001</option>
                          <option value="2002">2002</option>
                          <option value="2003">2003</option>
                          <option value="2004">2004</option>
                          <option value="2005">2005</option>
                          <option value="2006">2006</option>
                          <option value="2007">2007</option>
                          <option value="2008">2008</option>
                          <option value="2009">2009</option>
                          <option value="2010">2010</option>
                          <option value="2011">2011</option>
                          <option value="2012">2012</option>
                          <option value="2013">2013</option>
                          <option value="2014">2014</option>
                          <option value="2015">2015</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="">Status</option>
                          <option value="For Sale">For Sale</option>
                          <option value="For Rent">For Rent</option>
                          <option value="For Sale and Rent">
                            For Sale and Rent
                          </option>
                          <option value="On Hold">On Hold</option>
                          <option value="Sold">Sold</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Price ($)</Form.Label>

                        <Form.Select
                          value={priceStatus}
                          onChange={(e) => setPriceStatus(e.target.value)}
                        >
                          <option value="">Price Status</option>
                          <option value={"Request Price"}>Request Price</option>
                          <option value={"Negotiable"}>Negotiable</option>
                          <option value={"Last Price"}>Last Price</option>
                          <option value={"On Price"}>On Price</option>
                        </Form.Select>

                        <Form.Control
                          defaultValue={0}
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          placeholder="Price"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Category</Form.Label>
                        <Form.Select
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option>Category</option>
                          <option value="Muscle Car">Muscle Car</option>
                          <option value="Motorcycle">Motorcyle</option>
                          <option value="Luxury Performance">
                            Luxury Performance
                          </option>
                          <option value="Future Classics">
                            Future Classics
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Rent Starting Price ($)</Form.Label>
                        <Form.Control
                          value={rentStartingPrice}
                          onChange={(e) => setRentStartingPrice(e.target.value)}
                          placeholder="Rent Starting Price"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {/* <Col lg={3} md={4} xs={6}>
                      <Form.Group className='mb-3'>
                        <Form.Label>Car Type</Form.Label>
                        <Form.Select
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value=''>Type</option>
                          <option value='Classic'>Classic</option>
                          <option value='Modern'>Modern</option>
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Body</Form.Label>
                        <Form.Select
                          value={body}
                          onChange={(e) => setBody(e.target.value)}
                        >
                          <option value="">Body</option>
                          <option value="Convertible">Convertible</option>
                          <option value="Roadster">Roadster</option>
                          <option value="Coupe">Coupe</option>
                          <option value="Estate Car">Estate Car</option>
                          <option value="Hatchback">Hatchback</option>
                          <option value="Minivan">Minivian</option>
                          <option value="MPV">MPV</option>
                          <option value="Off-Road">Off-Road</option>
                          <option value="Pickup">Pickup</option>
                          <option value="Sedan">Sedan </option>
                          <option value="Saloon">Saloon</option>
                          <option value="Single Seater">Single Seater</option>
                          <option value="SUV">SUV</option>
                          <option value="Limousine">Limousine</option>
                          <option value="Landualet">Landualet</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Exterior Color</Form.Label>
                        <Form.Select
                          disabled={colorsLoading}
                          value={exteriorColor}
                          onChange={(e) => setExteriorColor(e.target.value)}
                        >
                          {colorsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            <>
                              <option value="">Exterior Color</option>
                              <option value="Black">Black</option>
                              <option value="Blue">Blue</option>
                              <option value="Brown">Brown</option>
                              <option value="Burgundy">Burgundy</option>
                              <option value="Gold">Gold</option>
                              <option value="Green">Green</option>
                              <option value="Grey">Grey</option>
                              <option value="Multicolor">Multicolor</option>
                              <option value="Orange">Orange</option>
                              <option value="Other">Other</option>
                              <option value="Purple">Purple</option>
                              <option value="Red">Red</option>
                              <option value="Sandy">Sandy</option>
                              <option value="Silver">Silver</option>
                              <option value="Turquoise">Turquiose</option>
                              <option value="White">White</option>
                              <option value="Yellow">Yellow</option>
                              {colorsData.map((c) => {
                                return <option value={c.name}>{c.name}</option>;
                              })}
                            </>
                          )}
                        </Form.Select>
                        {!colorsLoading && (
                          <>
                            <Button
                              variant="outline-success"
                              className="w-100"
                              onClick={() =>
                                setShowExteriorColorForm(!showExteriorColorForm)
                              }
                            >
                              Create Color
                            </Button>
                            {showExteriorColorForm && (
                              <Form.Group className="shadow p-2">
                                <Form.Control
                                  value={colorName}
                                  onChange={(e) => setColorName(e.target.value)}
                                  placeholder="Color Name"
                                />
                                <Button onClick={createColor} variant="success">
                                  Add Color
                                </Button>

                                <Form.Select
                                  value={colorId}
                                  onChange={(e) => setColorId(e.target.value)}
                                >
                                  <option value="">Color To Delete</option>
                                  {colorsData.map((c) => {
                                    return (
                                      <option value={c._id}>{c.name}</option>
                                    );
                                  })}
                                </Form.Select>
                                <Button onClick={deleteColor} variant="danger">
                                  Delete Color
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    {/*  */}
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Interior Color</Form.Label>

                        <Form.Select
                          disabled={colorsLoading}
                          value={interiorColor}
                          onChange={(e) => setInteriorColor(e.target.value)}
                        >
                          {colorsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            <>
                              <option value="">Interior Color</option>
                              <option value="Black">Black</option>
                              <option value="Blue">Blue</option>
                              <option value="Brown">Brown</option>
                              <option value="Burgundy">Burgundy</option>
                              <option value="Gold">Gold</option>
                              <option value="Green">Green</option>
                              <option value="Grey">Grey</option>
                              <option value="Multicolor">Multicolor</option>
                              <option value="Orange">Orange</option>
                              <option value="Other">Other</option>
                              <option value="Purple">Purple</option>
                              <option value="Red">Red</option>
                              <option value="Sandy">Sandy</option>
                              <option value="Silver">Silver</option>
                              <option value="Turquoise">Turquiose</option>
                              <option value="White">White</option>
                              <option value="Yellow">Yellow</option>
                            </>
                          )}

                          {colorsData.map((c) => {
                            return <option value={c.name}>{c.name}</option>;
                          })}
                        </Form.Select>
                        {!colorsLoading && (
                          <>
                            <Button
                              variant="outline-success"
                              className="w-100"
                              onClick={() =>
                                setShowInteriorColorForm(!showInteriorColorForm)
                              }
                            >
                              Create Color
                            </Button>
                            {showInteriorColorForm && (
                              <Form.Group className="shadow p-2">
                                <Form.Control
                                  value={colorName}
                                  onChange={(e) => setColorName(e.target.value)}
                                  placeholder="Color Name"
                                />
                                <Button onClick={createColor} variant="success">
                                  Add Color
                                </Button>
                                <Form.Select
                                  value={colorId}
                                  onChange={(e) => setColorId(e.target.value)}
                                >
                                  <option value="">Color To Delete</option>
                                  {colorsData.map((c) => {
                                    return (
                                      <option value={c._id}>{c.name}</option>
                                    );
                                  })}
                                </Form.Select>
                                <Button onClick={deleteColor} variant="danger">
                                  Delete Color
                                </Button>
                              </Form.Group>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col lg={3} md={4} xs={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>Exterior Color</Form.Label>
                      <Form.Select
                        value={exteriorColor}
                        onChange={(e) => setExteriorColor(e.target.value)}
                      >
                        <option value=''>Exterior Color</option>
                        <option value='Black'>Black</option>
                        <option value='Blue'>Blue</option>
                        <option value='Brown'>Brown</option>
                        <option value='Burgundy'>Burgundy</option>
                        <option value='Gold'>Gold</option>
                        <option value='Green'>Green</option>
                        <option value='Grey'>Grey</option>
                        <option value='Multicolor'>Multicolor</option>
                        <option value='Orange'>Orange</option>
                        <option value='Other'>Other</option>
                        <option value='Purple'>Purple</option>
                        <option value='Red'>Red</option>
                        <option value='Sandy'>Sandy</option>
                        <option value='Silver'>Silver</option>
                        <option value='Turquoise'>Turquiose</option>
                        <option value='White'>White</option>
                        <option value='Yellow'>Yellow</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={3} md={4} xs={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>Interior Color</Form.Label>

                      <Form.Select
                        value={interiorColor}
                        onChange={(e) => setInteriorColor(e.target.value)}
                      >
                        <option value=''>Interior Color</option>
                        <option value='Black'>Black</option>
                        <option value='Blue'>Blue</option>
                        <option value='Brown'>Brown</option>
                        <option value='Burgundy'>Burgundy</option>
                        <option value='Gold'>Gold</option>
                        <option value='Green'>Green</option>
                        <option value='Grey'>Grey</option>
                        <option value='Multicolor'>Multicolor</option>
                        <option value='Orange'>Orange</option>
                        <option value='Other'>Other</option>
                        <option value='Purple'>Purple</option>
                        <option value='Red'>Red</option>
                        <option value='Sandy'>Sandy</option>
                        <option value='Silver'>Silver</option>
                        <option value='Turquoise'>Turquiose</option>
                        <option value='White'>White</option>
                        <option value='Yellow'>Yellow</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Doors</Form.Label>
                        <Form.Select
                          value={doors}
                          onChange={(e) => setDoors(e.target.value)}
                          placeholder="Doors"
                        >
                          <option value="0">Doors</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group>
                        <Form.Label>Condition</Form.Label>
                        <Form.Select
                          value={condition}
                          onChange={(e) => setCondition(e.target.value)}
                        >
                          <option value="">Condition</option>
                          <option value="Damaged">Damaged</option>
                          <option value="For Parts">For Parts</option>
                          <option value="New">New</option>
                          <option value="Original">Original</option>
                          <option value="Other">Other</option>
                          <option value="Replica">Replica</option>
                          <option value="Restored">Restored</option>
                          <option value="Used">Used</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Registered Country</Form.Label>
                        <Form.Select
                          value={firstRegisteredCountry}
                          onChange={(e) =>
                            setFirstRegisteredCountry(e.target.value)
                          }
                        >
                          <option value="">First Registered Country</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Aland Islands">Aland Islands</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire, Sint Eustatius and Saba">
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos (Keeling) Islands">
                            Cocos (Keeling) Islands
                          </option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, Democratic Republic of the Congo">
                            Congo, Democratic Republic of the Congo
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curacao">Curacao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands (Malvinas)">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-Bissau">Guinea-Bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard Island and Mcdonald Islands">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="Holy See (Vatican City State)">
                            Holy See (Vatican City State)
                          </option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran, Islamic Republic of">
                            Iran, Islamic Republic of
                          </option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jersey">Jersey</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="Korea, Republic of">
                            Korea, Republic of
                          </option>
                          <option value="Kosovo">Kosovo</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libyan Arab Jamahiriya">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option value="Macedonia, the Former Yugoslav Republic of">
                            Macedonia, the Former Yugoslav Republic of
                          </option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia, Federated States of">
                            Micronesia, Federated States of
                          </option>
                          <option value="Moldova, Republic of">
                            Moldova, Republic of
                          </option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montenegro">Montenegro</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Netherlands Antilles">
                            Netherlands Antilles
                          </option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestinian Territory, Occupied">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russian Federation">
                            Russian Federation
                          </option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Saint Barthelemy">
                            Saint Barthelemy
                          </option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Martin">Saint Martin</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and the Grenadines">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Serbia and Montenegro">
                            Serbia and Montenegro
                          </option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Sint Maarten">Sint Maarten</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="South Georgia and the South Sandwich Islands">
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value="South Sudan">South Sudan</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen">
                            Svalbard and Jan Mayen
                          </option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syrian Arab Republic">
                            Syrian Arab Republic
                          </option>
                          <option value="Taiwan, Province of China">
                            Taiwan, Province of China
                          </option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                          </option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-Leste">Timor-Leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="United States Minor Outlying Islands">
                            United States Minor Outlying Islands
                          </option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Viet Nam">Viet Nam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.s.">
                            Virgin Islands, U.s.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          value={address}
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Select
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          placeholder="City"
                        >
                          <option value="">City</option>
                          <option value="Baabda">Baabda</option>
                          <option value="Chouf">Chouf</option>
                          <option value="Aley">Aley</option>
                          <option value="Bekaa">Bekaa</option>
                          <option value="Metn">Men</option>
                          <option value="Beirut">Beirut</option>
                          <option value="South">South</option>
                          <option value="North">North</option>
                          <option value="Jbeil">Jbeil</option>
                          <option value="Keserouan">Keserouan</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="">country</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Aland Islands">Aland Islands</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire, Sint Eustatius and Saba">
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos (Keeling) Islands">
                            Cocos (Keeling) Islands
                          </option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, Democratic Republic of the Congo">
                            Congo, Democratic Republic of the Congo
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curacao">Curacao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands (Malvinas)">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-Bissau">Guinea-Bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard Island and Mcdonald Islands">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="Holy See (Vatican City State)">
                            Holy See (Vatican City State)
                          </option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran, Islamic Republic of">
                            Iran, Islamic Republic of
                          </option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jersey">Jersey</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="Korea, Republic of">
                            Korea, Republic of
                          </option>
                          <option value="Kosovo">Kosovo</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libyan Arab Jamahiriya">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option value="Macedonia, the Former Yugoslav Republic of">
                            Macedonia, the Former Yugoslav Republic of
                          </option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia, Federated States of">
                            Micronesia, Federated States of
                          </option>
                          <option value="Moldova, Republic of">
                            Moldova, Republic of
                          </option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montenegro">Montenegro</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Netherlands Antilles">
                            Netherlands Antilles
                          </option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestinian Territory, Occupied">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russian Federation">
                            Russian Federation
                          </option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Saint Barthelemy">
                            Saint Barthelemy
                          </option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Martin">Saint Martin</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and the Grenadines">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Serbia and Montenegro">
                            Serbia and Montenegro
                          </option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Sint Maarten">Sint Maarten</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="South Georgia and the South Sandwich Islands">
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value="South Sudan">South Sudan</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen">
                            Svalbard and Jan Mayen
                          </option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syrian Arab Republic">
                            Syrian Arab Republic
                          </option>
                          <option value="Taiwan, Province of China">
                            Taiwan, Province of China
                          </option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                          </option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-Leste">Timor-Leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="United States Minor Outlying Islands">
                            United States Minor Outlying Islands
                          </option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Viet Nam">Viet Nam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.s.">
                            Virgin Islands, U.s.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h1 class="display-3">Specifications</h1>

                  <Row>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Gears</Form.Label>
                        <Form.Select
                          value={gears}
                          onChange={(e) => setGears(e.target.value)}
                          placeholder="Gears"
                        >
                          <option value="0">Gears</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Cylinders</Form.Label>
                        <Form.Select
                          value={cylinders}
                          onChange={(e) => setCylinders(e.target.value)}
                          placeholder="Cylinder"
                        >
                          <option value="0">Cylinders </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Chassis Number (VIN)</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Chassis Number (VIN)"
                          value={chassisNumber}
                          onChange={(e) => setChassisNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Horsepower</Form.Label>
                        <Form.Control
                          defaultValue={0}
                          type="number"
                          value={horsepower}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => setHorsepower(e.target.value)}
                          placeholder="Horsepower"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Engine Size(L)</Form.Label>
                        <Form.Control
                          defaultValue={0}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          value={engineSize}
                          onChange={(e) => setEngineSize(e.target.value)}
                          placeholder="Engine Size"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Transmission</Form.Label>
                        <Form.Select
                          value={transmission}
                          onChange={(e) => setTransmission(e.target.value)}
                        >
                          <option value="">Transmission</option>
                          <option value="Automatic">Automatic</option>
                          <option value="Manual">Manual</option>
                          <option value="Semi-automatic">Semi-automatic</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Engine Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Engine Number"
                          value={engineNumber}
                          onChange={(e) => setEngineNumber(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Legal Papers</Form.Label>
                        <Form.Select
                          value={legalPapers}
                          onChange={(e) => setLegalPapers(e.target.value)}
                        >
                          <option value="">Legal Papers</option>
                          <option value="Yes">Available</option>
                          <option value="No">Not Available</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Odometer(Km)</Form.Label>
                        <Form.Control
                          defaultValue={0}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          placeholder="Odometer"
                          value={odometer}
                          onChange={(e) => setOdometer(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Steering Wheel</Form.Label>
                        <Form.Select
                          value={steeringWheel}
                          onChange={(e) => setSteeringWheel(e.target.value)}
                        >
                          <option value="">Steering Wheel</option>
                          <option value="LHD">LHD</option>
                          <option value="RHD">RHD</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Drive Wheel</Form.Label>
                        <Form.Select
                          value={driveWheels}
                          onChange={(e) => setDriveWheels(e.target.value)}
                        >
                          <option value="">Drive Wheel</option>
                          <option value="FWD">FWD</option>
                          <option value="RWD">RWD</option>
                          <option value="AWD">AWD</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Fuel Type</Form.Label>
                        <Form.Select
                          value={fuelType}
                          onChange={(e) => setFuelType(e.target.value)}
                        >
                          <option value="">Fuel Type</option>
                          <option value="Petrol">Petrol</option>
                          <option value="Diesel">Diesel</option>
                          <option value="Electric">Electric</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h1 className="display-3">Badges</h1>
                  <div className="row w-100">
                    <div className="col-8">
                      <Form.Group className="mb-3 w-100">
                        <Form.Select
                          value={badgeInput}
                          onChange={(e) => setBadgeInput(e.target.value)}
                        >
                          <option value="">Badge</option>
                          <option value="Luxury">Luxury</option>
                          <option value="Like-New">Like-New</option>
                          <option value="Bargain">Bargain</option>
                          <option value="Rare">Rare</option>
                          <option value="Popular">Popular</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <button
                        className="w-100 btn btn-success"
                        onClick={() => {
                          if (badgeInput) {
                            setBadge((badge) => [...badge, badgeInput]);
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>

                    <div className="col-12">
                      <ul>
                        {badge.map((b) => {
                          return (
                            <li>
                              {b}{" "}
                              <button
                                onClick={() => {
                                  let temp = badge.filter((item) => item !== b);
                                  setBadge(temp);
                                }}
                                className="btn btn-danger btn-sm"
                              >
                                <i className="fa-solid fa-x"></i>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <h1 className="display-3">Description</h1>
                  <div
                    className="col-12"
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "500px",
                    }}
                  >
                    <ReactQuill
                      className="w-100"
                      value={description}
                      onChange={setDescription}
                      theme="snow"
                    />
                  </div>

                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      updateCar();
                    }}
                    variant="primary"
                    className="w-100"
                  >
                    Edit Car
                  </Button>
                </div>
              </div>
            </>
          )}
        </Container>
      </Main>
    </>
  );
}

export default EditCarForm;
