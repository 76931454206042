import { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {
  Card,
  Container,
  Form,
  ListGroup,
  Pagination,
  Row,
  Spinner
} from "react-bootstrap"
import { Map, Marker } from "react-map-gl"
import { useNavigate } from "react-router-dom"
import restorerApi from "../api/restorerApi"
import Choose from "../components/controlStatements/Choose"
import Otherwise from "../components/controlStatements/Otherwise"
import When from "../components/controlStatements/When"
import Main from "../components/Main/Main"
import RestorerCard from "../components/Restorer"
import SearchBar from "../components/SearchBar/SearchBar"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
// import Map from "../components/Map/Create"

export default function Restorer() {
  const navigate = useNavigate()
  const [restorers, setRestorers] = useState([])
  const [loading, setLoading] = useState()
  const [pages, setPages] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [searchText, setSearchText] = useState("")

  const onSearchSubmit = async (term) => {
    // console.log("new search submit", term)
    try {
      setLoading(true)

      const data = await restorerApi.searchRestorers({
        search: term,
        type
      })
      setRestorers(data.restorers)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
      // setLoading({ state: false, message: "" })
    }
  }

  const searchPagination = async (term, page = 1) => {
    try {
      setLoading(true)

      const data = await restorerApi.searchRestorers({
        search: term,
        page
      })
      setRestorers(data.restorers)
      setPages(data.pages)
      setCurrentPage(data.currentPage)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const getRestorers = async () => {
    try {
      setLoading(true)
      const data = await restorerApi.getRestorers()
      setRestorers(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const deleteRestorer = async (data) => {
    await restorerApi.deleteRestorer(data)
    getRestorers()
    notify.success("Restorer deleted succesfully")
  }
  const [type, setType] = useState()

  async function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(restorers)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setRestorers(items)

    // setLoading({ state: true, message: "Loading cars..." })
    setLoading(true)
    try {
      await restorerApi.updateRestorerPriority({ restorers: items, type })
    } catch (error) {
      notify.error(errorMessage(error))
    }
    setLoading(false)
    // setLoading({ state: false, message: "" })
  }

  useEffect(() => {
    // getRestorers()
    onSearchSubmit()
  }, [type])

  return (
    <Main>
      <Container>
        <button
          className='btn btn-primary'
          onClick={() => navigate("/restorer/create")}
        >
          Create Restorer
        </button>
        <div className='row'>
          <div className='col-8'>
            <SearchBar
              placeholder={"Search for restorers..."}
              onSearchSubmit={(term) => {
                onSearchSubmit(term)
                setSearchText(term)
              }}
            />
          </div>
          <div className='col-4'>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value=''>Type</option>
              <option value='promoted'>Promoted</option>
            </Form.Select>
          </div>
          <div className='col-12'>
            <Choose>
              <When condition={type === "promoted"}>
                <Card>
                  <Card.Header>Featured</Card.Header>
                  <Choose>
                    <When condition={loading}>
                      <Spinner animation='border' />
                    </When>
                    <Otherwise>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='cars'>
                          {(provided) => (
                            <ListGroup
                              variant='flush'
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {restorers.map((r, index) => {
                                return (
                                  <Draggable
                                    key={r._id}
                                    draggableId={r._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <ListGroup.Item
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className='characters-thumb'>
                                          <span>{r.name}</span>
                                        </div>
                                      </ListGroup.Item>
                                    )}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </ListGroup>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Otherwise>
                  </Choose>
                </Card>
              </When>
              <Otherwise></Otherwise>
            </Choose>
          </div>
        </div>

        <Choose>
          <When condition={loading}>Loading...</When>
          <When condition={restorers.length == 0 && !loading}>
            No Restorers found!
          </When>
          <Otherwise>
            <div className='row'>
              {restorers.map((restorer) => {
                return (
                  <div className='col-6'>
                    <RestorerCard
                      onDelete={deleteRestorer}
                      restorer={restorer}
                    />
                  </div>
                )
              })}
            </div>
            <Pagination className='mt-5'>
              {/* <Pagination.Item>Prev</Pagination.Item> */}
              {[...Array(pages)].map((p, index) => {
                return (
                  <Pagination.Item
                    onClick={() => searchPagination(searchText, index + 1)}
                    active={currentPage === index + 1}
                  >
                    {index + 1}
                  </Pagination.Item>
                )
              })}
            </Pagination>
          </Otherwise>
        </Choose>
      </Container>
    </Main>
  )
}
