import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop
} from "react-image-crop"
import { canvasPreview } from "./canvasPreview"
import { useDebounceEffect } from "./useDebounceEffect"

import "react-image-crop/dist/ReactCrop.css"
import { Button } from "react-bootstrap"
import { API_URLS } from "../../constants/routes"

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export default function AddImage({
  setDisplayedImages,
  setInputImages,
  file,
  handleCloseCropModal,
  setShowCropModal,
  index,
  carId,
  getImages
}) {
  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState()
  const [image, setImage] = useState()

  const uploadImage = async () => {
    const formData = new FormData()
    formData.append("image", file)
    formData.append("index", index)
    formData.append("carId", carId)

    await axios.post(API_URLS.EDIT_IMAGE, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })

    getImages()
  }

  function onSelectFile() {
    if (file) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      )
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    onSelectFile()
  }, [])
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }
  const formdata = new FormData()

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
        const canva = previewCanvasRef.current
        canva.toBlob((blob) => {
          // setImages((images) => [...images, blob])
          setImage(blob)
          console.log(blob)
        }, "image/jpeg")
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }

  const uploadImages = async () => {
    const data = formdata.get("image")
    const res = await axios.post("http://localhost:5000/car/upload", formdata, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })

    return res.data
  }

  return (
    <div className='App'>
      <div className='Crop-Controls'>
        <div>
          <label htmlFor='scale-input'>Scale: </label>
          <input
            id='scale-input'
            type='range'
            step='0.1'
            min='0'
            max='10'
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor='rotate-input'>Rotate: </label>
          <input
            id='rotate-input'
            type='range'
            step='15'
            min='0'
            max='180'
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
        {/* <div>
          <button onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? "off" : "on"}
          </button>
        </div> */}
      </div>
      <div
        style={{ width: "300px", border: "1px solid #111" }}
        className='d-flex justify-content-center align-items-center w-100 mb-2'
      >
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt='Crop me'
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </div>
      <div>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height
            }}
          />
        )}
      </div>
      {/* 
      <Button
        onClick={() => {
          uploadImage()
        }}
      >
        Upload
      </Button> */}
      <Button
        className='w-100'
        onClick={() => {
          // console.log(inputImages)
          console.log(file)
          setInputImages((inputImages) =>
            inputImages.filter((f) => f.name !== file.name)
          )
          setDisplayedImages((displayedImages) =>
            displayedImages.filter((f) => f != file)
          )

          setInputImages((inputImages) => [...inputImages, image])
          setDisplayedImages((displayedImages) => [...displayedImages, image])
          setShowCropModal(false)
        }}
      >
        Crop
      </Button>
    </div>
  )
}
