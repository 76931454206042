import axios from "axios"
import { API_URLS } from "../constants/routes"

const createCar = async (data) => {
  const response = await axios.post(API_URLS.CREATE_CAR, data)
  return response.data
}
const checkOffer = async (data) => {
  const response = await axios.post(API_URLS.CHECK_CAR_OFFER, data)
  return response.data
}
const checkPrivate = async (data) => {
  const response = await axios.post(API_URLS.CHECK_CAR_PRIVATE, data)
  return response.data
}
const switchCarImages = async (data) => {
  const response = await axios.post(API_URLS.SWITCH_IMAGES, data)
  return response.data
}
const checkFeatured = async (data) => {
  const response = await axios.post(API_URLS.CHECK_CAR_FEATURED, data)
  return response.data
}
const checkPhone = async (data) => {
  const response = await axios.post(API_URLS.CHECK_PHONE, data)
  return response.data
}
const deleteCar = async (data) => {
  const response = await axios.post(API_URLS.DELETE_CAR, data)
  return response.data
}
const getCars = async () => {
  const response = await axios.post(API_URLS.GET_CARS)
  return response.data
}

const getCarPriority = async (data) => {
  const res = await axios.post(API_URLS.GET_CAR_PRIORITY, data)
  return res.data
}

const getCarById = async (data) => {
  const response = await axios.post(API_URLS.GET_CAR_BY_ID, data)
  return response.data
}

const checkDiscount = async (data) => {
  const response = await axios.post(API_URLS.CHECK_DISCOUNT, data)
  return response.data
}

const checkPromoted = async (data) => {
  const response = await axios.post(API_URLS.CHECK_PROMOTED, data)
  return response.data
}
const checkRentPromoted = async (data) => {
  const response = await axios.post(API_URLS.CHECK_CAR_PROMOTED_RENT, data)
  return response.data
}
const checkVerified = async (data = {}) => {
  const response = await axios.post(API_URLS.CHECK_VERIFIED, data)
  return response.data
}
const confirmCar = async (data = {}) => {
  const res = await axios.post(API_URLS.CONFIRM_CAR, data)
  return res.data
}
const checkRentFeatured = async (data) => {
  const response = await axios.post(API_URLS.CHECK_CAR_FEATURED_RENT, data)
  return response.data
}
const addDiscount = async (data) => {
  const response = await axios.post(API_URLS.ADD_DISCOUNT, data)
  return response.data
}
const updateCarById = async (data) => {
  const response = await axios.put(API_URLS.UPDATE_CAR_BY_ID, data)
  return response.data
}

const setCarFeaturedPriority = async (data) => {
  const res = await axios.post(API_URLS.SET_CAR_FEATURED_PRIORITY, data)
  return res.data
}
const setCarRentFeaturedPriority = async (data) => {
  const res = await axios.post(API_URLS.SET_CAR_RENT_FEATURED_PRIORITY, data)
  return res.data
}

const checkBestOffer = async (data = {}) => {
  const res = await axios.post(API_URLS.CHECK_BEST_OFFER, data)
  return res.data
}
const setCarRentPromotedPriority = async (data) => {
  const res = await axios.post(API_URLS.SET_CAR_RENT_PROMOTED_PRIORITY, data)
  return res.data
}
const setCarPromotedPriority = async (data) => {
  const res = await axios.post(API_URLS.SET_CAR_PROMOTED_PRIORITY, data)
  return res.data
}

const setCarLatestPriority = async (data) => {
  const res = await axios.post(API_URLS.SET_CAR_LATEST_PRIORITY, data)
  return res.data
}
const updatePriority = async (data = {}) => {
  const res = await axios.post(API_URLS.UPDATE_PRIORITY, data)
  return res.data
}
const carApi = {
  checkBestOffer,
  setCarFeaturedPriority,
  setCarPromotedPriority,
  setCarLatestPriority,
  createCar,
  getCars,
  deleteCar,
  checkOffer,
  checkFeatured,
  checkPrivate,
  getCarById,
  updateCarById,
  checkDiscount,
  checkPhone,
  switchCarImages,
  addDiscount,
  checkPromoted,
  checkVerified,
  getCarPriority,
  checkRentFeatured,
  checkRentPromoted,
  setCarRentFeaturedPriority,
  setCarRentPromotedPriority,
  updatePriority,
  confirmCar
}

export default carApi
