import Choose from "../components/controlStatements/Choose"
import When from "../components/controlStatements/When"
import Otherwise from "../components/controlStatements/Otherwise"

import { useEffect } from "react"
import Car from "../components/Car/Car"
import { useState } from "react"
import carApi from "../api/carApi"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

import sellerApi from "../api/sellerApi"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "react-bootstrap"
function SellerCarList() {
  const [cars, setCars] = useState([])
  const [loading, setLoading] = useState({ state: false, message: "" })
  const { sellerId } = useParams()
  const [sellerName, setSellerName] = useState("")
  const navigate = useNavigate()
  const getSellerCars = async () => {
    try {
      setLoading({ state: true, message: "Loading cars..." })
      const data = await sellerApi.getSellerCars({ sellerId })

      setCars(data.cars)
      setSellerName(data.firstName + " " + data.lastName)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "" })
    }
  }

  const deleteCar = async (id) => {
    try {
      setLoading({ state: true, message: "Deleting car..." })
      await carApi.deleteCar(id)
      notify.success("Car deleted successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading({ state: false, message: "" })
      getSellerCars()
    }
  }

  useEffect(() => {
    getSellerCars()
  }, [])

  const renderedCars = cars.map((c) => (
    <Car key={c._id} onDelete={deleteCar} carDetails={c} />
  ))

  return (
    <>
      <div className='d-flex align-items-start w-100'>
        <Button onClick={() => navigate(-1)}>Back</Button>
      </div>
      <h2>{sellerName + " Cars"}</h2>
      {/* <SearchBar onSearchSubmit={(term) => onSearchSubmit(term)} /> */}
      <Choose>
        <When condition={loading.state}>
          <h2 style={{ textAlign: "center" }}>{loading.message}</h2>
        </When>
        <Otherwise>
          <Choose>
            <When condition={cars.length == 0}>No cars found!</When>
            <Otherwise>{renderedCars}</Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </>
  )
}

export default SellerCarList
