export const API_ROUTES = {
  POST: {
    CREATE_MACHINERY: "/machinery/create",
    GET_MACHINARY: "/machinery",
    EDIT_MACHINERY: "/machinery/edit",
    DELETE_MACHINERY: "/machinery/delete",
    CHECK_PRIVATE: "/machinery/check/private",
    GET_MACHINERY_BY_ID: "/machinery/id"
  }
}
