// import FeedbackCard from "../components/Feedback/FeedbackCard";
import { useEffect, useState } from "react"
import { Container, Pagination } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import contactUsApi from "../api/contactUsApi"
import formApi from "../api/formApi"
import ContactUsCard from "../components/ContactUs/ContactUsCard"
import Choose from "../components/controlStatements/Choose"
import Otherwise from "../components/controlStatements/Otherwise"
import When from "../components/controlStatements/When"
import Main from "../components/Main/Main"
import SearchBar from "../components/SearchBar/SearchBar"
import Header from "../layouts/Header"
// import * as Icon from "react-bootstrap-icons"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

function ContactUs() {
  const { employee } = useSelector((state) => state.employee)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [forms, setForms] = useState([])
  const [subject, setSubject] = useState()

  const [sorted, setSorted] = useState("")
  const [checked, setChecked] = useState()
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.contactUsAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState()
  const onSearchSubmit = async (term = "") => {
    try {
      setLoading(true)
      const data = await contactUsApi.searchForms({
        search: term,
        subject,
        sorted,
        checked,
        page: currentPage
      })
      setForms(data.forms)
      setCurrentPage(data.currentPage)
      setPages(data.pages)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    authorizeEmployee()
    onSearchSubmit()
  }, [subject, sorted, checked, currentPage])

  return (
    <>
      <Main>
        {/* <h1 className='mt-3 text-center text-primary'>Contact Us</h1> */}
        <Container>
          <SearchBar
            placeholder={"Seach contact us forms here..."}
            onSearchSubmit={(term) => onSearchSubmit(term)}
          />
          <div>
            <select
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value)
              }}
            >
              <option value=''>Subject</option>
              <option value='Car Request'>Car Request</option>
              <option value='Selling Privately'>Selling Privately</option>
              <option value='Rent'>Rent</option>
              <option value='Appraisal'>Appraisal</option>
              <option value='Transportation'>Transportation</option>
              <option value='Consultancy'>Consultancy</option>
              <option value='Restoration'>Restoration</option>
              <option value='Insurance'>Insurance</option>
              <option value='Other'>Other</option>
            </select>

            <select value={sorted} onChange={(e) => setSorted(e.target.value)}>
              <option value=''>Default Order</option>
              <option value='descending'>Latest</option>
              <option value='ascending'>Oldest</option>
            </select>
            <select
              value={checked}
              onChange={(e) => setChecked(e.target.value)}
            >
              <option value=''>All Forms</option>
              <option value='checked'>Checked Forms</option>
              <option value='notChecked'>Not Checked Forms</option>
            </select>
          </div>
          <div className='d-flex justify-content-center align-items-center mt-5 flex-column gap-3 mb-3'>
            <Choose>
              <When condition={loading}>
                <h5>Loading forms...</h5>
              </When>
              <Otherwise>
                {forms.map((c) => (
                  <ContactUsCard form={c} key={c._id} />
                ))}
              </Otherwise>
            </Choose>
          </div>

          <div className='w-100 d-flex justify-content-center align-items-center'>
            <Choose>
              <When condition={loading}>
                <>...</>
              </When>
              <Otherwise>
                <Pagination className='mt-5'>
                  {[...Array(pages)].map((p, index) => {
                    return (
                      <Pagination.Item
                        onClick={() => setCurrentPage(index + 1)}
                        active={currentPage === index + 1}
                      >
                        {index + 1}
                      </Pagination.Item>
                    )
                  })}
                </Pagination>
              </Otherwise>
            </Choose>
          </div>
        </Container>
      </Main>
    </>
  )
}
export default ContactUs
