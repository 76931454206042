module.exports.hull_material = [
  "Fiberglass",
  "Aluminum",
  "Steel",
  "Wood",
  "Composite",
  "Inflatable",
  "Ferrocement",
  "Carbon Fiber",
  "Some boats may be made of combination"
]
