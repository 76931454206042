module.exports.boat_type = [
  "Aft Cabin",
  "Aluminum Fish",
  "Antique and classic ( Power )",
  "Barge ( Power )",
  "Bass",
  "Bay",
  "Bowrider",
  "Canal and River Cruiser",
  "Cargo Ship",
  "Center Console",
  "Commercial Boat ( Power )",
  "Convertible",
  "Cruise Ship",
  "Cruiser ( Power )",
  "Cuddy Cabin",
  "Deck Boat",
  "Dinghy ( Power )",
  "Dive",
  "Downeast",
  "Dragger",
  "Dual Console",
  "Express Cruiser",
  "Flats",
  "Flybridy",
  "Freshwater Fishing",
  "Gulet ( Power )",
  "High Performance",
  "House Boat",
  "Inflatable",
  "Jet",
  "Jon",
  "Llaut",
  "Lobster",
  "Mega Yacht",
  "Motor Yacht",
  "Motorsailer ( Power )",
  "Narrow Boat",
  "Other ( Power )",
  "Passenger ",
  "Personal Watercraft",
  "Pilothouse",
  "Pontoon",
  "Power Catamaran",
  "Rigid Inflatable Boats ( RIB)",
  "Runabout",
  "Saltwater Fishing",
  "Ski and Fish",
  "Ski and Wakeboard Boat",
  "Skiff",
  "Sloop",
  "Sport Fishing",
  "Sports Cruiser",
  "Tender ( Power )",
  "Trawler",
  "Troller",
  "Tug",
  "Unspecified ( Power )",
  "Utility",
  "Walkaround",
  "All SailBoats",
  "Barge ( Sail )",
  "Beach Catarman",
  "Catarman",
  "Center Cockpit",
  "Commercial Boat ( Sail )",
  "Cruiser ( Sail )",
  "Cruiser / Racer",
  "Cutter",
  "Daysailer",
  "Deck Saloon",
  "Dinghy ( Sale )",
  "Gulet ( Sail )",
  "Ketch",
  "Motorsailer ( Sail )",
  "Multi-Hull",
  "Other ( Sail )",
  "Performance Sailboat",
  "Pilothouse ( Sail )",
  "Racer",
  "Schooner",
  "Sloop",
  "Trimaran",
  "Unspecified ( Sail )",
  "Yawl",
  "All Unpowered Boats",
  "Dinghy ( Unpowered )",
  "Kayak",
  "Other ( Unpowered )",
  "Tender ( Unpowered )"
]
