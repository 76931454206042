import { Card } from "react-bootstrap"
import ReactSwitch from "react-switch"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import machineryApi from "../../api/machinery"
export default function MachinerCard({ machinery }) {
  const [privateCheck, setPrivateCheck] = useState(machinery?.private)
  const [showDescription, setShowDescription] = useState(false)

  const [showDetails, setShowDetails] = useState(false)
  const navigate = useNavigate()

  const [loadingCheckingPrivate, setLoadingCheckingPrivate] = useState(false)
  const checkPrivate = async () => {
    try {
      setLoadingCheckingPrivate(true)
      await machineryApi.checkPrivate({ machineryId: machinery._id })
      setPrivateCheck(!privateCheck)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingCheckingPrivate(false)
    }
  }
  const variables = [
    {
      label: "Type",
      value: "type"
    },
    {
      label: "Brand",
      value: "brand"
    },
    {
      label: "Model",
      value: "model"
    },
    {
      label: "Year",
      value: "year"
    },
    {
      label: "Color",
      value: "color"
    },
    {
      label: "Condition",
      value: "condition"
    },
    {
      label: "Engine Type",
      value: "engineType"
    },
    {
      label: "Number of Wheels",
      value: "numberOfWheels"
    },
    {
      label: "Number of Axes",
      value: "numberOfAxes"
    },
    {
      label: "Fuel Capacity (L)",
      value: "fuelCapacity"
    },
    {
      label: "Max Speed",
      value: "maxSpeed"
    },
    {
      label: "Weight",
      value: "weight"
    },
    {
      label: "Special Features",
      value: "specialFeatures"
    }
  ]

  return (
    <Card className='mt-3 w-100'>
      <div className='p-3'>
        <div className='d-flex justify-content-center align-items-center'>
          <div>
            <Card.Title>{machinery?.brand + " " + machinery?.model}</Card.Title>
            <span class='text-muted'>{machinery?.createdAt}</span>
            <div className='text-center my-2'>
              <h6>Private</h6>
              {loadingCheckingPrivate ? (
                "Loading..."
              ) : (
                <ReactSwitch
                  checked={privateCheck}
                  onChange={(e) => {
                    checkPrivate()
                  }}
                />
              )}
            </div>
            <div className='d-flex justify-content-center align-items-center gap-2'>
              <button
                className='btn btn-primary'
                onClick={() => navigate(`/machinery/${machinery?._id}/edit`)}
              >
                Edit
              </button>
              <button className='btn btn-danger'>Delete</button>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setShowDescription(!showDescription)
        }}
        className='mb-1 btn btn-outline btn-dark'
      >
        {!showDescription ? "Show Description" : "Hide Description"}
      </button>
      {showDescription && (
        <p dangerouslySetInnerHTML={{ __html: machinery?.description }}></p>
      )}
      <button
        onClick={() => {
          setShowDetails(!showDetails)
        }}
        className='mb-1 btn btn-outline btn-dark'
      >
        {!showDetails ? "Show Details" : "Hide Details"}
      </button>
      {showDetails && (
        <div className='row row-cols-2 p-2'>
          {variables?.map((variable) => {
            return (
              <h6>
                <span className='text-success fw-bold'>{variable?.label}</span>{" "}
                {machinery[variable?.value]}
              </h6>
            )
          })}
        </div>
      )}
    </Card>
  )
}
