import { Envelope, Telephone, Alarm } from "react-bootstrap-icons"
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from "react-bootstrap"
import ReactSwitch from "react-switch"
import { useState } from "react"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import Date from "../Date/Date"
import sellCarApi from "../../api/sellCarApi"
import ipAddressApi from "../../api/ipAddressApi"
import Choose from "../controlStatements/Choose"
import Otherwise from "../controlStatements/Otherwise"
import When from "../controlStatements/When"
function SalesCard({ form, blocked, blockIp, loadingIp, unblockIp }) {
  const [checked, setChecked] = useState(form.checked)
  const [loading, setLoading] = useState(false)

  const [showCarInfo, setShowCarInfo] = useState(false)

  const checkForm = async () => {
    try {
      setLoading(true)
      await sellCarApi.checkForm({ sellCarFormId: form._id })
      notify.success("Form checked successfully")
      setChecked(!checked)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }
  const handleCheckButton = async () => {
    await checkForm()
  }

  return (
    <>
      <Card className='mt-3 w-100'>
        <div className='d-flex justify-content-between align-items-center p-3'>
          <Card.Body>
            <div className='text-center w-100 h3 text-success'>
              {form?.type?.toUpperCase()}
            </div>
            <section className='w-100 d-flex justify-content-between align-items-center'>
              <Card.Title>{form.firstName + " " + form.lastName}</Card.Title>

              {loading ? (
                <span>Loading...</span>
              ) : (
                <div className='d-flex justify-content-center align-items-center flex-column '>
                  <h6>Checked</h6>
                  <ReactSwitch checked={checked} onChange={handleCheckButton} />
                </div>
              )}
            </section>

            <Row>
              <Col md={12} xs={12}>
                Cars To Sell: {form.carsToSell.length} cars
              </Col>
            </Row>

            <Button
              onClick={() => setShowCarInfo(!showCarInfo)}
              variant='outline-primary w-100'
            >
              Show Cars Info
            </Button>
            {showCarInfo &&
              form.carsToSell.map((c, index) => {
                return (
                  <>
                    <h3>Car {index + 1}</h3>
                    <Row className='p-3'>
                      <Col md={4} xs={6}>
                        <span className='fw-bold'>Brand: </span>
                        {c.brand}
                      </Col>
                      <Col md={4} xs={6}>
                        <span className='fw-bold'>Model: </span> {c.model}
                      </Col>
                      <Col md={4} xs={6}>
                        <span className='fw-bold'>Year: </span> {c.year}
                      </Col>

                      <Col md={4} xs={6}>
                        <span className='fw-bold'>Condition: </span>
                        {c.condition}
                      </Col>
                      <Col md={4} xs={6}>
                        <span className='fw-bold'>Address: </span>
                        {c.address}
                      </Col>
                      <Col md={4} xs={6}>
                        <span className='fw-bold'>Country: </span>
                        {c.country}
                      </Col>
                      <Col md={12} xs={12}>
                        <span className='fw-bold'>Description:</span>
                        {c.description}
                      </Col>
                    </Row>
                  </>
                )
              })}
          </Card.Body>
        </div>

        <ListGroup className='list-group-flush'>
          <ListGroup.Item>
            Ip Address: {form?.ip}
            <Button
              disabled={loadingIp}
              onClick={!blocked ? blockIp : unblockIp}
            >
              <Choose>
                <When condition={loadingIp}>
                  <>Loading...</>
                </When>
                <Otherwise>
                  {blocked ? "UnBlock Ip Address" : "Block Ip Address"}{" "}
                </Otherwise>
              </Choose>
            </Button>{" "}
          </ListGroup.Item>
          <ListGroup.Item>
            {" "}
            <Envelope /> {form.email}{" "}
          </ListGroup.Item>
          <ListGroup.Item>
            <Telephone /> {form.countryCode + " " + form.phone}{" "}
          </ListGroup.Item>
          <ListGroup.Item>
            <Alarm />
            <Date time={form.createdAt} />
          </ListGroup.Item>
        </ListGroup>

        {/* <div className='d-flex justify-content-center align-items-center flex-column'>
              <h6>Checked</h6>
              {loading ? (
                "Loading..."
              ) : (
                <ReactSwitch checked={checked} onChange={handleCheckButton} />
              )}
            </div> */}
      </Card>
    </>
  )
}

export default SalesCard
