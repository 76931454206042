import { useEffect, useState } from "react"
import { Card, Row, Col, Button } from "react-bootstrap"
import { Envelope, PatchCheckFill } from "react-bootstrap-icons"
import ReactSwitch from "react-switch"
import UserLogs from "./UserLogs"
// import checkDashboard from "../../api/employeeApi"
import employeeApi from "../../api/employeeApi"
function UserCard({ employee, onDelete }) {
  const [logs, setLogs] = useState(false)
  const [dashboardChecked, setDashboardChecked] = useState(
    employee.dashboardAccess
  )
  const [salesChecked, setSalesChecked] = useState(employee.salesAccess)
  const [contactUsChecked, setContactUsChecked] = useState(
    employee.contactUsAccess
  )
  const [careersChecked, setCareersChecked] = useState(employee.careersAccess)
  const [feedbackChecked, setFeedbackChecked] = useState(
    employee.feedbackAccess
  )
  const [carsChecked, setCarsChecked] = useState(employee.carsAccess)
  const [securityChecked, setSecurityChecked] = useState(
    employee.securityAccess
  )
  const [sellersChecked, setSellersChecked] = useState(employee.sellersAccess)
  const [metricsChecked, setMetricsChecked] = useState(employee.metricsAccess)
  const [searchToolChecked, setSearchToolChecked] = useState(
    employee.searchToolAccess
  )
  const [loading, setLoading] = useState(false)
  const handleCheckDashboardButton = async () => {
    await employeeApi.checkDashboard(employee._id)
    setDashboardChecked(!dashboardChecked)
  }
  const handleCheckFeedbackButton = async () => {
    await employeeApi.checkFeedback(employee._id)
    setFeedbackChecked(!feedbackChecked)
  }

  const handleCheckContactUsButton = async () => {
    await employeeApi.checkContactUs(employee._id)
    setContactUsChecked(!contactUsChecked)
  }

  const handleCheckCarsButton = async () => {
    await employeeApi.checkCars(employee._id)
    setCarsChecked(!carsChecked)
  }

  const handleCheckCareersButton = async () => {
    await employeeApi.checkCareers(employee._id)
    setCareersChecked(!careersChecked)
  }
  const handleCheckSalesButton = async () => {
    await employeeApi.checkSales(employee._id)
    setSalesChecked(!salesChecked)
  }

  const handleCheckMetricsButton = async () => {
    await employeeApi.checkMetrics(employee._id)
    setMetricsChecked(!metricsChecked)
  }

  const handleCheckSecurityButton = async () => {
    await employeeApi.checkSecurity(employee._id)
    setSecurityChecked(!securityChecked)
  }

  const handleCheckSearchToolButton = async () => {
    await employeeApi.checkSearchTool(employee._id)
    setSearchToolChecked(!searchToolChecked)
  }

  const handleCheckSellerButton = async () => {
    await employeeApi.checkSellers(employee._id)
    setSellersChecked(!sellersChecked)
  }
  return (
    <>
      <Card className='mt-3 w-100'>
        <Card.Body>
          <Card.Title>
            {employee.fullName}{" "}
            {employee.verified && (
              <span className='text-primary'>
                <PatchCheckFill />
              </span>
            )}{" "}
          </Card.Title>
          <Card.Subtitle className='text-muted'>
            {employee.position}
          </Card.Subtitle>
          <h6 className='mt-3 mb-3 text-success'>Give Access To</h6>
          <Row>
            <Col md={4} xs={6}>
              <div>
                <h6>Dashboard</h6>
                <ReactSwitch
                  onChange={handleCheckDashboardButton}
                  checked={dashboardChecked}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Cars</h6>
                <ReactSwitch
                  checked={carsChecked}
                  onChange={handleCheckCarsButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Sales</h6>
                <ReactSwitch
                  checked={salesChecked}
                  onChange={handleCheckSalesButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Feedback</h6>
                <ReactSwitch
                  checked={feedbackChecked}
                  onChange={handleCheckFeedbackButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Careers</h6>
                <ReactSwitch
                  checked={careersChecked}
                  onChange={handleCheckCareersButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Contact Us</h6>
                <ReactSwitch
                  checked={contactUsChecked}
                  onChange={handleCheckContactUsButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Security</h6>
                <ReactSwitch
                  checked={securityChecked}
                  onChange={handleCheckSecurityButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Metrics</h6>
                <ReactSwitch
                  checked={metricsChecked}
                  onChange={handleCheckMetricsButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Search Tool</h6>
                <ReactSwitch
                  checked={searchToolChecked}
                  onChange={handleCheckSearchToolButton}
                />
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6>Sellers</h6>
                <ReactSwitch
                  checked={sellersChecked}
                  onChange={handleCheckSellerButton}
                />
              </div>
            </Col>
          </Row>
          <div className='d-flex justify-content-start align-items-center my-3 gap-2'>
            <Button disabled={true}>Edit</Button>
            <Button
              onClick={(e) => {
                e.preventDefault()
                onDelete(employee._id)
              }}
              disabled={loading}
              variant='danger'
            >
              {loading ? "Loading..." : "Remove"}
            </Button>
          </div>
          <Button
            onClick={() => setLogs(!logs)}
            variant='outline-primary'
            size='lg'
            className='w-100'
          >
            View Logs
          </Button>
          {logs && <UserLogs />}
        </Card.Body>
        <Card.Footer>
          <Envelope /> {employee.email}
        </Card.Footer>
        <Card.Footer>Created at {employee.createdAt}</Card.Footer>
      </Card>
    </>
  )
}

export default UserCard
