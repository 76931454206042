import { useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { Container } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Header from "../layouts/Header"
import CarList from "./CarList"
import * as notify from "../lib/notify"
import SearchBar from "../components/SearchBar/SearchBar"
import axios from "axios"
import { API_URLS } from "../constants/routes"
import Main from "../components/Main/Main"
function Cars() {
  const navigate = useNavigate()
  const { employee } = useSelector((state) => state.employee)
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.carsAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  useEffect(() => {
    authorizeEmployee()
  }, [])

  return (
    <>
      <Main>
        <Container>
          <Button
            className='mt-1'
            variant='primary'
            onClick={() => navigate("/cars/create")}
          >
            Create Car
          </Button>
        </Container>

        <div className='d-flex justify-content-center align-items-center flex-column p-2'>
          <CarList />
        </div>
      </Main>
    </>
  )
}

export default Cars
