import { useState } from "react"
import { useNavigate } from "react-router-dom"
import documentApi from "../api/documentApi"
import DocumentCard from "../components/Document/Card"
import Main from "../components/Main/Main"
import SearchBar from "../components/SearchBar/SearchBar"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
export default function Document() {
  const [loading, setLoading] = useState()
  const [documents, setDocuments] = useState([])
  const navigate = useNavigate()
  const getDocuments = async () => {
    try {
      setLoading(true)
      const documents = await documentApi.getDocuments()
      setDocuments(documents)
      console.log(documents)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const search = async (term) => {
    try {
      setLoading(true)
      const data = await documentApi.searchDocuments({ search: term })
      setDocuments(data.documents)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const [loadingCreatedDocument, setLoadingCreatedDocument] = useState(false)
  const createDocument = async () => {
    try {
      setLoadingCreatedDocument(true)
      const data = await documentApi.createDocument({})
      navigate(`/document/${data._id}`)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingCreatedDocument(false)
    }
  }

  return (
    <Main>
      <div className='container d-flex flex-column justify-content-center align-items-start gap-5'>
        <button
          onClick={createDocument}
          className='btn btn-success'
          disabled={loadingCreatedDocument}
        >
          {loadingCreatedDocument ? "Loading..." : "Create Document"}
        </button>

        <SearchBar
          onSearchSubmit={(term) => {
            search(term)
            // setSearchText(term)
          }}
          placeholder={"Search for blogs..."}
        />

        <div className='w-100'>
          <div className='row w-100'>
            {loading
              ? "Loading..."
              : documents.map((d, index) => {
                  return (
                    <div className='col-md-4 col-6 col-xs-12 gap-2'>
                      <DocumentCard d={d} getDocuments={getDocuments} />
                    </div>
                  )
                })}
          </div>
        </div>
      </div>
    </Main>
  )
}
