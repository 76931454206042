import { autopia_backend } from "../../lib/axios"
import { API_ROUTES } from "./routes"

const createMachinery = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.CREATE_MACHINERY, data)
  return res.data
}

const getMachinery = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.GET_MACHINARY, data)
  return res.data
}

const checkPrivate = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.CHECK_PRIVATE, data)
  return res.data
}

const deleteMachinery = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.DELETE_MACHINERY, data)
  return res.data
}

const editMachinery = async (data = {}) => {
  const res = await autopia_backend.post(API_ROUTES.POST.EDIT_MACHINERY, data)
  return res.data
}

const getMachineryById = async (data = {}) => {
  const res = await autopia_backend.post(
    API_ROUTES.POST.GET_MACHINERY_BY_ID,
    data
  )
  return res.data
}
const machineryApi = {
  createMachinery,
  editMachinery,
  deleteMachinery,
  getMachinery,
  checkPrivate,
  getMachineryById
}

export default machineryApi
