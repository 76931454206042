import useAsync from "./useAsync";
import sellerApi from "../api/sellerApi";
import * as notify from "../lib/notify";
import { errorMessage } from "../utils/errorMessage";
import {useEffect, useState} from "react";

export default function useSellers() {
  const [sellers, setSellers] = useState([]);

  const getSellers = useAsync({
    fn: sellerApi.getSellers,
    onSuccess: (result) => {
      setSellers(result);
    },
    onError: (error) => {
      notify.error(errorMessage(error));
    },
  });

  useEffect(() => {
    getSellers()
  }, []);

  return { sellers, loadingSellers: getSellers.loading };
}
