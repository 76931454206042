import axios from "axios"
import { API_URLS } from "../constants/routes"

const searchForms = async (data) => {
  const res = await axios.post(API_URLS.SEARCH_SELL_CAR, data)
  return res.data
}

const checkForm = async (data) => {
  const res = await axios.post(API_URLS.CHECK_SELL_CAR_FORM, data)
  return res.data
}

const sellCarApi = {
  searchForms,
  checkForm
}

export default sellCarApi
