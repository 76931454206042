import axios from "axios"
import { API_URLS } from "../constants/routes"

const createSeller = async (data) => {
  const res = await axios.post(API_URLS.CREATE_SELLER, data)
  return res.data
}
const getSellerCars = async (data) => {
  const res = await axios.post(API_URLS.GET_SELLER_CARS, data)
  return res.data
}

const getSellers = async () => {
  const res = await axios.post(API_URLS.GET_SELLERS)
  return res.data
}

const getSellerById = async (data) => {
  const res = await axios.post(API_URLS.GET_SELLER_BY_ID, data)
  return res.data
}
const editSeller = async (data) => {
  const res = await axios.post(API_URLS.EDIT_SELLER, data)
  return res.data
}

const deleteSeller = async (data) => {
  const res = await axios.post(API_URLS.DELETE_SELLER, data)
  return res.data
}
const sellerApi = {
  createSeller,
  getSellers,
  getSellerCars,
  deleteSeller,
  getSellerById,
  editSeller
}

export default sellerApi
