import { useState } from "react"
import { Card } from "react-bootstrap"
import { Upload } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import Choose from "../controlStatements/Choose"
import Otherwise from "../controlStatements/Otherwise"
import When from "../controlStatements/When"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import axios from "axios"
import { useEffect } from "react"
import documentApi from "../../api/documentApi"
export default function DocumentCard({ d, getDocuments }) {
  const navigate = useNavigate()
  const [inputImage, setInputImage] = useState()
  const [displayedImage, setDisplayedImage] = useState(null)

  const readImageUrl = (img) => {
    try {
      return URL.createObjectURL(img)
    } catch (error) {
      //   notify.error(errorMessage(error))
      console.log(error)
      notify.error(errorMessage(error))
    } finally {
    }
  }
  const [loading, setLoading] = useState()
  const handleDelete = async (id) => {
    try {
      setLoading(true)
      await documentApi.deleteDocument({ documentId: id })
    } catch (error) {
    } finally {
      setLoading(false)
      getDocuments()
    }
  }

  const [loadingImage, setLoadingImage] = useState(false)
  const createImage = async () => {
    try {
      setLoadingImage(true)
      await documentApi.uploadImage({ documentId: d._id, image: inputImage })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      getDocuments()
      setLoadingImage(false)
    }
  }
  const [uploadImage, setUploadImage] = useState(true)
  const [showImage, setShowImage] = useState(false)
  return (
    <Card style={{ width: "100%", maxWidth: "300px" }} className='my-2'>
      <Card.Body>
        <Card.Title>
          <h5 className='h6'>{d.title ? d.title : "Untitled"}</h5>
        </Card.Title>
        <button
          className='btn btn-primary btn-sm mr-2'
          onClick={() => navigate(`/document/${d._id}`)}
        >
          Edit
        </button>
        <button
          disabled={loading}
          onClick={() => handleDelete(d._id)}
          className='btn btn-danger btn-sm mx-1'
        >
          {loading ? "Loading" : "Delete"}
        </button>
        <button
          onClick={() => setShowImage(!showImage)}
          className='btn btn-outline-danger mt-3'
        >
          {showImage ? "Hide Thumbnail Image" : "Show Thumbnail Image"}
        </button>
        {showImage && (
          <>
            <div className='mt-3'>
              <div>
                <h6>Uploaded Thumbnail Image</h6>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    setUploadImage(!uploadImage)
                  }}
                >
                  {!uploadImage ? "Cancel" : "Upload Image"}
                </button>
              </div>
            </div>

            <div></div>

            {uploadImage ? (
              d?.image?.url ? (
                <img
                  src={d?.image?.url}
                  style={{
                    // height: "300px",
                    width: "100%",
                    objectFit: "contain"
                  }}
                  alt=''
                />
              ) : (
                <>No Uploaded Thumbnail</>
              )
            ) : (
              <Choose>
                <When condition={displayedImage === null}>
                  <div
                    className='d-flex  justify-content-center align-items-center '
                    style={{ height: "200px" }}
                  >
                    <label htmlFor='image' className='cursor-pointer'>
                      <div className='d-flex flex-column justify-content-center align-items-center gap-1 '>
                        <Upload size={"30px"} />
                        <h6>Add Image</h6>
                      </div>
                      <input
                        onChange={(e) => {
                          setInputImage(e.target.files[0])
                          setDisplayedImage(readImageUrl(e.target.files[0]))
                          // console.log(readImageUrl(e.target.files[0]))
                        }}
                        // onChange={(e) => {
                        //   handleCompressedUpload(e).then((data) => {})
                        //   setDisplayedImages([...displayedImages, ...e.target.files])
                        // }}
                        className='d-none'
                        id='image'
                        type='file'
                      />
                    </label>
                  </div>
                </When>
                <Otherwise>
                  <>
                    <img
                      src={displayedImage}
                      style={{
                        // height: "300px",
                        width: "100%",
                        objectFit: "contain"
                      }}
                      alt=''
                    />
                    <div className='flex items-center justify-center  mb-3'>
                      <button
                        onClick={() => {
                          setDisplayedImage("")
                          setInputImage("")
                        }}
                        className='btn btn-danger btn-sm mr-2'
                      >
                        X
                      </button>
                      <button
                        onClick={() => {
                          createImage()
                        }}
                        disabled={loadingImage}
                        className='btn btn-primary btn-sm '
                      >
                        {loadingImage ? "Loading..." : "Upload"}
                      </button>
                    </div>
                  </>
                </Otherwise>
              </Choose>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}
