import { useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import carApi from "../../../api/carApi"
import { errorMessage } from "../../../utils/errorMessage"
// import { Form } from "react-router-dom"
import * as notify from "../../../lib/notify"

export default function PriorityModal({
  handleClosePriorityModal,
  showPriorityModal,
  carId
}) {
  const [featuredPriority, setFeaturedPriority] = useState(0)
  const [latestPriority, setLatestPriority] = useState(0)
  const [promotedPriority, setPromotedPriority] = useState(0)
  const [rentPromotedPriority, setRentPromotedPriority] = useState(0)
  const [rentFeaturedPriority, setRentFeaturedPriority] = useState(0)
  const [loadingCarPriority, setLoadingCarPriority] = useState(false)
  const getCarPriority = async () => {
    try {
      setLoadingCarPriority(true)
      const data = await carApi.getCarPriority({ carId })
      setFeaturedPriority(data.featuredPriority)
      setLatestPriority(data.latestPriority)
      setPromotedPriority(data.promotedPriority)
      setRentFeaturedPriority(data.rentFeaturedPriority)
      setRentPromotedPriority(data.rentPromotedPriority)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingCarPriority(false)
    }
  }

  const [loadingFeaturedPriority, setLoadingFeaturedPriority] = useState(false)
  const setCarFeaturedPriority = async () => {
    try {
      setLoadingFeaturedPriority(true)
      await carApi.setCarFeaturedPriority({ carId, priority: featuredPriority })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingFeaturedPriority(false)
      getCarPriority()
    }
  }

  const [loadingLatestPriority, setLoadingLatestPriority] = useState(false)
  const setCarLatestPriority = async () => {
    try {
      setLoadingLatestPriority(true)
      await carApi.setCarLatestPriority({ carId, priority: latestPriority })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingLatestPriority(false)
      getCarPriority()
    }
  }
  const [loadingPromotedPriority, setLoadingPromotedPriority] = useState(false)
  const setCarPromotedPriority = async () => {
    try {
      setLoadingPromotedPriority(true)
      await carApi.setCarPromotedPriority({ carId, priority: promotedPriority })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingPromotedPriority(false)
      getCarPriority()
    }
  }

  const [loadingRentPromotedPriority, setLoadingRentPromotedPriority] =
    useState(false)
  const setCarRentPromotedPriority = async () => {
    try {
      setLoadingRentPromotedPriority(true)
      await carApi.setCarRentPromotedPriority({
        carId,
        priority: rentPromotedPriority
      })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingRentPromotedPriority(false)
      getCarPriority()
    }
  }
  const [loadingRentFeaturedPriority, setLoadingRentFeaturedPriority] =
    useState(false)
  const setCarRentFeaturedPriority = async () => {
    try {
      setLoadingRentFeaturedPriority(true)
      await carApi.setCarRentFeaturedPriority({
        carId,
        priority: rentFeaturedPriority
      })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingRentFeaturedPriority(false)
      getCarPriority()
    }
  }

  useEffect(() => {
    if (showPriorityModal) getCarPriority()
  }, [showPriorityModal])
  return (
    <Modal show={showPriorityModal} onHide={handleClosePriorityModal}>
      <Modal.Body>
        <Row className='gap-3'>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  <span className='fw-bold'>Enter Featured Priority: </span>
                </Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  disabled={loadingCarPriority}
                  placeholder='Enter featured priority...'
                  type='number'
                  value={featuredPriority}
                  onChange={(e) => setFeaturedPriority(e.target.value)}
                ></Form.Control>
              </Col>
              <Col xs={4}>
                <Button
                  disabled={loadingCarPriority || loadingFeaturedPriority}
                  variant='success'
                  onClick={() => {
                    // handleClosePriorityModal()
                    // setLoadingFeaturedPriority()
                    setCarFeaturedPriority()
                  }}
                >
                  {loadingFeaturedPriority || loadingCarPriority
                    ? "Loading..."
                    : "Submit"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  <span className='fw-bold'>Enter Latest Priority: </span>
                </Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  disabled={loadingCarPriority || loadingLatestPriority}
                  placeholder='Enter latest priority...'
                  type='number'
                  value={latestPriority}
                  onChange={(e) => setLatestPriority(e.target.value)}
                ></Form.Control>
              </Col>
              <Col xs={4}>
                <Button
                  disabled={loadingCarPriority}
                  variant='success'
                  onClick={() => {
                    // handleClosePriorityModal()
                    setCarLatestPriority()
                  }}
                >
                  {loadingLatestPriority || loadingCarPriority
                    ? "Loading..."
                    : "Submit"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  <span className='fw-bold'>Enter Promoted Priority: </span>
                </Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  disabled={loadingCarPriority || loadingPromotedPriority}
                  placeholder='Enter promoted priority...'
                  type='number'
                  value={promotedPriority}
                  onChange={(e) => setPromotedPriority(e.target.value)}
                  //   value={newPrice}
                  //   onChange={(e) => setNewPrice(e.target.value)}
                ></Form.Control>
              </Col>
              <Col xs={4}>
                <Button
                  variant='success'
                  disabled={loadingCarPriority}
                  onClick={() => {
                    // handleClosePriorityModal()
                    setCarPromotedPriority()
                  }}
                >
                  {loadingPromotedPriority || loadingCarPriority
                    ? "Loading..."
                    : "Submit"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  <span className='fw-bold'>
                    Enter Rent Promoted Priority:{" "}
                  </span>
                </Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  disabled={loadingCarPriority || loadingRentPromotedPriority}
                  placeholder='Enter rent promoted priority...'
                  type='number'
                  value={rentPromotedPriority}
                  onChange={(e) => setRentPromotedPriority(e.target.value)}
                  //   value={newPrice}
                  //   onChange={(e) => setNewPrice(e.target.value)}
                ></Form.Control>
              </Col>
              <Col xs={4}>
                <Button
                  variant='success'
                  disabled={loadingCarPriority || loadingRentPromotedPriority}
                  onClick={() => {
                    // handleClosePriorityModal()
                    setCarRentPromotedPriority()
                  }}
                >
                  {loadingRentPromotedPriority || loadingCarPriority
                    ? "Loading..."
                    : "Submit"}
                </Button>
              </Col>
            </Row>
          </Col>

          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <Form.Label>
                  <span className='fw-bold'>
                    Enter Rent Featured Priority:{" "}
                  </span>
                </Form.Label>
              </Col>
              <Col xs={8}>
                <Form.Control
                  disabled={loadingCarPriority || loadingRentFeaturedPriority}
                  placeholder='Enter rent featured priority...'
                  type='number'
                  value={rentFeaturedPriority}
                  onChange={(e) => setRentFeaturedPriority(e.target.value)}
                  //   value={newPrice}
                  //   onChange={(e) => setNewPrice(e.target.value)}
                ></Form.Control>
              </Col>
              <Col xs={4}>
                <Button
                  variant='success'
                  disabled={loadingCarPriority || loadingRentFeaturedPriority}
                  onClick={() => {
                    setCarRentFeaturedPriority()
                  }}
                >
                  {loadingRentFeaturedPriority || loadingCarPriority
                    ? "Loading..."
                    : "Submit"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            handleClosePriorityModal()
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
