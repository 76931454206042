import { useForm } from "react-hook-form";
import Main from "../../components/Main/Main";
import { Col, Form, Row } from "react-bootstrap";
import partApi from "../../api/part";
import * as notify from "../../lib/notify";
import { errorMessage } from "../../utils/errorMessage";
import {useEffect, useState} from "react";
import {useNavigate, useNavigation} from "react-router-dom";
import ImageUploader from "../../components/SharedComponents/ImageUploader";
import ReactSelect from "react-select";
import useSellers from "../../hooks/useSellers";
import useAsync from "../../hooks/useAsync";

export default function CreatePart() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm({ mode: "onChange", defaultValues: {} });

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { sellers } = useSellers();
  const [seller, setSeller] = useState(null);

  const variables = [
    {
      label: "Name",
      value: "name",
      validation: { required: "Name is required" },
    },
    {
      label: "Base Price ($)",
      value: "base_price",
      validation: {
        required: "Price is required",
        valueAsNumber: true,
        validate: {
          isNumber: v => !isNaN(v) || "Price must be a number",
          positive: v => parseFloat(v) > 0 || "Price must be greater than zero",

        }
      },
    },
    {
      label: "Discount Price ($)",
      value: "discount_price",
      validation: {
        valueAsNumber: true,
        validate: {
          positive: v => parseFloat(v) > 0 || "Price must be greater than zero",
          isNumber: v => !isNaN(v) || "Price must be a number",
        }
      },
    },
    {
      label: "Description",
      value: "description",
      as: "textarea",
      rows: 5,
      validation: { required: "Description is required" },
    },
  ];

  const createPart = useAsync({
    fn: partApi.createPart,
    onSuccess: (data = {}) => {
      navigate(-1)
      notify.success("Part created successfully");
    },
    onError: (error) => {
      notify.error(errorMessage(error));
    },
  });


  const onSubmit = (data) => {
    if (isValid) {
      createPart({ ...data, images, seller: seller?.value });
    } else {
      Object.keys(errors).forEach((key) =>
        setError(key, { type: "manual", message: errors[key]?.message }),
      );
    }
  };

  return (
    <Main>
      <div className="p-3">
        <Row>
          {variables.map(({ label, value, as, rows, validation }) => (
            <Col
              key={value}
              lg={as === "textarea" ? 12 : 4}
              md={as === "textarea" ? 12 : 6}
              sm={12}
            >
              <Form.Group className="m-2">
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  {...register(value, validation)}
                  as={as || "input"}
                  rows={rows || undefined}
                  placeholder={label}
                  isInvalid={!!errors[value]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[value]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          ))}

          <Col
            md={6}
            style={{
              zIndex: 5,
            }}
          >
            <Form.Group className="m-2">
              <Form.Label>Seller</Form.Label>
              <ReactSelect
                value={seller}
                onChange={(value) => setSeller(value)}
                className="mb-5"
                placeholder="Select Seller"
                options={sellers.map(({ _id, firstName, lastName }) => ({
                  value: _id,
                  label: `${firstName} ${lastName}`,
                }))}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <ImageUploader
              header={"Images"}
              title={""}
              subTitle={""}
              files={images}
              setFiles={setImages}
            />
          </Col>

          <Col md={12}>
            <button
              onClick={handleSubmit(onSubmit)}
              className="mt-2 btn btn-primary"
              disabled={loading}
            >
              {loading ? "Creating Part..." : "Create Part"}
            </button>
          </Col>
        </Row>
      </div>
    </Main>
  );
}
