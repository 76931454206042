import { Container, Table } from "react-bootstrap"
import Main from "../components/Main/Main"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import performanceApi from "../api/performance"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"
import { useEffect } from "react"
export default function PathPerformanceInfo() {
  const [info, setInfo] = useState([])
  const { path } = useParams()

  const [loadingPath, setLoadingPath] = useState(false)
  const getPathInfo = async () => {
    try {
      setLoadingPath(true)
      const data = await performanceApi.getPerformancePathInfo({
        path: path.replaceAll("-", "/")
      })
      setInfo(data)
      //   alert(JSON.stringify(data))
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingPath(false)
    }
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (path) getPathInfo()
  }, [path])
  return (
    <Main>
      <Container>
        <button
          onClick={() => {
            navigate("/performance")
          }}
          className='btn btn-primary'
        >
          Back
        </button>
        <div style={{ overflow: "auto" }}>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th>#</th>
                <th>Region</th>
                <th>Average Response Time</th>
                <th>Total Requests</th>
                <th>Total Mobile Requests</th>
                <th>Total Desktop Requests</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(info).map((region, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{region}</td>
                    <td>
                      {info[region].totalResponseTime /
                        info[region].totalRequestCount}
                    </td>
                    <td>{info[region].totalRequestCount}</td>
                    <td>{info[region].mobileRequestCount}</td>

                    <td>
                      {info[region].totalRequestCount -
                        info[region].mobileRequestCount}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </Main>
  )
}
