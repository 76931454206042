// import Header from "../layouts/Navbar";

import { Container } from "react-bootstrap"
import Main from "../components/Main/Main"

function Home() {
  return (
    <>
      <Main>
        <Container>
          <h1>
            Welcome to Autopia <span className='text-primary'>Admin</span>
          </h1>
        </Container>
      </Main>
    </>
  )
}

export default Home
