import { useEffect, useState } from "react"
import { Button, Container, Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import employeeApi from "../api/employeeApi"
import * as notify from "../lib/notify"
import { errorMessage } from "../utils/errorMessage"

function UsersSetInfo() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [fullName, setFullName] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const { id } = useParams()

  const formValidated = fullName && password && confirmPassword
  const passwordValidated = password === confirmPassword

  const handleSubmitButton = async (e) => {
    if (!formValidated) {
      notify.error("Please fill all inputs")
    } else if (!passwordValidated) {
      notify.error("Passwords doesn't match")
    } else {
      try {
        setLoading(true)
        await employeeApi.setInfoEmployee({ fullName, password, id })
        navigate("/login")
        notify.success("Account Created Successfully")
      } catch (error) {
        notify.error(errorMessage(error))
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {}, [])

  return (
    <>
      {/* <Header/> */}

      <Container>
        <h1 className='mt-5'>Set Up Your Account</h1>
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder={"Full Name"}
              type={"text"}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Password </Form.Label>
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={"Password"}
              type={"password"}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={"Confirm Password"}
              type={"password"}
            />
          </Form.Group>
          <Button onClick={handleSubmitButton} disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </Button>
        </Form>
      </Container>
    </>
  )
}

export default UsersSetInfo
