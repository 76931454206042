import { Button } from "react-bootstrap";

function UserLogs(){
    return (
        <>
          <p className="mt-3">Coming soon...</p>
         {/* <Button>Load Previous Logs</Button> */}
        </>
    )
}

export default UserLogs;