import { Envelope, Telephone, Alarm } from "react-bootstrap-icons"
import { Card, ListGroup } from "react-bootstrap"
import ReactSwitch from "react-switch"
import { useState } from "react"
import { errorMessage } from "../../utils/errorMessage"
import * as notify from "../../lib/notify"
import Date from "../Date/Date"
import contactUsApi from "../../api/contactUsApi"
function ContactUsCard({ form }) {
  const [checked, setChecked] = useState(form.checked)
  const [loading, setLoading] = useState(false)

  const checkForm = async () => {
    try {
      setLoading(true)
      await contactUsApi.checkForm({ contactUsFormId: form._id })
      setChecked(!checked)
      notify.success("Form Checked Successfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  const handleCheckButton = async () => {
    await checkForm()
    setChecked(!checked)
  }

  return (
    <>
      <Card className='mt-3 w-100'>
        <Card.Body>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <Card.Title>Subject {form.subject}</Card.Title>
              <Card.Subtitle className='text-muted mb-3'>
                {form.firstName + " " + form.lastName}
              </Card.Subtitle>
            </div>
            <div className='d-flex justify-content-center align-items-center flex-column'>
              <h6>Checked</h6>
              {loading ? (
                "Loading..."
              ) : (
                <ReactSwitch checked={checked} onChange={handleCheckButton} />
              )}
            </div>
          </div>

          <Card.Text>{form.message}</Card.Text>
        </Card.Body>
        <ListGroup className='list-group-flush'>
          <ListGroup.Item>
            {" "}
            <Envelope /> {form.email}{" "}
          </ListGroup.Item>
          <ListGroup.Item>
            <Telephone /> {form.phone}{" "}
          </ListGroup.Item>
          <ListGroup.Item>
            {" "}
            <Alarm /> <Date time={form.createdAt} />
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </>
  )
}

export default ContactUsCard
