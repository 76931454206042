import {
  Button,
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  NavLink,
  Modal,
  Form
} from "react-bootstrap"
import Header from "../layouts/Header"
import ReactSwitch from "react-switch"
import { Envelope } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import * as notify from "../lib/notify"
import { useEffect, useState } from "react"
import jobApi from "../api/jobApi"
import { errorMessage } from "../utils/errorMessage"
import Choose from "../components/controlStatements/Choose"
import Otherwise from "../components/controlStatements/Otherwise"
import When from "../components/controlStatements/When"
import Main from "../components/Main/Main"

function Careers() {
  const { employee } = useSelector((state) => state.employee)
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const handleEditModalClose = () => setShowEditModal(false)
  const handleEditModalShow = () => setShowEditModal(true)

  const navigate = useNavigate()
  const authorizeEmployee = () => {
    if (!employee) {
      navigate("/login")
      notify.error("You should be first logged in!")
    }
    if (employee && !employee.careersAccess) {
      navigate("/home")
      notify.error("Not Authorized")
    }
  }

  const getCareers = async () => {
    try {
      setLoading(true)
      const data = await jobApi.getJobs()
      setJobs(data)
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoading(false)
      console.log(jobs)
    }
  }

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [jobLink, setJobLink] = useState()
  const [jobId, setJobId] = useState()
  const initializeJobFormInputs = (job) => {
    setName(job.name)
    setJobLink(job.jobLink)
    setDescription(job.description)
    setJobId(job._id)
  }
  const [loadingEditingJob, setLoadingEditingJob] = useState(false)
  const editJob = async () => {
    try {
      setLoadingEditingJob(true)
      await jobApi.updateJob({ name, description, jobLink, jobId })
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingEditingJob(false)
      handleEditModalClose()
      getCareers()
    }
  }

  const [loadingDeleteCareer, setLoadingDeleteCareer] = useState()
  const deleteCareer = async (id) => {
    try {
      setLoadingDeleteCareer(true)
      setJobId(id)
      await jobApi.deleteJob({ id })
      notify.success("Job deleted succesfully")
    } catch (error) {
      notify.error(errorMessage(error))
    } finally {
      setLoadingDeleteCareer(false)
      getCareers()
    }
  }

  useEffect(() => {
    authorizeEmployee()
    getCareers()
  }, [])
  return (
    <>
      <Main>
        <Container>
          {/* <h1 className='text-primary text-center'>Careers</h1> */}
          {loading ? (
            <h2 class='text-center'>Loading careers...</h2>
          ) : (
            <>
              <Button
                variant='primary'
                onClick={() => navigate("/careers/create")}
              >
                Add Job
              </Button>
              <Row>
                <Col xs={12}>
                  {jobs.map((j) => (
                    <Card className='mt-3'>
                      <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <Card.Title>{j.name}</Card.Title>
                            <Card.Subtitle className='text-muted mb-3'>
                              Last edit at 10/9/2022 7:27pm
                            </Card.Subtitle>
                          </div>

                          <div className='d-flex justify-content-center align-items-center flex-column'>
                            <h6>Public</h6>
                            <ReactSwitch />
                          </div>
                        </div>
                        <p>
                          {j.description}
                          {/* At Autopia, we believe in the power of remote work, and we consider it the future of the digital business world. */}
                        </p>
                        <div>
                          <Button
                            onClick={(e) => {
                              e.preventDefault()
                              initializeJobFormInputs(j)
                              handleEditModalShow()
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className='ms-2'
                            variant='danger'
                            onClick={(e) => {
                              e.preventDefault()
                              deleteCareer(j._id)
                            }}
                            disabled={loadingDeleteCareer && jobId === j._id}
                          >
                            <Choose>
                              <When
                                condition={
                                  loadingDeleteCareer && jobId === j._id
                                }
                              >
                                Loading...
                              </When>
                              <Otherwise>Delete</Otherwise>
                            </Choose>
                          </Button>
                        </div>
                      </div>

                      <Card.Footer className='d-flex justify-content-start align-items-center gap-2'>
                        <NavLink href={j.jobLink} className='text-primary'>
                          Job Link
                        </NavLink>{" "}
                        {j.jobLink}
                      </Card.Footer>
                    </Card>
                  ))}
                </Col>
              </Row>
              <Modal
                show={showEditModal}
                onHide={handleEditModalShow}
                backdrop='static'
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className='mb-2'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Name'
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control
                      value={jobLink}
                      onChange={(e) => setJobLink(e.target.value)}
                      placeholder='Last Name'
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder='Phone'
                    ></Form.Control>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleEditModalClose}>
                    Close
                  </Button>
                  <Button
                    className='btn-success'
                    onClick={(e) => {
                      e.preventDefault()
                      editJob()
                    }}
                    disabled={loadingEditingJob}
                  >
                    <Choose>
                      <When condition={loadingEditingJob}>Loading...</When>
                      <Otherwise>Edit Job</Otherwise>
                    </Choose>
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Container>
      </Main>
    </>
  )
}

export default Careers
