import { useNavigate } from "react-router-dom"
import Main from "../../components/Main/Main"
import machineryApi from "../../api/machinery"
import { useEffect, useState } from "react"
import * as notify from "../../lib/notify"
import { errorMessage } from "../../utils/errorMessage"
import MachinerCard from "../../components/Machinery/Card"
import { Col, Row } from "react-bootstrap"
export default function Machinery() {
  const navigate = useNavigate()

  const [machineries, setMachineries] = useState([])
  const getMachinery = async () => {
    try {
      const data = await machineryApi.getMachinery()
      setMachineries(data)
    } catch (error) {
      notify.error(errorMessage(error))
    }
  }
  useEffect(() => {
    getMachinery()
  }, [])
  return (
    <Main>
      <div className='container'>
        <h1>Machinery</h1>
        <button
          className='btn btn-primary'
          onClick={() => {
            navigate("/machinery/create")
          }}
        >
          Create Machinery
        </button>
        <Row>
          {machineries.map((machinery) => {
            return (
              <Col md={6} sm={12} className='m-2'>
                <MachinerCard machinery={machinery}></MachinerCard>
              </Col>
            )
          })}
        </Row>
      </div>
    </Main>
  )
}
